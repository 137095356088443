import React, { useState } from 'react'
import CommonTableWithBorder from '../CommonTable/CommonTableWithBorder'
import { useSelector } from 'react-redux';
import { Option } from 'antd/es/mentions';
import { Select, Tag } from 'antd';
import { todayVariationsHindi, todayVariationstamil, todayVariationsTelugu, tomorrowVariationsHindi, tomorrowVariationstamil, tomorrowVariationsTelugu, toss } from '../CheckContentForDay/variationsConstant';

export const ExpiryKeywords = () => {
    const languages = useSelector((state) => state.app.languageData);
    const [language, setLanguage] = useState(languages?.[0]?.language_id);
    const [data,setData] = useState({
        2:[{expiry_type:"Today",key_words:todayVariationsTelugu},{expiry_type:"Tomorrow",key_words:tomorrowVariationsTelugu},{expiry_type:"1 Hour",key_words:toss?.slice(0,2)}],
        3:[{expiry_type:"Today",key_words:todayVariationsHindi},{expiry_type:"Tomorrow",key_words:tomorrowVariationsHindi},{expiry_type:"1 Hour",key_words:toss?.slice(2,3)}],
        4:[{expiry_type:"Today",key_words:todayVariationstamil},{expiry_type:"Tomorrow",key_words:tomorrowVariationstamil},{expiry_type:"1 Hour",key_words:[toss?.[toss?.length-1]]}]
    })
    
    const column = [
        {
            title: 'Expiry Time',
            render: (_, record) => (
                <div>
                    {record?.expiry_type}
                </div>
            ),
        },
        {
            title: 'Keywords',
            dataIndex: 'key_words',
            render: (_, record) => (
                <div>
                    {record?.key_words?.map((keyword) => (
                        <Tag
                            key={keyword}
                            color="blue"
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            {keyword}
                        </Tag>
                    ))}
                </div>
            ),
        },
    ]
  return (
    <div>
    <div style={{ display: 'flex', marginBottom: '1rem' }}>
        <Select
            onChange={(value) => setLanguage(value)}
            value={language}
            placeholder="Select language"
            className="language-select"
        >
            {languages?.map((e) => (
                <Option value={e.language_id} key={e.language_id}>
                    {e.language_name}
                </Option>
            ))}
        </Select>
    </div>
    <CommonTableWithBorder
        className={'no-padding-table'}
        data={data?.[language]}
        columns={column}
        disablePagination={true}
    />
    </div>

  )
}
