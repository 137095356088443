import { message, Button, Modal, Select, Popconfirm } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { getElectionData, postElectionData, getAssemblyConstituency, deleteAssemblyConstituency, deleteCandidate } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import AddAssemblyForm from './AddAssemblyForm'
import AddCandidateForm from './AddCandidateForm'

const AssemblyConstituency = () => {
    const [electionsData, setElectionsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [selectedStateKey, setSelectedStateKey] = useState(null);
    const [parties, setParties] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState(null)
    const [selectedAssembly, setSelectedAssembly] = useState(null)
    const [showAssemblyModal, setShowAssemblyModal] = useState(false);
    const [selectedLocation, setSelectionLocation] = useState('');
    const [assemblyData, setAssemblyData] = useState([])
    const [selectedAssemblyKey, setSelectedAssemblyKey] = useState(null);
    const [candidatesData, setCandidatesData] = useState([]);
    const [showCandidateModal, setShowCandidateModal] = useState(false)

    const openModal = (record, loc) => {
        setSelectionLocation(loc)
        setShowAssemblyModal(true)
        setSelectedAssembly(record)
    }

    const openCandidateModal = (record, loc) => {
        setSelectionLocation(loc)
        setShowCandidateModal(true)
        setSelectedCandidate(record)
    }

    const assemblyTableColumns = [
        {
            title: (
                <div className='location-align'>
                    Assembly
                    <div
                        className='add-btn'
                        onClick={() => openModal('', 'assembly')}
                    >
                        Add New
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                        }}>
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                        <div>
                        <EditOutlined
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    openModal(record, 'assembly')
                                }}
                            />
                            <Popconfirm
                                title="Delete Row"
                                description="Are you sure to delete this row?"
                                onConfirm={() => deleteRow(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                />
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        }
    ]

    const candidateColumns = [
        {
            title: (
                <div className='location-align'>
                    Candidates
                    {selectedAssemblyKey &&
                        <div
                            className='add-btn'
                            onClick={() => openCandidateModal('', 'Candidate')}
                        >
                            Add New
                        </div>
                    }
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                        }}>
                            <img
                                style={{ width: '3rem' }}
                                src={record?.party_logo}
                                alt="logo"
                            />
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                        <div>
                            <EditOutlined
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    openCandidateModal(record, 'Candidate')
                                }}
                            />
                            <Popconfirm
                                title="Delete Row"
                                description="Are you sure to delete this row?"
                                onConfirm={() => deleteCandidateRow(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                />
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        }
    ]

    useEffect(() => {
        getElectionInfo()
    }, [])

    useEffect(() => {
        if (selectedStateKey) {
            getAssemblyConstituencyData()
        }
    }, [selectedStateKey])

    const getAssemblyConstituencyData = async () => {
        setDataLoading(true)
        try {
            const response = await getAssemblyConstituency("assembly", selectedStateKey, accessToken)
            setAssemblyData(response?.data?.constituencies)
            if (selectedAssemblyKey) {
                let candidateData = response?.data?.constituencies?.filter((ele, index) => ele.id == selectedAssemblyKey)?.[0]
                setCandidatesData(candidateData?.candidates)
            }
            setDataLoading(false)
        } catch (err) {
            setAssemblyData([])
            setCandidatesData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const getElectionInfo = async () => {
        setDataLoading(true)
        try {
            const response = await getElectionData("assembly", accessToken)
            setElectionsData(response?.data?.states)
            setSelectedStateKey(response.data?.states[0]?.name)
            if (selectedStateKey || response.data?.states[0]?.name) {
                const filteredState = response.data?.states?.find((ele, i) => ele.name == selectedStateKey || response.data?.states[0]?.name)
                setParties(filteredState?.parties)
            }
            setDataLoading(false)
        } catch (err) {
            setElectionsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const assemblyClassName = (record) => {
        return record.id === selectedAssemblyKey ? 'selected-row' : '';
    };

    const onAssemblySelectRow = (record) => {
        setSelectedAssemblyKey(record.id);
        let candidateData = assemblyData?.filter((ele, index) => ele.id == record.id)[0]
        setCandidatesData(candidateData?.candidates)
    };

    const deleteRow = async (record) => {
        await deleteAssemblyConstituency(record.id, "assembly", selectedStateKey, accessToken)
        getAssemblyConstituencyData()
    }

    const deleteCandidateRow = async (record) => {
        await deleteCandidate(record.id, selectedAssemblyKey, selectedStateKey, "assembly", accessToken)
        getAssemblyConstituencyData()
    }

    const candidateModal = (
        <Modal
            open={showCandidateModal}
            title={selectedCandidate ? 'Edit Candidate' : 'Add Candidate'}
            onCancel={() => setShowCandidateModal(false)}
            footer={[
                <Button
                    form="candidateForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Add
                </Button>,
            ]}
        >
            <AddCandidateForm
                electionsData={electionsData}
                selectedLocation={selectedLocation}
                selectedStateKey={selectedStateKey}
                selectedCandidate={selectedCandidate}
                getTableData={getAssemblyConstituencyData}
                setShowModal={setShowCandidateModal}
                accessToken={accessToken}
                parties={parties}
                selectedAssemblyKey={selectedAssemblyKey}
            />
        </Modal>
    )

    const assemblyModal = (
        <Modal
            open={showAssemblyModal}
            title="Add New"
            onCancel={() => setShowAssemblyModal(false)}
            footer={[
                <Button
                    form="assemblyForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Add
                </Button>,
            ]}
        >
            <AddAssemblyForm
                electionsData={electionsData}
                selectedLocation={selectedLocation}
                selectedStateKey={selectedStateKey}
                selectedAssembly={selectedAssembly}
                getTableData={getAssemblyConstituencyData}
                setShowModal={setShowAssemblyModal}
                accessToken={accessToken}
            />
        </Modal>
    )

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Constituency Master</h2>
            </div>
            <div className="manage-content-inputs">
                <Select
                    value={selectedStateKey}
                    style={{ width: 200 }}
                    onChange={(val) => setSelectedStateKey(val)}
                >
                    {electionsData.map((option, index) => {
                        return (
                            <Option key={option?.name} value={option?.name}>
                                {option?.name}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <div className="table-list">
                <CommonTable
                    columns={assemblyTableColumns}
                    data={assemblyData}
                    dataLoading={dataLoading}
                    rowClassName={assemblyClassName}
                    disablePagination={true}
                    onRow={(record) => ({
                        onClick: () => onAssemblySelectRow(record),
                    })}
                />
                <CommonTable
                    columns={candidateColumns}
                    data={candidatesData}
                    dataLoading={dataLoading}
                    disablePagination={true}
                />
            </div>
            {selectedLocation == 'assembly' ?
                assemblyModal
                : selectedLocation == 'Candidate' &&
                candidateModal
            }
        </div>
    )
}

export default AssemblyConstituency;