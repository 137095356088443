import Sanscript from '@indic-transliteration/sanscript';

export const TransliterateComponent = (text, sourceLanguage, targetScheme = 'iso') => {
    // Transliterate the input text from sourceLanguage to targetScheme
    const transliteratedText = Sanscript?.t(text, sourceLanguage, targetScheme);
    // Normalize the transliterated text and remove diacritics
    const normalizedText = transliteratedText?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "");
    return normalizedText;
};

export default TransliterateComponent;
