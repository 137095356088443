import { Form, Input } from 'antd'
import { useEffect } from 'react'
import { postElectionData } from '../../AadhanApi'

const AddSourceForm = ({
    selectedRole, 
    getTableData, 
    setShowModal,
    selectedStateKey,
    electionsData,
    accessToken
}) => {
    const [form] = Form.useForm()
    const onFinish = async (values) => {
        let filteredData = electionsData;
        let stateIndex = filteredData.findIndex(state => state.name === selectedStateKey);
        let newSourceData = {
            "name": values.name,
        }
        if (stateIndex !== -1) {
            // Add the new party data to the parties array
            filteredData[stateIndex].media_sources.push(newSourceData);
        }
        let obj = {
            "election_type": "assembly",
            "states": [
                ...filteredData
            ]
        }
        if (selectedRole) {
            // await updateRole("obj", accessToken, selectedRole.id)
        } else {
            await postElectionData(obj, accessToken)
        }
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedRole) {
            form.setFieldsValue({
                name: selectedRole?.role,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRole])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Form
            id="sourceForm"
            form={form}
            name="sourceForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 5 }}
                label="Source Name"
                name="name"
                rules={[{ required: true, message: 'Please enter name' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    )
}

export default AddSourceForm;
