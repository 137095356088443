import React, { useEffect, useState } from 'react'
import {
    languageContent,
    TEXT_START_X,
    TEXT_END_X,
    LINE_HEIGHT,
    TIME_BASED_INDICES,
    TEXT_BASED_INDICES,
    FIXED_GAP_BETWEEN_ENTRIES,
} from './Constants'
import PanchangamTemplate from '../../assets/Panchangam/Astrology BG 01.jpg'
import { dayNamesData } from '../Daily Horoscope/LanguageSpecificDays'
import { monthsData } from '../Daily Horoscope/LanguageSpecificMonths.js'

function PanchangamCanvas({
    selectedLanguage,
    selectedMonth,
    selectedDay,
    inputValues,
}) {
    const [canvasRef, setCanvasRef] = useState(null)
    const [ctx, setCtx] = useState(null)
    const [fontsLoaded, setFontsLoaded] = useState(false)

    useEffect(() => {
        const initializeCanvas = async () => {
            try {
                const fontFamilies = Object.values(languageContent)
                    .map((content) => [
                        `${content.fontProperties.heading.fontSize} ${content.fontProperties.heading.fontFamily}`,
                        `${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`,
                        `${content.fontProperties.values.timeBasedFont.fontSize} ${content.fontProperties.values.timeBasedFont.fontFamily}`,
                        `${content.fontProperties.values.textBasedFont.fontSize} ${content.fontProperties.values.textBasedFont.fontFamily}`,
                    ])
                    .flat()

                await Promise.all(
                    fontFamilies.map((font) => document.fonts.load(font))
                )

                setFontsLoaded(true)

                if (canvasRef) {
                    const context = canvasRef.getContext('2d')
                    setCtx(context)

                    const img = new Image()
                    img.src = PanchangamTemplate

                    img.onload = () => {
                        canvasRef.width = 288
                        canvasRef.height = 624
                        context.drawImage(
                            img,
                            0,
                            0,
                            canvasRef.width,
                            canvasRef.height
                        )
                        renderCanvasContent()
                    }
                }
            } catch (error) {
                console.error('Error loading fonts:', error)
            }
        }

        initializeCanvas()
    }, [])

    useEffect(() => {
        if (canvasRef && fontsLoaded) {
            const context = canvasRef.getContext('2d')
            setCtx(context)
            renderCanvasContent()
        }
    }, [
        canvasRef,
        selectedLanguage,
        selectedMonth,
        selectedDay,
        inputValues,
        fontsLoaded,
    ])

    const renderCanvasContent = () => {
        if (!ctx || !canvasRef || !fontsLoaded) return

        const img = new Image()
        img.src = PanchangamTemplate
        img.onload = () => {
            canvasRef.width = 288
            canvasRef.height = 624
            ctx.drawImage(img, 0, 0, canvasRef.width, canvasRef.height)
            renderInitialText()
            renderInputValues()
        }
    }

    const renderInitialText = () => {
        if (!ctx) return

        const content = languageContent[selectedLanguage]

        // Heading
        ctx.fillStyle = '#F7AB4B'

        ctx.font = `bold ${content.fontProperties.heading.fontSize} ${content.fontProperties.heading.fontFamily}`
        if (selectedLanguage === 1) {
            ctx.fillText(content.heading, 22, 90)
        } else if (selectedLanguage === 2) {
            ctx.fillText(content.heading, 56, 95)
        } else if (selectedLanguage === 3) {
            ctx.fillText(content.heading, 53, 95)
        } else {
            ctx.fillText(content.heading, 26, 90)
        }

        // Date
        const formattedDate = `${String(selectedDay).padStart(2, '0')}-${String(
            selectedMonth + 1
        ).padStart(2, '0')}-24`
        ctx.fillStyle = '#FFFFFF'
        ctx.font = `15px Sree Krushnadevaraya`
        ctx.fillText(formattedDate, 116, 116)

        // Labels
        ctx.fillStyle = '#F7AB4B'
        content.labels.forEach((label, index) => {
            const y = 160 + index * 30
            ctx.font = `bold ${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`
            ctx.fillText(label, 10, y)
        })
    }

    const renderInputValues = () => {
        if (!ctx || !canvasRef) return

        const content = languageContent[selectedLanguage]

        // temporary canvas to draw our text
        const tempCanvas = document.createElement('canvas')
        tempCanvas.width = canvasRef.width
        tempCanvas.height = canvasRef.height
        const tempCtx = tempCanvas.getContext('2d')

        // heading
        tempCtx.fillStyle = '#FFD700'
        const headingFontSize = content.fontProperties.heading.fontSize
        tempCtx.font = `bold ${headingFontSize} ${content.fontProperties.heading.fontFamily}`

        let headingX, headingY
        if (selectedLanguage === 1) {
            headingX = 22
            headingY = 90
        } else if (selectedLanguage === 2) {
            headingX = 56
            headingY = 95
        } else if (selectedLanguage === 3) {
            headingX = 53
            headingY = 95
        } else {
            headingX = 26
            headingY = 90
        }
        tempCtx.fillText(content.heading, headingX, headingY)

        // Render date

        // Function to get language string from number
        const getLanguageString = (langNumber) => {
            switch (langNumber) {
                case 1:
                    return 'english'
                case 2:
                    return 'telugu'
                case 3:
                    return 'hindi'
                case 4:
                    return 'tamil'
                default:
                    return 'english'
            }
        }

        // Draw date
        const formatDate = (day, month, languageNumber) => {
            const language = getLanguageString(languageNumber)
            const currentYear = new Date().getFullYear()
            const monthName = monthsData[language][month]
            const dayName =
                dayNamesData[language][
                    new Date(currentYear, month, day).getDay()
                ]

            return `${monthName} ${day}, ${dayName}`
        }

        // Assume selectedLanguage is a number (1, 2, 3, or 4)
        const formattedDate = formatDate(
            selectedDay,
            selectedMonth,
            selectedLanguage
        )

        // Calculate text width to center it
        tempCtx.font = '15px Sree Krushnadevaraya'
        const textWidth = tempCtx.measureText(formattedDate).width
        const canvasWidth = tempCtx.canvas.width
        const x = (canvasWidth - textWidth) / 2

        tempCtx.fillStyle = '#FFFFFF'
        tempCtx.fillText(formattedDate, x, 116)

        let currentY = 160 // Starting Y position
        const lineHeight = LINE_HEIGHT[selectedLanguage] || LINE_HEIGHT.default

        Object.entries(inputValues).forEach(([index, value]) => {
            const labelY = currentY
            const numericIndex = parseInt(index)
            // Determine which font to use
            let useTextBasedFont = false
            if (selectedLanguage === 2) {
                useTextBasedFont = TEXT_BASED_INDICES[2].includes(numericIndex)
            } else {
                useTextBasedFont = numericIndex > TIME_BASED_INDICES.default
            }

            // Render label
            tempCtx.fillStyle = '#FFD700'
            tempCtx.font = `bold ${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`
            selectedLanguage === 3
                ? tempCtx.fillText(content.labels[index], 17, labelY)
                : tempCtx.fillText(content.labels[index], 10, labelY)

            const fontProperties = useTextBasedFont
                ? content.fontProperties.values.timeBasedFont
                : content.fontProperties.values.textBasedFont

            // Render input value
            tempCtx.fillStyle = '#FFFFFF'
            tempCtx.font = `${fontProperties.fontSize} ${fontProperties.fontFamily}`

            // Split text by manual line breaks
            const manualLines = value.split('\\')
            let lastLineY = currentY

            manualLines.forEach((manualLine, lineIndex) => {
                const words = manualLine.trim().split(' ')
                let line = ''
                let testLine = ''

                words.forEach((word) => {
                    testLine = line + word + ' '
                    const metrics = tempCtx.measureText(testLine)
                    if (selectedLanguage === 3) {
                        if (
                            metrics.width > TEXT_END_X - TEXT_START_X &&
                            line !== ''
                        ) {
                            tempCtx.fillText(line, TEXT_START_X - 10, currentY)
                            currentY += lineHeight
                            line = word + ' '
                        } else {
                            line = testLine
                        }
                    } else {
                        if (
                            metrics.width > TEXT_END_X - TEXT_START_X &&
                            line !== ''
                        ) {
                            tempCtx.fillText(line, TEXT_START_X, currentY)
                            currentY += lineHeight
                            line = word + ' '
                        } else {
                            line = testLine
                        }
                    }
                })

                if (line !== '') {
                    selectedLanguage === 3
                        ? tempCtx.fillText(line, TEXT_START_X - 10, currentY)
                        : tempCtx.fillText(line, TEXT_START_X, currentY)
                    currentY += lineHeight
                }

                if (lineIndex < manualLines.length - 1) {
                    currentY += lineHeight * 0.01
                }
            })

            // update lastLineY
            lastLineY = currentY - lineHeight

            //final currentY position
            currentY = lastLineY + FIXED_GAP_BETWEEN_ENTRIES
        })

        ctx.clearRect(0, 150, canvasRef.width, canvasRef.height - 150)

        const img = new Image()
        img.src = PanchangamTemplate
        ctx.drawImage(img, 0, 0, canvasRef.width, canvasRef.height)

        ctx.drawImage(tempCanvas, 0, 0)
    }

    return (
        <canvas
            ref={(canvas) => {
                setCanvasRef(canvas)
                if (canvas && fontsLoaded) {
                    const context = canvas.getContext('2d')
                    setCtx(context)
                    renderCanvasContent()
                }
            }}
            style={{
                width: '18rem',
                height: '39rem',
            }}
        />
    )
}

export default PanchangamCanvas
