import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Preview from './Preview/Preview'
import {
    LeftCircleFilled,
    RightCircleFilled,
    EditOutlined,
} from '@ant-design/icons'
import {
    getCardByContentId,
    getHtmlContentByContentId,
    getPollByContentId,
    getQuizByContentId,
    getShortNewsByContentId,
    getShortVideoByContentId,
    getUgcContentByContentId,
    getDevotionalVideoByContentId,
    getInHouseAdsDataByAdId,
    getVisualsById,
    gettvContentByContentId,
} from '../../AadhanApi'
import './PreviewCorousel.scss'
import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router'
import { changePopup } from '../../store/slice/AppSlice'

const PreviewCorousel = ({ data, contentId, tableType, onReviewClicked }) => {
    const index = data.findIndex((obj) => obj.content_id === contentId)
    const [isModalOpen, setIsModalOpen] = useState(true)
    const [ugPage, setUgPage] = useState(false)
    const [currentDataIndex, setCurrentDataIndex] = useState(index)
    const [contentData, setContentData] = useState('')
    const [videoPath, setVideoPath] = useState()
    const [questions, setQuestions] = useState({
        question: '',
        options: ['', ''],
    })
    const accessToken = useSelector((state) => state.app.accessToken)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        const modalMask = document.querySelector('.ant-modal-mask')
        const modalWrap = document.querySelector('.ant-modal-wrap')
        if (modalMask) {
            modalMask.style.display = 'none'
        }
        if (modalWrap) {
            modalWrap.style.width = 'calc(100% - 256px)'
            modalWrap.style.maxWidth = 'calc(100% - 256px)'
            modalWrap.style.marginLeft = '256px'
        }
    }, [])

    useEffect(() => {
        getDataByContentId()
    }, [currentDataIndex])

    const getDataByContentId = async () => {
        let response
        if (tableType === 'card') {
            response = await getCardByContentId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
        } else if (tableType === 'html') {
            response = await getHtmlContentByContentId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
        } else if (tableType === 'poll') {
            response = await getPollByContentId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
            setQuestions({
                question: response?.data?.[0]?.title,
                options: Object.values(response?.data?.[0]?.options),
            })
        } else if (tableType === 'quiz') {
            response = await getQuizByContentId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
            setQuestions({
                question: response?.data?.[0]?.title,
                options: Object.values(response?.data?.[0]?.options),
            })
        } else if (tableType === 'shortnews') {
            response = await getShortNewsByContentId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
            setVideoPath(response?.data?.[0]?.stream_video_url?.length!==0 ? response?.data?.[0]?.stream_video_url : undefined)
        } else if (tableType === 'inhousead') {
            response = await getInHouseAdsDataByAdId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
            const resObj = response?.data?.data
            const videoLink =
                resObj.stream_video_url ||
                resObj.yt_video_url ||
                resObj.mp4_video_url
            if (response?.data && response?.data?.data) {
                setContentData(response?.data?.data)
                setVideoPath(videoLink)
            } else {
                console.error('No inHouseAd data found in the expected format')
            }
        } else if (tableType === 'shortvideo') {
            response = await getShortVideoByContentId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
            setVideoPath(response?.data?.[0].stream_video_url)
        } else if (tableType === 'devotionalvideo') {
            response = await getDevotionalVideoByContentId(
                data[currentDataIndex]?.content_id,
                accessToken
            )
            setVideoPath(response?.data?.stream_video_url)
        }else if (tableType === 'tv') {
            if (data[currentDataIndex]?.content_status === 'approved') {
                response = await gettvContentByContentId(
                    data[currentDataIndex]?.content_id,
                    accessToken
                )
                setVideoPath(response?.data?.stream_video_url?.length!==0 ? response?.data?.stream_video_url?.[0] : undefined)
                setContentData(response?.data)
            } else {
                response = await gettvContentByContentId(
                    data[currentDataIndex]?.content_id,
                    accessToken
                )
                setVideoPath(response?.data?.stream_video_url?.length!==0 ? response?.data?.stream_video_url?.[0] : undefined)
                setContentData(response?.data)
                if (response?.data?.content_status === 'rejected') {
                    setUgPage(true)
                }
            }
            return
        }
        else if (tableType === 'ug') {
            if (data[currentDataIndex]?.content_status === 'approved') {
                response = await getShortNewsByContentId(
                    data[currentDataIndex]?.content_id,
                    accessToken
                )
                setVideoPath(response?.data?.stream_video_url?.length!==0 ? response?.data?.stream_video_url?.[0] : undefined)
                setContentData(response?.data?.[0])
            } else {
                response = await getUgcContentByContentId(
                    data[currentDataIndex]?.content_id,
                    accessToken
                )
                setVideoPath(response?.data?.stream_video_url?.length!==0 ? response?.data?.stream_video_url?.[0] : undefined)
                setContentData(response?.data)
                if (response?.data?.content_status === 'rejected') {
                    setUgPage(true)
                }
            }
            return
        }
        if (tableType !== 'ads' && response?.data?.length > 0) {
            setContentData(response?.data[0])
        }
    }

    const handleNext = () => {
        if (currentDataIndex == data?.length - 1) return
        setCurrentDataIndex((prevIndex) => (prevIndex + 1) % data.length)
    }

    const handlePrevious = () => {
        if (currentDataIndex == 0) return
        setCurrentDataIndex((prevIndex) =>
            prevIndex === 0 ? data.length - 1 : prevIndex - 1
        )
    }

    const closePopup = () => {
        setIsModalOpen(false)
        dispatch(changePopup(false))
    }

    const onEditClicked = () => {
        if (
            (location?.pathname === '/home/ug' || location?.pathname === '/home/tv') &&
            data[currentDataIndex]?.content_status === 'approved'
        ) {
            navigate(
                `/home/shortNewsContent?mode=edit&contentId=${data[currentDataIndex]?.content_id}`
            )
            dispatch(changePopup(false))
        } else {
            if (location?.pathname.includes('/home/ug') || location?.pathname === '/home/tv') {
                onReviewClicked(data[currentDataIndex])
            } else {
                navigate(
                    `${location?.pathname}Content?mode=edit&contentId=${data[currentDataIndex]?.content_id}`
                )
                dispatch(changePopup(false))
            }
        }
    }

    return (
        <Modal
            open={isModalOpen}
            width={'60%'}
            onCancel={() => closePopup()}
            className="carousel-preview-block"
        >
            <div className="preview-controls">
                <div className="back-btn" onClick={() => closePopup()}>
                    Back
                </div>
                {!ugPage && (
                    <div className="edit-btn" onClick={() => onEditClicked()}>
                        <EditOutlined />
                        Edit
                    </div>
                )}
            </div>
            <div className="posted-details">
                <div>
                    <div className="carousel-preview-header">Preview</div>
                    {location.pathname !== '/home/ads' && (
                        <>
                            <div>
                                Posted By: {data[currentDataIndex]?.uploaded_by}
                            </div>

                            {data[currentDataIndex]?.proof_read_by?.trim()
                                ?.length > 0 && (
                                <div>
                                    Approved By:{' '}
                                    {data[currentDataIndex]?.proof_read_by}
                                </div>
                            )}
                        </>
                    )}

                    {location.pathname === '/home/ads' && (
                        <>
                            <div>
                                Posted By:{' '}
                                {data[currentDataIndex]?.uploaded_by_name}
                            </div>
                            {data[currentDataIndex]?.proof_read_by_name?.trim()
                                ?.length > 0 && (
                                <div>
                                    Approved By:{' '}
                                    {data[currentDataIndex]?.proof_read_by_name}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="preview-corousel-container">
                    <LeftCircleFilled
                        style={{
                            fontSize: '50px',
                            cursor:
                                currentDataIndex > 0
                                    ? 'pointer'
                                    : 'not-allowed',
                        }}
                        onClick={handlePrevious}
                    ></LeftCircleFilled>
                    <Preview
                        title={contentData?.title}
                        description={contentData?.description}
                        cardImages={contentData?.image_urls}
                        weburl={contentData?.web_url}
                        questions={questions}
                        image={contentData?.image_url}
                        videoPath={videoPath}
                        ad_type={contentData?.ad_type}
                        approveContent={data[currentDataIndex]?.content_status === 'approved' && videoPath ? true :false}
                        
                    />
                    <RightCircleFilled
                        style={{
                            fontSize: '50px',
                            cursor:
                                currentDataIndex === data?.length - 1
                                    ? 'not-allowed'
                                    : 'pointer',
                        }}
                        onClick={handleNext}
                    ></RightCircleFilled>
                </div>
                <div style={{ marginTop: '14px' }}>
                    <div>
                        Posted:{' '}
                        {dayjs(data[currentDataIndex]?.uploaded_date).format(
                            'DD-MMM-YYYY h:mm A'
                        )}
                    </div>
                    {location?.pathname !== '/home/devotionalVideo' && (
                        <div>
                            Status: {data[currentDataIndex]?.content_status}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default PreviewCorousel
