import { CloudUploadOutlined, FontSizeOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import {
    expiryHoursOptions,
    isPublish,
    isSubmitAndDraft,
    TableTypeFromRoutesContent,
} from '../App.constants'
import { getImageUrl } from '../../AadhanApi'
import { useEffect, useState } from 'react'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import './AddCard.scss'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Speaker from '../Speaker'

const DraggableUploadListItem = ({ originNode, file, fileList }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: file.uid,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    }
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            <span className="card-number">
                {fileList?.findIndex((f) => f.uid === file.uid) + 1}
            </span>
            {file.status === 'error' && isDragging
                ? originNode.props.children
                : originNode}
        </div>
    )
}

const AddCardForm = ({
    accessToken,
    setFileList,
    fileList,
    initialFormData,
    languageData,
    categories,
    onSave,
    onPublish,
    isLoading,
    setIsLoading,
    contentStatus,
    languageOption,
    setLanguageOption
}) => {
    const [form] = Form.useForm()
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const [fileListError, setFileListError] = useState()
    const [titleError, setTitleError] = useState()
    const [title, setTitle] = useState('')
    const location = useLocation()
    const currentTableType =
        TableTypeFromRoutesContent[location?.pathname] ?? 'shortnews'
    const [imageError, setImageError] = useState(false)

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })
    useEffect(() => {
        form.setFieldsValue(initialFormData)
        handleTitleChange(initialFormData?.title)
        setLanguageOption(initialFormData?.language)
        handleEnglishTitleChange(initialFormData?.englishTitle)
    }, [form, initialFormData])

    const onFinish = (values) => {
        if (fileList?.length === 0) {
            setFileListError(true)
        } else {
            onPublish(values)
        }
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo?.errorFields?.length &&
            errorInfo.errorFields.forEach((ele) => {
                if (ele?.name === 'title' || ele?.name === 'category') {
                    window.scrollTo(0, 0)
                }
            })
        console.log('Failed:', errorInfo)
    }
    // const englishTitleRules =  [
    //     {
    //         required: true,
    //         message: 'Value is required and should be less than 75 characters',
    //     },
    //     {
    //         validator(_, value) {
    //             if (value && value.trim().length > 0) {
    //                 return Promise.resolve();
    //             }
    //             return Promise.reject('English Title cannot be empty');
    //         },
    //     },
    // ]

    const draggerProps = {
        name: 'file',
        multiple: true,
        onRemove: (file) => {
            const index = fileList?.indexOf(file)
            const newFileList = fileList?.slice()
            if (index > -1) {
                newFileList?.splice(index, 1)
            }
            setFileList(newFileList)
        },
        customRequest: ({ file, onSuccess }) => {
            setIsLoading(true)
            checkImageDimensions(file)
                .then(({ width, height }) => {
                    if (width && height) {
                        setImageError(false)
                        getImageUrl(
                            file,
                            'card',
                            accessToken,
                            languageOption
                        )
                            .then((pathUriArr) => {
                                setIsLoading(false)
                                if (pathUriArr?.data) {
                                    const path =
                                        pathUriArr?.data;
                                    setFileList([...fileList, path])
                                    onSuccess(path)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    } else {
                        setImageError(true)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    }

    const checkImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.onload = (event) => {
                const img = new Image()
                img.src = event.target.result

                img.onload = () => {
                    const width = img.width
                    const height = img.height
                    resolve({ width, height })
                }

                img.onerror = (error) => {
                    reject(error)
                }
            }

            reader.readAsDataURL(file)
        })
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setFileList((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id)
                const overIndex = prev.findIndex((i) => i.uid === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
        }
    }
    const onChange = ({ fileList: newFileList }) => {
        // Update file list state
        setFileList(newFileList);
    
        if (newFileList.length > 0) {
            const lastFile = newFileList[newFileList.length - 1];
            if (lastFile && lastFile.originFileObj instanceof Blob) {
                const reader = new FileReader();
    
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
    
                    img.onload = () => {
                        const width = img.width;
                        const height = img.height;
                        if (width && height) {
                            setImageError(false);
                        } else {
                            setImageError(true);
                        }
                    };
                };
    
                reader.readAsDataURL(lastFile.originFileObj);
            } else {
                // setImageError(true);
            }
        } else {
            setFileList([]);
            setImageError(true);
        }
    };
    

    const handleLanguageChange = (id) => {
        setLanguageOption(id)
        form.setFieldValue('category', undefined)
    }

    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
            setTitle(cleanedValue)
            form.setFieldValue('title', cleanedValue)
            setTitleError(cleanedValue.length > 75)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
            setTitleError(false)
        }
    }
    const handleEnglishTitleChange = (value) => {
        form.setFieldValue('englishTitle', value)
    }

    const handleSave = async (type) => {
        try {
            const validation = await form.validateFields([
                'title',
                'language',
                'category',
                'englishTitle',
                'expiryHours',
            ])
            if (validation?.errorFields?.length > 0) {
                return
            }
        } catch (e) {
            e?.errorFields?.length &&
                e.errorFields.forEach((ele) => {
                    if (ele?.name == 'title' || ele?.name == 'category') {
                        window.scrollTo(0, 0)
                    }
                })
            return
        }
        if ((type === 'submit' || type === 'draft') && fileList?.length === 0) {
            setFileListError(true)
        } else {
            onSave({ ...form.getFieldsValue() }, type)
        }
    }
console.log(fileList)
    return (
        <div id="form" style={{ width: '100%' }}>
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                initialValues={initialFormData}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select onChange={handleLanguageChange}>
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message:
                                'Value is required and should be less than 75 characters',
                        },
                    ]}
                >
                    <Input.TextArea
                        className={titleError ? 'error' : ''}
                        maxLength={75}
                        value={title}
                        onChange={(e) => handleTitleChange(e.target.value)}
                        placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                    />
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        className="description-subtext"
                    >
                        <span
                            style={{
                                color: title?.length > 75 ? 'red' : null,
                            }}
                        >
                            {' '}
                            {75 - (title?.length ?? 0)} characters
                        </span>
                        <span
                            style={{
                                display: 'flex',

                                fontSize: '1rem',
                                fontWeight: '500',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                type="primary"
                                ghost={true}
                                className="format-button"
                                onClick={() => {
                                    if (title) {
                                        let cleanedValue = title
                                            ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                            .replace(
                                                /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                                                '$1 '
                                            ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                                            .replace(
                                                /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/,
                                                '$1'
                                            ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                                            .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                                            .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                                            .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                                            .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                                            .trim() // Trim leading and trailing spaces

                                        cleanedValue = cleanedValue.trimStart()
                                        if (/[.!?]$/.test(cleanedValue)) {
                                            cleanedValue =
                                                cleanedValue.trimEnd()
                                        }

                                        setTitle(cleanedValue)

                                        form.setFieldValue(
                                            'title',
                                            cleanedValue
                                        )
                                        setTitleError(cleanedValue.length > 75)
                                    } else {
                                        setTitle('')
                                        form.setFieldValue('title', '')

                                        setTitleError(false)
                                    }
                                }}
                            >
                                <FontSizeOutlined />
                            </Button>

                            <span
                                style={{ marginTop: '3px', marginLeft: '5px' }}
                            >
                                <Speaker
                                    text={title}
                                    language={
                                        languageOption === 1
                                            ? 'en-US'
                                            : languageOption === 2
                                            ? 'hi-IN'
                                            : languageOption === 4
                                            ? 'ta-IN'
                                            : 'hi-IN'
                                    }
                                />
                            </span>
                        </span>
                    </span>
                </Form.Item>

                <Form.Item
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select placeholder="--Select Category--">
                        {categories?.map((option) => {
                                return (
                                    <Select.Option
                                        key={option?.category_name}
                                        value={option?.category_id}
                                    >
                                        {option?.category_name}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Form.Item>
                <Form.Item label="Popular Card" name="popularCard">
                    <Radio.Group>
                        <Radio value={true}> Yes </Radio>
                        <Radio value={false}> No </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="expiryHours"
                    label="Expiry Hours"
                    rules={[{ required: true }]}
                >
                    <Select style={{ width: 200 }}>
                        {expiryHoursOptions.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{ required: true }]}
                    label="Publish"
                    name="publish"
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY h:mm A"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>
                {/* {isPublish(userPermissions, 'Content', 'Card') &&
                    languageOption !== 2 && (
                        <Form.Item
                            label="Proof read"
                            name="proofRead"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an option',
                                },
                            ]}
                            className="custom-form-item"
                        >
                            <Radio.Group>
                                <Radio value={true}> Yes </Radio>
                                <Radio value={false}> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )} */}
                <Form.Item
                    rules={[{ required: true }]}
                    wrapperCol={{ span: 24 }}
                >
                    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                        <SortableContext
                            items={fileList?.map((i) => i?.uid)}
                            strategy={verticalListSortingStrategy}
                        >
                            <Dragger
                                listType="picture"
                                fileList={fileList}
                                onChange={onChange}
                                accept="image/jpeg,image/png"
                                {...draggerProps}
                                itemRender={(originNode, file, fileList) => (
                                    <DraggableUploadListItem
                                        originNode={originNode}
                                        file={file}
                                        fileList={fileList}
                                    />
                                )}
                            >
                                <p className="ant-upload-drag-icon">
                                    <CloudUploadOutlined />
                                </p>
                                <Button>Select Your Card</Button>
                                <div
                                    style={{
                                        color: '#199be9',
                                        marginTop: '5px',
                                    }}
                                >
                                    Image size 1080*2340
                                </div>
                            </Dragger>
                        </SortableContext>
                    </DndContext>
                    {fileListError && (
                        <p className="error-card"> Please Upload a Card</p>
                    )}
                    {imageError && (
                        <p className="error-card">
                            Card Image size should be 1080*2340
                        </p>
                    )}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 32 }}>
                    {isSubmitAndDraft(userPermissions, 'Content', 'Card') && (
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            onClick={() => handleSave('draft')}
                            disabled={isLoading || contentStatus == 'approved'}
                        >
                            Draft
                        </Button>
                    )}
                    {isSubmitAndDraft(userPermissions, 'Content', 'Card') && (
                        <Button
                            className="bg-color width-150 submit-buttons"
                            type="primary"
                            onClick={() => handleSave('submit')}
                            disabled={isLoading || contentStatus == 'approved'}
                        >
                            Submit
                        </Button>
                    )}
                    {isPublish(userPermissions, 'Content', 'Card') && (
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            htmlType="submit"
                            disabled={isLoading}
                        >
                            Publish
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
export default AddCardForm
