import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
const { RangePicker } = DatePicker

const DateRange = (props) => {
    const { addDates, clearDates } = props
    const [datevalues, setDateValues] = useState([dayjs(), dayjs()])

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            const startDate = dayjs(dates[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            const endDate = dayjs(dates[1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            setDateValues(dates)
            addDates(startDate, endDate)
        } else {
            clearDates()
            setDateValues([])
        }
    }
    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs()],
        },
        {
            label: 'Yesterday',
            value: [dayjs().add(-1, 'd'), dayjs()],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ]

    return (
        <RangePicker
            value={datevalues}
            presets={rangePresets}
            format="DD/MM/YYYY"
            disabledDate={(currentDate) => currentDate > new Date()}
            onChange={onRangeChange}
        />
    )
}
export default DateRange
