import { DatePicker, Select, message } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import './TimeSlotReport.scss'
import CommonTable from '../CommonTable/CommonTable'
import {
    typeOptions,
    timeOptions
} from './TimeSlotReport.constant'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
import { getTimeSlotReport } from '../../AadhanApi'

const TimeSlotReport = () => {
    const [reportsData, setReportsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [typeOption, setTypeOption] = useState(typeOptions[0]?.value)
    const [messageApi, contextHolder] = message.useMessage()
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })

    const timeSlotTableColumns = [
        {
            title: 'Time Slot',
            dataIndex: 'time_slot',
            key: 'time_slot',
            render: (_, record) => timeOptions[record.hour],
        },
        {
            title: '# Posts',
            dataIndex: 'published_contents',
            key: 'published_contents',
        },
    ]

    useEffect(() => {
        if(languageOption) {
            timeSlotReportDetails()
        }
    }, [languageOption, dates, typeOption])

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }

    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
    }

    const timeSlotReportDetails = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const response = await getTimeSlotReport(
                languageOption,
                typeOption,
                startDateFormat,
                endDateFormat,
                accessToken
            )
            setReportsData(response.data.data)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }
    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Time Slot Report</h2>
                <div className="date-picker-container">
                    <span style={{ marginRight: '5px' }}>Date Range :</span>
                    <ManageContentDateRange
                        dates={dates}
                        addDates={addDates}
                        clearDates={clearDates}
                    />
                </div>
            </div>
            <div className="manage-content-inputs">
                <Select 
                    value={languageOption} 
                    style={{ width: 200 }}
                    onChange={(val) => setLanguageOption(val)}
                >
                    {languageData.map((option) => {
                        return (
                            <Option key={option?.language_id} value={option?.language_id}>
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select 
                    style={{ width: 200 }} 
                    value={typeOption}
                    onChange={(val) => setTypeOption(val)}
                >
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <CommonTable 
                columns={timeSlotTableColumns} 
                data={reportsData}
                dataLoading={dataLoading}
            />
        </div>
    )
}

export default TimeSlotReport
