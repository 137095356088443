import React, { useState } from 'react'
import '../stories.scss'
import { DatePicker, Select,Switch } from 'antd'
import { useSelector } from 'react-redux'
import CommonTableWithBorder from '../../CommonTable/CommonTableWithBorder'
import CustomSteps from '../CustomSteps'
import Comment from './Comment'
import AssignModal from './AssignModel'
const CentralTeamOps = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const [language, setLanguage] = useState(1)
    const types = ['All', 'Not Assigned', 'In Progress', 'Completed']
    const dataSource = [
        {
            key: '1',
            storyTitle: 'Breaking News: Market Crash',
            footage: 'market_crash.mp4',
            writer: 'John Doe',
            videoEditor: 'Jane Smith',
            publishing: '2024-07-22',
            status: 'Published',
        },
        {
            key: '2',
            storyTitle: 'Local Hero Saves Cat',
            footage: 'hero_saves_cat.mp4',
            writer: 'Alice Johnson',
            videoEditor: 'Bob Brown',
            publishing: '2024-07-21',
            status: 'In Progress',
        },
        // Add more data as needed
    ]

    const columns = [
        {
            title: 'Story Title',
            dataIndex: 'storyTitle',
            key: 'storyTitle',
            render: (text, record) => (
              <div className="view_story">
                  <div>
                  <div className="" style={{textDecoration:"underline",color:"blue"}}>Story Name</div>
                  <div>date</div>
                  </div>
                  <Comment/>
                  
              </div>
          ),
              
        },
        {
            title: 'Footage',
            // dataIndex: 'footage',
            key: 'footage',
            render: (text, record) => (
                <div className="stage">
                    <div className="name">Reporter Name</div>
                    <AssignModal title={'Reporter'}/>
                    <CustomSteps/>
                </div>
            ),
        },
        {
            title: 'Writer',
            dataIndex: 'writer',
            key: 'writer',
            render: (text, record) => (
              <div className="stage">
                  <div className="name">Writer Name</div>
                  <AssignModal title={'Writer'}/>
                  <CustomSteps/>
              </div>
          ),
            
        },
        {
            title: 'Video Editor',
            dataIndex: 'videoEditor',
            key: 'videoEditor',
            render: (text, record) => (
              <div className="stage">
                  <div className="name">Editor Name</div>
                  <AssignModal title={'Editor'}/>
                  <CustomSteps/>
              </div>
          ),
        },
        {
            title: 'Publishing',
            dataIndex: 'publishing',
            key: 'publishing',
            render: (text, record) => (
              <div className="stage">
                  <div className="name">Publisher Name</div>
                  <AssignModal title={'Publisher'}/>
                  <CustomSteps/>
              </div>
          ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
              <div className="stage">
                  
                  <button className="stage_btn" >Update</button>
                  <div>icons</div>
              </div>
          ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
              <Switch
              onChange={handleActionClick}
              checkedChildren="OPEN"
              unCheckedChildren="CLOSE"
              defaultChecked
              className="active_status"
             
          />
            ),
        },
    ]

    const handleActionClick = (record) => {
        console.log('Action clicked for:', record)
        // Perform any action here (e.g., edit, delete)
    }

    const handleLanguageChange = (e) => {
        
    }
    return (
        <div>
            <div className="title">Assigned Stories - Central Team</div>
            <hr></hr>
            <div className="filters" style={{ paddingRight: '45%' }}>
                <Select
                    style={{ width: 180 }}
                    defaultValue={language}
                    onChange={handleLanguageChange}
                >
                    {languageData?.map((option) => (
                        <Select.Option
                            key={option?.language_id}
                            value={option?.language_id}
                        >
                            {option?.language_name}
                        </Select.Option>
                    ))}
                </Select>

                <DatePicker />
                <Select style={{ width: 180 }} defaultValue={'All'}>
                    {types?.map((option) => (
                        <Select.Option key={option} value={option}>
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ padding: '1rem'}}>
                <CommonTableWithBorder
                    className={'no-padding-table'}
                    data={dataSource}
                    columns={columns}
                />
            </div>
        </div>
    )
}

export default CentralTeamOps
