import {Button} from 'antd'
import './NotificationsNewDashboard.scss'
import NotificationStatsCard from './NotificationStatsCard'

const NotificationsNewDashboard = () => {
    return (
        <div className='dashboard-container'>
            <div style={{display : "flex",justifyContent:"space-between"}}>
                <h4>NotificationsNewDashboard</h4>
                <Button type="primary">Send Notification</Button>
            </div>
            <NotificationStatsCard />
        </div>

    )
}

export default NotificationsNewDashboard