import { Select, message } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import { getCSVData, getVideoReport } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { typeOptions } from './VideoReport.constant'
import dayjs from 'dayjs'
import './VideoReport.scss'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'

const VideoReport = () => {
    const [reportsData, setReportsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const [messageApi, contextHolder] = message.useMessage()
    const [typeOption, setTypeOption] = useState(typeOptions[0]?.value)
    const videoReportTableColumns = [
        {
            title: 'Video Title',
            dataIndex: 'video_title',
            key: 'video_title',
        },
        {
            title: 'Total Views',
            dataIndex: 'view_count',
            key: 'view_count',
        },
        {
            title: 'Watch Time',
            dataIndex: 'watch_time',
            key: 'watch_time',
        },
        {
            title: 'Unique Views',
            dataIndex: 'unique_viewers',
            key: 'unique_viewers',
        },
        {
            title: 'Video Category',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Overall Score',
            dataIndex: 'Overall Score',
            key: 'Overall Score',
        },
        {
            title: 'Video Type',
            dataIndex: 'content_type',
            key: 'content_type',
        },
        {
            title: 'Quality score',
            dataIndex: 'video_quality_score',
            key: 'video_quality_score',
        },
        {
            title: 'Duration',
            dataIndex: 'video_duration',
            key: 'video_duration',
        },
        {
            title: 'Smoothness',
            dataIndex: 'smoothness_score',
            key: 'smoothness_score',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                   {record?.smoothness_score * 100}
                </div>
            ),
        },
    ]

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }

    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
    }

    useEffect(() => {
        if (languageOption) {
            getVideoReportDetails()
        }
    }, [languageOption, dates, typeOption])

    const getVideoReportDetails = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const response = await getVideoReport(
                languageOption,
                startDateFormat,
                endDateFormat,
                typeOption,
                accessToken
            )
            setReportsData(response.data)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const getCSVDetails = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            await getCSVData(
                languageOption,
                startDateFormat,
                endDateFormat,
                typeOption,
                accessToken
            )
            setDataLoading(false)
        } catch (err) {
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Video Report</h2>
                <div className="date-picker-container">
                    <span style={{ marginRight: '5px' }}>Date Range :</span>
                    <ManageContentDateRange
                        dates={dates}
                        addDates={addDates}
                        clearDates={clearDates}
                    />
                </div>
            </div>
            <div className="manage-content-inputs">
                <Select 
                    value={languageOption} 
                    style={{ width: 200 }}
                    onChange={(val) => setLanguageOption(val)}
                >
                    {languageData.map((option) => {
                        return (
                            <Select.Option 
                                key={option?.language_id} 
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        )
                    })}
                </Select>
                <Select
                    style={{ width: 200 }} 
                    value={typeOption}
                    onChange={(val) => setTypeOption(val)}
                >
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <CommonTable
                columns={videoReportTableColumns}
                data={reportsData}
                dataLoading={dataLoading}
                disablePagination={true}
            />
            {reportsData?.length > 0 &&
                <button className='export-to-csv' onClick={getCSVDetails}>
                    <span>Export to CSV</span>
                </button>
            }
        </div>
    )
}
export default VideoReport
