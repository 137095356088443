import { useEffect, useRef, useState } from 'react'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import AddShortNewsForm from './AddShortNewsForm/AddShortNewsForm'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getHashtagTableData, getNewsSections, getShortNewsByContentId } from '../../AadhanApi'
import { Button, message, Modal, Spin } from 'antd'
import { CloseSquareFilled } from '@ant-design/icons'
import Tab from './Tab'
import './AddShortNewsForm/AddShortNewsForm.scss'
import ImageLibrary from '../imageLibrary/ImageLibrary'
import ShortNewsImageUpload from './ShortNewsImageUpload'
import ShortNewsVideoUpload from './AddShortNewsForm/ShortNewsVideoUpload'
import TextArea from 'antd/es/input/TextArea'
import { BreakingNews } from './AddShortNewsForm/BreakingNews'
const AddShortNews = ({ resetWriterDashboard }) => {
    const employeeLanguage = useSelector((state) => state.app.employeeLanguages)
    const [messageApi, contextHolder] = message.useMessage()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [imagePath, setImagePath] = useState('')
    const [videoPath, setVideoPath] = useState({ stream_url: '', yt_url: '' })
    const location = useLocation()
    const [hashTagId, setHashTagId] = useState()
    const [code, setCode] = useState('')
    const [contentStatus, setContentStatus] = useState('')
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('contentId')
    const mode = searchparam.get('mode')
    const languageData = useSelector((state) => state.app.languageData)
    const [categories,setCategoris] = useState([]);
    const accessToken = useSelector((state) => state.app.accessToken)
    const [pro, setPro] = useState('')
    const [anti, setAnti] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [generate, setGenerate] = useState(false)
    const [otherLink, setOtherLink] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [selectedCategory, setSelectedCategory] = useState(
        ''
    )
    const navigate = useNavigate()
    const [isImageAdding, setIsImageAdding] = useState(false)
    const [isVideoAdding, setIsVideoAdding] = useState(false)
    const [isOriginal, setIsOriginal] = useState(true)
    const [isSensitive, setIsSensitive] = useState(false)
    const [initialFormData, setInitialFormData] = useState({
        language: languageData?.[0]?.language_id,
        disturbingVisuals: 'no',
        publish: dayjs(),
        locationSpecific: 'no',
        proofRead: '',
        expiryHours: 'Never',
        gender: 'all',
        media: 'youtubeLink',
    })
    const getMedia = (response) => {
        if (response?.stream_video_url !== '') {
            return 'video'
        } else if (response?.yt_video_url !== '') {
            return 'youtubeLink'
        } else {
            return 'otherLink'
        }
    }
    const getNewsSection=async()=>{
        const data= await getNewsSections(languageOption,'all',accessToken)
        setCategoris(data?.data?.data)

    }
    useEffect(()=>{
        if(languageOption){
            
            getNewsSection()
        }
    },[languageOption])
    useEffect(() => {
        setSearchTerm(title?.replaceAll(' ', ','))
    }, [title])
    const updateFormData = async (contentId, accessToken) => {
        try {
            const response = await getShortNewsByContentId(
                contentId,
                accessToken
            )
            if (response?.data) {
                setSearchTerm(response?.data?.[0]?.title?.replaceAll(' ', ','))
                setLanguageOption(response?.data?.[0]?.language_id)
                setCode(response?.data[0].code)
                setImagePath(response?.data?.[0]?.image_url)
                if (response?.data?.[0]?.stream_video_url !== '') {
                    setVideoPath({
                        stream_url: response?.data?.[0]?.stream_video_url,
                        yt_url: '',
                    })
                } else if (response?.data?.[0]?.yt_video_url !== '') {
                    setVideoPath({
                        yt_url: response?.data?.[0]?.yt_video_url,
                        stream_url: '',
                    })
                }
                if (
                    response?.data?.[0]?.sub_category_id !== '' &&
                    response?.data?.[0]?.sub_category_id !== undefined &&
                    response?.data?.[0]?.sub_category_id !== null
                ) {
                    setPro(response?.data?.[0]?.sub_category_id)
                }
                if (
                    response?.data?.[0]?.anti_sub_category_id !== '' &&
                    response?.data?.[0]?.anti_sub_category_id !== undefined &&
                    response?.data?.[0]?.anti_sub_category_id !== null
                ) {
                    setAnti(response?.data?.[0]?.anti_sub_category_id)
                }
                setTitle(response?.data?.[0]?.title)
                setDescription(response?.data?.[0]?.description)
                setHashTagId(response?.data?.[0]?.hashtag_id)
                setContentStatus(response?.data?.[0]?.content_status)
                setInitialFormData({
                    ...initialFormData,
                    streamVideoPath: response?.data?.[0]?.stream_video_url,
                    title: response?.data?.[0]?.title,
                    otherLink:
                        response?.data?.[0]?.mp4_video_url === ''
                            ? ''
                            : response?.data?.[0]?.mp4_video_url,
                    language: response?.data?.[0]?.language_id,
                    category: response?.data?.[0]?.category_id,
                    disturbingVisuals: response?.data?.[0]?.disturbing_visuals,
                    media: getMedia(response?.data?.[0]),
                    publish: dayjs(
                        dayjs(response?.data?.[0]?.published_date).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                    ),
                    gender: response?.data?.[0]?.gender,
                    locationSpecific:
                        response?.data?.[0]?.state !== '' ? 'yes' : 'no',
                    expiryHours:
                        response?.data?.[0]?.expiry_hours == 0
                            ? 'Never'
                            : response?.data?.[0]?.expiry_hours,
                    shortDescription: response?.data?.[0]?.description,
                    district_id: response?.data?.[0]?.district,
                    districts: response?.data?.[0]?.district_name,
                    constituency_ids: response?.data?.[0]?.constituency_ids,
                    assemblyconstituencies:
                        response?.data?.[0]?.constituency_names,
                    mandal_id: response?.data?.[0]?.mandal,
                    mandal: response?.data?.[0]?.mandal_name,
                    subcategory: response?.data?.[0]?.sub_category_name,
                    subcategory_id: response?.data?.[0]?.sub_category_id,
                    r2_video_url: response?.data?.[0]?.r2_video_url,
                    youtubeLink:
                        response?.data?.[0]?.yt_video_url === ''
                            ? ''
                            : response?.data?.[0]?.yt_video_url,
                    proofRead:
                        response?.data?.[0]?.content_status !== 'draft' &&
                        response?.data?.[0]?.content_status !== 'submit'
                            ? response?.data?.[0]?.is_proof_read
                            : '',
                    image: response?.data?.[0].image_url,
                    state_id: !response?.data?.[0]?.state
                        ? []
                        : response?.data?.[0]?.state?.split(','),
                    state: response?.data?.[0]?.state_name,
                    keywords: response?.data?.[0]?.keywords,
                    uploadedBy: response?.data?.[0]?.uploaded_by,
                    uploadedByName: response?.data?.[0]?.uploaded_by_name,
                    uploadedDate: response?.data?.[0]?.uploaded_date,
                    is_celebration: response?.data?.[0]?.is_celebration,
                    entities: response?.data?.[0]?.entites,
                    iab_category: response?.data?.[0]?.iab_category,
                    assigned_to: response?.data?.[0]?.assigned_to,
                    assigned_at: response?.data?.[0]?.assigned_at,
                })
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get Short news Data from content Id',
                duration: 5,
            })
        }
    }

    useEffect(() => {
        if (contentId) {
            updateFormData(contentId, accessToken)
        }
    }, [contentId])

    const titleChange = (newTitle) => {
        setTitle(newTitle)
    }

    const descriptionChange = (newDesc) => {
        const inputLines = newDesc.split('\n')?.length
        if (inputLines <= 9) {
            setDescription(newDesc)
        }
    }
    useEffect(() => {
        setIsOriginal(false)
        setIsSensitive(false)
    }, [imagePath])
    const handleOk = () => {
        //   setIsModalOpen(false);
        setIsVideoAdding(false)
        setIsImageAdding(false)
    }
    const handleCancel = () => {
        setIsVideoAdding(false)
        setIsImageAdding(false)
    }
    const [error, setError] = useState('')

    const handleInputChange = (e) => {
        const value = e.target.value
        setVideoPath({ yt_url: value, stream_url: '' })
        const youtubeRegex =
            /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/
        if (!youtubeRegex.test(value) && value !== '') {
            setError('Please enter a valid YouTube link')
        } else {
            setError('')
        }
    }
    const childRef = useRef()

    const handleButtonClick = () => {
        if (childRef.current) {
            childRef.current.publish_review()
        }
    }
    return (
        <div
            style={{
                padding: '0 1rem',
                marginTop: location.pathname.includes('writer-dashboard')
                    ? '1.5rem'
                    : '',
            }}
        >
            {contextHolder}
            {!location.pathname.includes('writer-dashboard') && (
                <div className="title_shortNews">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3>
                            {mode === 'edit'
                                ? 'Edit Short News'
                                : mode === 'review'
                                ? 'Review Short News'
                                : 'Add Short News'}
                        </h3>
                        <h3 className="language_title">
                            {employeeLanguage.length === 1
                                ? '( ' +
                                  languageData.find(
                                      (e) =>
                                          e.language_id ===
                                          employeeLanguage?.[0]
                                  )?.language_name +
                                  ' )'
                                : ''}
                        </h3>
                        <span
                            style={{
                                fontSize: '18px',
                                marginLeft: '1rem',
                                color: 'gray',
                            }}
                        >
                            {mode === 'review'
                                ? initialFormData?.uploadedByName
                                : ''}
                        </span>
                    </div>
                    <Button
                        onClick={() => navigate(-1)}
                        ghost={true}
                        style={{ border: 'none' }}
                        type="primary"
                        icon={
                            <CloseSquareFilled
                                style={{
                                    color: 'LightCoral',
                                    fontSize: '1.8rem',
                                }}
                            />
                        }
                    ></Button>
                </div>
            )}

            <div className="news-layout">
                <Modal
                    title={
                        isImageAdding ? (
                            <div style={{ display: 'flex' }}>
                                Add Image ({' '}
                                <div
                                    style={{
                                        color: 'gray',
                                        fontWeight: 400,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>Image size 540</span>{' '}
                                    <span style={{ padding: '0 2px' }}>x</span>{' '}
                                    <span>525</span>
                                </div>
                                <div
                                    style={{
                                        color: 'gray',
                                        fontWeight: 400,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    ,
                                    <span style={{ marginLeft: '.5rem' }}>
                                        Max size 100kb
                                    </span>
                                </div>{' '}
                                )
                            </div>
                        ) : (
                            'Add Video'
                        )
                    }
                    width={isImageAdding ? '63%' : '30%'}
                    className="image_modal_shortnews"
                    style={{ top: isImageAdding ? '13%' : '' }}
                    open={isImageAdding || isVideoAdding}
                    onOk={handleOk}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <div className="" style={{ flex: 2 }}>
                        <div>
                            {isImageAdding  && (
                                <div>
                                {selectedCategory === 8 || selectedCategory === 32 || selectedCategory === 20 ? <Tab
                                tab1={
                                    <BreakingNews
                                    imagePath={imagePath}
                                    language_id={languageOption}
                                    setImagePath={setImagePath}
                                    isOriginal={isOriginal}
                                    setIsImageAdding={setIsImageAdding}
                                    setIsOriginal={setIsOriginal}
                                    isSensitive={isSensitive}
                                    setIsSensitive={setIsSensitive}
                                    />
                                }
                                tab1text={'Breaking News'}
                                    tab2={
                                        <ShortNewsImageUpload
                                            accessToken={accessToken}
                                            languageOption={languageOption}
                                            title={title}
                                            setImagePath={setImagePath}
                                            imagePath={imagePath}
                                            isOriginal={isOriginal}
                                            setIsOriginal={setIsOriginal}
                                            isSensitive={isSensitive}
                                            setIsSensitive={setIsSensitive}
                                            setIsImageAdding={setIsImageAdding}
                                        />
                                    }
                                    tab2text={'Upload'}
                                    tab3={
                                        <ImageLibrary
                                            imagePath={imagePath}
                                            language_id={languageOption}
                                            setImagePath={setImagePath}
                                            isOriginal={isOriginal}
                                            setIsImageAdding={setIsImageAdding}
                                            setIsOriginal={setIsOriginal}
                                            isSensitive={isSensitive}
                                            setIsSensitive={setIsSensitive}
                                            searchTerm={searchTerm}
                                            setSearchTerm={setSearchTerm}
                                        />
                                    }
                                    tab3text={'Library'}
                                /> : <Tab
                                tab1={
                                    <ShortNewsImageUpload
                                        accessToken={accessToken}
                                        languageOption={languageOption}
                                        title={title}
                                        setImagePath={setImagePath}
                                        imagePath={imagePath}
                                        isOriginal={isOriginal}
                                        setIsOriginal={setIsOriginal}
                                        isSensitive={isSensitive}
                                        setIsSensitive={setIsSensitive}
                                        setIsImageAdding={setIsImageAdding}
                                    />
                                }
                                tab1text={'Upload'}
                                tab2={
                                    <ImageLibrary
                                        imagePath={imagePath}
                                        language_id={languageOption}
                                        setImagePath={setImagePath}
                                        isOriginal={isOriginal}
                                        setIsImageAdding={setIsImageAdding}
                                        setIsOriginal={setIsOriginal}
                                        isSensitive={isSensitive}
                                        setIsSensitive={setIsSensitive}
                                        searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}
                                    />
                                }
                                tab2text={'Library'}
                            />}</div>
                            )}
                            {isVideoAdding && (
                                <div>
                                    <Tab
                                        icon={true}
                                        tab1={
                                            <ShortNewsVideoUpload
                                                handleCancel={handleCancel}
                                                accessToken={accessToken}
                                                setVideoPath={setVideoPath}
                                                videoPath={videoPath}
                                            />
                                        }
                                        tab1text={'Upload'}
                                        tab2={
                                            <div>
                                                <TextArea
                                                    value={videoPath?.yt_url}
                                                    placeholder="YouTube Link"
                                                    onChange={handleInputChange}
                                                    style={{
                                                        minHeight: '140px',
                                                    }}
                                                />
                                                {error && (
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        {error}
                                                    </span>
                                                )}
                                                <div>
                                                    <Button
                                                        onClick={handleOk}
                                                        style={{
                                                            margin: '.5rem 0',
                                                            float: 'right',
                                                        }}
                                                        type="primary"
                                                    >
                                                        Ok
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                        tab2text={'Youtube'}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
                {(generate || isLoading) && (
                    <div className="spin_shortnews">
                        {' '}
                        <Spin tip="" size="large"></Spin>
                    </div>
                )}

                <div
                    className="news-form"
                    style={{ opacity: generate || isLoading ? '.3' : '1' }}
                >
                    <AddShortNewsForm
                        ref={childRef}
                        setImagePath={setImagePath}
                        imagePath={imagePath}
                        descriptionChange={descriptionChange}
                        titleChange={titleChange}
                        initialFormData={initialFormData}
                        languageData={languageData}
                        categories={categories}
                        accessToken={accessToken}
                        mode={mode}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        resetWriterDashboard={resetWriterDashboard}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isVideoAdding={isVideoAdding}
                        isImageAdding={isImageAdding}
                        setIsImageAdding={setIsImageAdding}
                        setIsVideoAdding={setIsVideoAdding}
                        generate={generate}
                        setGenerate={setGenerate}
                        employeeLanguage={employeeLanguage}
                        contentId={contentId}
                        setVideoPath={setVideoPath}
                        videoPath={videoPath}
                        setHashTagId={setHashTagId}
                        hashTagId={hashTagId}
                        code={code}
                        contentStatus={contentStatus}
                        setOtherLink={setOtherLink}
                        languageOption={languageOption}
                        setLanguageOption={setLanguageOption}
                        pro={pro}
                        anti={anti}
                    />
                </div>
                <div
                    className="smartphone-preview"
                    style={{ opacity: generate ? '.3' : '1' }}
                >
                    {mode === 'review' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '.8rem',
                            }}
                        >
                            <Button
                                className="width-120"
                                disabled={generate}
                                onClick={handleButtonClick}
                                type="primary"
                            >
                                Approve
                            </Button>
                        </div>
                    )}
                    <SmartphonePreview
                        image={imagePath}
                        title={title}
                        videoPath={
                            videoPath?.stream_url
                                ? videoPath?.stream_url
                                : videoPath?.yt_url
                        }
                        description={description}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddShortNews
