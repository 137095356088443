export const typeOptions = [
    {
        label: 'Short',
        value: 'short',
        label: 'Card',
        value: 'card',
        label: 'Video',
        value: 'video',
    },
]

export const writerOptions = [
    { label: 'All Writers', value: 'all' },
    { label: 'Atul', value: 'atul' },
    { label: 'Arun', value: 'arun' },
]

export const timeOptions = [
    { label: '6AM - 10AM', value: '6-10' },
    { label: '10AM - 3PM', value: '10-3' },
]
