export const breakingNewsImages = {
    2: [
        'https://static.aadhan.in/breaking_news/telugu/breaking_news1.jpg',
        'https://static.aadhan.in/breaking_news/telugu/breaking_news2.jpg',
        'https://static.aadhan.in/breaking_news/telugu/breaking_news3.jpg',
        'https://static.aadhan.in/breaking_news/telugu/breaking_news4.jpg',
        'https://static.aadhan.in/breaking_news/telugu/breaking_news5.jpg',
        'https://static.aadhan.in/breaking_news/telugu/breaking_news6.jpg',
        'https://static.aadhan.in/breaking_news/telugu/breaking_news7.jpg',
    ],
    3: [
        'https://static.aadhan.in/breaking_news/hindi/breaking_news1.jpg',
        'https://static.aadhan.in/breaking_news/hindi/breaking_news2.jpg',
        'https://static.aadhan.in/breaking_news/hindi/breaking_news3.jpg',
        'https://static.aadhan.in/breaking_news/hindi/breaking_news4.jpg',
    ],
    4: [
        'https://static.aadhan.in/breaking_news/tamil/breaking_news1.jpg',
        'https://static.aadhan.in/breaking_news/tamil/breaking_news2.jpg',
        'https://static.aadhan.in/breaking_news/tamil/breaking_news3.jpg',
        'https://static.aadhan.in/breaking_news/tamil/breaking_news4.jpg',
    ],
}
