import React from 'react'
import './report.scss'
const Repoter = () => {
  return (
    // <div className="card-container">
    //   <div className='front'>Hello</div>
    //   <div className='back'>World</div>
    // </div>
    <></>
  )
}

export default Repoter
