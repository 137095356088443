import React, { useRef, useState } from 'react'
import CropperModal from './cropperModal'
import ImageCompressor from './ImageCompressor'
import { useLocation } from 'react-router'

function ImageUploader({
    getImagePath,
    onChange,
    value,
    inHouseAd,
    modal,
    setAct,
}) {
    const [isCompress, setIsCompress] = useState('false')
    const [file, setFile] = React.useState(null)
    const [isValidFile, setisValidFile] = useState(false)
    const inputRef = useRef(null)
    const location = useLocation()
    const onFileInputChange = (e) => {
        setAct?.(true)
        if (e?.target?.files && e?.target?.files?.length > 0) {
            const fileInput = e?.target?.files[0]
            const maxSize = inHouseAd ? 1000 : 100
            if (fileInput?.size / 1024 <= maxSize) {
                setFile(e?.target?.files[0])
                setisValidFile(true)
                setIsCompress(false)
            } else {
                setisValidFile(false)
                setFile(e?.target?.files[0])
                setIsCompress(true)
            }
        }
    }

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <input
                    onClick={() => {
                        inputRef.current.value = null
                    }}
                    ref={inputRef}
                    accept="image/jpeg,image/png"
                    type="file"
                    onChange={(e) => {
                        onFileInputChange(e)
                    }}
                />

                {isCompress === true && !isValidFile ? (
                    <ImageCompressor
                        file={file}
                        modal={modal}
                        onConfirm={(ImageCompressor) => {
                            getImagePath(ImageCompressor)
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                        }}
                        onDiscard={() => {
                            inputRef.current.value = null
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                        }}
                        onCompleted={() => setFile(null)}
                    />
                ) : (
                    <CropperModal
                        modal={modal}
                        file={file}
                        onConfirm={(croppedFile) => {
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                            getImagePath(croppedFile)
                        }}
                        onDiscard={() => {
                            inputRef.current.value = null
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                        }}
                        onCompleted={() => setFile(null)}
                    />
                )}

                {/* <a href="https://squoosh.app/" target="_blank">
                Open Squoosh to Format Image
            </a> */}
            </div>
            <div>
                {!location.pathname.includes('shortNews') && (
                    <div>
                        <div
                            style={{
                                color: '#199be9',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div>
                                {' '}
                                <span>Image size 540</span>{' '}
                                <span style={{ padding: '0 2px' }}>x</span>{' '}
                                <span>525</span>
                            </div>
                        </div>
                        <div
                            style={{
                                color: '#199be9',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>Max size 100kb</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ImageUploader
