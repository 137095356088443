import React, { useEffect, useState } from 'react'
import translate from 'translate'
import { Button, Modal, Radio } from 'antd'
import { useDispatch} from 'react-redux'
import { updateHoroscopeContent } from '../../store/slice/HoroscopeSlice'
const TranslateModal = ({
    horoscopeType,
    date,
    currentLanguage,
    setGeneral,
    familyLove,
    setFamilyLove,
    finance,
    setFinance,
    health,
    setHealth,
    jobCareer,
    setJobCareer,
    general,
    setTranslateHeader,
    setLanguage,
    horoscopeName,
    arraay,
    horoscopeData
}) => {
    
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage)
    const [lan,setLan] = useState(currentLanguage);
    const[isHaving,setIshaving]=useState(false);
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const languages = ['English', 'Telugu', 'Hindi', 'Tamil']
    function handleLanguageChange(e) {
        setSelectedLanguage(e.target.value)
        setIshaving(false)
        
        if (
            (horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.finance !== null && horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.finance !== "") || 
            (horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.family !== null && horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.family !== "") ||
            (horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.career !== null && horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.career !== "") ||
            (horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.health !== null && horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.health !== "") ||
            (horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.general !== null && horoscopeData[horoscopeName]?.[arraay[e.target.value]]?.general !== "")
        ) {
            setIshaving(true);
            
        } else {
            setIshaving(false);
        }
        
        
       

    }
    
    
    const translateText = async (targetLanguage) => {
        let generalTranslate = "";
        let jobCareerTranslate = "";
        let healthTranslate = "";
        let financeTranslate = "";
        let familyLoveTranslate = "";
    
        try {
            if (general) {
                generalTranslate = await translate(general, { from: lan === 'English' ? 'english' : lan === 'Tamil' ? 'ta' : lan === 'Telugu' ? 'te' : 'hindi', to: targetLanguage });
                setGeneral(generalTranslate);
            }
    
            if (familyLove) {
                familyLoveTranslate = await translate(familyLove, { from: lan === 'English' ? 'english' : lan === 'Tamil' ? 'ta' : lan === 'Telugu' ? 'te' : 'hindi', to: targetLanguage });
                setFamilyLove(familyLoveTranslate);
            }
    
            if (finance) {
                financeTranslate = await translate(finance, { from: lan === 'English' ? 'english' : lan === 'Tamil' ? 'ta' : lan === 'Telugu' ? 'te' : 'hindi', to: targetLanguage });
                setFinance(financeTranslate);
            }
    
            if (jobCareer) {
                jobCareerTranslate = await translate(jobCareer, { from: lan === 'English' ? 'english' : lan === 'Tamil' ? 'ta' : lan === 'Telugu' ? 'te' : 'hindi', to: targetLanguage });
                setJobCareer(jobCareerTranslate);
            }
    
            if (health) {
                healthTranslate = await translate(health, { from: lan === 'English' ? 'english' : lan === 'Tamil' ? 'ta' : lan === 'Telugu' ? 'te' : 'hindi', to: targetLanguage });
                setHealth(healthTranslate);
            }
    
            await dispatch(updateHoroscopeContent([
                { gen:  generalTranslate },
                { job:  jobCareerTranslate },
                { health: healthTranslate },
                { finance:  financeTranslate },
                { familyLove: familyLoveTranslate }
            ]));
        } catch (error) {
            console.error('Translation error:', error);
        }
        setLanguage(selectedLanguage);
        setTranslateHeader(selectedLanguage);
    };
    
    
       
    function handleTranslate() {
        translateText(
            selectedLanguage === 'Tamil'
                ? 'ta'
                : selectedLanguage === 'Telugu'
                ? 'te'
                : selectedLanguage === 'English'
                ? 'english'
                : 'hindi'
        )

        setLan(selectedLanguage)
        localStorage.setItem('onTranslate', true);

    
        handleOk()
    }
  
    useEffect(()=>{
     setLan(currentLanguage)
    },[currentLanguage])

    return (
        <>
            <Button type='primary' onClick={showModal}>Translate</Button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                footer={false}
                closable={false}
                style={{ textAlign: 'center' }}
                onCancel={handleCancel}
            >
                <h6>{'Translate ' + horoscopeType + ' of ' + date}</h6>
                <hr></hr>
                <div
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                    <div>
                        <h6>From</h6>
                        <Button
                            style={{
                                backgroundColor: 'lightGray',
                                padding: '0 2rem 0 2rem',
                            }}
                        >
                            {lan}
                        </Button>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h6>To</h6>
                        <Radio.Group onChange={(e) => handleLanguageChange(e)}>
                            {languages.map((e) => {
                                if (e !== lan) {
                                    return (
                                        <p>
                                            <Radio
                                                style={{
                                                    color: 'blue',
                                                    fontWeight: '500',
                                                }}
                                                value={e}
                                                key={e}
                                            >
                                                {e}
                                            </Radio>
                                        </p>
                                    )
                                }
                            })}
                        </Radio.Group>
                    </div>
                </div>
                {isHaving && <p style={{color:"red"}}>{"* For this date "+date+", Daily Horoscope in "+selectedLanguage+" language was already updated. Do you want to proceed and Over write the content ?"}</p>}
                <Button
                    type='primary'
                    onClick={() => handleTranslate()}
                    style={{
                        
                        padding: '0 2rem 0 2rem',
                    }}
                >
                   {isHaving ? "Yes  Translate" :" Translate"}
                </Button>
            </Modal>
        </>
    )
}
export default TranslateModal
