import { Form, Input, Select, message } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { createSource, updateSource } from '../../AadhanApi'

const AddSource = ({
    setShowModal,
    selectedSource,
    getSourceTable,
    accessToken,
    selectedLanguage,
}) => {
    const [form] = Form.useForm()
    const languageData = useSelector((state) => state.app.languageData)
    const employeeId = useSelector((state) => state.app.employeeId)
    const [messageApi, contextHolder] = message.useMessage()
    const onFinish = async (values) => {
        const obj = {
            source_name: values.sourceName,
            language_id: values.language,
            source_logo_url: '',
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            is_active: true,
        }
        try {
            if (selectedSource) {
                await updateSource(obj, accessToken, selectedSource.source_id)
            } else {
                await createSource(obj, accessToken)
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }

        if (getSourceTable) getSourceTable()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedSource) {
            form.setFieldsValue({
                sourceName: selectedSource?.source_name,
                language: selectedLanguage,
            })
        } else {
            form.resetFields()
        }
    }, [selectedSource])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    return (
        <>
            {contextHolder}
            <Form
                id="sourceForm"
                form={form}
                name="sourceForm"
                scrollToFirstError
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Source Name"
                    name="sourceName"
                    rules={[
                        { required: true, message: 'Please enter source name' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select Language' },
                    ]}
                >
                    <Select placeholder="--Select Language--">
                        {languageData.map((option, index) => (
                            <Select.Option
                                key={index}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </>
    )
}

export default AddSource
