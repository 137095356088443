import React, { useEffect, useState } from 'react'
import { Table, Popconfirm, Button, Modal } from 'antd'
import AddEmployeeShift from './AddEmployeeShift'
import './employeeShifts.scss'
import moment from 'moment'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getEmployeeShifts, deleteEmployeeShifts } from '../../AadhanApi'

function EmployeeShifts() {
    const accessToken = useSelector((state) => state.app.accessToken)
    const [selectedShift, setSelectedShift] = useState(null)
    const [shiftData, setShiftData] = useState([])
    const [shiftModal, setShiftModal] = useState(false)

    const fetchShifts = async () => {
        try {
            const response = await getEmployeeShifts(accessToken)
            setShiftData(response?.data?.data)
        } catch (error) {
            console.error('Failed to fetch shift data:', error)
        }
    }

    const handleDeleteShift = async (record) => {
        try {
            await deleteEmployeeShifts(accessToken, record?.shift_id)
            fetchShifts()
        } catch (error) {
            console.log(error)
        }
    }

    const handleAddShift = () => {
        setSelectedShift(null)
        setShiftModal(true)
    }

    const handleEditShift = (record) => {
        setSelectedShift(record)
        setShiftModal(true)
    }

    useEffect(() => {
        fetchShifts()
    }, [])

    useEffect(() => {
        if (!shiftModal) {
            setSelectedShift(null)
        }
    }, [shiftModal])

    const employeeShiftColumns = [
        {
            title: 'Shift Name',
            dataIndex: 'shift_name',
            key: 'shift_name',
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (_, record) => (
                <span>
                    {moment.unix(record.start_time).format('DD/MM/YYYY h:mm A')}
                </span>
            ),
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',
            render: (_, record) => (
                <span>
                    {moment.unix(record.end_time).format('DD/MM/YYYY h:mm A')}
                </span>
            ),
        },
        {
            title: 'Action',
            render: (text, record) => (
                <span>
                    <EditOutlined
                        style={{ paddingRight: '5px', cursor: 'pointer' }}
                        onClick={() => handleEditShift(record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteShift(record)}
                    >
                        <DeleteOutlined
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ]

    return (
        <div>
            <div className="shifts">
                <h2>Manage Employee Shifts</h2>
                <Button
                    style={{ backgroundColor: '#1677ff', color: 'white' }}
                    onClick={handleAddShift}
                >
                    Add Employee Shifts
                </Button>
            </div>
            <div className="tableShift">
                <Table
                    columns={employeeShiftColumns}
                    dataSource={shiftData}
                    pagination={false}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Modal
                    open={shiftModal}
                    closable={true}
                    title={
                        selectedShift
                            ? 'Edit Employee Shift'
                            : 'Add Employee Shift'
                    }
                    onCancel={() => setShiftModal(false)}
                    footer={[
                        <div style={{ width: '100%' }}>
                            <Button
                                style={{ margin: '0 auto', display: 'block' }}
                                form="shiftForm"
                                key="submit"
                                htmlType="submit"
                                type="primary"
                            >
                                Submit
                            </Button>
                            ,
                        </div>,
                    ]}
                >
                    <AddEmployeeShift
                        selectedShift={selectedShift}
                        accessToken={accessToken}
                        setShowModal={setShiftModal}
                        refreshShifts={fetchShifts}
                        shiftModal={shiftModal}
                    />
                </Modal>
            </div>
        </div>
    )
}

export default EmployeeShifts
