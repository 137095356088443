import { Select, message, DatePicker } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import { getStateContentReport, getWriters } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { typeOptions } from './StateContentReport.constant'
import dayjs from 'dayjs'
import './StateContentReport.scss'

const StateContentReport = () => {
    const [reportsData, setReportsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [writerDetails, setWriterDetails] = useState([])
    const [messageApi, contextHolder] = message.useMessage()
    const [day, setDay] = useState(dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
    const [typeOption, setTypeOption] = useState(typeOptions[0]?.value)
    const [writerOption, setWriterOption] = useState('All')

    const stateContentTableColumns = [
        {
            title: 'State',
            dataIndex: 'state_name',
            key: 'state_name',
        },
        {
            title: '# Posts',
            dataIndex: 'published_posts',
            key: 'published_posts',
        },
        {
            title: 'Target',
            dataIndex: 'daily_content_target',
            key: 'daily_content_target',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                    {record?.daily_content_target == 0 ? <div className="not-applicable">NA</div>:
                        record?.published_posts > record?.daily_content_target ? 
                            <div className="achieved">Achieved</div> 
                            : 
                            <div className="missed">Missed</div>
                    }
                </div>
            )
        }
    ]

    useEffect(() => {
        if(languageOption) {
            getWriterDetails()
        }
    }, [languageOption])

    useEffect(() => {
        if(languageOption) {
            getStateContentReportDetails()
        }
    }, [languageOption, day, typeOption, writerOption])

    const getWriterDetails = async () => {
        setDataLoading(true)
        try {
            const response = await getWriters(
                languageOption,
                "active",
                "internal",
                accessToken
            )
            setWriterDetails(response.data)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get writer details',
                duration: 5,
            })
        }
    }


    const getStateContentReportDetails = async () => {
        const formatedDate = dayjs(day).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const response = await getStateContentReport(
                languageOption,
                formatedDate,
                typeOption,
                writerOption,
                accessToken
            )
            setReportsData(response.data)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const onChange = (date) => {
        const dateFormat = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        setDay(dateFormat)
    }

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>State Content Report</h2>
                <div className="date-picker-container">
                        <span style={{ marginRight: '5px' }}>Date Range :</span>
                        <DatePicker 
                            style={{ width: 200 }}
                            format="DD/MM/YYYY"
                            onChange={onChange}
                            defaultValue={dayjs()}
                        />
                </div>
            </div>
            <div className="manage-content-inputs">
                <Select 
                    value={languageOption} 
                    style={{ width: 200 }}
                    onChange={(val) => setLanguageOption(val)}
                >
                    {languageData.map((option, index) => {
                        return (
                            <Option 
                                key={option?.language_id} 
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    style={{ width: 200 }} 
                    value={typeOption}
                    onChange={(val) => setTypeOption(val)}
                >
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={writerOption}
                    style={{ width: 200 }}
                    onChange={(val) => setWriterOption(val)}
                >
                    <Option key='All' value='All'>
                        All Writers
                    </Option>
                    {writerDetails.map((option) => {
                        return (
                            <Option key={option.employee_id} value={option.employee_id}>
                                {option.employee_name}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <CommonTable 
                columns={stateContentTableColumns} 
                data={reportsData}
                dataLoading={dataLoading} 
            />
        </div>
    )
}

export default StateContentReport
