import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import './CommonTable.scss'
import AddHoroscopeModal from '../AddHoroscope/AddHoroscopeModal'
import AddPanchangamModal from '../AddPanchangam/AddPanchangamModal'
import {
    getDailyHoroscope,
    getMonthlyHoroscope,
    getPanchangamDate,
    getWeeklyHoroscope,
    getYearlyHoroscope,
} from '../../AadhanApi'
import { languageOptions } from '../AddAd/AddAdForm/AddAdConstant'

const HoroscopeTable = ({
    columns,
    token,
    setDataLoading,
    dataLoading,
    rowClassName,
    disablePagination,
    onRow,
    language,
    year,
    month,
    horoscopeType,
    option,
    setLanguageOption,
}) => {
    const languages = {1:'English',2: 'Telugu',3:'Hindi', 4:'Tamil'}
    const [inputColumns, setInputColums] = useState([...columns])
    const [paginationOptions, setpaginationOptions] = useState({
        pageSizeOptions: ['6'],
        showSizeChanger: true,
        current: 1,
    })
    
    
    const [allDates, setAllDates] = useState([])
    const [languageName, setLanguageName] = useState(languages[language])
    
    
    function monthToNumber(month) {
        if (month.toLowerCase() === 'january') {
            return 1
        } else if (month.toLowerCase() === 'february') {
            return 2
        } else if (month.toLowerCase() === 'march') {
            return 3
        } else if (month.toLowerCase() === 'april') {
            return 4
        } else if (month.toLowerCase() === 'may') {
            return 5
        } else if (month.toLowerCase() === 'june') {
            return 6
        } else if (month.toLowerCase() === 'july') {
            return 7
        } else if (month.toLowerCase() === 'august') {
            return 8
        } else if (month.toLowerCase() === 'september') {
            return 9
        } else if (month.toLowerCase() === 'october') {
            return 10
        } else if (month.toLowerCase() === 'november') {
            return 11
        } else if (month.toLowerCase() === 'december') {
            return 12
        } else {
            return null // Invalid month
        }
    }
    function formatDate(dateString) {
        if (!dateString || typeof dateString !== 'string') {
            return ''; // Handle null, undefined, or non-string input
        }
    
        const [year, month, day] = dateString.split('-').map(Number);
    
        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            return ''; // Handle invalid date format
        }
    
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const monthName = months[month - 1]; // Adjust for zero-based index
        const formattedDay = String(day).padStart(2, '0');
        const formattedMonth = monthName;
        const formattedDate = `${formattedDay} - ${formattedMonth} - ${year}`;
    
        return formattedDate;
    }
    async function getAllDatesForMonth(month, year) {
        const currMonth = monthToNumber(month)
        setDataLoading(true)
        const dailyData = await getDailyHoroscope(token, year, currMonth)

        const t = dailyData.data.data.map((e) => {
            return {
                ...e,
                day: formatDate(e.day),
                Aries: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'aries'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Aries'}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        setLanguageOption={setLanguageOption}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Aquarius: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        horoscopeName={'aquarius'}
                        language={languageName}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        month={month}
                        title={'Aquarius'}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Capricon: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'capricorn'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Capricorn'}
                        date={formatDate(e.day)}
                        setRender={setRender}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                    />
                ),
                Gemini: (
                    <AddHoroscopeModal
                        setRender={setRender}
                        token={token}
                        horoscopeName={'gemini'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Gemini'}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                    />
                ),
                Leo: (
                    <AddHoroscopeModal
                        token={token}
                        setRender={setRender}
                        horoscopeName={'leo'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Leo'}
                        setLanguageOption={setLanguageOption}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                    />
                ),
                Libra: (
                    <AddHoroscopeModal
                        token={token}
                        setRender={setRender}
                        horoscopeName={'libra'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Libra'}
                        setLanguageOption={setLanguageOption}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                    />
                ),

                Sagittarius: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        setRender={setRender}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Sagittarius'}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'sagittarius'}
                    />
                ),
                Pisces: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        setRender={setRender}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Pisces'}
                        setLanguageOption={setLanguageOption}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'pisces'}
                    />
                ),
                Taurus: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        setRender={setRender}
                        token={token}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Taurus'}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'taurus'}
                    />
                ),
                Scorpio: (
                    <AddHoroscopeModal
                        token={token}
                        setRender={setRender}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Scorpio'}
                        setLanguageOption={setLanguageOption}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'scorpio'}
                    />
                ),
                Virgo: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        setRender={setRender}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Virgo'}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'virgo'}
                    />
                ),
                Cancer: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        setRender={setRender}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Cancer'}
                        date={formatDate(e.day)}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'cancer'}
                    />
                ),
            }
        })

        setAllDates(t)
        setDataLoading(false)
    }

    const handleOnchange = (pagination, filters, sorter) => {
        setpaginationOptions({ ...pagination })
        
        if (sorter.field && sorter.order) {
            setInputColums((prevState) => {
                const sortedColumn = prevState.find(
                    (col) => col.dataIndex === sorter.field
                )
                sortedColumn.sortOrder = sorter.order
                return [...prevState]
            })
        }
    }

    useEffect(() => {
        setInputColums([...columns])
    }, [columns])

    function formatDateRange(dateRange) {
        const [startDate, endDate] = dateRange.split(' - ')
        const [startYear, startMonth, startDay] = startDate
            .split('-')
            .map(Number)
        const [endYear, endMonth, endDay] = endDate.split('-').map(Number)

        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ]
        const formattedStartDate = `${String(startDay).padStart(2, '0')} ${
            months[startMonth - 1]
        } ${String(startYear).slice(-2)}`
        const formattedEndDate = `${String(endDay).padStart(2, '0')} ${
            months[endMonth - 1]
        } ${String(endYear).slice(-2)}`

        return `${formattedStartDate} to ${formattedEndDate}`
    }
    async function getAllDatesForWeekly(year, month) {
        const currMonth = monthToNumber(month)
        setDataLoading(true)
        const dailyData = await getWeeklyHoroscope(token, year, currMonth)

        const t = dailyData.data.data.map((e) => {
            return {
                ...e,
                day: formatDateRange(
                    e.week_start_date + ' - ' + e.week_end_date
                ),
                Aries: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'aries'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Aries'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        setLanguageOption={setLanguageOption}
                        setRender={setRender}
                        horoscopeData={e.data}
                    />
                ),
                Aquarius: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        horoscopeName={'aquarius'}
                        language={languageName}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        month={month}
                        title={'Aquarius'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        setRender={setRender}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                    />
                ),
                Capricon: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'capricorn'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Capricon'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        setRender={setRender}
                        horoscopeData={e.data}
                    />
                ),
                Gemini: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'gemini'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Gemini'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Leo: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'leo'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Leo'}
                        setLanguageOption={setLanguageOption}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Libra: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'libra'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Libra'}
                        setLanguageOption={setLanguageOption}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Sagittarius: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Sagittarius'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'sagittarius'}
                        setRender={setRender}
                    />
                ),
                Pisces: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Pisces'}
                        setLanguageOption={setLanguageOption}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'pisces'}
                        setRender={setRender}
                    />
                ),
                Taurus: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Taurus'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'taurus'}
                        setRender={setRender}
                    />
                ),
                Scorpio: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Scorpio'}
                        setLanguageOption={setLanguageOption}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'scorpio'}
                        setRender={setRender}
                    />
                ),
                Virgo: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Virgo'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                        horoscopeName={'virgo'}
                    />
                ),
                Cancer: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Cancer'}
                        date={formatDateRange(
                            e.week_start_date + ' - ' + e.week_end_date
                        )}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'cancer'}
                        setRender={setRender}
                    />
                ),
            }
        })

        setAllDates(t)
        setDataLoading(false)
    }

    async function generateMonths(year) {
        const monthsByNumber = {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December',
        }
        setDataLoading(true)
        const mothlyData = await getMonthlyHoroscope(token, year)

        const t = mothlyData.data.data.map((e) => {
            return {
                ...e,
                day: monthsByNumber[e.month],
                Aries: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'aries'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Aries'}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        setLanguageOption={setLanguageOption}
                        setRender={setRender}
                        horoscopeData={e.data}
                    />
                ),
                Aquarius: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        horoscopeName={'aquarius'}
                        language={languageName}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        month={month}
                        title={'Aquarius'}
                        date={monthsByNumber[e.month]}
                        setRender={setRender}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                    />
                ),
                Capricon: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'capricorn'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Capricon'}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        setRender={setRender}
                        horoscopeData={e.data}
                    />
                ),
                Gemini: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'gemini'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Gemini'}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Leo: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'leo'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Leo'}
                        setLanguageOption={setLanguageOption}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Libra: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'libra'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Libra'}
                        setLanguageOption={setLanguageOption}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Sagittarius: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Sagittarius'}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'sagittarius'}
                        setRender={setRender}
                    />
                ),
                Pisces: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Pisces'}
                        setLanguageOption={setLanguageOption}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'pisces'}
                        setRender={setRender}
                    />
                ),
                Taurus: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Taurus'}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'taurus'}
                        setRender={setRender}
                    />
                ),
                Scorpio: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Scorpio'}
                        setLanguageOption={setLanguageOption}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'scorpio'}
                        setRender={setRender}
                    />
                ),
                Virgo: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Virgo'}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                        horoscopeName={'virgo'}
                    />
                ),
                Cancer: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Cancer'}
                        date={monthsByNumber[e.month]}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'cancer'}
                        setRender={setRender}
                    />
                ),
            }
        })

        setAllDates(t)
        setDataLoading(false)
    }

    async function generateYears() {
        setDataLoading(true)

        const YearlyData = await getYearlyHoroscope(token)

        const t = YearlyData.data.data.map((e) => {
            return {
                ...e,
                day: e.year,
                Aries: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'aries'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Aries'}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        setLanguageOption={setLanguageOption}
                        setRender={setRender}
                        horoscopeData={e.data}
                    />
                ),
                Aquarius: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        horoscopeName={'aquarius'}
                        language={languageName}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        month={month}
                        title={'Aquarius'}
                        date={e.year}
                        setRender={setRender}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                    />
                ),
                Capricon: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'capricorn'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Capricon'}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        setRender={setRender}
                        horoscopeData={e.data}
                    />
                ),
                Gemini: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'gemini'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Gemini'}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Leo: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'leo'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Leo'}
                        setLanguageOption={setLanguageOption}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Libra: (
                    <AddHoroscopeModal
                        token={token}
                        horoscopeName={'libra'}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Libra'}
                        setLanguageOption={setLanguageOption}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                    />
                ),
                Sagittarius: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Sagittarius'}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'sagittarius'}
                        setRender={setRender}
                    />
                ),
                Pisces: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Pisces'}
                        setLanguageOption={setLanguageOption}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'pisces'}
                        setRender={setRender}
                    />
                ),
                Taurus: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        setLanguageOption={setLanguageOption}
                        year={year}
                        title={'Taurus'}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'taurus'}
                        setRender={setRender}
                    />
                ),
                Scorpio: (
                    <AddHoroscopeModal
                        token={token}
                        Id={e.horoscope_id}
                        language={languageName}
                        month={month}
                        year={year}
                        title={'Scorpio'}
                        setLanguageOption={setLanguageOption}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'scorpio'}
                        setRender={setRender}
                    />
                ),
                Virgo: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Virgo'}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        setRender={setRender}
                        horoscopeName={'virgo'}
                    />
                ),
                Cancer: (
                    <AddHoroscopeModal
                        Id={e.horoscope_id}
                        token={token}
                        language={languageName}
                        month={month}
                        year={year}
                        setLanguageOption={setLanguageOption}
                        title={'Cancer'}
                        date={e.year}
                        horoscopeType={horoscopeType}
                        horoscopeData={e.data}
                        horoscopeName={'cancer'}
                        setRender={setRender}
                    />
                ),
            }
        })

        setAllDates(t)
        setDataLoading(false)
    }
    
    async function getPanchangamDateData() {
        setDataLoading(true)
        const monthNumber = monthToNumber(month)
        const data = await getPanchangamDate(year, monthNumber, token)
        

        const updatedPanchangamData = data.data.data.map((e) => {
            return {
                ...e,
                Date:formatDate(e.day),
                Add: (
                    <AddPanchangamModal
                        language={language}
                        token={token}
                        setDataLoading={setDataLoading}
                        Date={formatDate(e.day)}
                        panchangamData={e}
                        title={'Add Panchangam'}
                        setRender={setRender}
                    />
                ),
            }
        })

        setAllDates(updatedPanchangamData)
        setDataLoading(false)
    }

    const [render, setRender] = useState(0)

    useEffect(() => {
        setAllDates([])
        setLanguageName(languages[language])

        if (horoscopeType === 'Daily Horoscope' && option !== 'panchangam') {
            setRender(0)
            getAllDatesForMonth(month, year)
        
        } else if (
            horoscopeType === 'Weekly Horoscope' &&
            option !== 'Panchangam'
        ) {
            setRender(0)
            getAllDatesForWeekly(year, month)
            
        } else if (
            horoscopeType === 'Monthly Horoscope' &&
            option !== 'panchangam'
        ) {
            setRender(0)
            generateMonths(year)
        
        } else if (
            horoscopeType === 'Yearly Horoscope' &&
            option !== 'panchangam'
        ) {
            setRender(0)
            
            generateYears()
        } else {
            setRender(0)
            getPanchangamDateData()
        }
    }, [
        language,
        month,
        year,
        languageName,
        horoscopeType,
        option,
        languageOptions,
        render,
        
    ])

    // useEffect(() => {
    //     setpaginationOptions({ ...paginationOptions, current: 1 })

    // }, [allDates])

    return (
        <div className="table-container">
            <Table
                scroll={{ x: '100%', y: option === 'panchangam' ? 366 : 320 }}
                pagination={false}
                columns={inputColumns}
                dataSource={allDates}
                loading={dataLoading}
                onChange={handleOnchange}
                rowClassName={rowClassName}
                onRow={onRow}
            />
        </div>
    )
}
export default HoroscopeTable
