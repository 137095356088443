import { Form, Input, Select, message, Button} from 'antd'
import { CheckCircleOutlined, RedoOutlined } from '@ant-design/icons'
import CommonTable from '../CommonTable/CommonTable'
import { getElectionData, postPartyDataEntry } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { typeOptions } from './DataEntry.constants'
import './DataEntry.scss'

let globalObj = {};
const DataEntry = () => {
    const [dataLoading, setDataLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [stateOption, setStateOption] = useState('')
    const [mediaSources, setMediaSources] = useState([])
    const [electionData, setElectionData] = useState([])
    const [typeSelection, setTypeSelection] = useState(typeOptions[0]?.value)
    const [mediaSelection, setMediaSelection] = useState('')
    const [partyWiseData, setPartyWiseData] = useState([])
    const [successMsg, setSuccessMsg] = useState(false)

    useEffect(() => {
        if (successMsg) {
            const timeoutId = setTimeout(() => {
                setSuccessMsg(false);
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [successMsg]);

    const changeRecord = (event, record, partyName) => {
        let leading = record.leading;
        let won = record.won;
        if(globalObj?.data?.[record.name]) {
            leading = globalObj.data[record.name].leading
            won = globalObj.data[record.name].won
        }
        if (partyName == 'leading') {
            leading = event.target.value
        } else {
            won = event.target.value
        }
        let obj = {
            data: {
                ...globalObj.data,
                [record.name]: { "leading": leading, "won": won }
            }
        }
        globalObj = obj
    }

    const electionTableColumns = [
        {
            title: 'Party',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Leading',
            dataIndex: 'leading',
            key: 'leading',
            render: (val, record) => {
                return (
                    <Form.Item className='election-input-box'>
                        <Input defaultValue={val} onChange={(event) => changeRecord(event, record, "leading")} />
                        <CheckCircleOutlined style={{ 'marginLeft': '5px', 'color': 'blue', 'cursor': 'pointer' }} onClick={() => onFinish('checkMark')} />
                    </Form.Item>
                )
            }
        },
        {
            title: 'Won',
            dataIndex: 'won',
            key: 'won',
            render: (val, record) => {
                return (
                    <Form.Item className='election-input-box'>
                        <Input defaultValue={val} onChange={(event) => changeRecord(event, record, 'won')} />
                        <CheckCircleOutlined style={{ 'marginLeft': '5px', 'color': 'blue', 'cursor': 'pointer' }} onClick={() => onFinish('checkMark')} />
                    </Form.Item>
                )
            }
        },
        {
            title: 'Total',
            dataIndex: 'contested',
            key: 'contested',
            render: (val, record) => {
                return (
                    <p>{record.leading + record.won}</p>
                )
            }
        }
    ]

    useEffect(() => {
        getElectionDataInfo()
    }, [stateOption, mediaSelection])

    const getElectionDataInfo = async () => {
        setDataLoading(true)
        try {
            const response = await getElectionData(typeSelection, accessToken)
            setElectionData(response.data?.states)
            setStateOption(stateOption || response.data?.states[0]?.name)
            setMediaSelection(mediaSelection || response.data?.states[0]?.media_sources[0]?.name)
            let stateIndex;
            let mediaIndex;
            if (stateOption) {
                stateIndex = response.data?.states?.findIndex((ele, i) => ele.name == stateOption)
            }
            if (mediaSources.length) {
                mediaIndex = response.data?.states[stateIndex]?.media_sources?.findIndex((ele, i) => ele.name == mediaSelection)
                setMediaSources(mediaSources.length ? mediaSources : response.data?.states[stateIndex]?.media_sources)
            } else {
                setMediaSources(response.data?.states[0]?.media_sources)
            }
            if (partyWiseData.length) {
                setPartyWiseData(response.data?.states[stateIndex]?.media_sources[mediaIndex]?.party_wise_data)
            } else {
                setPartyWiseData(response.data?.states[0]?.media_sources[0]?.party_wise_data)
            }
            setDataLoading(false)
        } catch (err) {
            setElectionData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const onFinish = async (check) => {
        try {
            await postPartyDataEntry(typeSelection, stateOption, mediaSelection, globalObj, accessToken)
            globalObj = {}
            getElectionDataInfo()
            setSuccessMsg(true)
        } catch (err) {
            console.log(err)
        }
    }

    const changeState = (val) => {
        setStateOption(val)
        let filteredState = electionData?.find(ele => ele.name === val)
        setMediaSelection(filteredState?.media_sources[0]?.name)
        setMediaSources(filteredState?.media_sources)
        setPartyWiseData(filteredState?.media_sources[0]?.party_wise_data || [])
    }

    const changeMedia = (val) => {
        setMediaSelection(val)
        let filteredState = electionData?.find(ele => ele.name === stateOption)
        let filteredMedia = filteredState?.media_sources?.find(ele => ele.name === val)
        setPartyWiseData(filteredMedia?.party_wise_data || [])
    }

    const { Option } = Select
    return (
        <div className="report-container data-entry-container">
            <div className="content-header data-entry-header">
                <h2>Election results</h2>
                 {successMsg &&
                    <div className='success-msg'>Election results saved successfully</div>
                }
                <Button className='refresh-button' onClick={() => getElectionDataInfo()}>
                    <RedoOutlined />
                    Refresh
                </Button>
            </div>
            <div className="manage-content-inputs">
                <Select
                    placeholder="Select Election"
                    value={typeSelection}
                    style={{ width: 200 }}
                    onChange={(val) => setTypeSelection(val)}
                >
                    {typeOptions.map((option) => {
                        return (
                            <Option key={option?.value} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    placeholder="Select State"
                    style={{ width: 150 }}
                    onChange={(val) => changeState(val)}
                    value={stateOption}
                >
                    {electionData?.map((option) => (
                        <Select.Option
                            key={option.name}
                            value={option.name}
                        >
                            {option.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    placeholder="Select Media Source"
                    style={{ width: 150 }}
                    onChange={(val) => changeMedia(val)}
                    value={mediaSelection}
                >
                    {mediaSources?.map((option) => (
                        <Select.Option
                            key={option.name}
                            value={option.name}
                        >
                            {option.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <CommonTable
                key={JSON.stringify(partyWiseData)}
                columns={electionTableColumns}
                data={partyWiseData}
                dataLoading={dataLoading}
            />
            {partyWiseData.length > 0 &&
                <button className='save-btn' onClick={() => onFinish()}>
                    <span>Save</span>
                </button>
            }
        </div>
    )
}

export default DataEntry;