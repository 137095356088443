import './SmartphonePreview.scss';
import { Checkbox } from 'antd';

const SmartphonePreviewUploadLibrary = ({
    imagePath,
    videoPath,
    setIsSensitive,
    isSensitive,
    isOriginal,
    setIsOriginal,
}) => {
    const getImage = () => {
        return (
            <img
                alt="news"
                style={{objectFit:"cover",height:260,width:260,borderRadius:5,padding:1,boxShadow:"2px 2px 2px lightgray",marginBottom:"1rem"}}
                src={imagePath}
            />
        );
    };
    return (
        <div className="smartphone-preview-shortnews_" style={{marginTop:"2rem"}}>
            {!videoPath && getImage()}
            {imagePath && (
                <div className="content_image_type_">
                    <Checkbox
                        className="check_box_preview_"
                        checked={isOriginal}
                        onChange={(e) => setIsOriginal(e.target.checked)}
                    >
                        Original Image
                    </Checkbox>
                    <Checkbox
                        className="check_box_preview_"
                        checked={isSensitive}
                        onChange={(e) => setIsSensitive(e.target.checked)}
                    >
                        Sensitive Content
                    </Checkbox>
                </div>
            )}
        </div>
    );
};

export default SmartphonePreviewUploadLibrary;
