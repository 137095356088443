export const employeeTypeOptions = [
    {
        value: 'employee',
        label: 'Employee',
    },
    {
        value: 'reporter',
        label: 'Reporter',
    }
]

export const audienceType = [
    {
        label: "To Selected Language Audience",
        value: "languageAudience",
    },
    {
        label: "To Custom Audience",
        value: "customAudience",
    }
]