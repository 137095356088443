import { Select, message } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import './CategoryTimeSlotReport.scss'
import CommonTable from '../CommonTable/CommonTable'
import {
    timeOptions,
    typeOptions,
    userTypeOptions,
} from './CategoryTimeSlotReport.constant'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
import { useEffect, useState } from 'react'
import { getWriters, getCategoryReport } from '../../AadhanApi'

const CategoryTimeSlotReport = () => {
    const [reportsData, setReportsData] = useState([])
    const [writerDetails, setWriterDetails] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [writerOption, setWriterOption] = useState('All')
    const [timeOption, setTimeOption] = useState(timeOptions[0]?.value)
    const [typeOption, setTypeOption] = useState(typeOptions[0]?.value)
    const [userTypeOption, setUserTypeOption] = useState(userTypeOptions[0]?.value)
    const [messageApi, contextHolder] = message.useMessage()
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const categoryTimeSlotTableColumns = [
        {
            title: 'Category Name',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: '# Posts',
            dataIndex: 'no_of_posts',
            key: 'no_of_posts',
        }
    ]

    useEffect(() => {
        if(languageOption) {
            getWriterDetails()
        }
    }, [languageOption])

    useEffect(() => {
        if(languageOption) {
            categoryReportDetails()
        }
    }, [languageOption, dates, writerOption, timeOption, typeOption, userTypeOption])

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }

    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
    }

    const getWriterDetails = async () => {
        setDataLoading(true)
        try {
            const response = await getWriters(
                languageOption,
                "active",
                "internal",
                accessToken
            )
            setWriterDetails(response.data)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get writer details',
                duration: 5,
            })
        }
    }

    const categoryReportDetails = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        const [startTime, endTime] = timeOption.split('-')
        setDataLoading(true)
        try {
            const response = await getCategoryReport(
                languageOption,
                typeOption,
                startTime,
                endTime,
                startDateFormat,
                endDateFormat,
                writerOption,
                userTypeOption,
                accessToken
            )
            setReportsData(response.data.data)
            setDataLoading(false)
            
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Category Time Slot Report</h2>
                <div className="date-picker-container">
                        <span style={{ marginRight: '5px' }}>Date Range :</span>
                        <ManageContentDateRange
                            dates={dates}
                            addDates={addDates}
                            clearDates={clearDates}
                        />
                </div>
            </div>
            <div className="manage-content-inputs">
                <Select 
                    value={languageOption} 
                    style={{ width: 200 }}
                    onChange={(val) => setLanguageOption(val)}
                >
                    {languageData.map((option, index) => {
                        return (
                            <Option key={option?.language_id} value={option?.language_id}>
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select 
                    style={{ width: 200 }} 
                    value={typeOption}
                    onChange={(val) => setTypeOption(val)}
                >
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={writerOption}
                    style={{ width: 200 }}
                    onChange={(val) => setWriterOption(val)}
                >
                    <Option key='All' value='All'>
                        All Writers
                    </Option>
                    {writerDetails.map((option) => {
                        return (
                            <Option key={option.employee_id} value={option.employee_id}>
                                {option.employee_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select 
                    style={{ width: 200 }} 
                    value={timeOption}
                    onChange={(val) => setTimeOption(val)}
                >
                    {timeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option.value}>
                                {option.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                style={{ width: 200}}
                value={userTypeOption}
                onChange={(val) => setUserTypeOption(val)}>
                    {
                        userTypeOptions.map((option, index) => {
                            return(
                                <Option key={index} value={option.value}>
                                {option.label}
                                </Option>
                            )
                        })
                    }
                </Select>
            </div>
            <CommonTable 
                columns={categoryTimeSlotTableColumns} 
                data={reportsData}
                dataLoading={dataLoading}
            />
        </div>
    )
}

export default CategoryTimeSlotReport
