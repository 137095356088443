import { Form, Input, Select, Button, message } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import CommonTable from '../CommonTable/CommonTable'
import { getElectionData, getAssemblyConstituency, postVotes } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { typeOptions, rounds } from './AssemblyConstituencyDataEntry.constants'
import './AssemblyConstituencyDataEntry.scss'

let globalObj;
const DataEntry = () => {
    const [dataLoading, setDataLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [electionsData, setElectionsData] = useState([])
    const [typeSelection, setTypeSelection] = useState(typeOptions[0]?.value)
    const [round, setRound] = useState(rounds[0]?.value)
    const [assemblyData, setAssemblyData] = useState([])
    const [candidatesData, setCandidatesData] = useState([])
    const [selectedStateKey, setSelectedStateKey] = useState(null);
    const [selectedAssemblyKey, setSelectedAssemblyKey] = useState(null);
    const [successMsg, setSuccessMsg] = useState(false)

    useEffect(() => {
        getElectionInfo()
    }, []);

    useEffect(() => {
        if (selectedStateKey) {
            getAssemblyConstituencyData(selectedStateKey)
        }
    }, [selectedStateKey, round, selectedAssemblyKey])

    const getElectionInfo = async () => {
        setDataLoading(true)
        try {
            const response = await getElectionData("assembly", accessToken)
            setElectionsData(response?.data?.states)
            setSelectedStateKey(response.data?.states[0]?.name)
            getAssemblyConstituencyData(response.data?.states[0]?.name)
            setDataLoading(false)
        } catch (err) {
            setElectionsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const getAssemblyConstituencyData = async (state) => {
        setDataLoading(true)
        try {
            const response = await getAssemblyConstituency("assembly", state, accessToken)
            setAssemblyData(response?.data?.constituencies)
            if (selectedAssemblyKey) {
                let candidateData = response?.data?.constituencies?.filter((ele, index) => ele.id == selectedAssemblyKey)?.[0]
                setCandidatesData(candidateData?.candidates)
                globalObj = candidateData?.candidates
            } else {
                setSelectedAssemblyKey(response?.data?.constituencies?.[0]?.id)
                setCandidatesData(response?.data?.constituencies?.[0]?.candidates)
                globalObj = response?.data?.constituencies?.[0]?.candidates
            }
            setDataLoading(false)
        } catch (err) {
            setAssemblyData([])
            setCandidatesData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const changeRecord = (event, record) => {
        let obj = globalObj.map((ele, i) => {
            if (ele.id == record.id) {
                return { ...ele, [ele.id]: event.target.value }
            } else {
                return { [ele.id]: ele?.rounds[round] ? ele?.rounds[round] : 0, ...ele }
            }
        })
        globalObj = obj
    };

    const candidateTableColumns = [
        {
            title: 'Candidate',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                    }}>
                        <img
                            style={{ width: '3rem' }}
                            src={record?.party_logo}
                            alt="logo"
                        />
                        <p style={{ marginBottom: '0px' }}>
                            {record?.name}
                        </p>
                    </div>
                )
            },
        },
        {
            title: `Votes (Round ${round})`,
            dataIndex: 'votes',
            key: 'votes',
            render: (val, record) => {
                return (
                    <Form.Item className='election-input-box'>
                        <Input key={round} defaultValue={record?.rounds[round]} onChange={(event) => changeRecord(event, record)} />
                    </Form.Item>
                )
            }
        }
    ]

    const onFinish = async () => {
        let obj = globalObj.map((ele, i) => {
            return { [ele.id]: [ele[ele.id]]?.[0] }
        })
        let result = {};

        obj.forEach(d => {
            for (let key in d) {
                result[key] = d[key];
            }
        });
        const updatedData = {
            round: round,
            data: result
        }
        try {
            await postVotes(updatedData, typeOptions, selectedStateKey, selectedAssemblyKey, accessToken)
            getAssemblyConstituencyData(selectedStateKey)
            setSuccessMsg(true)
        } catch (err) {
            console.log(err)
        }
    }

    const changeAssembly = (val) => {
        setSelectedAssemblyKey(val)
        let candidateData = assemblyData?.filter((ele, index) => ele.id == val)?.[0]
        setCandidatesData(candidateData?.candidates)
        globalObj = candidateData?.candidates
    }

    const changeState = (val) => {
        setSelectedStateKey(val)
        setSelectedAssemblyKey('')
        getAssemblyConstituencyData(val)
    }

    const { Option } = Select
    return (
        <div className="report-container data-entry-container">
            <div className="content-header data-entry-header">
                <h2>Constituency Results</h2>
                {successMsg &&
                    <div className='success-msg'>Candidate votes saved successfully</div>
                }
                <Button className='refresh-button' onClick={() => getAssemblyConstituencyData(selectedStateKey)}>
                    <RedoOutlined />
                    Refresh
                </Button>
            </div>
            <div className="manage-content-inputs">
                <Select
                    value={typeSelection}
                    style={{ width: 200 }}
                    onChange={(val) => setTypeSelection(val)}
                >
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={option?.value} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={selectedStateKey}
                    style={{ width: 200 }}
                    onChange={(val) => changeState(val)}
                >
                    {electionsData.map((option, index) => {
                        return (
                            <Option key={option?.name} value={option?.name}>
                                {option?.name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    placeholder="Select Constituency"
                    style={{ width: 150 }}
                    onChange={(val) => changeAssembly(val)}
                    value={selectedAssemblyKey}
                >
                    {assemblyData?.map((option) => (
                        <Select.Option
                            key={option.id}
                            value={option.id}
                        >
                            {option.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    placeholder="Select Round"
                    style={{ width: 150 }}
                    onChange={(val) => setRound(val)}
                    value={round}
                >
                    {rounds?.map((option) => (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <CommonTable
                key={JSON.stringify(candidatesData)}
                columns={candidateTableColumns}
                data={candidatesData}
                dataLoading={dataLoading}
            />
            {candidatesData?.length > 0 &&
                <button className='save-btn' onClick={() => onFinish()}>
                    <span>Save</span>
                </button>
            }
        </div>
    )
}

export default DataEntry;