import { message, Button, Modal, Select, Popconfirm } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { getElectionData, getStates, postElectionData } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import './Master.scss'
import AddStateForm from './AddStateForm'
import AddPartyForm from './AddPartyForm'
import AddSourceForm from './AddSourceForm'
import { typeOptions } from './Master.constant'

const Master = () => {
    const [electionsData, setElectionsData] = useState([])
    const [states, setStates] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [selectedStateKey, setSelectedStateKey] = useState(null);
    const [parties, setParties] = useState([]);
    const [mediaSources, setMediaSources] = useState([]);
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [selectedLocation, setSelectionLocation] = useState('');
    const [typeSelection, setTypeSelection] = useState(typeOptions[0]?.value)

    const openModal = (loc) => {
        setSelectionLocation(loc)
        setShowRoleModal(true)
    }

    const onEditClicked = () => {

    }

    const stateTableColumns = [
        {
            title: (
                <div className='location-align'>
                    States
                    <div
                        className='add-btn'
                        onClick={() => openModal('State')}
                    >
                        Add New
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
        }
    ]

    const partyColumns = [
        {
            title: (
                <div className='location-align'>
                    Parties
                    {selectedStateKey &&
                        <div
                            className='add-btn'
                            onClick={() => openModal('Party')}
                        >
                            Add New
                        </div>
                    }
                </div>
            ),
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                        }}>
                            <img
                                style={{ width: '3rem' }}
                                src={record?.logo}
                                alt="logo"
                            />
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                        <div>
                            {/* <EditOutlined
                                style={{ marginRight: '5px', cursor: 'pointer' }}
                                onClicked={() => onEditClicked()}
                            /> */}
                            <Popconfirm
                                title="Delete Row"
                                description="Are you sure to delete this row?"
                                onConfirm={() => deleteRow(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                                />
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        }
    ]

    const sourceColumns = [
        {
            title: (
                <div className='location-align'>
                    Media Sources
                    {selectedStateKey &&
                        <div
                            className='add-btn'
                            onClick={() => openModal('Source')}
                        >
                            Add New
                        </div>
                    }
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
        }
    ]

    useEffect(() => {
        getStatesDetails()
        getElectionInfo()
    }, [typeSelection])

    const getElectionInfo = async () => {
        setDataLoading(true)
        try {
            const response = await getElectionData(typeSelection, accessToken)
            setElectionsData(response?.data?.states)
            if (selectedStateKey) {
                const filteredState = response.data?.states?.find((ele, i) => ele.name == selectedStateKey)
                setParties(filteredState?.parties)
                setMediaSources(filteredState?.media_sources)
            }
            setDataLoading(false)
        } catch (err) {
            setElectionsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const getStatesDetails = async () => {
        try {
            const response = await getStates(accessToken)
            setStates(response?.data)
        } catch (err) {
            setStates([])
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const stateClassName = (record) => {
        return record.name === selectedStateKey ? 'selected-row' : '';
    };

    const onStateSelectRow = (record) => {
        setSelectedStateKey(record.name);
        setParties(record?.parties)
        setMediaSources(record?.media_sources)
    };

    const deleteRow = (record) => {
        // let filteredData = electionsData;
        // let stateIndex = filteredData.findIndex(state => state.name === selectedStateKey);
        // const filteredMedia = filteredData[stateIndex].parties.filter(source => source.name !== record.name);
        // let obj = {
        //     "election_type": typeSelection,
        //     "states": [
        //         ...filteredMedia
        //     ]
        // }
        // onFinish(obj)
        // let filteredData = electionsData;
        // let stateIndex = filteredData.findIndex(state => state.name === selectedStateKey);
        // if (stateIndex !== -1) {
        //     const mediaSourceIndex = filteredData[stateIndex].parties.findIndex(source => source.name === record.name);
        //     if (mediaSourceIndex !== -1) {
        //         filteredData[stateIndex].media_sources[mediaSourceIndex].party_wise_data[rowIndex][partyName] = event.target.value;
        //     }
        // }
        // let obj = {
        //     "election_type": typeSelection,
        //     "states": [
        //         ...filteredData
        //     ]
        // }
    }

    const onFinish = async (data) => {
        try {
            await postElectionData(data, accessToken)
            getElectionInfo()
        } catch (err) {
            console.log(err)
        }
    }

    const stateModal = (
        <Modal
            open={showRoleModal}
            title="Add New"
            onCancel={() => setShowRoleModal(false)}
            footer={[
                <Button
                    form="stateForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Add
                </Button>,
            ]}
        >
            <AddStateForm
                electionsData={electionsData}
                states={states}
                selectedLocation={selectedLocation}
                selectedStateKey={selectedStateKey}
                selectedRole={false}
                getTableData={getElectionInfo}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
            />
        </Modal>
    )

    const partyModal = (
        <Modal
            open={showRoleModal}
            title="Add New"
            onCancel={() => setShowRoleModal(false)}
            footer={[
                <Button
                    form="partyForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Add
                </Button>,
            ]}
        >
            <AddPartyForm
                electionsData={electionsData}
                selectedLocation={selectedLocation}
                selectedStateKey={selectedStateKey}
                selectedRole={false}
                getTableData={getElectionInfo}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
            />
        </Modal>
    )

    const sourceModal = (
        <Modal
            open={showRoleModal}
            title="Add New"
            onCancel={() => setShowRoleModal(false)}
            footer={[
                <Button
                    form="sourceForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Add
                </Button>,
            ]}
        >
            <AddSourceForm
                electionsData={electionsData}
                selectedLocation={selectedLocation}
                selectedStateKey={selectedStateKey}
                selectedRole={false}
                getTableData={getElectionInfo}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
            />
        </Modal>
    )

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Election Master</h2>
            </div>
            <div className="manage-content-inputs">
                <Select
                    value={typeSelection}
                    style={{ width: 200 }}
                    onChange={(val) => setTypeSelection(val)}
                >
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={option?.value} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>

            </div>
            <div className="table-list">
                <CommonTable
                    columns={stateTableColumns}
                    data={electionsData}
                    dataLoading={dataLoading}
                    rowClassName={stateClassName}
                    disablePagination={true}
                    onRow={(record) => ({
                        onClick: () => onStateSelectRow(record),
                    })}
                />
                <CommonTable
                    columns={partyColumns}
                    data={parties}
                    dataLoading={dataLoading}
                    disablePagination={true}
                />
                <CommonTable
                    columns={sourceColumns}
                    data={mediaSources}
                    dataLoading={dataLoading}
                    disablePagination={true}
                />
            </div>
            {selectedLocation == 'State' ?
                stateModal
                : selectedLocation == 'Party' ?
                    partyModal
                    : sourceModal
            }
        </div>
    )
}

export default Master;