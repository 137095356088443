import React, { useRef, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { monthsData } from './LanguageSpecificMonths.js'
import Aquarius from '../../assets/horoscopeCardsTemplates/Aquarius.jpg'
import Aries from '../../assets/horoscopeCardsTemplates/Aries.jpg'
import Cancer from '../../assets/horoscopeCardsTemplates/Cancer.jpg'
import Capricorn from '../../assets/horoscopeCardsTemplates/Capricorn.jpg'
import Gemini from '../../assets/horoscopeCardsTemplates/Gemini.jpg'
import Leo from '../../assets/horoscopeCardsTemplates/Leo.jpg'
import Libra from '../../assets/horoscopeCardsTemplates/Libra.jpg'
import Pisces from '../../assets/horoscopeCardsTemplates/Pisces.jpg'
import Sagittarius from '../../assets/horoscopeCardsTemplates/Sagittarius.jpg'
import Scorpio from '../../assets/horoscopeCardsTemplates/Scorpio.jpg'
import Taurus from '../../assets/horoscopeCardsTemplates/Taurus.jpg'
import Virgo from '../../assets/horoscopeCardsTemplates/Virgo.jpg'

const HoroscopeCanvas = React.forwardRef(
    (
        {
            modalText,
            month,
            day,
            currentHoroscope,
            onImageExport,
            setDrawCanvas,
            language,
            zodiacData,
        },
        ref
    ) => {
        const horoscopeImages = {
            default: {
                Aquarius,
                Aries,
                Cancer,
                Capricorn,
                Gemini,
                Leo,
                Libra,
                Pisces,
                Sagittarius,
                Scorpio,
                Taurus,
                Virgo,
            },
        }

        const getLanguageKey = (languageId) => {
            const languageMap = {
                1: 'english',
                2: 'telugu',
                3: 'hindi',
                4: 'tamil',
            }
            return languageMap[languageId] || 'english'
        }

        const translateMonth = (englishMonth, selectedLanguage) => {
            const languageKey = getLanguageKey(selectedLanguage)
            const monthIndex = monthsData.english.indexOf(englishMonth)
            return monthsData[languageKey][monthIndex]
        }

        const imageRef = useRef(null)
        const [position, setPosition] = useState({ x: 0.5, y: 0.495 })
        const [fontLoaded, setFontLoaded] = useState(false)
        const [displayDimensions] = useState({
            width: 196.8,
            height: 426.666666667,
        })
        const [exportDimensions] = useState({
            width: 315.022624434,
            height: 683.438914027,
        })
        const calculateScaleFactor = useCallback(
            (isExport) => {
                const baseWidth = 295.2
                return isExport
                    ? exportDimensions.width / baseWidth
                    : displayDimensions.width / baseWidth
            },
            [displayDimensions, exportDimensions]
        )

        useEffect(() => {
            const loadFonts = async () => {
                const mallannaPromise = new FontFace(
                    'Mallanna',
                    'url(https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDGwzEzRwVvJ7.woff2)'
                ).load()

                const meeraInimaiPromise = new FontFace(
                    'Meera Inimai',
                    'url(https://fonts.gstatic.com/s/meerainimai/v12/845fNMM5EIqOW5MPuvO3ILep_2jDVevnLQ.woff2)'
                ).load()

                await Promise.all([mallannaPromise, meeraInimaiPromise])
                document.fonts.add(mallannaPromise)
                document.fonts.add(meeraInimaiPromise)
                setFontLoaded(true)
            }
            loadFonts()
        }, [])

        useEffect(() => {
            const font = new FontFace(
                'Mallanna',
                'url(https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDGwzEzRwVvJ7.woff2)'
            )

            font.load()
                .then(() => {
                    document.fonts.add(font)
                    setFontLoaded(true)
                })
                .catch((error) => {
                    console.error('Failed to load Mallanna font:', error)
                    setFontLoaded(true)
                })
        }, [])

        const getLocalizedHoroscopeName = useCallback(
            (englishName) => {
                if (!zodiacData || Object.keys(zodiacData).length === 0) {
                    return englishName
                }

                const zodiac = Object.values(zodiacData).find(
                    (z) => z[1].trim() === englishName.trim()
                )
                return zodiac ? zodiac[language] : englishName
            },
            [zodiacData, language]
        )

        const drawImageWithAspectRatio = useCallback(
            (context, image, targetWidth, targetHeight) => {
                const imageAspectRatio = image.width / image.height
                let drawWidth = targetWidth
                let drawHeight = targetHeight
                let offsetX = 0
                let offsetY = 0

                if (imageAspectRatio > 1) {
                    drawHeight = targetWidth / imageAspectRatio
                    offsetY = (targetHeight - drawHeight) / 2
                } else {
                    drawWidth = targetHeight * imageAspectRatio
                    offsetX = (targetWidth - drawWidth) / 2
                }

                context.drawImage(
                    image,
                    offsetX,
                    offsetY,
                    drawWidth,
                    drawHeight
                )
                context.imageSmoothingEnabled = true
                context.imageSmoothingQuality = 'high'
            },
            []
        )

        const drawTextWithSpacing = useCallback(
            (context, text, x, y, maxWidth, lineHeight, letterSpacing) => {
                if (!text || typeof text !== 'string') return

                const words = text.split(' ')
                let line = ''
                let lines = []

                for (const word of words) {
                    const testLine = line + (line ? ' ' : '') + word
                    const metrics = context.measureText(testLine)
                    const testWidth =
                        metrics.width + letterSpacing * (testLine.length - 1)

                    if (testWidth > maxWidth && line) {
                        lines.push(line)
                        line = word
                    } else {
                        line = testLine
                    }
                }

                lines.push(line)

                const maxLines = 12
                if (lines.length > maxLines) {
                    lines = lines.slice(0, maxLines - 1)
                    lines.push('... (text truncated)')
                }

                lines.forEach((lineText, index) => {
                    const additionalSpacing =
                        letterSpacing * (lineText.length - 1)
                    context.fillText(
                        lineText,
                        x,
                        y + index * lineHeight,
                        maxWidth + additionalSpacing
                    )
                })
            },
            []
        )

        const drawHoroscopeName = useCallback(
            (
                context,
                horoscopeName,
                width,
                height,
                scaleFactor = 1,
                isExport = false
            ) => {
                const isTamil = language === 4
                const isHindi = language === 3
                const isTelugu = language === 2

                let nameFontSize, nameY, fontFamily

                if (isTamil) {
                    nameFontSize = width * (isExport ? 0.09 : 0.09)
                    nameY = height * 0.44
                    fontFamily = 'Meera Inimai'
                } else if (isHindi) {
                    nameFontSize = width * (isExport ? 0.121 : 0.121)
                    nameY = height * 0.44
                    fontFamily = 'AdobeDevanagariBold'
                } else if (isTelugu) {
                    nameFontSize = width * (isExport ? 0.08 : 0.08)
                    nameY = height * 0.438
                    fontFamily = 'Mandali'
                } else {
                    nameFontSize = width * (isExport ? 0.095 : 0.095)
                    nameY = height * 0.438
                    fontFamily = 'Gurajada'
                }
                context.font = `bold ${nameFontSize}px ${fontFamily}`
                context.fillStyle = 'rgba(247,171,75,255)'
                context.textAlign = 'center'
                context.fillText(horoscopeName, width * 0.5, nameY)
            },
            [language]
        )

        const drawHoroscopeText = useCallback(
            (
                context,
                text,
                position,
                width,
                height,
                scaleFactor = 1,
                isExport = false
            ) => {
                if (!text) return

                const isTelugu = language === 2
                const isHindi = language === 3
                const isTamil = language === 4

                let fontSize, lineHeight, letterSpacing, maxWidth, fontFamily

                if (isTamil) {
                    fontSize = width * (isExport ? 0.052 : 0.052)
                    lineHeight = width * 0.077
                    letterSpacing = width * (isExport ? 0.002 : 0.002)
                    maxWidth = width * (isExport ? 0.835 : 0.835)
                    fontFamily = 'Meera Inimai'
                } else if (isTelugu) {
                    fontSize = width * (isExport ? 0.0548 : 0.0548)
                    lineHeight = fontSize * 1.26
                    letterSpacing = width * (isExport ? 0.002 : 0.002)
                    maxWidth = width * (isExport ? 0.81 : 0.81)
                    fontFamily = 'Mallanna'
                } else if (isHindi) {
                    fontSize = width * (isExport ? 0.0542 : 0.0542)
                    lineHeight = width * 0.09
                    letterSpacing = width * (isExport ? 0.0022 : 0.0022)
                    maxWidth = width * (isExport ? 0.86 : 0.86)
                    fontFamily = 'Mangal'
                    console.log(fontSize, lineHeight, maxWidth)
                    // fontSize = width * 0.0548
                    // lineHeight = width * 0.09
                    // context.font = `${fontSize}px Mangal`
                    // maxWidth = width * 0.79
                    // 32.332 53.1 466.1
                    // 10.784640000000001 17.712 155.472
                    // 20.715887782779763 34.02244343887187 298.6414479634309
                } else {
                    fontSize = width * (isExport ? 0.0525 : 0.0525)
                    lineHeight = fontSize * 1.4
                    letterSpacing = width * (isExport ? 0.002 : 0.002)
                    maxWidth = width * (isExport ? 0.83 : 0.83)
                    fontFamily = 'Mallanna'
                }

                context.font = `${fontSize}px ${fontFamily}`
                context.fillStyle = 'white'
                context.textAlign = 'center'

                const x = width * position.x
                const y = height * position.y

                drawTextWithSpacing(
                    context,
                    text,
                    x,
                    y,
                    maxWidth,
                    lineHeight,
                    letterSpacing
                )
            },
            [drawTextWithSpacing, language]
        )

        const drawDateText = useCallback(
            (
                context,
                month,
                day,
                width,
                height,
                scaleFactor = 1,
                isExport = false
            ) => {
                const baseFontSize = isExport ? 13.35 : 11.2
                const fontSize = baseFontSize * scaleFactor * 1.23
                const lineHeight = fontSize * 1.32
                const letterSpacing = (isExport ? 1 : 0.4) * scaleFactor

                const isTamil = language === 4
                const fontFamily = isTamil
                    ? 'Meera Inimai'
                    : 'Sree Krushnadevaraya'

                context.font = `${fontSize}px ${fontFamily}`
                context.fillStyle = 'rgba(247,171,75,255)'
                context.textAlign = 'center'

                let boxStart, boxEnd
                if (isTamil) {
                    boxStart = { x: width * 0.127, y: height * 0.9315 }
                    boxEnd = { x: width * 0.87, y: height * 0.9315 }
                } else {
                    boxStart = { x: width * 0.3768, y: height * 0.931 }
                    boxEnd = { x: width * 0.614, y: height * 0.931 }
                }

                const boxWidth = boxEnd.x - boxStart.x
                const centerX = boxStart.x + boxWidth / 2
                const dateY = boxStart.y

                const translatedMonth = translateMonth(month, language)
                const formattedDay = String(day).padStart(2, '0')
                const combinedText = `${translatedMonth} ${formattedDay}`

                drawTextWithSpacing(
                    context,
                    combinedText,
                    centerX,
                    dateY,
                    boxWidth,
                    lineHeight,
                    letterSpacing
                )
            },
            [drawTextWithSpacing, language]
        )

        const drawCanvas = useCallback(
            (
                width,
                height,
                scaleFactor = 1,
                applyDpr = true,
                isExport = false
            ) => {
                if (!ref.current || !imageRef.current || !fontLoaded) return

                const canvas = ref.current
                const context = canvas.getContext('2d')
                const dpr = applyDpr ? window.devicePixelRatio || 1 : 1

                const scaledWidth = width * scaleFactor
                const scaledHeight = height * scaleFactor

                canvas.width = scaledWidth * dpr
                canvas.height = scaledHeight * dpr
                context.scale(dpr, dpr)

                canvas.style.width = `${scaledWidth}px`
                canvas.style.height = `${scaledHeight}px`

                context.resetTransform()
                context.scale(dpr, dpr)

                context.clearRect(0, 0, scaledWidth, scaledHeight)
                drawImageWithAspectRatio(
                    context,
                    imageRef.current,
                    scaledWidth,
                    scaledHeight
                )
                const textScaleFactor = calculateScaleFactor(isExport)

                const localizedName =
                    getLocalizedHoroscopeName(currentHoroscope)

                drawHoroscopeName(
                    context,
                    localizedName,
                    scaledWidth,
                    scaledHeight,
                    textScaleFactor,
                    isExport
                )
                drawHoroscopeText(
                    context,
                    modalText,
                    position,
                    scaledWidth,
                    scaledHeight,
                    textScaleFactor,
                    isExport
                )
                drawDateText(
                    context,
                    month,
                    day,
                    scaledWidth,
                    scaledHeight,
                    textScaleFactor,
                    isExport
                )
            },
            [
                modalText,
                month,
                day,
                position,
                ref,
                calculateScaleFactor,
                drawImageWithAspectRatio,
                drawHoroscopeText,
                drawDateText,
                fontLoaded,
            ]
        )

        useEffect(() => {
            setDrawCanvas(
                () => (width, height, scaleFactor, applyDpr, isExport) =>
                    drawCanvas(
                        isExport
                            ? exportDimensions.width
                            : displayDimensions.width,
                        isExport
                            ? exportDimensions.height
                            : displayDimensions.height,
                        scaleFactor,
                        applyDpr,
                        isExport
                    )
            )
        }, [drawCanvas, setDrawCanvas, exportDimensions, displayDimensions])

        useEffect(() => {
            const image = new Image()
            const isTamil = language === 4
            const imageSet =
                // isTamil
                //     ? horoscopeImages.tamil
                horoscopeImages.default
            if (imageSet.hasOwnProperty(currentHoroscope)) {
                image.src = imageSet[currentHoroscope]
            } else {
                console.error(
                    `No image found for horoscope: ${currentHoroscope}`
                )
            }
            image.onload = () => {
                imageRef.current = image
                if (fontLoaded && imageRef.current) {
                    drawCanvas(
                        displayDimensions.width,
                        displayDimensions.height,
                        1,
                        true,
                        false
                    )
                }
                // drawCanvas(295.2, 640, 1, true)
                // drawCanvas(196.8, 426.666666667, 1, true)
            }
            image.onerror = (error) => {
                console.error('Error loading image:', error)
            }
        }, [
            drawCanvas,
            currentHoroscope,
            displayDimensions,
            fontLoaded,
            imageRef,
            language,
        ])

        const handleMouseDown = useCallback((e) => {
            const canvas = ref.current
            const rect = canvas.getBoundingClientRect()

            const handleMouseMove = (moveEvent) => {
                setPosition({
                    x: (moveEvent.clientX - rect.left) / rect.width,
                    y: (moveEvent.clientY - rect.top) / rect.height,
                })
            }

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove)
                document.removeEventListener('mouseup', handleMouseUp)
            }

            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)
        }, [])

        return (
            <div className="horoscope-canvas-container">
                <canvas
                    className="canvas"
                    ref={ref}
                    onMouseDown={handleMouseDown}
                    aria-label="Horoscope Canvas"
                ></canvas>
            </div>
        )
    }
)
HoroscopeCanvas.propTypes = {
    modalText: PropTypes.string.isRequired,
    month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currentHoroscope: PropTypes.string.isRequired,
    onImageExport: PropTypes.func.isRequired,
    setDrawCanvas: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    zodiacData: PropTypes.object.isRequired,
}

export default HoroscopeCanvas
