import OpenAI from 'openai'

const openai = new OpenAI({
    apiKey: 'sk-proj-vxH2FBo5vM79zKwK1RQ7T3BlbkFJUu8EaHRK5T7toUypZxsN',
    dangerouslyAllowBrowser: true,
})

export default async function summarizeText(originalText) {
    try {
        const response = await openai.chat.completions.create({
            model: 'gpt-4o-mini',
            messages: [
                {
                    role: 'user',
                    content: `Please summarize the following horoscope in 30-35 words. The summary should be clear, coherent, and strictly in the same language as the original text. Focus on key aspects such as health, finance, job/career, family/love, and any notable events. Ensure that the summary captures the main points accurately, with all sentences being complete and contextually relevant, similar to daily horoscope readings found in books or articles. For Telugu and other regional languages, ensure the response is simple, readable, and uses local language that everyone can understand easily. Avoid complex or strong words, and make the text feel natural, as if written by a person rather than generated by an AI model. Conclude with a statement that reflects the overall theme or mood of the day for the reader based on the horoscope sign, keeping the tone relatable and conversational.: "${originalText}"`,
                },
            ],
            max_tokens: 320,
            n: 1,
            stop: null,
            temperature: 0.1,
        })

        if (response.choices && response.choices.length > 0) {
            return response.choices[0].message.content.trim()
        } else {
            throw new Error('No response from  the OpenAI')
        }
    } catch (error) {
        console.error('Error:', error)
        throw error
    }
}
