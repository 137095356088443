import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   panchangamYears : [],
   panchangamMonths:[],
   panchangamSeasons:[],
   panchangamSolistices:[],
   panchangamPaksha:[],
   panchangamData:[]
}

export const PanchangamSlice = createSlice({
    name: 'panchangam',
    initialState,
    reducers: {
        addPanchangamYears: (state, action) => {
            state.panchangamYears = action?.payload
        },
        addPanchangamMonths:(state,action) => {
            state.panchangamMonths=action?.payload
        },
        addPanchangamSeasons:(state,action) => {
            state.panchangamSeasons=action?.payload
        },
        addPanchangamPaksha:(state,action) => {
            state.panchangamPaksha=action?.payload
        },
        addPanchangamSolistices:(state,action) => {
            state.panchangamSolistices=action?.payload
        },
        addPanchangamForm:(state,action)=>{
            state.panchangamData=[...state.panchangamData,action?.payload];
        }
      
        
    },
})

export const {
   addPanchangamYears,addPanchangamMonths,addPanchangamSeasons,addPanchangamSolistices,addPanchangamPaksha,addPanchangamForm
} = PanchangamSlice.actions

export default PanchangamSlice.reducer
