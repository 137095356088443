import { Button, Form, Input, message } from 'antd'
import { generateVerificationCode, resetPassword } from '../../AadhanApi'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AadhanIcon from '../../assets/Aadhan.svg'
import { LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
const Forgotpassword = () => {
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [verificationCode, setVerificationCode] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [isVerificationLoading, setisVerificationLoading] = useState(false)
    const [isPasswordreset, setIsPasswordReset] = useState(false)

    const onFinishGenerate = async (values) => {
        setisVerificationLoading(true)
        try {
            const response = await generateVerificationCode(
                { email: values?.email },
                accessToken
            )
            if (response?.status === 200) {
                setVerificationCode(true)
                messageApi.open({
                    type: 'success',
                    content: 'Verification Code sent to you email',
                    duration: 5,
                })
            } else {
                setVerificationCode(false)
            }
            setisVerificationLoading(true)
        } catch (err) {
            setisVerificationLoading(true)
            setVerificationCode(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to generate verification Code',
                duration: 5,
            })
        }
    }

    const onFinishDetails = async (values) => {
        try {
            const response = await resetPassword(
                {
                    email: values?.email,
                    verification_code: values?.code,
                    new_password: values?.password,
                },
                accessToken
            )
            if (response?.status === 200) {
                setIsPasswordReset(true)
                messageApi.open({
                    type: 'success',
                    content: 'Password reset done',
                    duration: 5,
                })
            } else {
                setIsPasswordReset(false)
                messageApi.open({
                    type: 'error',
                    content: 'Password reset failed',
                    duration: 5,
                })
            }
        } catch (err) {
            console.log(err)
            setIsPasswordReset(false)
            messageApi.open({
                type: 'error',
                content: 'Password reset failed',
                duration: 5,
            })
        }
    }

    const onFinishFailed = () => {
        console.log('failed')
    }

    const EmailAndCodeForm = (
        <>
            {isPasswordreset ? (
                <span className="click-here">
                    <Link to="/">Please click here to Login Again</Link>{' '}
                </span>
            ) : (
                <Form
                    form={form}
                    name="emailPasswordForm"
                    colon={false}
                    scrollToFirstError
                    initialValues={{
                        email: '',
                        code: '',
                        password: '',
                    }}
                    onFinish={onFinishDetails}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Email is required',
                            },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: 'Code is required',
                            },
                        ]}
                    >
                        <Input placeholder="Code" />
                    </Form.Item>
                    <Form.Item
                        label="New Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'password',
                            },
                        ]}
                    >
                        <Input placeholder="New Password" type="password" />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Reset password
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>
    )

    const GenerateCodeForm = (
        <Form
            form={form}
            name="generateCode"
            colon={false}
            scrollToFirstError
            initialValues={{ email: '' }}
            onFinish={onFinishGenerate}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Email is required',
                    },
                ]}
            >
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit">
                    {isVerificationLoading ? (
                        <LoadingOutlined />
                    ) : (
                        'Generate Code'
                    )}
                </Button>
            </Form.Item>
        </Form>
    )

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            {contextHolder}
            <div className="forgot-password-box">
                <img
                    style={{ width: '7rem', marginBottom: '2rem' }}
                    src={AadhanIcon}
                    alt="login logo"
                />
                {verificationCode ? EmailAndCodeForm : GenerateCodeForm}
            </div>
        </div>
    )
}

export default Forgotpassword
