import { Select, message } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProofReaderReport } from '../../AadhanApi'

const ProofReadersReport = () => {
    const monthArr = [
        { month_name: 'Jan', month_code: 1 },
        { month_name: 'Feb', month_code: 2 },
        { month_name: 'Mar', month_code: 3 },
        { month_name: 'Apr', month_code: 4 },
        { month_name: 'May', month_code: 5 },
        { month_name: 'June', month_code: 6 },
        { month_name: 'July', month_code: 7 },
        { month_name: 'Aug', month_code: 8 },
        { month_name: 'Sep', month_code: 9 },
        { month_name: 'Oct', month_code: 10 },
        { month_name: 'Nov', month_code: 11 },
        { month_name: 'Dec', month_code: 12 }
    ]
    let yearArr = []
    for (let i = 2018; i <= 2099; i++) {
        yearArr.push(i)
    }
    const currentDate = dayjs();

    const [proofReportColumns, setProofReportColumns] = useState([])
    const [reportsData, setReportsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()
    const [month, setMonth] = useState(currentDate.month()+1)
    const [year, setYear] = useState(currentDate.year())
    const [datesCount, setDatesCount] = useState(30)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(languageData?.[0]?.language_id)

    const isSunday = (day) => {
        const date = new Date(year, month - 1, day);
        return date.getDay() === 0;
    }

    useEffect(() => {
        let columns = [
            {
                title: 'Emp Name',
                dataIndex: 'full_name',
                key: 'full_name',
            },
        ]
        const dateInMonth = dayjs(`${year}-${month}-01`)
        const dates = dateInMonth.daysInMonth()
        const arr = Array.from({ length: dates })
        setDatesCount(dates)
        arr.forEach((_, index) => {
            columns.push({
                title: <span className={isSunday(index+1) ? 'sunday-header' : ''}>{index+1}</span>,
                dataIndex: `${index}`,
                key: `${index}`,
            })
        })
        columns.push({
            title: 'Total',
            dataIndex: 'total_contents',
            key: 'total_contents',
        })
        setProofReportColumns(columns)
    }, [year, month])

    useEffect(() => {
        getProofReaderReportDetails(languageOption, year, month)
    }, [languageOption, year, month])

    const getProofReaderReportDetails = async (languageOption, year, month) => {
        setDataLoading(true)
        try {
            const response = await getProofReaderReport(
                languageOption,
                year,
                month,
                accessToken
            )
            const dataSource = []
            response.data.data.forEach((employee) => {
                const { employee_id, full_name, daily_report, total_contents } = employee;
                const flatDailyReport = Object.values(daily_report);
                const entry = {
                    key: employee_id,
                    employee_id,
                    full_name,
                    ...flatDailyReport,
                    total_contents
                }
                dataSource.push(entry);
            });
            setReportsData(dataSource)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Proof Readers Report</h2>
            </div>
            <div className="manage-content-inputs">
                <Select 
                    value={languageOption} 
                    style={{ width: 200 }}
                    onChange={(val) => setLanguageOption(val)}
                >
                    {languageData.map((option) => {
                        return (
                            <Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={year}
                    onChange={(val) => setYear(val)}
                    style={{ width: 200 }}
                >
                    {yearArr.map((option, index) => {
                        return (
                            <Option key={index} value={option}>
                                {option}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={month}
                    onChange={(val) => setMonth(val)}
                    style={{ width: 200 }}
                >
                    {monthArr.map((option) => {
                        return (
                            <Option
                                key={option?.month_code}
                                value={option?.month_code}
                            >
                                {option?.month_name}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <CommonTable 
                columns={proofReportColumns} 
                data={reportsData} 
                dataLoading={dataLoading}
            />
        </div>
    )
}

export default ProofReadersReport
