import { Select } from 'antd';
import React from 'react';
export const SelectComponent = ({ data, value, onChange, key_value, display, className, style,placeholder }) => {
  return (
    <Select className={className} value={value} placeholder={placeholder} style={style} onChange={(value)=>(onChange(value))}>
      {data?.map((e) => (
        <Select.Option key={e?.[key_value]} value={e?.[key_value]}>
          {e[display]}
        </Select.Option>
      ))}
    </Select>
  );
};
