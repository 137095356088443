import { Form, Input, DatePicker } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import { AddEmployeeShifts, updateEmployeeShifts } from '../../AadhanApi';

function AddEmployeeShift({ selectedShift, accessToken, setShowModal, refreshShifts, shiftModal }) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedShift) {
            form.setFieldsValue({
                shift: selectedShift.shift_name,
                startTime: moment.unix(selectedShift.start_time),
                endTime: moment.unix(selectedShift.end_time),
            });
        } else {
            form.resetFields();
        }
    }, [selectedShift, form, shiftModal]);

    const onFinish = async (values) => {
        const request = {
            shift_name: values.shift,
            start_time: values.startTime.unix(),
            end_time: values.endTime.unix(),
        };
        try {
            if (selectedShift) {
                await updateEmployeeShifts(accessToken, selectedShift?.shift_id, request);
            } else {
                const response = await AddEmployeeShifts(accessToken, request);
                if (response?.data?.success === true) {
                    refreshShifts();
                }
            }
            form.resetFields();
            setShowModal(false);
        } catch (error) {
            console.error('Failed to add shift:', error);
        }
    };

    const onFinishFailed = (error) => {
        console.log('Form submission failed:', error);
    };

    return (
        <Form
            id="shiftForm"
            form={form}
            name="shiftForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 24 }}
                label="Shift Name"
                name="shift"
                rules={[
                    {
                        required: true,
                        message: 'Please enter shift name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 24 }}
                label="Start Time"
                name="startTime"
                rules={[
                    {
                        required: true,
                        message: 'Please enter start time',
                    },
                ]}
            >
                <DatePicker
                    showTime
                    format="DD/MM/YYYY h:mm A"
                    disabledDate={(currentDate) =>
                        currentDate && currentDate < moment().startOf('day')
                    }
                />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 24 }}
                label="End Time"
                name="endTime"
                rules={[
                    {
                        required: true,
                        message: 'Please enter end time',
                    },
                ]}
            >
                <DatePicker
                    showTime
                    format="DD/MM/YYYY h:mm A"
                    disabledDate={(currentDate) =>
                        currentDate && currentDate < moment().startOf('day')
                    }
                />
            </Form.Item>
        </Form>
    );
}

export default AddEmployeeShift;
