import { Form, Input, Select, Space, message , DatePicker } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { createHashtag, updateHashtag } from '../../AadhanApi'

const AddHashtag = ({
    setShowModal,
    selectedHashtag,
    getHashtagData,
    accessToken,
}) => {
    const employeeId = useSelector((state) => state.app.employeeId)
    const [messageApi, contextHolder] = message.useMessage()
    const [form] = Form.useForm()
    const languageData = useSelector((state) => state.app.languageData)
    
    useEffect(() => {
        if (selectedHashtag) {
            form.setFieldsValue({
                language: languageData?.find(
                    (language) =>
                        language.language_name === selectedHashtag.language_name
                )?.language_id,
                hashtagName: selectedHashtag.hashtag_name,
                expiry_date: selectedHashtag?.expiry_date
                    ? dayjs(selectedHashtag.expiry_date)
                    : '',
            })
        } else {
            form.resetFields()
        }
    }, [selectedHashtag, languageData])
    
    const onFinish = async (values) => {
        const expiryDateTime = dayjs(values.expiry_date).set('hour', dayjs().hour()).set('minute', dayjs().minute()).set('second', dayjs().second())
        const obj = {
            hashtag_name: values.hashtagName,
            language_id: values.language,
            is_trending: true,
            is_active: true,
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            expiry_date: expiryDateTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        try {
            if (selectedHashtag) {
               const data= await updateHashtag(obj, accessToken, selectedHashtag.hashtag_id)
               if(data?.status===200){

                   getHashtagData()
               }
            } else {
                await createHashtag(obj, accessToken)
                getHashtagData()
            }
            messageApi.success('Hashtag saved successfully')
           
            setShowModal(false)
            form.resetFields()
        } catch (err) {
            messageApi.error(err?.response?.data?.detail || 'An error occurred')
        }
        
        setShowModal(false)
        form.resetFields()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const onChange = (date, dateString) => {
    }
    return (
        <>
            {contextHolder}
            <Form
                id="hashtagForm"
                form={form}
                name="hashtagForm"
                scrollToFirstError
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select Language' },
                    ]}
                >
                    <Select placeholder="--Select Language--">
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Hashtag Name"
                    name="hashtagName"
                    rules={[
                        { required: true, message: 'Please enter tag name' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Expiry Date"
                    name="expiry_date"
                    rules={[{ required: true, message: 'select expiry date' }]}
                >
                    
                        <DatePicker onChange={onChange} />
                    
                </Form.Item>
            </Form>
        </>
    )
}

export default AddHashtag
