import React, { useEffect, useState } from 'react'
import './tv.scss'
import { useSelector } from 'react-redux'
import { SelectComponent } from '../AntdComponent/SelectComponent'
import { SelectComponentWithSearch } from '../AntdComponent/SelectComponentWithSearch'
import dayjs from 'dayjs'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
import { getUgcContent } from '../../AadhanApi'
import { Button, message } from 'antd'
import Search from 'antd/es/input/Search'
import { PlayCircleOutlined, YoutubeFilled } from '@ant-design/icons'
import CommonTable from '../CommonTable/CommonTable'
import { useLocation, useNavigate } from 'react-router'

export const ApprovedTVContent = () => {
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const languageData = useSelector((state) => state.app.languageData)
    const [messageApi, contextHolder] = message.useMessage()
    const accessToken = useSelector((state) => state.app.accessToken)
    const allStates = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const [state, setState] = useState(undefined)
    const [tvContent, setTvContent] = useState([])
    const [title, setTitle] = useState('')
    const navigate = useNavigate()
    const [dataLoading, setDataLoading] = useState(false)
    const location = useLocation()
    const [district, setDistrict] = useState(undefined)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [filtersLoaded, setFiltersLoaded] = useState(false)  // Add this

    const currentFilters = {
        state,
        district,
        languageOption,
        dates,
    }

    function handleLanguageChange(value) {
        setLanguageOption(value)
    }

    function handleStateChange(value) {
        setState(value)
    }

    function handleDistrictChange(value) {
        setDistrict(value)
    }

    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }

    const defaultFilters = {
        state: undefined,
        district: undefined,
        languageOption: languageData?.[0]?.language_id || '',
        dates: {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        },
    }

    // Load filters from localStorage or set defaults
    useEffect(() => {
        const savedFilters = JSON.parse(localStorage.getItem('tvContentFilters')) || defaultFilters
        setState(savedFilters.state)
        setDistrict(savedFilters.district)
        setLanguageOption(savedFilters.languageOption)
        setDates(savedFilters.dates)
        setFiltersLoaded(true)  
    }, [location.pathname])

    const getUgcData = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const tvDataResponse = await getUgcContent(
                languageOption,
                'approved',
                true,
                startDateFormat,
                endDateFormat,
                state,
                accessToken,
                district === '' ? '' : district,
                title,
                true,
                2
            )
            setTvContent(tvDataResponse?.data)
        } catch (err) {
            console.log('error is ::::', err)
            setTvContent([])
            if (err?.response?.status === 401) {
                displayError('You are unauthorised, Please login again')
            } else {
                displayError('Failed to get UG Content')
            }
        } finally {
            setDataLoading(false)
        }
    }

    const tvColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width:400,
            render: (_, record) => (
                <div className="ugc_title">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <p
                            style={{
                                color: 'blue',
                                margin: 0,
                                cursor: 'pointer',
                            }}
                            href="/"
                            // onClick={() => viewPopup(record.content_id)}
                        >
                            {record?.title}
                        </p>
                        <span style={{ fontSize: '1.5rem' }}>
                            {record?.stream_video_url?.length ? (
                                <PlayCircleOutlined style={{ color: 'blue' }} />
                            ) : record?.yt_video_url ? (
                                <YoutubeFilled />
                            ) : (
                                ''
                            )}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            title: 'Submitted Date',
            dataIndex: 'uploaded_date',
            render: (_, record) =>
                dayjs(record?.uploaded_date).format('DD MMM YYYY, hh:mm A'),
            key: 'uploadedDate',
        },
        {
            title: 'District',
            dataIndex: 'district_name',
            key: 'district_name',
        },
        {
            title: 'Constituency',
            dataIndex: 'constituency_names',
            key: 'constituency_names',
            render: (_, record) => (
                <span style={{ fontSize: '1rem' }}>
                    {record?.constituency_names?.join(', ')}
                </span>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: `${'Actions'}`,
            render: (_, record) => (
                <div size="middle">
                    <div style={{ fontSize: '1.2rem' }}>
                        <Button
                            type='primary'
                            ghost
                            className="review-btn"
                            disabled={record.isreviewing}
                            onClick={() =>{ localStorage.setItem('tvContentFilters', JSON.stringify(currentFilters)); navigate(`${location?.pathname}View?mode=edit&contentId=${record.content_id}`)}}
                        >
                            {'View'}
                        </Button>
                    </div>
                </div>
            ),
        },
    ]

    useEffect(() => {
        if (filtersLoaded) {  // Only trigger API call after filters are loaded
            getUgcData()
        }
    }, [languageOption, state, district, dates, filtersLoaded])

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }

    return (
        <div className="tv_container">
            <div className="tv-title">
                <h4>Approved TV Content</h4>
                <div className="date-picker-container">
                    <span style={{ marginRight: '5px' }}>Date Range :</span>
                    <ManageContentDateRange dates={dates} addDates={addDates} />
                </div>
            </div>
            <hr style={{ color: 'gray', margin: '10px 0' }}></hr>

            <div className="filters-tv">
                <SelectComponent
                    data={languageData}
                    value={languageOption}
                    onChange={handleLanguageChange}
                    key_value={'language_id'}
                    display={'language_name'}
                    style={{marginRight:"1rem",width:170}}
                    placeholder={''}
                />
                <SelectComponentWithSearch
                    data={allStates}
                    value={state}
                    onChange={handleStateChange}
                    key_value={'state_id'}
                    display={'name'}
                    style={{marginRight:"1rem",width:170}}
                    placeholder={'Select State'}
                />
                <SelectComponentWithSearch
                    data={allDistricts?.[state]}
                    value={district}
                    onChange={handleDistrictChange}
                    key_value={'district_id'}
                    display={'name'}
                    className={'language_filter'}
                    style={{marginRight:"1rem",width:180}}
                    placeholder={'Select District'}
                />
                <Search
                    className="custom-search"
                    placeholder="search by Title"
                    enterButton="Search"
                    size="middle"
                    allowClear
                    onChange={(e) => setTitle(e.target.value)}
                    onSearch={() => getUgcData()}
                    value={title}
                />
            </div>
            <div>
                <CommonTable columns={tvColumns} data={tvContent} dataLoading={dataLoading}/>
            </div>
        </div>
    )
}
