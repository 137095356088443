import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const SelectHoroscope = ({ title, language, setHoroscopeName_ }) => {
    const arr = {
        Aries: 0,
        Taurus: 1,
        Gemini: 2,
        Cancer: 3,
        Leo: 4,
        Virgo: 5,
        Libra: 6,
        Scorpio: 7,
        Sagittarius: 8,
        Capricon: 9,
        Aquarius: 10,
        Pisces: 11,
    }
    if (language === 'English') language = '1'
    if (language === 'Hindi') language = '3'
    if (language === 'Tamil') language = '4'
    if (language === 'Telugu') language = '2'
    const zodiac = useSelector((state) => state.horoscope.zodiacs)
    const [index, setIndex] = useState(arr[title])
    const [name, setName] = useState(zodiac?.[index]?.[1])

    useEffect(() => {
        if (zodiac) {
            setName(zodiac?.[index]?.[1])
        }
    }, [title])

    return (
        <div
            style={{
                width: '100%',
                backgroundColor: '#f0f0f0',
                marginTop: '1rem',
                display: 'flex',
                border: 'solid lightgray 1px',
            }}
        >
            {zodiac?.map((e) => {
                return (
                    <div
                        onClick={() => {
                            setName(e[1])
                            setHoroscopeName_(e[1].toLowerCase().trim())
                        }}
                        style={{
                            display: 'flex',
                            flex: '1',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor:
                                name === e[1] ? 'white' : 'transparent',
                            border:
                                name === e[1] ? 'solid lightblue 1px' : 'none',
                        }}
                    >
                        <div>
                            <img
                                style={{ width: '4rem', padding: '.2rem' }}
                                src={e.image_url}
                            />
                        </div>
                        <div style={{ textAlign: 'center', fontWeight: '600' }}>
                            {e[language]}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SelectHoroscope
