import React, { useEffect, useState } from 'react'
import './SnippetCategory.scss'
import { useSelector } from 'react-redux'
import { Button, message, Popconfirm, Select } from 'antd'
import CommonTable from '../../CommonTable/CommonTable'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import AddSnippetCategory from './AddSnippetCategory'
import { WEEKDAY_OPTIONS } from './SnippetCategoryConstants'
import {
    getSnippetCategories,
    deleteSnippetCategory,
    getSnippets,
} from '../../../AadhanApi'
import { useNavigate } from 'react-router'

function SnippetCategoryMain() {
    const navigate = useNavigate()
    const languageData = useSelector((state) => state.app.languageData)
    const token = useSelector((state) => state.app.accessToken)
    const { Option } = Select
    const [selectedLanguage, setSelectedLanguage] = useState(
        languageData?.[0]?.language_id
    )
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [recurrenceType, setRecurrenceType] = useState('all')
    const [editingCategory, setEditingCategory] = useState(null)
    const [existingCategories, setExistingCategories] = useState({})
    const [tableData, setTableData] = useState([])

    const showModal = (category = null) => {
        setEditingCategory(category)
        setIsModalOpen(true)
    }

    const fetchCategories = async () => {
        try {
            const response = await getSnippetCategories(
                selectedLanguage,
                recurrenceType,
                token
            )
            if (response.data.success) {
                const enrichedData = await Promise.all(
                    response.data.data.map(async (item) => {
                        const snippetsResponse = await getSnippets(
                            selectedLanguage,
                            item.snippet_category_id,
                            token
                        )
                        const snippetCount = snippetsResponse.data.success
                            ? snippetsResponse.data.data.length
                            : 0
                        return {
                            ...item,
                            no_of_items: snippetCount,
                        }
                    })
                )
                setTableData(enrichedData)
                // Update existingCategories
                const categoriesByLanguage = {}
                enrichedData.forEach((category) => {
                    if (!categoriesByLanguage[category.language_id]) {
                        categoriesByLanguage[category.language_id] = []
                    }
                    categoriesByLanguage[category.language_id].push(
                        category.snippet_category_name.toLowerCase()
                    )
                })
                setExistingCategories(categoriesByLanguage)
            } else if (
                response.data.success === false &&
                response.data.message === 'No data found'
            ) {
                console.error(
                    'API request was successful, but no data is available: ',
                    response
                )
                message.error(
                    'No data is available for the selected language and recurring type'
                )
                setTableData([])
            } else {
                console.error('API request was not successful: ', response)
                message.error('Failed to fetch categories')
                setTableData([])
            }
        } catch (error) {
            console.error('Error fetching categories:', error)
            setTableData([])
        }
    }

    const handleSuccess = (languageId, recurrenceType) => {
        if (languageId) setSelectedLanguage(languageId)
        if (recurrenceType) setRecurrenceType(recurrenceType)
        fetchCategories()
    }
    useEffect(() => {
        if (selectedLanguage && recurrenceType) {
            fetchCategories()
        }
    }, [selectedLanguage, recurrenceType])

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value)
    }

    const handleRecurrenceTypeChange = (value) => {
        setRecurrenceType(value)
    }

    const RECURRENCE_OPTIONS = [
        { label: '--All--', value: 'all' },
        { label: 'Daily', value: 'is_daily' },
        { label: 'Weekly', value: 'is_weekly' },
        { label: 'Monthly', value: 'is_monthly' },
        { label: 'Date Specific', value: 'is_date_specific' },
        { label: 'Date Range', value: 'is_date_range' },
    ]

    const handleEdit = (record) => {
        showModal(record)
    }

    const handleDelete = async (categoryId) => {
        try {
            await deleteSnippetCategory(categoryId, token)
            message.success('Category deleted successfully')

            const deletedCategory = tableData.find(
                (cat) => cat.snippet_category_id === categoryId
            )
            if (deletedCategory) {
                const languageId = deletedCategory.language_id
                setExistingCategories((prev) => ({
                    ...prev,
                    [languageId]: prev[languageId].filter(
                        (name) =>
                            name !==
                            deletedCategory.snippet_category_name.toLowerCase()
                    ),
                }))
            }

            fetchCategories()
        } catch (error) {
            console.error('Error deleting category:', error)
            message.error('Failed to delete category')
        }
    }
    const handleBackToSnippetsBtn = () => {
        navigate('/home/snippet')
    }

    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'snippet_category_name',
            key: 'snippet_category_name',
            width: '21%',
            render: (text, record) => (
                <div>
                    <div>{text}</div>
                    <div
                        style={{
                            color: 'gray',
                            fontSize: '0.8rem',
                            marginTop: '2px',
                        }}
                    >
                        {record.local_name}
                    </div>
                </div>
            ),
        },
        {
            title: 'Recurring Type',
            dataIndex: 'type',
            key: 'type',
            width: '21%',
            render: (_, record) => {
                let type = 'No Recurring'
                let timeInfo = ''

                if (record.is_daily) {
                    type = 'Daily'
                    timeInfo = `${moment(record.start_time).format(
                        'h:mm A'
                    )} - ${moment(record.end_time).format('h:mm A')}`
                } else if (record.is_weekly) {
                    type = 'Weekly'
                    timeInfo = `${
                        WEEKDAY_OPTIONS.find(
                            (day) => day.value === record.day_of_week
                        )?.label
                    }, ${moment(record.start_time).format('h:mm A')} - ${moment(
                        record.end_time
                    ).format('h:mm A')}`
                } else if (record.is_monthly) {
                    type = 'Monthly'
                    timeInfo = `Day ${record.day_of_month}, ${moment(
                        record.start_time
                    ).format('h:mm A')} - ${moment(record.end_time).format(
                        'h:mm A'
                    )}`
                } else if (record.is_date_specific) {
                    type = 'Date Specific'
                    timeInfo = `${moment(record.start_time).format(
                        'DD/MM/YYYY, h:mm A'
                    )} - ${moment(record.end_time).format('h:mm A')}`
                } else if (record.is_date_range) {
                    type = 'Date Range'
                    timeInfo = `${moment(record.start_date_range).format(
                        'DD/MM/YYYY'
                    )} - ${moment(record.end_date_range).format('DD/MM/YYYY')}`
                }

                return (
                    <div>
                        <div>{type}</div>
                        <div style={{ fontSize: '0.8em', color: '#888' }}>
                            {timeInfo}
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'No of Snippets',
            dataIndex: 'no_of_items',
            key: 'no_of_items',
            width: '19%',
            render: (text, record) => (
                <span
                    style={{
                        textDecoration: 'underline',
                        color: 'blue',
                        cursor: 'pointer',
                    }}
                    onClick={() =>
                        navigate('/home/snippet', {
                            state: {
                                selectedLanguage: record.language_id,
                                selectedCategory: record.snippet_category_id,
                            },
                        })
                    }
                >
                    {text}
                </span>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '19%',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        fontSize: '1.2rem',
                    }}
                >
                    <EditOutlined
                        aria-label="Edit category"
                        onClick={() => handleEdit(record)}
                    />
                    <Popconfirm
                        title="Delete Category"
                        description="Are you sure you want to delete this Snippet Category?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                            handleDelete(record.snippet_category_id)
                        }
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px' }}
                            aria-label="Delete Category"
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div className="snippet_category_container">
            <div className="arrowAndTitle">
                <svg
                    className="svg-arrow"
                    height="35px"
                    id="Layer_1"
                    style={{
                        enableBackground: 'new 0 0 512 512',
                        marginRight: '0px',
                        marginLeft: '-8px',
                        cursor: 'pointer',
                    }}
                    onClick={handleBackToSnippetsBtn}
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="35px"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <polygon
                        points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
                        fill="black"
                    />
                </svg>

                <h2 className="CategoryTitle"> Manage Snippet Category</h2>
            </div>
            <hr></hr>
            <div className="dropdownsAndButtons">
                <div>
                    <Select
                        className="dropdown-1"
                        onChange={handleLanguageChange}
                        value={selectedLanguage}
                        placeholder="Select Language"
                    >
                        {languageData.map((arr) => (
                            <Option
                                key={arr.language_id}
                                value={arr.language_id}
                            >
                                {arr.language_name}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        className="dropdown-2"
                        placeholder="Choose recurring type"
                        value={recurrenceType}
                        onChange={handleRecurrenceTypeChange}
                    >
                        {RECURRENCE_OPTIONS.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Button
                        type="primary"
                        onClick={() => showModal()}
                        className="addcategoryBtn"
                    >
                        Add Category
                    </Button>
                </div>
            </div>
            <div className="table">
                <CommonTable columns={columns} data={tableData} />
            </div>
            <AddSnippetCategory
                isModalOpen={isModalOpen}
                handleCancel={() => {
                    setIsModalOpen(false)
                    setEditingCategory(null)
                }}
                languageData={languageData}
                token={token}
                onSuccess={handleSuccess}
                editingCategory={editingCategory}
                selectedLanguage={selectedLanguage}
                existingCategories={existingCategories}
            />
        </div>
    )
}

export default SnippetCategoryMain
