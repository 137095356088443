import { Form, Input, Radio, Select, Checkbox, Button } from 'antd'
import { useEffect, useState } from 'react'
import { sendNotificationData } from '../../../AadhanApi'
import { useSelector } from 'react-redux'

const CustomNotificationNew = ({
    setShowModal,
    accessToken,
    selectedNotification,
    cardOptionRef,
    getTableData,
    messageApi,
    setDataLoading,
    setShowCustomNotificationNewModal,
    languageData,
    setTitle,
}) => {
    const [form] = Form.useForm()

    const allStates = useSelector((state) => state.app.locations)
    const [stateName, setStateName] = useState()
    const [districts, setDistricts] = useState()

    const [isCustomTitle, setIsCustomTitle] = useState(false)

    const [disable, setDisable] = useState(false)

    useEffect(() => {
        setDisable(false)
        if (selectedNotification) {
            form.setFieldsValue({
                title: selectedNotification.title,
                sub_category_name: selectedNotification?.sub_category_name,
                state_name: selectedNotification?.state_name?.[0],
                district_name: selectedNotification?.district_name?.[0],
                "audienceType": "default",
            })
            setStateName(selectedNotification?.state_name)
            setDistricts(allStates?.find(state => state.name === selectedNotification?.state_name?.[0])?.districts)
        } else {
            form.resetFields()
        }
        form.setFieldsValue({ is_image: false })
    }, [selectedNotification])

    const sendNotification = async (values) => {
        const state_id = allStates.filter(state => state.name === values.state_name)[0]?.state_id
        const district_id = allStates.filter(state => state.name === values.state_name)[0]?.districts.
            filter(district => district.name === values.district_name)?.[0]?.district_id
        setDataLoading(true)
        
        try {
            const response = await sendNotificationData(
                {
                    title: values?.title,
                    content_id: selectedNotification?.content_id,
                    content_type: cardOptionRef.current,
                    is_image: values.is_image,
                    state_id: state_id || "",
                    district_id: district_id || "",
                    sub_category_id: selectedNotification.sub_category_id || 0,
                },
                accessToken
            )
            if (response?.data) {
                messageApi.open({
                    type: 'success',
                    content: 'Notification Sent',
                    duration: 5,
                })
                getTableData()
                setShowModal(false)
                form.resetFields()
            }
        } catch (err) {
            messageApi.open({
                type: 'error',
                content: 'Failed send Notification',
                duration: 5,
            })
        }
        setDataLoading(false)
    }
    const onFinish = async (values) => {
        setDisable(true)
        sendNotification(values)
        setShowModal(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const disabledInputStyle = {
        backgroundColor: '#f0f0f0',
        color: '#000000',
        borderColor: '#d9d9d9',
        cursor: 'not-allowed',
    };
    return (
        <Form
            id="form"
            form={form}
            name="basic"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 10 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            }}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please enter title' }]}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 18 }}
                style={{ marginBottom: '10px' }}
            >
                <Input
                    disabled={!isCustomTitle}
                    style={!isCustomTitle ? disabledInputStyle : {}}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Form.Item>
            <Form.Item
                name="customTitle"
                style={{
                    marginBottom: '10px',
                }}
                valuePropName="checked"
            >
                <Checkbox
                    onChange={(e) => {
                        setIsCustomTitle(e.target.checked)
                        form.setFieldsValue({ customTitle: e.target.checked })
                    }}

                >Custom Title</Checkbox>
            </Form.Item>
            <Form.Item
                name="is_image"
                style={{
                    marginBottom: '10px',
                }}
            >
                <Checkbox
                    onChange={(e) => {
                        form.setFieldsValue({ is_image: e.target.checked })
                    }}
                >Image</Checkbox>
            </Form.Item>
            <hr style={{ width: 600 }} ></hr>

            <Form.Item
                label="Select the Target Audience"
                labelCol={{ span: 8 }}
                name="audienceType"
            >
                <Radio.Group>
                    <Radio value="default">Default</Radio>
                    <Radio value="customAudience">Custom Audience</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="Language"
                labelCol={{ span: 4 }}
                style={{ pointerEvents: 'none' }}
            >
                <Select
                    value={selectedNotification.language_id}
                    suffixIcon={null}
                >
                    {[selectedNotification.language_id].map((item, index) => (
                        <Select.Option key={index} value={item}>{
                            languageData.find((l) => l.language_id === item).language_name
                        }</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                wrapperCol={{ span: 24 }}
                shouldUpdate={(prevValues, currentValues) => prevValues.audienceType !== currentValues.audienceType}
            >
                {({ getFieldValue }) => {
                    return getFieldValue("audienceType") === "customAudience" ? (
                        <Form.Item
                            name="customAudienceType"
                            style={{ marginBottom: '10px' }}
                        >
                            <Radio.Group
                                onChange={() => {
                                    form.setFieldsValue({
                                        state_name: selectedNotification?.state_name?.[0],
                                        district_name: selectedNotification?.district_name?.[0],
                                        sub_category_name: selectedNotification?.sub_category_name
                                    })
                                }}
                            >
                                <Radio value="audienceState">By State</Radio>
                                <Radio value="audienceDistrict">By District</Radio>
                                {selectedNotification.sub_category_name &&
                                    <Radio value="audienceCategory">By Sub Category</Radio>
                                }
                            </Radio.Group>
                        </Form.Item>
                    ) : null

                }}
            </Form.Item>

            <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.customAudienceType !== currentValues.customAudienceType
                    || prevValues.audienceType !== currentValues.audienceType
                }
                style={{ marginBottom: '6px' }}
            >
                {({ getFieldValue }) => {
                    return ((getFieldValue("customAudienceType") === "audienceState") &&
                        getFieldValue("audienceType") === "customAudience"
                    ) ? (
                        <Form.Item
                            name="state_name"
                            value={stateName}
                            label="State"
                            labelCol={{ span: 8 }}
                        >
                            <Select
                                placeholder="Select State"
                            // suffixIcon={null}
                            >
                                {allStates.map(state => (
                                    <Select.Option key={state.state_id} value={state.name} >{state.name} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : (getFieldValue("customAudienceType") === "audienceDistrict" &&
                        getFieldValue("audienceType") === "customAudience"
                    ) ? (
                        <div>
                            <Form.Item
                                name="state_name"
                                value={stateName}
                                label="State"
                                labelCol={{ span: 8 }}
                            >
                                <Select
                                    onChange={(state_name) => {
                                        setDistricts(
                                            allStates.find(state => state.name === state_name).districts
                                        )
                                    }}
                                    placeholder="Select State"
                                >
                                    {allStates.map(state => (
                                        <Select.Option key={state.state_id} value={state.name}>{state.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="district_name"
                                label="District"
                                labelCol={{ span: 8 }}
                            >
                                <Select
                                    placeholder="Select District"
                                // suffixIcon={null}
                                >
                                    {districts?.map(district => (
                                        <Select.Option key={district.district_id} value={district.name}>{district.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    ) : (getFieldValue("customAudienceType") === "audienceCategory" &&
                        getFieldValue("audienceType") === "customAudience"
                    ) ? (
                        <Form.Item
                            label="Sub Category"
                            name="sub_category_name"
                            style={{ marginBottom: '6px', pointerEvents: 'none' }}
                            labelCol={{ span: 12 }}
                        >
                            <Select
                                placeholder="Sub Category"
                                suffixIcon={null}
                            >
                                {[selectedNotification.sub_category_name].map((item, index) => (
                                    <Select.Option key={index} value={item} >{item}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : null

                }}
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    disabled={disable}
                >
                    Submit
                </Button>
            </div>
        </Form>
    )
}

export default CustomNotificationNew
