import React from 'react'
import { breakingNewsImages } from '../BreakingNewsImage'
import "./AddShortNewsForm.scss"

export const BreakingNews = ({
    imagePath,
    language_id,
    setImagePath,
    test,
    isOriginal,
    setIsImageAdding,
    setIsOriginal,
    isSensitive,
    setIsSensitive}
) => {
    const images = breakingNewsImages;
    function handleSelectImage(url){
         setImagePath(url)
         setIsImageAdding(false)
    }
    return <div style={{display:"flex",flexWrap:"wrap",gap:10,justifyContent:"flex-start"}}>
        {images?.[language_id]?.map((e)=><img className='breaking_news_hover' alt="image" src={e} style={{weight:150,height:150,borderRadius:5,overflow:"hidden",cursor:"pointer"}} onClick={()=>handleSelectImage(e)} />)}
    </div>
}
