import { DownloadOutlined } from '@ant-design/icons'
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Radio,
    Row,
    Select,
    Spin,
} from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
    getHashtagTableData,
} from '../../AadhanApi'
import { useSelector } from 'react-redux'
import MuxPlayer from '@mux/mux-player-react'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import { expiryHoursOptions } from '../ReviewContent/ReviewContentForm/ReviewContentConstant'
const NewsContent = ({
    data,
    initialFormData,
    languageData,
    categories,
    accessToken,
    contentId,
    hashTagId,
    setHashTagId,
    sourceId,
    setFileList,
    fileList,
    videoList,
    code,
    selectedImageUrl,
    selectedVideoUrl,
    languageOption,
    setLanguageOption,
}) => {
    const [form] = Form.useForm()
    const [subCategory, setSubCategory] = useState([])
    const roleId = useSelector((state) => state.app.role_id)
    const [shortDescription, setShortDescription] = useState(
        initialFormData?.shortDescription
    )
    const [selectedCategory, setSelectedCategory] = useState(
        initialFormData?.category_id
    )
    console.log()
    const [rejectionReason, setRejectionReason] = useState('')
    const [hashTagOptions, setHashtagOptions] = useState([])
    const [allHashTagOptions, setAllHashtagOptions] = useState([])
    const [keywords, setKeywords] = useState([])
    const [videoPathUrl, setVideoPathUrl] = useState(
        initialFormData?.stream_video_url
    )
    const [hashData, setHashData] = useState([])
    const [hashTagValue, setHashTagValue] = useState('')
    const [titleError, setTitleError] = useState()
    const [title, setTitle] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const locationsData = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const allMandals = useSelector((state) => state.app.mandals)
    const [districtsData, setDistrictsData] = useState([])
    const [mandalsData, setMandalsData] = useState([])
    const tokenRegex = /token=([^&]*)/
    const playbackIdRegex = /\/([^\.]*)\.m3u8/
    const [selectStateId, setSelectStateId] = useState('')
    const [change, setChange] = useState(0)
    const [assemblyConstituenciesData, setAssemblyConstituenciesData] =
        useState([])
    const [generate, setGenerate] = useState(false)
    useEffect(() => {
        setSelectStateId(initialFormData?.state_id)
        setLanguageOption(
            initialFormData?.language_id !== '' ? initialFormData?.language : 1
        )
    }, [locationsData, initialFormData, videoPathUrl])
    useEffect(() => {
        setDistrictsData(allDistricts?.[selectStateId])
    }, [selectStateId])
    const onStateChange = (id) => {
        setIsrun(1)
        form.setFieldsValue({ district: '' })
        form.setFieldValue({ assemblyconstituencies: '' })
        setMandalsData([])
        let ids

        locationsData.map((e) => {
            if (e.name === id) {
                setSelectStateId(e.state_id)
                ids = e.state_id
            }
        })
        if (ids) {
            getAssembly_Constituencies(ids)
        }
    }
    const onDistrictChange = (id) => {
        let temp = []
        districtsData.map((e) => {
            if (id.includes(e.name) && !temp.includes(e.district_id)) {
                temp.push(e.district_id)
            }
        })
        if (temp.length !== 0) {
            getMandalsList(temp[temp.length - 1])
        }
    }

    const onMandalChange = (id) => {
        let temp = []
        mandalsData.map((e) => {
            if (id.includes(e.name) && !temp.includes(e.mandal_id)) {
                temp.push(e.mandal_id)
            }
        })
      
    }

    useEffect(() => {
        if (initialFormData?.language === languageOption) {
            
        }
    }, [initialFormData?.language === languageOption, initialFormData])
    const getMandalsList = async (id) => {
        const arr = allDistricts?.[selectStateId]?.filter((e) => {
            if (form.getFieldValue('district').includes(e.name)) {
                return e.district_id
            }
        })

        let temp = []
        arr?.map((e) => {
            temp.push(...allMandals?.[e.district_id])
        })

        setMandalsData(temp)
    }

    const getAssembly_Constituencies = async (id) => {
        setAssemblyConstituenciesData(allConstituencies?.[id])
    }
    function handleAssemblyConstituencies(event) {
        let temp = []
        assemblyConstituenciesData.map((e) => {
            if (
                event.includes(e.name) &&
                !temp.includes(e.assembly_constituency_id)
            ) {
                temp.push(e.assembly_constituency_id)
            }
        })
    }
    const [isrun, setIsrun] = useState(0)
    useEffect(() => {
        if (isrun === 0) {
            locationsData.map((e) => {
                if (initialFormData.state) {
                    if (initialFormData.state.includes(e.name)) {
                        getAssembly_Constituencies(e.state_id).then(() => {})
                    }
                }
            })

            if (initialFormData.district_id) {
                initialFormData.district_id.map((e) => {
                    getMandalsList(e)
                })
            }
        }
    }, [initialFormData])

    useEffect(() => {
        locationsData.map((e) => {
            if (initialFormData.state_name) {
                if (initialFormData.state_name.includes(e.name)) {
                    getAssembly_Constituencies(e.state_id).then(() => {
                        handleAssemblyConstituencies([e.state_id])
                    })
                }
            }
        })
    }, [change, locationsData])
    useEffect(() => {
        setShortDescription(initialFormData?.shortDescription)
    }, [initialFormData])

    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setVideoPathUrl(form.getFieldValue('video'))
        if (
            initialFormData?.keywords !== '' &&
            initialFormData?.keywords?.split(',')?.length > 0
        ) {
            setKeywords(initialFormData?.keywords?.split(','))
        }
        if (initialFormData?.state) {
            let filteredState = locationsData
                ?.map((ele) => {
                    if (initialFormData?.state.includes(ele.state_id)) {
                        return ele
                    }
                })
                .filter((Undefined) => Undefined !== undefined)

            let filteredDistrict = []
            filteredState?.forEach((ele) => {
                filteredDistrict.push(ele.districts)
            })
            let splitDistricts = []
            filteredDistrict.forEach((ele, i) => {
                splitDistricts = [...splitDistricts, ...ele]
            })
            setDistrictsData(splitDistricts)
            if (initialFormData?.district) {
                let filteredMandals = []
                splitDistricts?.forEach((ele) => {
                    filteredMandals.push(ele.mandals)
                })

                let splitMandals = []
                filteredMandals.forEach((ele, i) => {
                    splitMandals = [...splitMandals, ...ele]
                })
                setMandalsData(splitMandals)
            }
        }
    }, [form, initialFormData, locationsData])

    useEffect(() => {
        if (initialFormData?.language || languageData?.[0]?.language_id) {
            updateHashtagSource(
                initialFormData?.language !== '' ? languageOption : 1
            )
        }
        setRejectionReason('')
    }, [languageOption])
    useEffect(() => {
        let arr = []
        const temp1 = initialFormData?.mandal_id

        const name = initialFormData?.mandal

        temp1?.map((e, index) => {
            arr.push({ name: name[index], id: e })
        })
      

        let arr1 = []
        const temp2 = initialFormData?.district_id

        const name_ = initialFormData?.district

        temp2?.map((e, index) => {
            arr1.push({ name: name_[index], id: e })
        })
    }, [initialFormData])
    const shouldRenderSubcategory =
        selectedCategory &&
        roleId !== 5 &&
        roleId !== 3 &&
        subCategory?.length > 0 &&
        form.getFieldValue('category') !== undefined

    useEffect(() => {
        if (hashTagId) {
            setHashTagValue(
                hashData?.filter((h) => h.hashtag_id === hashTagId)?.[0]
                    ?.hashtag_name ?? ''
            )
        }
    }, [hashData])

    const updateHashtagSource = async (language_id) => {
        if (language_id) {
            const hashtagDataResponse = await getHashtagTableData(
                language_id,
                '',
                accessToken
            )

            setHashData(hashtagDataResponse?.data)

            setHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                })) ?? []
            )
            setAllHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                })) ?? []
            )
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const onHashTagSelect = (val) => {
        setHashTagValue(val)
        setHashTagId(
            hashData?.filter((h) => h.hashtag_name === val)?.[0]?.hashtag_id
        )
    }
    const updateProStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === parseInt(id)
                    ? { ...subCat, pro: !subCat?.pro, anti: false }
                    : { ...subCat, pro: false }
            )
        )
    }

    const updateAntiStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === parseInt(id)
                    ? { ...subCat, anti: !subCat?.anti, pro: false }
                    : { ...subCat, anti: false }
            )
        )
    }
    const handleDownload = async (url, type) => {
      try {
          const response = await fetch(url, { mode: 'cors' }); // Ensure CORS is allowed
          const blob = await response.blob();
          const blobUrl = window.URL.createObjectURL(blob);
          
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `${type}.${blob.type.split('/')[1]}`; // Set appropriate extension based on blob type
          document.body.appendChild(link);
          link.click();
          
          // Clean up
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(link);
      } catch (error) {
          console.error('Download failed:', error);
      }
  };
    return (
        <div style={{ position: 'relative' }}>
            {generate || isDisabled ? (
                <Spin className="ug_loading" size="large"></Spin>
            ) : (
                <></>
            )}
            <div
                id="form"
                style={{
                    width: '100%',
                    opacity: generate || isDisabled ? '.6' : '',
                }}
            >
                <Form
                    form={form}
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={14}>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Select
                                    // onChange={}
                                    placeholder="--Select Category--"
                                >
                                    {categories?.map((option) => {
                                        return (
                                            <Select.Option
                                                key={option?.category_id}
                                                value={option?.category_id}
                                            >
                                                <div>
                                                    {option?.category_name}
                                                    <span
                                                        style={{
                                                            color: 'gray',
                                                        }}
                                                    >{` ( ${option?.display_name} )`}</span>
                                                </div>
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            {shouldRenderSubcategory ? (
                                <Form.Item name="subcategory" label=" ">
                                    {subCategory?.map((subcat) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    margin: '1% 0 1% 0',
                                                }}
                                                key={subcat.sub_category_id}
                                            >
                                                <span
                                                    style={{
                                                        marginRight: '1rem',
                                                        maxWidth: '10rem',
                                                    }}
                                                >
                                                    {subcat.sub_category_name}
                                                </span>
                                                <div
                                                    style={{
                                                        diplay: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Radio
                                                        onClick={(e) =>
                                                            updateProStatus(
                                                                e.target.value
                                                            )
                                                        }
                                                        checked={subcat.pro}
                                                        type="radio"
                                                        name="pro"
                                                        value={
                                                            subcat.sub_category_id
                                                        }
                                                    />
                                                    <label
                                                        style={{
                                                            margin: '0 .5rem 0 0',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        pro
                                                    </label>

                                                    <Radio
                                                        onClick={(e) =>
                                                            updateAntiStatus(
                                                                e.target.value
                                                            )
                                                        }
                                                        checked={subcat.anti}
                                                        type="radio"
                                                        name="anti"
                                                        value={
                                                            subcat.sub_category_id
                                                        }
                                                    />
                                                    <label
                                                        style={{
                                                            margin: '0 0 0 0',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        anti
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Form.Item>
                            ) : null}

                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Value is required and should be less than 75 characters',
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    className={titleError ? 'error' : ''}
                                    maxLength={75}
                                    value={title}
                                    placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Short Description"
                                name="shortDescription"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please input description',
                                    },
                                ]}
                            >
                                <div style={{ position: 'relative' }}>
                                    <Input.TextArea
                                        value={shortDescription}
                                        rows={2}
                                        autoSize
                                        style={{ minHeight: '5rem' }}
                                    />
                                </div>
                            </Form.Item>
                            <Row gutter={[24, 24]}></Row>

                            {initialFormData?.created_type !== 'reporter' && (
                                <Form.Item label="Gender" name="gender">
                                    <Radio.Group>
                                        <Radio value={'male'}> Male </Radio>
                                        <Radio value={'female'}> Female </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.breakingNews !==
                                    currentValues.breakingNews
                                }
                            >
                                {selectedCategory === 8 ? (
                                    <Form.Item
                                        name="expiryHours"
                                        label="Expiry Hours"
                                        rules={[{ required: true }]}
                                    >
                                        <Select style={{ width: 200 }}>
                                            {expiryHoursOptions?.map(
                                                (option, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                ) : null}
                            </Form.Item>
                            {/* {initialFormData?.created_type !== 'reporter' && ( */}
                            <Form.Item label="Hashtag">
                                <AutoComplete
                                    options={hashTagOptions}
                                    value={hashTagValue}
                                    onSearch={(text) => {
                                        if (text) {
                                            setHashTagValue(text)
                                            setHashtagOptions(
                                                allHashTagOptions?.filter(
                                                    ({ value }) =>
                                                        value
                                                            ?.toLowerCase()
                                                            ?.includes(
                                                                text?.toLowerCase()
                                                            )
                                                )
                                            )
                                        } else {
                                            setHashtagOptions(allHashTagOptions)
                                        }
                                    }}
                                    onSelect={onHashTagSelect}
                                    placeholder="select hashtag"
                                />
                            </Form.Item>
                            {/* )} */}
                            {/* {initialFormData?.created_type !== 'reporter' && ( */}
                            <Form.Item
                                rules={[{ required: true }]}
                                label="Publish"
                                name="publish"
                            >
                                <DatePicker
                                    showTime
                                    format="DD/MM/YYYY h:mm A"
                                    disabledDate={(currentDate) =>
                                        currentDate < new Date()
                                    }
                                />
                            </Form.Item>
                            {/* )} */}

                            <div>
                                <Form.Item
                                    label="Location Specific"
                                    name="locationSpecific"
                                >
                                    <Radio.Group>
                                        <Radio value="yes"> Yes </Radio>
                                        <Radio value="no"> No </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues?.locationSpecific !==
                                        currentValues?.locationSpecific
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('locationSpecific') ===
                                        'yes' ? (
                                            <Form.Item
                                                name="state"
                                                label="State"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'please select an option',
                                                    },
                                                ]}
                                            >
                                                {locationsData && (
                                                    <Select
                                                        onChange={onStateChange}
                                                        placeholder="Select State"
                                                    >
                                                        {locationsData?.map(
                                                            (option) => (
                                                                <Select.Option
                                                                    key={
                                                                        option.state_id
                                                                    }
                                                                    value={
                                                                        option.name
                                                                    }
                                                                >
                                                                    {
                                                                        option.name
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue('state', [])
                                        )
                                    }
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.state !== currentValues.state
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('state') &&
                                        getFieldValue('state').length !== 0 ? (
                                            <Form.Item
                                                name="district"
                                                label="District"
                                            >
                                                <Select
                                                    placeholder="Select District"
                                                    onChange={onDistrictChange}
                                                    mode="multiple"
                                                >
                                                    {districtsData?.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={
                                                                    option.name
                                                                }
                                                                value={
                                                                    option.name
                                                                }
                                                            >
                                                                {option.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue('district', [])
                                        )
                                    }
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.state !== currentValues.state
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('state') &&
                                        getFieldValue('state').length !== 0 ? (
                                            <Form.Item
                                                name="assemblyconstituencies"
                                                label="Assembly-Constituencies"
                                            >
                                                <Select
                                                    placeholder="Select Assembly-Constituencies"
                                                    onChange={
                                                        handleAssemblyConstituencies
                                                    }
                                                    onClick={() => setChange(1)}
                                                    mode="multiple"
                                                >
                                                    {assemblyConstituenciesData?.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={
                                                                    option?.assembly_constituency_id
                                                                }
                                                                value={
                                                                    option?.name
                                                                }
                                                            >
                                                                {option?.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue(
                                                'assemblyconstituencies',
                                                []
                                            )
                                        )
                                    }
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.state !== currentValues.state
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('district') &&
                                        getFieldValue('district')?.length !==
                                            0 ? (
                                            <Form.Item
                                                name="mandal"
                                                label="Mandal"
                                            >
                                                <Select
                                                    mode="multiple"
                                                    onClick={() => setChange(5)}
                                                    placeholder="Select Mandal"
                                                    onChange={onMandalChange}
                                                >
                                                    {mandalsData?.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={
                                                                    option.name
                                                                }
                                                                value={
                                                                    option.name
                                                                }
                                                            >
                                                                {option.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue('district', [])
                                        )
                                    }
                                </Form.Item>
                            </div>
                            <Form.Item label=" ">
                                <Col
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {/* Image Section */}
                                    {selectedImageUrl && (
                                        <div
                                            style={{
                                                width: '48%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <img
                                                src={selectedImageUrl}
                                                alt="News"
                                                style={{
                                                    width: '100%',
                                                    height: 170,
                                                    objectFit: 'contain',
                                                    borderRadius: '8px',
                                                    marginBottom: '0.5rem',
                                                }}
                                            />
                                            <Button
                                                type="primary"
                                                icon={<DownloadOutlined />}
                                                onClick={() =>
                                                    handleDownload(
                                                        selectedImageUrl,
                                                        'image'
                                                    )
                                                }
                                                block
                                            >
                                                Download Image
                                            </Button>
                                        </div>
                                    )}

                                    {/* Video Section */}
                                    {videoList?.length !== 0 && (
                                        <div
                                            style={{
                                                width: '48%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <MuxPlayer
                                                playbackId={
                                                    videoList[0].match(
                                                        playbackIdRegex
                                                    )[1]
                                                }
                                                tokens={{
                                                    playback:
                                                        videoList[0].match(
                                                            tokenRegex
                                                        )[1],
                                                }}
                                                style={{
                                                    width: '100%',
                                                    height: 170,
                                                    borderRadius: '8px',
                                                    marginBottom: '0.5rem',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                            <Button
                                                type="primary"
                                                icon={<DownloadOutlined />}
                                                onClick={() =>
                                                    handleDownload(
                                                        videoList[0],
                                                        'video'
                                                    )
                                                }
                                                block
                                            >
                                                Download Video
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </Form.Item>
                        </Col>

                        <Col
                            span={10}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <div>
                                <SmartphonePreview
                                    title={title}
                                    description={shortDescription}
                                    image={selectedImageUrl}
                                    videoPath={videoList?.[0]}
                                    videoList={videoList}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default NewsContent
