import {
    Button,
    Form,
    Input,
    Modal,
    Popconfirm,
    Space,
    Table,
    message,
} from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteRole, getRoleList } from '../../AadhanApi'
import AddRole from './AddRole'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import './Permissions.scss'
import dayjs from 'dayjs'
const ManageRoles = () => {
    const [showRoleModal, setShowRoleModal] = useState(false)
    const [selectedRole, setSelectedRole] = useState()
    const [messageApi, contextHolder] = message.useMessage()
    const accessToken = useSelector((state) => state.app.accessToken)
    useEffect(() => {
        getTableData()
    }, [])

    const getTableData = async () => {
        try {
            const roleListResponse = await getRoleList(accessToken)
            const mappedData = roleListResponse.data?.map((role) => {
                return {
                    id: role.role_id,
                    role: role.role_name,
                    createdDate: role.created_date,
                    createdBy: role.created_by,
                }
            })
            setDataSource(mappedData)
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get Roles',
                duration: 5,
            })
        }
    }
    const [dataSource, setDataSource] = useState([])

    const onEditClicked = (record) => {
        setSelectedRole(record)
        setShowRoleModal(true)
    }

    const deleteRow = async (record) => {
        try {
            const response = await deleteRole(record.id, accessToken)
            if (response?.status === 200) {
                getTableData()
                messageApi.open({
                    type: 'success',
                    content: 'Roles deleted succesfully',
                    duration: 5,
                })
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to delete Roles',
                duration: 5,
            })
        }
    }

    const defaultColumns = [
        {
            title: 'Role',
            dataIndex: 'role',
            width: '30%',
            editable: true,
        },
        {
            title: 'Date Created',
            render: (_, record) =>
                dayjs(record?.createdDate).format('DD MMM YYYY, hh:mm a'),
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <Space size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined onClick={() => onEditClicked(record)} />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {' '}
                        |
                        <DeleteOutlined style={{ paddingLeft: '16px' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const roleModal = (
        <Modal
            open={showRoleModal}
            title="ROLE INFORMATION"
            onCancel={() => setShowRoleModal(false)}
            footer={[
                <Button key="back" onClick={() => setShowRoleModal(false)}>
                    Cancel
                </Button>,
                <Button
                    form="roleForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Save Role
                </Button>,
            ]}
        >
            <AddRole
                selectedRole={selectedRole}
                getTableData={getTableData}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
            />
        </Modal>
    )
    return (
        <div className="manage-permissions-container">
            {contextHolder}
            <h2 className="heading">Manage Roles</h2>
            <Button
                onClick={() => setShowRoleModal(true)}
                type="primary"
                style={{ width: '8rem', marginBottom: '1rem' }}
            >
                Add Role
            </Button>
            <Table bordered dataSource={dataSource} columns={defaultColumns} />
            {roleModal}
        </div>
    )
}
export default ManageRoles
