import React, { useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';
import { SoundOutlined,MutedOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

const Speaker = ({ text, language }) => {
  const voicesRef = useRef([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [voicesLoaded, setVoicesLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Set to true initially to show mute icon
  const location = useLocation();

  useEffect(() => {
    window.speechSynthesis.cancel();
  }, [text]);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        voicesRef.current = voices;
        setVoicesLoaded(true);
      } else {
        // Retry loading voices
        setTimeout(loadVoices, 100);
      }
    };

    if ('speechSynthesis' in window) {
      window.speechSynthesis.onvoiceschanged = loadVoices;
      loadVoices();
    }

    return () => {
      window.speechSynthesis.onvoiceschanged = null;
    };
  }, []);

  useEffect(() => {
    // Stop speaking when the route changes
    return () => {
      if (isSpeaking) {
        window.speechSynthesis.cancel();
        setIsSpeaking(false);
      }
    };
  }, [location.pathname, isSpeaking]);

  const speak = () => {
    if (!('speechSynthesis' in window)) {
      message.error('Sorry, your browser does not support speech synthesis or you may not installed the language package.');
      return;
    }

    if (!voicesLoaded) {
      message.warning('Loading voices, please try again.');
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = language;

    const voice = language === 'en-US'
      ? voicesRef.current.find(voice => voice.lang === language && voice.name === "Microsoft Mark - English (United States)")
      : voicesRef.current.find(voice => voice.lang === language);

    if (voice) {
      utterance.voice = voice;
      setIsSpeaking(true);
      setIsMuted(false);
      utterance.onend = () => setIsSpeaking(false);
      utterance.onerror = () => {
        setIsSpeaking(false);
        if (location.pathname.includes('=')) {
          message.error('An error occurred while speaking.');
        }
      };
      window.speechSynthesis.speak(utterance);
    } else {
      message.error(`Sorry, the specified language (${language}) is not supported by your browser.`);
    }
  };

  const handleClick = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setIsMuted(true);
    } else {
      speak();
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        ghost={true}
        type="primary"
        icon={isMuted ? <MutedOutlined /> : <SoundOutlined />}
        onClick={handleClick}
        // loading={isSpeaking}
      >
       
      </Button>
    </div>
  );
};

export default Speaker;
