import React from 'react'
import { Button, Modal } from 'antd'
import Tab from '../../AddShortNews/Tab'
import ShortNewsImageUpload from '../../AddShortNews/ShortNewsImageUpload'
import ImageLibrary from '../../imageLibrary/ImageLibrary'
import ShortNewsVideoUpload from '../../AddShortNews/AddShortNewsForm/ShortNewsVideoUpload'
import TextArea from 'antd/es/input/TextArea'

const MultimediaModal = ({
    isImageAdding,
    isVideoAdding,
    handleModalCancel,
    accessToken,
    selectedLanguage,
    title,
    setImagePath,
    imagePath,
    isOriginal,
    setIsOriginal,
    isSensitive,
    setIsSensitive,
    searchTerm,
    setSearchTerm,
    videoPath,
    setVideoPath,
    handleInputChange,
}) => {
    return (
        <Modal
            title={
                isImageAdding ? (
                    <div style={{ display: 'flex' }}>
                        Add Image ({' '}
                        <div
                            style={{
                                color: 'gray',
                                fontWeight: 400,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>Image size 540</span>{' '}
                            <span style={{ padding: '0 2px' }}>x</span>{' '}
                            <span>525</span>
                        </div>
                        <div
                            style={{
                                color: 'gray',
                                fontWeight: 400,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            ,
                            <span style={{ marginLeft: '.5rem' }}>
                                Max size 100kb
                            </span>
                        </div>{' '}
                        )
                    </div>
                ) : (
                    'Add Video'
                )
            }
            width={isImageAdding ? '63%' : '30%'}
            className="image_modal_shortnews"
            style={{ top: isImageAdding ? '13%' : '' }}
            open={isImageAdding || isVideoAdding}
            onCancel={handleModalCancel}
            footer={null}
        >
            <div className="" style={{ flex: 2 }}>
                <div>
                    {isImageAdding && (
                        <Tab
                            tab1={
                                <ShortNewsImageUpload
                                    accessToken={accessToken}
                                    languageOption={selectedLanguage}
                                    title={title}
                                    setImagePath={setImagePath}
                                    imagePath={imagePath}
                                    isOriginal={isOriginal}
                                    setIsOriginal={setIsOriginal}
                                    isSensitive={isSensitive}
                                    setIsSensitive={setIsSensitive}
                                    setIsImageAdding={handleModalCancel}
                                />
                            }
                            tab1text={'Upload'}
                            tab2={
                                <ImageLibrary
                                    imagePath={imagePath}
                                    language_id={selectedLanguage}
                                    setImagePath={setImagePath}
                                    isOriginal={isOriginal}
                                    setIsImageAdding={handleModalCancel}
                                    setIsOriginal={setIsOriginal}
                                    isSensitive={isSensitive}
                                    setIsSensitive={setIsSensitive}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                />
                            }
                            tab2text={'Library'}
                        />
                    )}
                    {isVideoAdding && (
                        <div>
                            <Tab
                                icon={true}
                                tab1={
                                    <ShortNewsVideoUpload
                                        handleCancel={handleModalCancel}
                                        accessToken={accessToken}
                                        setVideoPath={setVideoPath}
                                        videoPath={videoPath}
                                    />
                                }
                                tab1text={'Upload'}
                                tab2={
                                    <div>
                                        <TextArea
                                            value={videoPath?.yt_url}
                                            placeholder="YouTube Link"
                                            onChange={handleInputChange}
                                            style={{
                                                minHeight: '140px',
                                            }}
                                        />
                                        <div>
                                            <Button
                                                onClick={handleModalCancel}
                                                style={{
                                                    margin: '.5rem 0',
                                                    float: 'right',
                                                }}
                                                type="primary"
                                            >
                                                Ok
                                            </Button>
                                        </div>
                                    </div>
                                }
                                tab2text={'Youtube'}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default MultimediaModal
