import { Select, message } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUgcReport} from '../../AadhanApi'
import { monthArr, approvalOptions } from './UgcReport.constant'

const UgcReport = () => {
    let yearArr = []
    for (let i = 2018; i <= 2099; i++) {
        yearArr.push(i)
    }
    const currentDate = dayjs()
    const [ugcReportColumns, setUgcReportColumns] = useState([])
    const [reportsData, setReportsData] = useState([])
    const statesData = useSelector((state)=>state.app.locations)
    const [dataLoading, setDataLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()
    const [month, setMonth] = useState(currentDate.month() + 1)
    const [stateId, setStateId] = useState(null)
    const [statusOption, setStatusOption] = useState('approved')
    const [selectedStateKey, setSelectedStateKey] = useState(null)
    const [year, setYear] = useState(currentDate.year())
    const [datesCount, setDatesCount] = useState(30)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )

    const isSunday = (day) => {
        const date = new Date(year, month - 1, day)
        return date.getDay() === 0
    }

    useEffect(() => {
        let columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]
        const dateInMonth = dayjs(`${year}-${month}-01`)
        const dates = dateInMonth.daysInMonth()
        const array = Array.from({ length: dates })
        setDatesCount(dates)
        array.forEach((_, index) => {
            columns.push({
                title: (
                    <span
                        className={isSunday(index + 1) ? 'sunday-header' : ''}
                    >
                        {index + 1}
                    </span>
                ),
                dataIndex: `${index}`,
                key: `${index}`,
            })
        })
        columns.push({
            title: 'Total',
            dataIndex: 'total_contents',
            key: 'total_contents',
        })
        setUgcReportColumns(columns)
    }, [year, month])

    useEffect(() => {
        if (languageOption) {
            getUgcReportDetails(languageOption, statusOption, year, month,  selectedStateKey)
        }
    }, [languageOption, year, month, statusOption, selectedStateKey])

    const getStatesDetails = async () => {
        
       
        setStateId(statesData[1]?.state_id)
        setSelectedStateKey(statesData[1]?.state_id)
    }
    useEffect(() => {
        getStatesDetails()
    }, [statesData])

    const getUgcReportDetails = async (
        languageOption,
        statusOption,
        year,
        month,
        selectedStateKey
    ) => {
        setDataLoading(true)
        try {
            if(selectedStateKey){
                const response = await getUgcReport(
                    languageOption,
                    statusOption,
                    year,
                    month,
                    selectedStateKey,
                    accessToken
                )
                const dataSource = []
                response.data.daily_productivity_report.forEach((employee) => {
                    const { employee_id, name, daily_report, total_contents } =
                        employee
                    if (total_contents > 0) {
                        const flatDailyReport = Object.values(daily_report)
                        const entry = {
                            key: employee_id,
                            employee_id,
                            name,
                            ...flatDailyReport,
                            total_contents,
                        }
                        dataSource.push(entry)
                    }
                })
                if (dataSource.length) {
                    const totalCountsByDay = {}
                    const dateInMonth = dayjs(`${year}-${month}-01`)
                    const datesInMonthCount = dateInMonth.daysInMonth()
                    response.data.daily_productivity_report.forEach((employee) => {
                        for (let day = 1; day <= datesInMonthCount; day++) {
                            const dailyCount = employee.daily_report[day]
                            totalCountsByDay[day] =
                                (totalCountsByDay[day] || 0) + dailyCount
                        }
                    })
                    dataSource.push({
                        name: 'Total',
                        ...Object.values(totalCountsByDay),
                    })
                }
                setReportsData(dataSource)
                setDataLoading(false)
            }
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>UGC Report</h2>
            </div>
            <div className="manage-content-inputs">
                <Select
                    value={languageOption}
                    onChange={(val) => setLanguageOption(val)}
                    style={{ width: 200 }}
                >
                    {languageData.map((option) => {
                        return (
                            <Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={statusOption}
                    onChange={(val) => setStatusOption(val)}
                    style={{ width: 200 }}
                >
                    {approvalOptions.map((option) => {
                        return (
                            <Option key={option?.value} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={year}
                    onChange={(val) => setYear(val)}
                    style={{ width: 200 }}
                >
                    {yearArr.map((option, index) => {
                        return (
                            <Option key={index} value={option}>
                                {option}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={month}
                    onChange={(val) => setMonth(val)}
                    style={{ width: 200 }}
                >
                    {monthArr.map((option) => {
                        return (
                            <Option
                                key={option?.month_code}
                                value={option?.month_code}
                            >
                                {option?.month_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    defaultValue={'Andhra Prasdesh'}
                    onChange={(e) => {
                        setSelectedStateKey(e)
                    }}
                    style={{ width: 200, marginRight: '10px' }}
                >
                    {statesData.map((state) => (
                        <Select.Option
                            key={state.state_id}
                            value={state.state_id}
                        >
                            {state.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <CommonTable
                data={reportsData}
                columns={ugcReportColumns}
                dataLoading={dataLoading}
                disablePagination={true}
            />
        </div>
    )
}

export default UgcReport
