import React, { useEffect, useState } from 'react'
import './dashboard.scss'
import { Button, message, notification, Popover, Switch, Table } from 'antd'
import {
    BellOutlined,
    RedoOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import {
    getEmployeeDetails,
    getNewsSections,
    getRejectedReasons,
    getUGContent,
    isAvailableApi,
    logoutEmployee,
} from '../../AadhanApi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Uploadedicon from '../../assets/uploaded_icon.svg'
import { addNewsSectionData, addRejectedReasonData, userLogin } from '../../store/slice/AppSlice'
import { useDispatch } from 'react-redux'
const NewDashBoard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [todayData, setTodayData] = useState([])
    const [yesterData, setYesterData] = useState([])
    const employeeId = useSelector((state) => state.app.employeeId)
    const [oldData, setOldData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    let optionsString = localStorage.getItem('options')
    let options = JSON.parse(optionsString)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const categories = useSelector((state)=>state.app.newsSectionData)
    const location = useLocation()
    const rejectedReasonData = useSelector((state)=>state.app.rejectedReasonData)
    const [messageApi, contextHolder] = message.useMessage()
    const [isAvailable, setIsAvailabel] = useState(
        localStorage?.getItem('is_Available') === undefined &&
            localStorage?.getItem('is_Available') === null
            ? false
            : localStorage?.getItem('is_Available')
    )
    const [unassignedCount, setUnassignedCount] = useState([
        { title: 'Unassigned Content Count', count: 0 },
    ])
    const [showPopover, setShowPopover] = useState(false)
    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }
    useEffect(()=>{
        const fetchRejectedReasons = async () => {
            if (rejectedReasonData?.length===0 && options?.language) {
                try {
                    const rejectedDataResponse = await getRejectedReasons(options?.language, accessToken);
                    dispatch(addRejectedReasonData(rejectedDataResponse?.data?.data?.rejected_reasons));
                } catch (error) {
                    console.error("Error fetching rejected reasons:", error);
                }
            }
        };
        fetchRejectedReasons()
        const fetchNewsSection = async () => {
            try {
                const rejectedDataResponse = await getNewsSections(
                    languageData?.[0]?.language_id,
                    'all',
                    accessToken
                )
                dispatch(addNewsSectionData(rejectedDataResponse?.data?.data))
                
            } catch (error) {
                console.error('Error fetching news reasons:', error)
            }
        }
        if(categories?.length===0 && options?.language){
            fetchNewsSection()
        }
        
    },[JSON.parse(localStorage.getItem('options'))?.language,localStorage])
    
    const displayAuthenticationError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: (
                <div style={{ display: 'flex', gap: '5px' }}>
                    {errMessage}
                    <Link to="/">
                        Click here to login or will be redirected to Login page
                        in 5 seconds
                    </Link>
                </div>
            ),
            duration: 10,
        })
        setTimeout(() => {
            navigate('/')
        }, 5000)
    }
    const onReviewClicked = async (record) => {
        if(record.created_type==="" || !record.created_type){
            navigate(`${'/home/shortNews'}Content?mode=review&contentId=${record.content_id}`)
        }
        else{

            navigate(
                `${location?.pathname}Content?mode=edit&contentId=${record.content_id}`
            )
        }
    }
    useEffect(() => {
        if (
            localStorage?.getItem('is_Available') === '' ||
            localStorage?.getItem('is_Available') === undefined ||
            localStorage?.getItem('is_Available') === null
        ) {
            message.info(
                'Please set the availability status to "Active" before starting to review the content.'
            )
        }
    }, [])
    const getUgContentFunc = async (todaydate, yesterdaydate) => {
        try {
           if(options?.language){
            const ugcDataResponse = await getUGContent(
                accessToken,
                options?.language,
                undefined,
                undefined,
                undefined
            )
            let today = []
            let yesterday = []
            let old = []
            ugcDataResponse?.data?.data?.forEach((e) => {
                if (e?.assigned_at?.slice(0, 10) === todaydate) {
                    today.push(e)
                } else if (e.assigned_at?.slice(0, 10) === yesterdaydate) {
                    yesterday.push(e)
                } else {
                    old.push(e)
                }
            })
            setUnassignedCount([
                {
                    title: 'Unassigned Content Count',
                    count:
                        ugcDataResponse?.data?.unassigned_count !== undefined
                            ? ugcDataResponse?.data?.unassigned_count
                            : 0,
                },
            ])
            return { today, yesterday, old }
           }
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get UG Content')
            }
            return { today:[], yesterday:[], old:[] }
        }
    }
    const refetchData = async () => {
        const newData = await getUgContentFunc(
            dayjs().startOf('day').format('YYYY-MM-DD'),
            dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
        )

        setTimeout(() => {
            setTodayData(newData?.today)
            setYesterData(newData?.yesterday)
            setOldData(newData?.old)
        }, 1000 * 60) // Delay of 1 minute before updating the displayed data
    }
    useEffect(() => {
        async function initialfetch() {
            const today = dayjs().startOf('day').format('YYYY-MM-DD')
            const yesterday = dayjs()
                .subtract(1, 'day')
                .startOf('day')
                .format('YYYY-MM-DD')
            const newData = await getUgContentFunc(today, yesterday)
            setTodayData(newData?.today)
            setYesterData(newData?.yesterday)
            setOldData(newData?.old)
        }
        if (!isAvailable) {
            initialfetch()
        }
    }, [])
    useEffect(() => {
        if (!isAvailable) return; // Skip if status is false
    
        setDataLoading(true);
        const today = dayjs().startOf('day').format('YYYY-MM-DD');
        const yesterday = dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD');
        let isMounted = true;
        let interval;
    
        const clearExistingInterval = () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    
        const fetchLatestData = async () => {
            const newData = await getUgContentFunc(today, yesterday);
            if (isMounted) {
                setTodayData(newData?.today);
                setYesterData(newData?.yesterday);
                setOldData(newData?.old);
            }
            
            const res = await getEmployeeDetails(
                localStorage.getItem('EMPLOYEE_ID'),
                accessToken
            );
            
            if (isMounted) {
                const isAvailable = res?.data?.[0]?.is_availdable === true;
                localStorage.setItem('is_Available', isAvailable);
                setIsAvailabel(isAvailable);
            }
            if ([...newData?.today, ...newData?.yesterday, ...newData?.old]?.length > 0) {
                clearExistingInterval();
            }
        };
    
        const startInterval = () => {
            clearExistingInterval(); // Clear any existing interval
    
            // Check if data length is 0
            if ([...oldData, ...yesterData, ...todayData]?.length === 0) {
                interval = setInterval(fetchLatestData, 1000 * 60 * 2); // 2 minutes refetch
            }
        };
    
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                fetchLatestData(); // Fetch the latest data immediately when the tab is visible
            } else {
                clearExistingInterval(); // Pause fetching if the tab is inactive
            }
        };
    
        // Initial data fetch
        getUgContentFunc(today, yesterday).then((initialData) => {
            if (isMounted) {
                setTodayData(initialData?.today);
                setYesterData(initialData?.yesterday);
                setOldData(initialData?.old);
                setDataLoading(false);
                startInterval(); // Start the interval if needed
            }
        });
    
        // Start visibility change listener
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // Clean up on component unmount
        return () => {
            isMounted = false;
            clearExistingInterval();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [options?.language]); // Ensure dependencies trigger correct behavior
    
    const calculateTimeSince = (dateString) => {
        const givenDate = new Date(dateString)
        const currentDate = new Date()
        const diffInMs = currentDate - givenDate
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
        const diffInHours = Math.floor(
            (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        const diffInMinutes = Math.floor(
            (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
        )
        const diffInSeconds = Math.floor((diffInMs % (1000 * 60)) / 1000)
        return {
            days: diffInDays,
            hours: diffInHours,
            minutes: diffInMinutes,
            seconds: diffInSeconds,
        }
    }
    // breaking_news
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '40%',
            sorter: (a, b) => {
                // Check if both items have category_id in [8, 20, 32]
                const isACategory = a?.category_id === 8 || a?.category_id === 20 || a?.category_id === 32;
                const isBCategory = b?.category_id === 8 || b?.category_id === 20 || b?.category_id === 32;
            
                if (isACategory && isBCategory) {
                    return 0; 
                }
                if (isACategory) {
                    return -1;
                }
                if (isBCategory) {
                    return 1; 
                }
                const aHasCreatedType = a.created_type !== null && a.created_type !== undefined;
                const bHasCreatedType = b.created_type !== null && b.created_type !== undefined;
            
                if (!aHasCreatedType && bHasCreatedType) {
                    return 1; 
                }
                if (aHasCreatedType && !bHasCreatedType) {
                    return -1; 
                }
            
                return 0; 
            },
            
            defaultSortOrder: 'ascend',
            render: (_, record) => (
                <div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p style={{ display: 'flex', alignItems: 'center', gap: '5px', margin: '0px'}}>
                        {record?.category_id !== 8 && record?.category_id!==20 && record?.category_id!==32 && record?.created_type !== 'reporter' && 
                    <div style={{marginRight:9,width:"1rem"}}/>
                        }
                        {record?.created_type === 'reporter' && (
                            <img
                                style={{
                                    width: '1rem',
                                    marginRight: '9px',
                                }}
                                alt="ViewIcon"
                                src={Uploadedicon}
                            />
                        )}
                        {(record?.category_id === 8 | record?.category_id===20 | record?.category_id===32) ? (
                            <span className="flashing-icon" style={{gap:5,marginRight:9}}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={"1rem"}
                                    viewBox="0 0 24 24"
                                    fill="orange"
                                    className="size-6"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                        ) : null}
                        {record?.title}
                    </p>
                </div>
            </div>
            
            ),
        },
        {
            title: 'Submitted Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            render: (_, record) => (
                <div>
                    <div>
                        {dayjs(record?.uploaded_date).format('DD MMM YYYY')}
                    </div>
                    {dayjs(record?.uploaded_date).format('hh:mm:ss A')}
                    <div></div>
                </div>
            ),
            // defaultSortOrder: 'ascend',
            sorter: (a, b) =>
                dayjs(a.uploaded_date).unix() - dayjs(b.uploaded_date).unix(),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'District',
            dataIndex: 'district_name',
            key: 'district_name',
            render: (_, record) => record?.district_name?.[0],
        },
        {
            title: 'Time Since',
            dataIndex: 'time_since',
            key: 'time_since',
            sorter: (a, b) => {
                const timeA = calculateTimeSince(b.assigned_at)
                const timeB = calculateTimeSince(a.assigned_at)
                return (
                    timeB.days * 1440 +
                    timeB.hours * 60 +
                    timeB.minutes -
                    (timeA.days * 1440 + timeA.hours * 60 + timeA.minutes)
                )
            },
            // defaultSortOrder: 'descend',
            render: (_, record) => {
                const timesince = calculateTimeSince(record?.assigned_at)
                let day = ' day, '
                if (timesince?.days > 1) {
                    day = ' days, '
                }
                return (
                    <div
                        style={{
                            fontWeight: '500',
                            color:
                                timesince?.days > 0 ||
                                timesince?.hours > 0 ||
                                timesince?.minutes > 15
                                    ? 'red'
                                    : 'black',
                        }}
                    >
                        {timesince?.days > 0 ? timesince?.days + `${day} ` : ''}
                        {timesince?.hours > 0 ? `${timesince?.hours} hr ` : ''}
                        {timesince?.minutes > 0
                            ? `${timesince?.minutes} min`
                            : ''}
                    </div>
                )
            },
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Button type="primary" onClick={() => onReviewClicked(record)}>
                    Review
                </Button>
            ),
        },
    ]
    useEffect(()=>{
        setDataLoading(true)
        setTimeout(()=>{
            async function get() {
                const today = dayjs().startOf('day').format('YYYY-MM-DD')
                const yesterday = dayjs()
                    .subtract(1, 'day')
                    .startOf('day')
                    .format('YYYY-MM-DD')
                const newData = await getUgContentFunc(today, yesterday)
                setTodayData(newData?.today)
                setYesterData(newData?.yesterday)
                setOldData(newData?.old)
                setDataLoading(false)
            }
            if(isAvailable && localStorage?.getItem('is_Available')!==isAvailable){
                    
                get()
            }
        },[2000])
          if(!isAvailable){
            setDataLoading(false)
          }
    },[isAvailable===true])
    const onLogout = async (status) => {
    try {
        await logoutEmployee(accessToken, employeeId,status);
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('USER_PERMISSIONS');
        localStorage.removeItem('EMPLOYEE_ID');
        localStorage.removeItem('EMPLOYEE_NAME');
        localStorage.removeItem('options');
        dispatch(addRejectedReasonData([]));
        dispatch(addNewsSectionData([]));
        dispatch(userLogin({}));
        notification.success({
            message: 'Logout Successful',
            description: 'You have been logged out successfully.',
        });
        navigate('/');
    } catch (error) {
        console.error('Error during logout:', error);
        notification.error({
            message: 'Logout Failed',
            description: 'There was an error during logout. Please try again.',
        });
    }
};

    const handleConfirm = async () => {
        notification.open({
            message: 'Availability Update',
            description:
                'Your tasks for today have been reassigned. Please take a break."',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        setIsAvailabel('')
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false, true, 'day_off')
        setTodayData([])
        setYesterData([])
        setOldData([])
        setShowPopover(false)
        onLogout('day_off')
        
    }

    const handleCancel = async () => {
        notification.open({
            message: 'Availability Update',
            description: 'Short break time! Take a moment to recharge.',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        // message.info("Short break time! Take a moment to recharge.");
        setIsAvailabel('')
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false, true, 'short_break')
        setTodayData([])
        setYesterData([])
        setOldData([])
        setShowPopover(false)
    }
    const handleSwitchChange = async (checked) => {
        if (!checked) {
            setShowPopover(true)
        } else {
            setIsAvailabel(true)
            localStorage.setItem('is_Available', true)
            await isAvailableApi(accessToken, true, false, "active")
        }
    }
    return (
        <div className="main_dev">
            <div className="dash_board_header">
                <h4>Review Content</h4>
               
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.1rem',
                        fontWeight: '500',
                        margin: 'auto',
                        
                    }}
                >
                    <label
                        style={{
                            marginRight: '.5rem',
                            color: 'orange',
                            fontSize: '1.1rem',
                        }}
                    >
                        Pending :
                    </label>
                    <span
                        style={{
                            color: 'red',
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                        }}
                    >
                          {unassignedCount?.[0]?.count}
                    </span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.1rem',
                        fontWeight: '500',
                        margin: 'auto',
                    
                    }}
                >
                    <label
                        style={{
                            marginRight: '.5rem',
                            color: 'green',
                            fontSize: '1.1rem',
                        }}
                    >
                        In Review:
                    </label>
                    <span
                        style={{
                            color: 'red',
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {todayData?.length + yesterData?.length + oldData?.length}
                      
                    </span>
                </div>
                <div className="active_btn">
                    <Button
                        style={{ marginRight: '3rem', opacity: '.7' }}
                        type="primary"
                        ghost
                        onClick={async () => {
                            setDataLoading(true)
                            const newData = await getUgContentFunc(
                                dayjs().startOf('day').format('YYYY-MM-DD'),
                                dayjs()
                                    .subtract(1, 'day')
                                    .startOf('day')
                                    .format('YYYY-MM-DD')
                            )
                            setTodayData(newData?.today)
                            setYesterData(newData?.yesterday)
                            setOldData(newData?.old)
                            setDataLoading(false)
                            const res = await getEmployeeDetails(
                                localStorage.getItem('EMPLOYEE_ID'),
                                accessToken
                            )
                            localStorage.setItem(
                                'is_Available',
                                res?.data?.[0]?.is_availdable === true
                                    ? true
                                    : ''
                            )
                            setIsAvailabel(
                                res?.data?.[0]?.is_availdable === true
                                    ? true
                                    : ''
                            )
                        }}
                    >
                        <RedoOutlined />
                        Refresh
                    </Button>
                    <label style={{ marginRight: '1rem' }}>
                        Availability :
                    </label>
                    <Popover
                        content={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginRight: '2rem',
                                }}
                            >
                                <Button
                                    className="dayOff"
                                    style={{
                                        width: 150,
                                        marginBottom: '.5rem',
                                        background: 'blue',
                                        color: 'white',
                                    }}
                                    onClick={handleCancel}
                                >
                                    Short Break
                                </Button>
                                <Button
                                    className="dayOff"
                                    style={{
                                        width: 150,
                                        background: 'red',
                                        color: 'white',
                                    }}
                                    onClick={handleConfirm}
                                >
                                    Day Off
                                </Button>
                            </div>
                        }
                        title="You are about to set"
                        trigger="click"
                        visible={showPopover}
                        onVisibleChange={(visible) =>
                            !visible && setShowPopover(false)
                        }
                    >
                        <Switch
                            value={isAvailable}
                            onChange={handleSwitchChange}
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                            className="active_status"
                        />
                    </Popover>
                </div>
            </div>
            <div className="outer_dev">
                <Table
                    className="no-padding-table"
                    dataSource={[...oldData, ...yesterData, ...todayData]}
                    columns={columns}
                    pagination={{ pageSize: 20 }}
                    bordered
                    loading={dataLoading}
                />
            </div>
        </div>
    )
}
export default NewDashBoard
