import React, { useState } from 'react'
import ImageUploader from '../image-uploader-crop/ImageUploader'
import { getImageUrl } from '../../AadhanApi'
import { Spin } from 'antd'
import SmartphonePreviewUploadLibrary from '../SmartphonePreview/SmartPhonePreviewUploadLibray'

const ShortNewsImageUpload = ({
    accessToken,
    languageOption,
    setImagePath,
    setIsImageAdding,
    imagePath,
    setIsSensitive,
    isSensitive,
    isOriginal,
    setIsOriginal,
}) => {
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [act, setAct] = useState(false)
    const getImagePath = async (path) => {
        setIsImageUploading(true)
        const pathUriArr = await getImageUrl(
            path,
            'shortnews',
            accessToken,
            languageOption
        )
        if (pathUriArr?.data) {
            setImagePath(pathUriArr?.data)
            setIsImageAdding(false)
            setIsImageUploading(false)
        }
    }
    return (
        <div className="video_upload_shortnews">
            <ImageUploader
                modal={true}
                getImagePath={getImagePath}
                setAct={setAct}
            />
            {isImageUploading && (
                <div className="upload_image_shortnews">
                    <Spin></Spin>
                </div>
            )}
            {imagePath && !isImageUploading && !act && (
                <SmartphonePreviewUploadLibrary
                    isOriginal={isOriginal}
                    setIsOriginal={setIsOriginal}
                    isSensitive={isSensitive}
                    setIsSensitive={setIsSensitive}
                    imagePath={imagePath}
                />
            )}
        </div>
    )
}

export default ShortNewsImageUpload
