// import React from 'react';
// import { Steps } from 'antd';
// import './stories.scss';

// const CustomSteps = () => {
//   const getDotStyle = (title) => {
//     switch (title) {
//       case 'Finished':
//         return { backgroundColor: 'green' };
//       case 'In Progress':
//         return { backgroundColor: 'blue' };
//       case 'Waiting':
//         return { backgroundColor: 'gray' };
//       default:
//         return {};
//     }
//   };

//   const customDot = (dot, { title }) => (
//     <span className="ant-steps-dot" style={getDotStyle(title)}>{dot}</span>
//   );

//   return (
//     <div className="custom_steps">
//                         <Steps
//                             size="small"
//                             progressDot={customDot}
//                             current={1}
//                             className='custom-steps'
//                             items={[
//                                 {
//                                     title: 'Finished',
                                    
//                                 },
//                                 {
//                                     title: 'In Progress',
                                    
//                                 },
//                                 {
//                                     title: 'Waiting',
                                    
//                                 },
//                             ]}
//                         />
//                     </div>
//   );
// };

// export default CustomSteps;

import React from 'react';
import { Steps } from 'antd';
import './stories.scss'; // Import your custom CSS

const CustomSteps = () => {
  const getDotStyle = (title) => {
    switch (title) {
      case 'Assigned':
        return { backgroundColor: 'blue' };
      case 'Accepted':
        return { backgroundColor: 'yellow' };
      case 'Completed':
        return { backgroundColor: 'lightGreen' };
      default:
        return {};
    }
  };

  const customDot = (dot, { title }) => (
    <div
      className="custom-dot"
      style={getDotStyle(title)}
    />
  );

  return (
    <div className='custom_steps'>
        <Steps
      size="small"
      className="custom-steps"
      progressDot={customDot}
      current={1}
      items={[
        {
          title: 'Assigned',
        },
        {
          title: 'Accepted',
        },
        {
          title: 'Completed',
        },
      ]}
    />
    </div>
  );
};

export default CustomSteps;
