import React, { useState } from 'react'
import './ViewPageVisuals.scss'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import PreviewModal from './PreviewModal'
const ViewPageVisuals = ({ visuals, editVisuals, deleteVisuals }) => {
    const [hover, setHover] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [img, setImg] = useState([])
    const handleMouseOver = (id) => {
        setHover((prev) => ({ ...prev, [id]: true }))
    }

    const handleMouseLeave = (id) => {
        setHover((prev) => ({ ...prev, [id]: false }))
    }
    function handleClick(data) {
        setImg(data)
        setIsModalOpen(true)
    }

    return (
        <div>
            <div className="outer-container">
                {visuals?.map((e) => (
                    <div
                        onMouseOver={() => handleMouseOver(e.id)}
                        onMouseLeave={() => handleMouseLeave(e.id)}
                        key={e.id}
                        className="visual_container"
                    >
                        <img
                            onClick={() => handleClick(e.images)}
                            loading="lazy"
                            className="visuals_img"
                            src={`${e?.images?.[0]}`}
                            alt="visual"
                        />
                        {hover[e.id] && (
                            <div className="visual_button">
                                <div
                                    className="action_btn"
                                    onClick={() => editVisuals(e)}
                                >
                                    <EditFilled />
                                </div>
                                <div className="action_btn">
                                    <Popconfirm
                                        title="Delete Visual"
                                        description="Are you sure to delete this visual?"
                                        onConfirm={() => deleteVisuals(e)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteFilled
                                            style={{ paddingLeft: '5px' }}
                                        />
                                    </Popconfirm>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <PreviewModal
                data={img}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </div>
    )
}

export default ViewPageVisuals
