import React from 'react';
import './NumberedBulletList.scss'

const NumberedBulletList = ({ text }) => {
  const processText = (text) => {
    // Split the text into lines
    const lines = text.split('\n');
    let bulletCounter = 0;
    let numberCounter = 0;
    // Process each line and wrap it with a bullet or number
    const processedLines = lines.map((line, index) => {
      const cleanedLine = line.trim();
      if (cleanedLine.startsWith('**')) {
        numberCounter++;
        return <div key={index} className=''>
          {numberCounter}. {cleanedLine.slice(2).trim()}</div>; // Bullet point for lines starting with '**'
      } else if (cleanedLine.startsWith('*')) {
        bulletCounter++;
        return <div key={index} className=""><div className='bullet-point-item'>{cleanedLine.slice(1).trim()}</div></div>; // Number for lines starting with '*'
      } else {
        return <div key={index} className='newLine'>{cleanedLine}</div> // Default behavior
      }
    });

    return processedLines;
  };

  return <>{(text.startsWith('*') || text.startsWith('**')) ? processText(text) : text}</>;
};

export default NumberedBulletList;