export const languageOptions = [
    {
        label: 'English',
        value: 'english',
    },
    {
        label: 'Telugu',
        value: 'telugu',
    },
    {
        label: 'Tamil',
        value: 'tamil',
    },
    {
        label: 'Hindi',
        value: 'hindi',
    },
]

export const AdTypeOptions = [
    {
        label: 'Card',
        value: 'card',
    },
    {
        label: 'Short',
        value: 'short',
    },
]

export const typeOptions = [
    {
        label: 'Short News',
        value: 'shortnews',
    },
    {
        label: 'HTML',
        value: 'html',
    },
    {
        label: 'Short Video',
        value: 'shortvideo',
    },
    {
        label: 'Card',
        value: 'card',
    },
]

export const publishRadioOptions = [
    {
        label: 'Immediately',
        value: 'immediately',
    },
    {
        label: 'Schedule',
        value: 'schedule',
    },
]