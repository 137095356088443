import { useSelector } from 'react-redux'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import AddDevotionalVideoForm from './AddDevotionalVideoForm/AddDevotionalVideoForm'
import { useLocation, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import {
    createDevotionalVideo,
    getDevotionalVideoByContentId,
    updateDevotionalVideo,
} from '../../AadhanApi'
import { Button, message } from 'antd'
import { CloseSquareFilled } from '@ant-design/icons'

const AddDevotionalVideo = () => {
    const location = useLocation()
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('contentId')
    const mode = searchparam.get('mode')
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [videoPath, setVideoPath] = useState()
    const [imagePath, setImagePath] = useState('')
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [messageApi, contextHolder] = message.useMessage()
    const titleChange = (newTitle) => {
        setTitle(newTitle)
    }
    const [isLoading, setIsLoading] = useState(false)

    const [initialFormData, setInitialFormData] = useState({
        language: languageData?.[0]?.language_id,
        title: '',
        thumbnailImage: '',
        days: [],
    })

    const updateFormData = async (contentId, accessToken) => {
        const response = await getDevotionalVideoByContentId(
            contentId,
            accessToken
        )
        if (response?.data) {
            setTitle(response?.data?.title)
            setVideoPath(response?.data?.stream_video_url)
            setImagePath(response?.data?.thumbnailimage_url)
            setInitialFormData({
                ...initialFormData,
                language: response?.data?.language_id,
                title: response?.data?.title,
                thumbnailImage: response?.data?.thumbnailimage_url,
                video: response?.data?.stream_video_url,
                days: response?.data?.days,
            })
        }
    }

    useEffect(
        () => {
            if (contentId) {
                updateFormData(contentId, accessToken)
            }
        },
        [contentId],
        initialFormData
    )

    const createRequest = (values, type) => {
        return {
            title: values?.title
                ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g, '$1 ') // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                .trim(), // Trim leading and trailing spaces
            language_id: values?.language,
            thumbnailimage_url: imagePath,
            stream_video_url: videoPath,
            days: values?.days,
        }
    }

    const onSave = async (values, type) => {
        setIsLoading(true)
        if (!imagePath) {
            messageApi.open({
                type: 'error',
                content: 'Please select thumbnail',
                duration: 5,
            })
            return
        }
        const request = createRequest(values, type)
        if (mode === 'edit') {
            try {
                const videoResponse = await updateDevotionalVideo(
                    request,
                    contentId,
                    accessToken
                )
                if (
                    videoResponse?.data?.message ===
                    'devotional content updated successfully'
                ) {
                    setIsLoading(false)
                    navigate('/home/devotionalVideo')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to update Devotional video',
                    duration: 5,
                })
            }
        } else if (mode === 'add') {
            try {
                const videoResponse = await createDevotionalVideo(
                    request,
                    accessToken
                )
                if (
                    videoResponse?.data?.message ===
                    'devotional content created successfully'
                ) {
                    setIsLoading(false)
                    navigate('/home/devotionalVideo')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to create Short video',
                    duration: 5,
                })
            }
        }
    }

    return (
        <div style={{ padding: '0 1rem' }}>
            {contextHolder}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h3>
                    {mode === 'edit'
                        ? 'Edit Devotional Video'
                        : 'Add Devotional Video'}
                </h3>
                <Button
                    onClick={() => navigate(-1)}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{ color: 'LightCoral', fontSize: '1.8rem' }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>
            <div className="add-ad-container row">
                <div className="col-md">
                    <AddDevotionalVideoForm
                        titleChange={titleChange}
                        initialFormData={initialFormData}
                        accessToken={accessToken}
                        onSave={onSave}
                        setVideoPath={setVideoPath}
                        languageData={languageData}
                        setImagePath={setImagePath}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                    />
                </div>
                <div className="col-md">
                    <SmartphonePreview title={title} videoPath={videoPath} />
                </div>
            </div>
        </div>
    )
}
export default AddDevotionalVideo
