import { Button, message, Popconfirm, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Snippets.scss'
import CommonTable from '../../CommonTable/CommonTable'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router'
import {
    deleteSnippet,
    getSnippetCategories,
    getSnippets,
} from '../../../AadhanApi'
function SnippetsMain() {
    const navigate = useNavigate()
    const location = useLocation()
    const languageData = useSelector((state) => state.app.languageData)
    const token = useSelector((state) => state.app.accessToken)
    const { Option } = Select

    const [selectedLanguage, setSelectedLanguage] = useState(
        location.state?.selectedLanguage || languageData?.[0]?.language_id
    )
    const [selectedCategory, setSelectedCategory] = useState(
        location.state?.selectedCategory || null
    )
    const [categories, setCategories] = useState([])
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getSnippetCategories(
                    selectedLanguage,
                    'all',
                    token
                )
                if (response.data.success) {
                    setCategories(response.data.data)

                    if (!selectedCategory && response.data.data.length > 0) {
                        setSelectedCategory(
                            response.data.data[0].snippet_category_id
                        )
                    }
                }
            } catch (error) {
                console.error('Error fetching categories:', error)
                message.error('Failed to fetch categories')
                setCategories([])
                // setSelectedCategory(null)
            }
        }

        if (selectedLanguage) {
            fetchCategories()
        }
    }, [selectedLanguage, token])

    // Fetch snippets when language or category changes
    useEffect(() => {
        const fetchSnippets = async () => {
            if (!selectedLanguage || !selectedCategory) return

            try {
                setLoading(true)
                const response = await getSnippets(
                    selectedLanguage,
                    selectedCategory,
                    token
                )
                if (response.data.success) {
                    setTableData(response.data.data)
                }
            } catch (error) {
                console.error('Error fetching snippets:', error)
                message.error('Failed to fetch snippets')
            } finally {
                setLoading(false)
            }
        }

        fetchSnippets()
    }, [selectedLanguage, selectedCategory, token])

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value)
        setSelectedCategory(null)
        setCategories([])
        setTableData([])
    }

    const handleCategoryChange = (value) => {
        setSelectedCategory(value)
    }

    const handleEdit = (record) => {
        navigate('/home/addsnippet-form', {
            state: {
                editingSnippet: record,
                selectedLanguage: selectedLanguage,
                selectedCategory: selectedCategory,
            },
        })
    }

    const handleDelete = async (contentId) => {
        try {
            await deleteSnippet(contentId, token)
            message.success('Snippet deleted successfully')

            const response = await getSnippets(
                selectedLanguage,
                selectedCategory,
                token
            )
            if (response.data.success) {
                setTableData(response.data.data)
            }
        } catch (error) {
            console.error('Error deleting snippet:', error)
            message.error('Failed to delete snippet')
        }
    }

    const columns = [
        {
            title: 'Snippet Name',
            dataIndex: 'title',
            key: 'title',
            width: '33%',
        },

        {
            title: 'Category Name',
            dataIndex: 'snippet_category_name',
            key: 'snippet_category_name',
            width: '21%',
            render: (text, record) => (
                <div>
                    <div>{text}</div>
                    <div
                        style={{
                            color: 'gray',
                            fontSize: '0.8rem',
                            marginTop: '2px',
                        }}
                    >
                        {record.local_name}
                    </div>
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '33%',
            render: (_, record) => (
                <div>
                    <EditOutlined
                        onClick={() => handleEdit(record)}
                        style={{ cursor: 'pointer' }}
                    />
                    <Popconfirm
                        title="Delete Visual"
                        description="Are you sure to delete this visual?"
                        onConfirm={() => handleDelete(record.content_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div className="snippet_container">
            <h2>Manage Snippets</h2>
            <hr></hr>
            <div className="dropdownsAndBtn">
                <div>
                    <Select
                        className="dropdown-1"
                        onChange={handleLanguageChange}
                        value={selectedLanguage}
                        placeholder="Select Language"
                    >
                        {languageData.map((arr) => (
                            <Option
                                key={arr.language_id}
                                value={arr.language_id}
                            >
                                {arr.language_name}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        className="dropdown-2"
                        placeholder="Choose category"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        {categories.map((category) => (
                            <Option
                                key={category.snippet_category_id}
                                value={category.snippet_category_id}
                            >
                                {category.snippet_category_name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <Button
                        type="primary"
                        onClick={() => navigate('/home/addsnippet-form')}
                    >
                        Add Snippet
                    </Button>
                    <Button
                        type="primary"
                        className="viewCategoriesBtn"
                        onClick={() => navigate('/home/category-snippet')}
                    >
                        View Categories
                    </Button>
                </div>
            </div>
            <div className="table">
                <CommonTable
                    columns={columns}
                    data={tableData}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default SnippetsMain
