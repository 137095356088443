import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    catgoryData: [],
    tableData: [],
}

export const ManageContentSlice = createSlice({
    name: 'manageContent',
    initialState,
    reducers: {
        updateCategoryData: (state, action) => {
            state.catgoryData = action?.payload
        },
        updateTableData: (state, action) => {
            state.tableData = action?.payload
        },
    },
})

export const { updateCategoryData, updateTableData } =
    ManageContentSlice.actions

export default ManageContentSlice.reducer
