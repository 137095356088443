import React, { useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { MessageTwoTone, RobotOutlined, SendOutlined} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import "../stories.scss"
const Comment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment,setComment] = useState('')
  const [allComments,setAllComments] = useState([])
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>

      <div onClick={showModal} className='chat_btn'><MessageTwoTone /></div>
    
      <Modal title="Comments" footer={false} width={600} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <hr></hr>
        <Form
                    name="comments"
                    scrollToFirstError
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the story title',
                            },
                        ]}
                    >
                        <Input disabled placeholder="Story Title" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the story description',
                            },
                        ]}
                    >
                        <TextArea disabled placeholder="Story Description" rows={4} />
                    </Form.Item>
                    <div className='chats'>
                     {allComments?.map((e)=>{
                        return <div className='comment'>
                            <div>{e.message}</div>
                            <div style={{fontWeight:"600",display:"flex",alignItems:"center"}}><RobotOutlined  style={{marginRight:".3rem"}} />{e.user}</div>
                        </div>
                     })}
                    </div>
                    <div className='message_box'>

                    <TextArea value={comment} onChange={(e)=>setComment(e.target.value)} placeholder="Add comment" rows={4} />
                    <SendOutlined onClick={()=>setAllComments([...allComments,{message:comment,user:"Baskar Raja"}])} className='send_btn' />
                    </div>
                    </Form>
      </Modal>
    </>
  );
};
export default Comment;