import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    weeklyDatesRange:[],
    zodiacs:[],
    dailyHoroscopes:[],
    updateHoroscopeContent:[]
}

export const HoroscopeSlice = createSlice({
    name: 'horoscope',
    initialState,
    reducers: {
        addWeeklyDates: (state, action) => {
            state.weeklyDatesRange = action.payload
            
        },
        addZodiacs:(state,action)=>{
            state.zodiacs=action.payload
        },
        addDailyHoroscopeData:(state,action) => {
           state.dailyHoroscopes = action.payload
        
        },
        updateHoroscopeContent:(state,action)=>{
            state.updateHoroscopeContent=action.payload
        }
        
    },
})

export const {
   addWeeklyDates,addZodiacs,addDailyHoroscopeData,updateHoroscopeContent
} = HoroscopeSlice.actions

export default HoroscopeSlice.reducer
