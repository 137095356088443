import {
    ContainerOutlined,
    DashboardOutlined,
    LineChartOutlined,
    PlaySquareOutlined,
    ScheduleOutlined,
    UsergroupAddOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons'

import Feedback from '../../assets/Feedback.svg'
import Master from '../../assets/Master.svg'
import astrology from '../../assets/sun 1.svg'
import RolesAndPermisions from '../../assets/Roles_Permissions.svg'

export const RootNavbarMenuKeys = [
    'content',
    'master',
    'feedback',
    'categories',
    'employees',
    'rolesPermissions',
    'reports',
    'astrology',
    'review',
    'stories',
    'library',
    'writer-dashboard',
    'editorDashboard',
    'tv-content',
]

export const NavBarItems = [
    {
        key: 'editorDashboard',
        label: 'Editor Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'review',
        label: 'SubEditor Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'writer-dashboard',
        label: 'Writer Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'stories',
        label: 'Stories',
        icon: <VideoCameraOutlined />,
        children: [
            { key: 'list-of-stories', label: 'List of Stories' },
            { key: 'stages', label: 'Stages' },
            { key: 'central-team-ops', label: 'Central Team Ops' },
            { key: 'media-team', label: 'Media Team' },
            { key: 'reporter', label: 'Reporter' },
        ],
    },
    {
        key: 'content',
        label: 'Content',
        icon: <ContainerOutlined />,
        children: [
            { key: 'ads', label: 'Ads' },
            { key: 'card', label: 'Card' },
            { key: 'html', label: 'HTML' },
            { key: 'poll', label: 'Poll' },
            { key: 'quiz', label: 'Quiz' },
            { key: 'shortNews', label: 'Short News' },
            { key: 'shortVideo', label: 'Short Video' },
            { key: 'devotionalVideo', label: 'Devotional Video' },
            { key: 'notifications', label: 'Notifications' },
            { key: 'ug', label: 'UG Content' },
        ],
    },
    {
        key: 'tv-content',
        label: 'TV Content',
        icon: <ContainerOutlined />,
        children: [
            { key: 'tv', label: 'Manage TV Content' },
            { key: 'approved-content', label: 'Approved TV Content' },
        ],
    },
    {
        key: 'library',
        label: 'Library',
        icon: <ScheduleOutlined />,
        children: [
            {
                key: 'playlist',
                label: 'Playlist',
            },
            {
                key: 'category',
                label: 'Category',
            },
            {
                key: 'visuals',
                label: 'Visuals',
            },
            {
                key: 'daily-horoscope',
                label: 'Daily Horoscope',
            },
            {
                key: 'daily-panchangam',
                label: 'Daily Panchangam',
            },
            {
                key: 'videos',
                label: 'Videos',
                icon: <PlaySquareOutlined />,
            },
            {
                key: 'snippet',
                label: 'Manage Snippet',
            },
        ],
    },
    {
        key: 'master',
        label: 'Master',
        icon: <img style={{ width: '1rem' }} alt="Master" src={Master} />,
        children: [
            { key: 'languages', label: 'Languages' },
            { key: 'hashtags', label: 'Hashtags' },
            { key: 'categories', label: 'Categories' },
            { key: 'news-sections', label: 'News Sections' },
            { key: 'subcategories', label: 'Sub Categories' },
            { key: 'locations', label: 'Locations' },
            { key: 'sources', label: 'Sources' },
            { key: 'features', label: 'Features' },
        ],
    },
    {
        key: 'employees',
        label: 'Employees',
        icon: <UsergroupAddOutlined />,
        children: [
            { key: 'createEmployee', label: 'Create New Employee' },
            { key: 'employeeShifts', label: 'Employee Shifts' },
            { key: 'employees', label: 'List All Employee' },
            { key: 'stringers', label: 'List All Stringers' },
            { key: 'manage-tv-stringers', label: 'Manage TV Stringers' },
            { key: 'manage-app-stringers', label: 'Manage App Stringers' },
            { key: 'manage-sr-citizen-stringers', label:  'Manage Sr Citizen Stringer' },
            { key: 'manage-citizen-stringers', label: 'Manage Citizen Stringer' },
            { key: 'billing', label: 'Billing' },
        ],
    },
    {
        key: 'feedback',
        label: 'Feedback',
        icon: <img style={{ width: '1rem' }} alt="Feedback" src={Feedback} />,
    },
    {
        key: 'astrology',
        label: 'Astrology',
        icon: <img style={{ width: '1rem' }} alt="Astrology" src={astrology} />,
        children: [
            { key: 'horoscope', label: 'Horoscope' },
            { key: 'panchangam', label: 'Panchangam' },
        ],
    },
    {
        key: 'rolesPermissions',
        label: 'Roles and Permissions',
        icon: (
            <img
                style={{ width: '1rem' }}
                alt="Roles and Permissions"
                src={RolesAndPermisions}
            />
        ),
        children: [
            {
                key: 'assignRolesAndPermissions',
                label: 'Assign Roles & Permissions',
            },
            { key: 'manageRoles', label: 'Manage Roles' },
            { key: 'managePermissions', label: 'Manage Permissions' },
        ],
    },
    {
        key: 'reports',
        label: 'Reports',
        icon: <LineChartOutlined />,
        children: [
            {
                key: 'categoryTimeSlotReport',
                label: 'Category Time Slot Report',
            },
            { key: 'contentEditsReport', label: 'Content Edits Reports' },
            {
                key: 'contentReportByLanguage',
                label: 'Content Report By Language',
            },
            { key: 'dailyContentReport', label: 'Daily Content Report' },
            { key: 'employeeReport', label: 'Employee Report' },
            { key: 'notificationReport', label: 'Notification Report' },
            { key: 'proofReadersReport', label: 'Proof Readers Report' },
            { key: 'sourceReport', label: 'Source Report' },
            { key: 'statesContentReport', label: 'State Content Report' },
            { key: 'timeSlotReport', label: 'Time Slot Report' },
            { key: 'topicsReport', label: 'Topics Report' },
            { key: 'ugcReport', label: 'UGC Report' },
            { key: 'videoReport', label: 'Video Report' },
            { key: 'districtsReport', label: 'Districts Report' },
            { key: 'reporterPaymentReport', label: 'Reporter Payment Report' },
            { key: 'ugContentReport', label: 'UG Content Report' },
            {
                key: 'searchByKeywordsReport',
                label: 'Search By Keywords Report',
            },
            {
                key: 'ugContentProofReadersReporter',
                label: 'UG Content Proof Readers Report',
            },
        ],
    },
]

export const findParentKeyByChildKey = (
    childKeyToFind,
    navItems,
    parentKey = null
) => {
    for (const item of navItems) {
        if (item?.key === childKeyToFind) {
            return parentKey
        } else if (item?.children && item?.children.length > 0) {
            const foundKey = findParentKeyByChildKey(
                childKeyToFind,
                item.children,
                item.key
            )
            if (foundKey) {
                return foundKey
            }
        }
    }
    return null
}

export const userPermissions = {
    Content: {},
    Reports: {
        'State Content Report': ['Can View', 'Can Edit', 'Can Delete'],
    },
    Master: {
        Hashtags: [
            'Can Add',
            'Can View',
            'Can Delete',
            'Can Approve',
            'Can Edit',
        ],
        Sources: [
            'Can Add',
            'Can Approve',
            'Can Edit',
            'Can Delete',
            'Can View',
        ],
        Locations: [
            'Can Add',
            'Can Approve',
            'Can Delete',
            'Can View',
            'Can Edit',
        ],
        Categories: [
            'Can Add',
            'Can Approve',
            'Can View',
            'Can Edit',
            'Can Delete',
        ],
        Languages: [
            'Can Add',
            'Can View',
            'Can Edit',
            'Can Delete',
            'Can Approve',
        ],
    },
    Feedback: { Feedback: [] },
    'Roles and Permissions': {
        'Assign Roles & Permissions': [
            'Can Add',
            'Can View',
            'Can Edit',
            'Can Delete',
            'Can Approve',
        ],
        'Manage Roles': [
            'Can Add',
            'Can View',
            'Can Edit',
            'Can Delete',
            'Can Approve',
        ],
    },
    Employees: {
        'Create New Employee': ['Can Add', 'Can Edit', 'Can View'],
        'List All Employee': ['Can View'],
    },
}
