import React, { useState } from 'react'
import { Button, DatePicker, Form, Input, Modal, Radio, Select } from 'antd'
import '../stories.scss'
import TextArea from 'antd/es/input/TextArea'
import { Option } from 'antd/es/mentions'
const AddStories = ({ languageData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [language, setLanguage] = useState(1)
    const storyType = [
        'Breaking',
        'Lifetime Stories',
        'Heritage',
        'Event Based',
        'Before and After',
        'Live',
    ]
    const deadline = [
        '1 hr',
        '2 hrs',
        '4 hrs',
        '8 hrs',
        'Today',
        'No deadline',
        'Others',
    ]
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const handleLanguageChange = (e) => {
    
        setLanguage(e)
    }
    const onFinish = (values) => {
        console.log('Received values:', values)
    }

    return (
        <>
            <Button className="button" type="primary" onClick={showModal}>
                Add Story
            </Button>
            <Modal
                width={700}
                title="Add Story"
                open={isModalOpen}
                footer={null}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <hr></hr>
                <Form
                    name="story_form"
                    scrollToFirstError
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                >
                    <Form.Item  value={language} required={true} label="Language" name="language">
                        <Select
                            style={{ width: 140 }}
                            defaultValue={language}
                            onChange={handleLanguageChange}
                        >
                            {languageData?.map((option) => (
                                <Select.Option
                                    key={option?.language_id}
                                    value={option?.language_id}
                                >
                                    {option?.language_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the story title',
                            },
                        ]}
                    >
                        <Input placeholder="Story Title" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the story description',
                            },
                        ]}
                    >
                        <TextArea placeholder="Story Description" rows={4} />
                    </Form.Item>

                    <Form.Item
                        label="Team Type"
                        name="teamType"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the team type',
                            },
                        ]}
                    >
                        <Radio.Group
                        // defaultValue={'mediaTeam'}
                        >
                            <Radio value="mediaTeam">Media Team</Radio>
                            <Radio value="reporterLead">Reporter Lead</Radio>
                            <Radio value="stringer">Stringer</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Story Type"
                        name="storyType"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the story type',
                            },
                        ]}
                    >
                        <Select placeholder="Select Story Type">
                            {storyType.map((e) => (
                                <Select.Option key={e} value={e}>
                                    {e}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Location"
                        name="location"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the location',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Location" />
                    </Form.Item>

                    <Form.Item label="References" name="references">
                        <Input placeholder="Enter References" />
                    </Form.Item>

                    <Form.Item
                        label="Assign to"
                        name="assignTo"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please select an employee to assign to',
                            },
                        ]}
                    >
                        <Select placeholder="Select Employee">
                            <Option value="employee1">Employee 1</Option>
                            <Option value="employee2">Employee 2</Option>
                            {/* Add more options as needed */}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Bonus Amount" name="bonusAmount">
                        <Input type='Number' placeholder="Enter Bonus" />
                    </Form.Item>
                    
                    {/* Is Video Mandatory */}
                    <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.teamType !==
                        currentValues?.teamType
                    }
                >
                    {({ getFieldValue })=>getFieldValue('teamType')==='stringer' ? <Form.Item
                        label="Is Video Mandatory"
                        name="isVideoMandatory"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please select Is Video Mandatory',
                            },
                        ]}
                    >
                        <Radio.Group >
                            <Radio value="yes">Yes</Radio>
                            <Radio value="no">No</Radio>
                           
                        </Radio.Group>
                    </Form.Item> : 
                    <Form.Item
                        label="Video Coverage Type"
                        name="videoCoverageType"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please select the video coverage type',
                            },
                        ]}
                    >
                        <Radio.Group >
                            <Radio value="short">Short</Radio>
                            <Radio value="video">Video</Radio>
                            <Radio value="live">Live</Radio>
                        </Radio.Group>
                    </Form.Item>}
                    </Form.Item>
                    <Form.Item
                        label="Deadline"
                        name="deadline"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the deadline',
                            },
                        ]}
                    >
                        <Select>
                            {deadline.map((e) => (
                                <Select.Option value={e} key={e}>
                                    {e}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default AddStories
