import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayerWithThumbnail = ({ videoUrl, thumbnailUrl }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true); // New state to handle valid/invalid URL

    const handlePlay = () => {
        if (ReactPlayer.canPlay(videoUrl)) {
            setIsPlaying(true); // Switch to playing mode if URL is valid
        } else {
            setIsValidUrl(false); // Mark URL as invalid
        }
    };

    return (
        <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
            {isPlaying && isValidUrl ? (
                <ReactPlayer
                    url={videoUrl}
                    playing={isPlaying}
                    controls
                    width="100%"
                    height="100%"
                    muted
                    style={{
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    onError={() => {
                        setIsValidUrl(false); // Handle errors during playback
                        setIsPlaying(false);
                    }}
                />
            ) : (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${thumbnailUrl})`,
                        backgroundColor: 'gray',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderTopLeftRadius: '5px',
                        borderTopRightRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    {isValidUrl ? (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '40px',
                            }}
                            onClick={handlePlay}
                        >
                            ▶️
                        </div>
                    ) : (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                fontSize: '18px',
                                textAlign: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                padding: '10px',
                                borderRadius: '5px',
                            }}
                        >
                            Invalid Video URL
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default VideoPlayerWithThumbnail;
