import React, { useEffect, useState } from 'react'
import { Form, Input, Select, Button, message } from 'antd'
import {
    CloseSquareFilled,
    PictureFilled,
    VideoCameraFilled,
} from '@ant-design/icons'
import SmartphonePreview from '../../SmartphonePreview/SmartphonePreview'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import MultimediaModal from './MultimediaModal'
import {
    createSnippet,
    getSnippetCategories,
    updateSnippet,
} from '../../../AadhanApi'
import moment from 'moment'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'

const { Option } = Select
const { TextArea } = Input

function AddSnippetsForm() {
    const navigate = useNavigate()
    const location = useLocation()
    const { editingSnippet } = location.state || {}
    const isEditing = Boolean(editingSnippet)

    const [form] = Form.useForm()
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)

    const [selectedLanguage, setSelectedLanguage] = useState(
        languageData?.[1]?.language_id
    )
    const [categories, setCategories] = useState([])
    const [title, setTitle] = useState('')
    const [shortDescription, setShortDescription] = useState('')
    const [remainingCharCount, setRemainingCharCount] = useState(400)
    const [isImageAdding, setIsImageAdding] = useState(false)
    const [isVideoAdding, setIsVideoAdding] = useState(false)
    const [imagePath, setImagePath] = useState('')
    const [videoPath, setVideoPath] = useState({ stream_url: '', yt_url: '' })
    const [isOriginal, setIsOriginal] = useState(true)
    const [isSensitive, setIsSensitive] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [category, setCategory] = useState('')

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getSnippetCategories(
                    selectedLanguage,
                    'all',
                    accessToken
                )
                if (response.data.success) {
                    setCategories(response.data.data)
                }
            } catch (error) {
                console.error('Error fetching categories:', error)
            }
        }

        if (selectedLanguage) {
            fetchCategories()
        }
    }, [selectedLanguage, accessToken])

    const handleTitleChange = (value) => {
        setTitle(value)
        setSearchTerm(value.replaceAll(' ', ','))
    }

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value)
        setCategory('')
        setCategories([])
        form.setFieldValue('category', undefined)
    }

    const changeDescriptionValue = (value) => {
        setShortDescription(value)
        setRemainingCharCount(400 - value.length)
    }

    const handleImageClick = () => {
        setIsImageAdding(true)
    }

    const handleVideoClick = () => {
        setIsVideoAdding(true)
    }

    const handleModalCancel = () => {
        setIsImageAdding(false)
        setIsVideoAdding(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Form validation failed:', errorInfo)
    }

    const handleInputChange = (e) => {
        const value = e.target.value
        setVideoPath({ yt_url: value, stream_url: '' })
    }

    // Initialize form with editing values if available
    useEffect(() => {
        if (editingSnippet) {
            form.setFieldsValue({
                language: editingSnippet.language_id,
                category: editingSnippet.snippet_category_id,
                title: editingSnippet.title,
                shortDescription: editingSnippet.description,
            })
            setTitle(editingSnippet.title)
            setShortDescription(editingSnippet.description)
            setSelectedLanguage(editingSnippet.language_id)
            setCategory(editingSnippet.snippet_category_id)

            // Set multimedia paths if they exist
            if (editingSnippet.image_url) {
                setImagePath(editingSnippet.image_url)
            }
            if (editingSnippet.yt_video_url || editingSnippet.mp4_video_url) {
                setVideoPath({
                    yt_url: editingSnippet.yt_video_url || '',
                    stream_url: editingSnippet.mp4_video_url || '',
                })
            }
        }
    }, [editingSnippet, form])
    const prepareRequestData = (status, keywords) => {
        const employeeId = localStorage.getItem('EMPLOYEE_ID')
        const employeeName = localStorage.getItem('EMPLOYEE_NAME')

        const selectedCategory = categories.find(
            (cat) => cat.snippet_category_id === category
        )

        const requestData = {
            snippet_category_name:
                selectedCategory?.snippet_category_name || '',
            content_status: status,
            content_type: 'shortnews',
            created_type: '',
            description: shortDescription,
            image_url: imagePath,
            image_url_16_9: '',
            image_url_3_4: '',
            keywords: keywords,
            language_id: selectedLanguage,
            left_color: '#000000',
            right_color: '#000000',
            mp4_video_url: videoPath.stream_url || '',
            thumbnailimage_url: 'string',
            title: title,
            uploaded_by: parseInt(employeeId) || 0,
            uploaded_by_name: employeeName || 'string',
            uploaded_date: moment().toISOString(),
            yt_video_url: videoPath.yt_url || '',
            content_id: editingSnippet?.content_id || '',
            snippet_category_id: category,
            code: '',

            ...(editingSnippet && {
                content_id: editingSnippet.content_id,
                code: editingSnippet.code,
                dynamic_url: editingSnippet.dynamic_url || '',
                stream_video_url: editingSnippet.stream_video_url || '',
            }),
        }
        return requestData
    }

    const handleSubmit = async (status) => {
        try {
            if (!imagePath) {
                message.warning('Please add the required fields')
                return
            }

            const keywordsResponse = await generateKeyWord(
                title,
                shortDescription,
                selectedLanguage
            )
            const keywords = keywordsResponse?.keywords || ''

            const requestData = prepareRequestData(status, keywords)

            let response

            if (editingSnippet) {
                response = await updateSnippet(
                    editingSnippet.content_id,
                    requestData,
                    accessToken
                )
            } else {
                response = await createSnippet(requestData, accessToken)
            }

            if (response.data.success) {
                message.success(
                    `Snippet ${
                        editingSnippet ? 'updated' : 'created'
                    } successfully`
                )
                navigate('/home/snippet', {
                    state: {
                        selectedLanguage,
                        selectedCategory: category,
                    },
                    replace: true,
                })
            }
        } catch (error) {
            if (error.errorFields) {
                const missingFields = error.errorFields
                    .map((field) => field.name[0])
                    .join(', ')
                message.error(
                    `Please fill in all required fields: ${missingFields}`
                )
            } else {
                console.error('Error saving snippet:', error)
                message.error(
                    `Failed to ${editingSnippet ? 'update' : 'create'} snippet`
                )
            }
        }
    }

    const onFinish = (values) => {
        handleSubmit('submitted')
    }

    const handleSave = () => {
        handleSubmit('draft')
    }

    return (
        <div className="total-form">
            <div className="headingAndIcon">
                <h2>{isEditing ? 'Edit Snippet' : 'Add Snippet'}</h2>
                <Button
                    onClick={() => navigate(-1)}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{
                                color: 'LightCoral',
                                fontSize: '1.8rem',
                            }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>

            <div className="formAndPreview">
                <div id="form" style={{ width: '100%', position: 'relative' }}>
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 18 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a language',
                                },
                            ]}
                        >
                            <Select
                                className="dropdown-1"
                                onChange={handleLanguageChange}
                                value={selectedLanguage}
                                placeholder="Select Language"
                            >
                                {languageData.map((arr) => (
                                    <Option
                                        key={arr.language_id}
                                        value={arr.language_id}
                                    >
                                        {arr.language_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a category',
                                },
                            ]}
                        >
                            <Select
                                placeholder="--Select Category--"
                                onChange={(value) => setCategory(value)}
                            >
                                {categories.map((category) => (
                                    <Option
                                        key={category.snippet_category_id}
                                        value={category.snippet_category_id}
                                    >
                                        {category.snippet_category_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Headline"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a headline',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <TextArea
                                maxLength={75}
                                value={title}
                                onChange={(e) =>
                                    handleTitleChange(e.target.value)
                                }
                                placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                            />
                            <span className="description-subtext">
                                <span
                                    style={{
                                        color:
                                            title.length > 75
                                                ? 'red'
                                                : '#555555',
                                    }}
                                >
                                    {75 - title.length} characters
                                </span>
                            </span>
                        </Form.Item>

                        <Form.Item
                            label="Story Details"
                            name="shortDescription"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter story details',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <TextArea
                                value={shortDescription}
                                rows={2}
                                autoSize
                                style={{ minHeight: '10rem' }}
                                onChange={(e) =>
                                    changeDescriptionValue(e.target.value)
                                }
                                placeholder="Upto 60 words"
                            />
                            <span className="description-subtext">
                                <span
                                    style={{
                                        color:
                                            remainingCharCount === 0
                                                ? 'red'
                                                : '#555555',
                                    }}
                                >
                                    {remainingCharCount} characters
                                </span>
                            </span>
                        </Form.Item>

                        <Form.Item label="Multimedia" name="multimedia">
                            <div style={{ display: 'flex' }}>
                                <span
                                    style={{ marginRight: '3rem' }}
                                    className="multimedia"
                                    onClick={handleImageClick}
                                >
                                    <PictureFilled style={{ color: 'gray' }} />{' '}
                                    Image
                                </span>
                                <span
                                    className="multimedia"
                                    onClick={handleVideoClick}
                                >
                                    <VideoCameraFilled
                                        style={{ color: 'gray' }}
                                    />{' '}
                                    Video
                                </span>
                            </div>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 5, span: 17 }}>
                            <div className="buttons_shortnews">
                                <Button
                                    className="width-100"
                                    type="primary"
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                                <Button
                                    className="submit-buttons width-120"
                                    type="primary"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    <SmartphonePreview
                        image={imagePath}
                        title={title}
                        videoPath={
                            videoPath?.stream_url
                                ? videoPath?.stream_url
                                : videoPath?.yt_url
                        }
                        description={shortDescription}
                    />
                </div>
            </div>

            <MultimediaModal
                isImageAdding={isImageAdding}
                isVideoAdding={isVideoAdding}
                handleModalCancel={handleModalCancel}
                accessToken={accessToken}
                selectedLanguage={selectedLanguage}
                title={title}
                setImagePath={setImagePath}
                imagePath={imagePath}
                isOriginal={isOriginal}
                setIsOriginal={setIsOriginal}
                isSensitive={isSensitive}
                setIsSensitive={setIsSensitive}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                videoPath={videoPath}
                setVideoPath={setVideoPath}
                handleInputChange={handleInputChange}
            />
        </div>
    )
}

export default AddSnippetsForm
