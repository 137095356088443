import React, { useEffect, useState } from 'react'
import { getReportersPaymentReport} from '../../AadhanApi'
import {Select, Table } from 'antd'
import { monthArr } from '../ContentEditsReport/ContentEditReport.constant'
import './stylesPayment.scss'
import { useSelector } from 'react-redux'

const ReporterPaymentReport = () => {
    const locationsData = useSelector((state)=>state.app.locations)
    const [dataLoading, setDataLoading] = useState(false)
    const preMonth =new Date().getMonth() ===1 ? 11 : new Date().getMonth()-1
    const year =new Date().getMonth()===1 ? new Date().getFullYear()-1 :new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(year)
    const [stateId, setStateId] = useState()
    const [monthId, setMonthId] = useState(monthArr[preMonth].month_code)
    const [reportesData, setReportersData] = useState([])
    
    const [total, setTotal] = useState({
        img: 0,
        video: 0,
        bonus: 0,
        amount: 0,
    })
    
    
    const startYear = 2021

    const years = []

    for (let year = startYear; year <= new Date().getFullYear()+5; year++) {
        years.push(year)
    }
    
    
   

    const columns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'PhoneNumber',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
        },
        {
            title: 'Images',
            dataIndex: 'content_with_images',
            key: 'content_with_images',
        },
        {
            title: 'Videos',
            dataIndex: 'content_with_videos',
            key: 'content_with_videos',
        },
        {
            title: 'Bonus',
            dataIndex: 'bonus_amount',
            key: 'bonus_amount',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            defaultSortOrder: 'descend',
            sorter:(a,b)=>(a.amount-b.amount),
            sortDirections: ['descend', 'ascend'],
        },
        
    ]
    const calculateTotal = async (arr, total) => {
        setTotal({ img: 0, video: 0, bonus: 0, amount: 0 })
        return new Promise((resolve, reject) => {
            try {
                arr.forEach((e) => {
                    total.img += e.content_with_images
                    total.video += e.content_with_videos
                    total.amount += e.amount
                    total.bonus += e.bonus_amount
                })
                resolve(total)
            } catch (error) {
                reject(error)
            }
        })
    }
    
    const token = localStorage.getItem('ACCESS_TOKEN')
    async function getPaymentData() {
        
        if (stateId && monthId && selectedYear) {
            setDataLoading(true)
            const data = await getReportersPaymentReport(
                token,
                selectedYear,
                monthId,
                stateId
            )
            const arr = Object.values(data.data.data.invoices).map(
                (value) => value
            )
            setTotal({ img: 0, video: 0, bonus: 0, amount: 0 })
            if (arr.length !== 0) {
                calculateTotal(arr, total)
                    .then((newTotal) => {
                        setTotal({ img: 0, video: 0, bonus: 0, amount: 0 })
                        setTotal(newTotal)
                        arr.push({
                            full_name: (
                                <div
                                    style={{
                                        color: 'red',
                                        fontSize: '1.4rem',
                                        fontWeight: '500',
                                }}
                                >
                                    Total
                                </div>
                            ),
                            content_with_images: (
                                <div
                                    style={{
                                        color: 'black',
                                        fontSize: '1.4rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    {total.img}
                                </div>
                            ),
                            content_with_videos: (
                                <div
                                    style={{
                                        color: 'black',
                                        fontSize: '1.4rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    {total.video}
                                </div>
                            ),
                            amount: (
                                <div
                                    style={{
                                        color: 'black',
                                        fontSize: '1.4rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    {total.amount}
                                </div>
                            ),
                            bonus_amount: (
                                <div
                                    style={{
                                        color: 'black',
                                        fontSize: '1.4rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    {total.bonus}
                                </div>
                            ),
                        })
                    })
                    .catch((error) => {
                        console.error('Error calculating total:', error)
                    })
            }
            setReportersData(arr)

            setDataLoading(false)
        }
    }
    function handleStateChange(e){
        setTotal({
            img: 0,
            video: 0,
            bonus: 0,
            amount: 0,
        })
        
        setStateId(e)
    }
   
   
    useEffect(() => {
        setReportersData([])
        setTotal({ img: 0, video: 0, bonus: 0, amount: 0 })
        getPaymentData()
    }, [stateId, monthId, selectedYear])
    return (
        <div>
            <h2 style={{ padding: '0 0 0 1rem' }}>Reporters Payment Report</h2>
            <hr></hr>
            <div style={{ display: 'flex', padding: '0 2rem 0 1rem' }}>
                <Select
                    defaultValue={year}
                    onChange={(e) => setSelectedYear(e)}
                    style={{
                        width: '10rem',
                        height: '2rem',
                        marginRight: '2rem',
                    }}
                
                >
                    {years.map((e) => {
                        return (
                            <Select.Option value={e} key={e}>
                                {e}
                            </Select.Option>
                        )
                    })}
                </Select>
                <Select
                    defaultValue={monthArr[preMonth].month_name}
                    onChange={(e) => setMonthId(e)}
                    style={{
                        width: '10rem',
                        height: '2rem',
                        marginRight: '2rem',
                    }}
        
                >
                    {monthArr.map((e) => {
                        return (
                            <Select.Option
                                
                                value={e.month_code}
                                key={e.month_code}
                            >
                                {e.month_name}
                            </Select.Option>
                        )
                    })}
                </Select>
                <Select
                    onChange={(e) => {
                        handleStateChange(e);
                    }}
                    allowClear
                    style={{ width: '10rem', height: '2rem' }}
                    placeholder="Select State"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {locationsData.map((e) => {
                        return (
                            <Select.Option value={e.state_id} key={e.state_id}>
                                {e.name}
                            </Select.Option>
                        )
                    })}
                </Select>
            </div>
            <Table
                style={{ marginTop: '1rem' }}
                rowClassName={(record, index) =>
                    index === reportesData.length-1 ? 'table-row-last' :
                    index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                loading={dataLoading}
                dataSource={reportesData}
                columns={columns}
                pagination={false}
            />
            
        </div>
    )
}

export default ReporterPaymentReport
