import { Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { updateReporterBonus,getReporterInvoices} from '../../AadhanApi'

const AddBonusForm = ({
    selectedRecord,
    getTableData,
    month,
    year,
    selectedRole,
    setShowModal,
    accessToken,
}) => {
    const [form] = Form.useForm()
    const onFinish = async (values) => {
        if (selectedRole) {
        } else {
            await updateReporterBonus(
                selectedRecord?.user_id,
                month,
                year,
                values.amount,
                accessToken
            )
        }
        setShowModal(false)
        getTableData()
        form.resetFields()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    useEffect(() => {
        if (selectedRecord) {
            form.setFieldsValue({
                amount: selectedRecord?.bonus_amount,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRecord])
    return (
        <Form
            id="roleForm"
            form={form}
            name="roleForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 7 }}
                label="Bonus Amount"
                name="amount"
                rules={[{ required: true, message: 'Please enter amount' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    )
}

export default AddBonusForm
