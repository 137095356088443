import { Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { postAssemblyConstituency, updateAssembly  } from '../../AadhanApi'

const AddAssemblyForm = ({
    selectedAssembly,
    getTableData,
    setShowModal,
    selectedStateKey,
    accessToken
}) => {
    const [form] = Form.useForm()
    const onFinish = async (values) => {
        if (selectedAssembly) {
            let obj = {
                "name": values.assemblyConstituency,
                "state": selectedStateKey,
                "id": selectedAssembly?.id
            }
            await updateAssembly("assembly", obj, accessToken)
        } else {
            let obj = {
                state: selectedStateKey,
                name: values.assemblyConstituency
            }
            await postAssemblyConstituency(obj, "create", "assembly", accessToken)
        }
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedAssembly) {
            form.setFieldsValue({
                assemblyConstituency: selectedAssembly?.name,
            })
        } else {
            form.resetFields()
        }
    }, [selectedAssembly])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Form
            id="assemblyForm"
            form={form}
            name="assemblyForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="assemblyConstituency"
                label={
                    <div style={{textAlign: 'center'}}>
                        Assembly<br />Constituency
                    </div>
                }
                rules={[
                    {
                        required: true,
                        message: 'please enter assembly',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    )
}

export default AddAssemblyForm;
