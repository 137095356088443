import { DatePicker, message } from 'antd'
import './DailyContentReport.scss'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getDailyContentReport } from '../../AadhanApi'
import dayjs from 'dayjs'
import CommonTable from '../CommonTable/CommonTable'

const DailyContentReport = () => {
    const [reportsData, setReportsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [day, setDay] = useState(dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'))

    useEffect(() => {
        getDailyContentReportDetails()
    }, [day])

    const getDailyContentReportDetails = async () => {
        const dateFormat = dayjs(day).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const response = await getDailyContentReport(
                dateFormat,
                accessToken
            )
            setReportsData(response.data.data)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const onChange = (date) => {
        const dateFormat = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        setDay(dateFormat)
    }

    const dailyContentTableColumns = [
        {
            title: 'LANGUAGE',
            dataIndex: 'regional_name',
            key: 'regional_name',
        },
        {
            title: '# NEWS',
            dataIndex: 'no_of_short_news',
            key: 'no_of_short_news',
        },
        {
            title: '# CARD',
            dataIndex: 'no_of_cards',
            key: 'no_of_cards',
        },
        {
            title: '# VIDEO',
            dataIndex: 'no_of_short_videos',
            key: 'no_of_short_videos',
        },
        {
            title: '# QUIZ',
            dataIndex: 'no_of_quizzes',
            key: 'no_of_quizzes',
        },
        {
            title: '# POLL',
            dataIndex: 'no_of_polls',
            key: 'no_of_polls',
        },
    ]

    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Daily Content Report</h2>
            </div>
            <div className="manage-content-inputs">
                <DatePicker 
                    style={{ width: 200 }}
                    format="DD/MM/YYYY"
                    onChange={onChange}
                    defaultValue={dayjs()}
                />
            </div>
            <CommonTable 
                columns={dailyContentTableColumns} 
                data={reportsData} 
                dataLoading={dataLoading}
                disablePagination={true}
            />
        </div>
    )
}

export default DailyContentReport
