import { Select, message } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getContentEditReport } from '../../AadhanApi'
import {
    typeOptions,
    monthArr
} from './ContentEditReport.constant'

const ContentEditReport = () => {
    let yearArr = []
    for (let i = 2018; i <= 2099; i++) {
        yearArr.push(i)
    }
    const currentDate = dayjs();
    const [contentReportColumns, setContentReportColumns] = useState([])
    const [reportsData, setReportsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()
    const [month, setMonth] = useState(currentDate.month() + 1)
    const [year, setYear] = useState(currentDate.year())
    const [datesCount, setDatesCount] = useState(30)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [typeOption, setTypeOption] = useState(typeOptions[0]?.value)

    useEffect(() => {
        let columns = [
            {
                title: 'Name',
                dataIndex: 'full_name',
                key: 'full_name',
            },
        ]
        const dateInMonth = dayjs(`${year}-${month}-01`);
        const dates = dateInMonth.daysInMonth()
        const array = Array.from({ length: dates })
        setDatesCount(dates)
        array.forEach((_, index) => {
            columns.push({
                title: <span className={isSunday(index+1) ? 'sunday-header' : ''}>{index+1}</span>,
                dataIndex: `${index}`,
                key: `${index}`,
            })
        })
        columns.push({
            title: 'Total',
            dataIndex: 'total_edits',
            key: 'total_edits',
        })
        setContentReportColumns(columns)
    }, [year, month])

    useEffect(() => {
        getContentEditReportDetails(typeOption, year, month)
    }, [typeOption, year, month])

    const isSunday = (day) => {
        const date = new Date(year, month - 1, day);
        return date.getDay() === 0;
    }

    const getContentEditReportDetails = async (typeOption, year, month) => {
        setDataLoading(true)
        try {
            const response = await getContentEditReport(
                typeOption,
                year,
                month,
                accessToken
            )
            const dataSource = []
            response.data.data.forEach((employee) => {
                const { language_id, full_name, daily_report, total_edits } = employee;
                const flatDailyReport = Object.values(daily_report);
                const entry = {
                    key: language_id,
                    full_name,
                    ...flatDailyReport,
                    total_edits
                }
                dataSource.push(entry);
            });

            const totalCountsByDay = {};
            response.data.data.forEach(employee => {
                for (let day = 1; day <= datesCount; day++) {
                    const dailyCount = employee.daily_report[day];
                    totalCountsByDay[day] = (totalCountsByDay[day] || 0) + dailyCount;
                }
            });
            if(response.data.data.length) {
                dataSource.push({'full_name': 'Total', ...Object.values(totalCountsByDay)})
            }
            setReportsData(dataSource)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Content Edits Reports</h2>
            </div>
            <div className="manage-content-inputs">
                <Select
                    value={typeOption}
                    onChange={(event) => setTypeOption(event)}
                    style={{ width: 200 }}
                >
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={month}
                    onChange={(val) => setMonth(val)}
                    style={{ width: 200 }}
                >
                    {monthArr.map((option) => {
                        return (
                            <Option
                                key={option?.month_code}
                                value={option?.month_code}
                            >
                                {option?.month_name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={year}
                    onChange={(val) => setYear(val)}
                    style={{ width: 200 }}
                >
                    {yearArr.map((option, index) => {
                        return (
                            <Option key={index} value={option}>
                                {option}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <CommonTable
                data={reportsData}
                columns={contentReportColumns}
                dataLoading={dataLoading}
                disablePagination={true}
            />
        </div>
    )
}

export default ContentEditReport;
