import { Form, Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import { createCandidate, updateCandidate } from '../../AadhanApi'

const AddCandidateForm = ({
    selectedCandidate,
    selectedAssemblyKey,
    selectedStateKey,
    getTableData,
    setShowModal,
    parties,
    accessToken
}) => {
    const [selectedParty, setSelectedParty] = useState(null);
    const [form] = Form.useForm()
    const onFinish = async (values) => {
        if (selectedCandidate) {
            let obj = {
                "name": values.candidateForm,
                "party": selectedParty.name,
                "party_logo": selectedParty.logo,
                "constituency_id": selectedAssemblyKey,
                "id": selectedCandidate?.id
            }
            await updateCandidate("assembly", selectedStateKey, obj, accessToken)
        } else {
            let obj = {
                "state": selectedStateKey,
                "name": values.candidateForm,
                "party": selectedParty.name,
                "party_logo": selectedParty.logo
            }
            await createCandidate(selectedAssemblyKey, selectedStateKey, "assembly", obj, accessToken)
        }
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedCandidate) {
            form.setFieldsValue({
                candidateForm: selectedCandidate?.name,
                party: selectedCandidate?.party
            })
        } else {
            form.resetFields()
        }
    }, [selectedCandidate])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const handleSelect = (value, option) => {
        // Find the selected party based on the selected value
        const selectedParty = parties.find((party) => party.name === value);
    
        // Set the selected party to the component state
        setSelectedParty(selectedParty);
      };

    return (
        <Form
            id="candidateForm"
            form={form}
            name="candidateForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="candidateForm"
                label={
                    <div style={{textAlign: 'center'}}>
                        Candidate<br />Name
                    </div>
                }
                rules={[
                    {
                        required: true,
                        message: 'please enter candidate name',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="party"
                label="Party"
                rules={[
                    {
                        required: true,
                        message: 'please select an option',
                    },
                ]}
            >
                <Select
                    placeholder="Select Party"
                    onSelect={handleSelect}
                >
                    {parties?.map((option) => (
                        <Select.Option
                            key={option.name}
                            value={option.name}
                        >
                            {option.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    )
}

export default AddCandidateForm;
