const OpenAI = require('openai')
const openai = new OpenAI({
    apiKey: 'sk-proj-vxH2FBo5vM79zKwK1RQ7T3BlbkFJUu8EaHRK5T7toUypZxsN',
    dangerouslyAllowBrowser: true,
})
export default async function getApiResponse(message) {
    try {
        const response = await openai.chat.completions.create(message)

        if (response.choices && response.choices.length > 0) {
            return response?.choices[0]?.message?.content
        }
    } catch (error) {
        console.error('Error:', error)
        return ""
    }
}
