import React from 'react'

const Stages = () => {
  return (
    <div>
      stages
    </div>
  )
}

export default Stages
