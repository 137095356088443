import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Spin, Checkbox, Button } from 'antd';
import './imageLibrary.scss';
import { getImages } from '../../AadhanApi';
import Search from 'antd/es/input/Search';
import { CloseSquareFilled } from '@ant-design/icons';

function ImageLibrary({
    setImagePath,
    language_id,
    setIsSensitive,
    isSensitive,
    isOriginal,
    setIsOriginal,
    searchTerm,
    setSearchTerm,
    setIsImageAdding
}) {
    const token = useSelector((state) => state.app.accessToken);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if(searchTerm){
            fetchImages(searchTerm);
        }
    }, []);

    const fetchImages = async () => {
        try {
            setLoading(true);
            const response = await getImages(language_id, searchTerm, token);
            const formattedImages = response.data.data.map((image) => ({
                url: image.image_url,
                keywords: image.keywords || '',
            }));
            setImages(formattedImages);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image);
        setImagePath(image.url)
        setIsImageAdding(false)
    };

    const handleSearch = () => {
        if(searchTerm){
            fetchImages();
        }
    };

    const handleCloseSelectedImage = () => {
        setSelectedImage(null);
    };

    return (
        <div className='outer_div'>
        <div style={{width:"99.9%",marginLeft:"auto"}}> <Search
                className="search-bar"
                placeholder="Search by title or keywords..."
                enterButton="Search"
                size="medium"
                onSearch={handleSearch}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
            /></div>
        <div className="image-library-container">
           
            <div
                className="content-wrapper"
                style={{
                    flexDirection: selectedImage ? 'row' : 'column',
                }}
            >
                <div
                    className="image-library-modal"
                    style={{
                        // flex: selectedImage ? '2' : '1',
                        // height:380,
                        // overflowY:"auto"
                    }}
                >
                    {!loading && images.length === 0 && (
                        <div className="loading">No Data</div>
                    )}
                    {loading ? (
                        <div className="loading">
                            <Spin size="large"></Spin>
                        </div>
                    ) : (
                        <Row gutter={16}>
                            <Col>
                                <div className="image-grid">
                                    <Row gutter={[10, 10]}>
                                        {images.map((image, index) => (
                                            <Col key={index} style={{margin:4}}>
                                                <div
                                                    onClick={() =>
                                                        handleImageSelect(
                                                            image
                                                        )
                                                    }
                                                    className={'image-containr'}
                                                >
                                                    <img
                                                        className="grid-image"
                                                        alt={image.keywords}
                                                        src={image.url}
                                                        />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
                
                {/* {selectedImage && (
                    <div
                        className={`selected-image-container ${
                            selectedImage ? 'visible' : ''
                        }`}
                        style={{
                            width: selectedImage ? '30%' : '0%',
                        }}
                    >
                       
                        <div style={{width:200,height:200,position:"relative",borderRadius:6,overflow:"hidden"}}>
                        <Button
                            className="close-btn"
                            onClick={handleCloseSelectedImage}
                            icon={<CloseSquareFilled/>}
                        >
                            
                        </Button>
                        <img alt="Selected" src={selectedImage.url} />
                        </div>
                        <div className="content_image_type">
                            <Checkbox
                                checked={isOriginal}
                                className='check_box_preview'
                                onChange={(e) =>
                                    setIsOriginal(e.target.checked)
                                }
                                >
                                Original Image
                            </Checkbox>
                            <Checkbox
                            className='check_box_preview'
                                checked={isSensitive}
                                onChange={(e) =>
                                    setIsSensitive(e.target.checked)
                                }
                            >
                                Sensitive Content
                            </Checkbox>
                        </div>
                        <Button type='primary' onClick={()=>setImagePath(selectedImage.url)} style={{marginTop:"5px"}}>Select</Button>
                    </div>
                )} */}
            </div>
        </div>
                </div>
    );
}

export default ImageLibrary;
