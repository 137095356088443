export const typeOptions = [
    { label: 'Assembly Election', value: 'assembly' },
]

export const rounds = [
    { label: "Round 1", value: 1},
    { label: "Round 2", value: 2},
    { label: "Round 3", value: 3},
    { label: "Round 4", value: 4},
    { label: "Round 5", value: 5},
    { label: "Round 6", value: 6},
    { label: "Round 7", value: 7},
    { label: "Round 8", value: 8},
    { label: "Round 9", value: 9},
    { label: "Round 10", value: 10},
    { label: "Round 11", value: 11},
    { label: "Round 12", value: 12},
    { label: "Round 13", value: 13},
    { label: "Round 14", value: 14},
    { label: "Round 15", value: 15},
    { label: 'Round 16', value: 16},
    { label: "Round 17", value: 17},
    { label: 'Round 18', value: 18},
    { label: 'Round 19', value: 19},
    { label: "Round 20", value: 20},
    { label: "Round 21", value: 21},
    { label: 'Round 22', value: 22},
    { label: 'Round 23', value: 23},
    { label: 'Round 24', value: 24},
    { label: "Round 25", value: 25},
    { label: "Round 26", value: 26},
    { label: "Round 27", value: 27},
    { label: 'Round 28', value: 28},
    { label: 'Round 29', value: 29},
    { label: 'Round 30', value: 30},
    { label: 'Round 31', value: 31},
    { label: 'Round 32', value: 32},
    { label: 'Round 33', value: 33},
    { label: "Round 34", value: 34},
    { label: 'Round 35', value: 35},
    { label: "Round 36", value: 36},
    { label: 'Round 37', value: 37},
    { label: 'Round 38', value: 38},
    { label: 'Round 39', value: 39},
    { label: 'Round 40', value: 40},
    { label: 'Round 41', value: 41},
    { label: 'Round 42', value: 42},
    { label: 'Round 43', value: 43},
    { label: 'Round 44', value: 44},
    { label: 'Round 45', value: 45},
    { label: "Round 46", value: 46},
    { label: "Round 47", value: 47},
    { label: 'Round 48', value: 48},
    { label: "Round 49", value: 49},
    { label: 'Round 50', value: 50},
]