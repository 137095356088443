import { DragOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
const DraggableList = ({ orderedCategory, setOrderedCategory ,removeCategory}) => {
    const onDragEnd = (result) => {
        if (!result.destination) return
        const reorderedItems = Array.from(orderedCategory)
        const [removed] = reorderedItems.splice(result.source.index, 1)
        reorderedItems.splice(result.destination.index, 0, removed)
        setOrderedCategory(reorderedItems)
    }
    return (
        <div style={{margin:""}}>
        {orderedCategory.length!==0 && 
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
            {(provided) => (
                <div
                    style={{
                        display: 'inline-block',
                        minWidth:220,
                        backgroundColor:"transparent",
                        padding: '.3rem',
                        marginTop:"-5%",
                        // border:"solid 1px lightgray",
                        background: 'linear-gradient(to right, #e6e6fa, #e0ffff)',
                        borderRadius:"4px"
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {orderedCategory.map((item, index) => (
                        <Draggable
                            key={item?.value}
                            draggableId={item?.value}
                            index={index}
                        >
                            {(provided) => (
                                <div
                                    
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    
                                    style={{
                                        userSelect: 'none',
                                        padding: '4px 1rem 4px .3rem',
                                        position:"relative",
                                        margin: '0 0 4px 0',
                                        backgroundColor:'#8C8C8C4D',
                                        color: '#333',
                                        boxShadow: '1px 1px 1px gray',
                                        ...provided.draggableProps.style,
                                        
                                    }}
                                >
                                    <DragOutlined
                                        style={{ padding: '0 .5rem 0 0'}}
                                    />
                                    {item.label}
                                    <span onClick={()=>removeCategory(item?.value)} style={{position:"absolute",right:5,cursor:"pointer",color:"red"}}><MinusCircleOutlined /></span>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
        }
        </div>
    )
}

export default DraggableList
