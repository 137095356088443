import React, { useState, useEffect } from 'react'
import { Select, Button, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useSelector } from 'react-redux'
import './Panchangam.scss'
import PanchangamCanvas from './PanchangamCanvas'
import {
    languageContent,
    allMonths,
    TEXT_START_X,
    TEXT_END_X,
    LINE_HEIGHT,
    TIME_BASED_INDICES,
    TEXT_BASED_INDICES,
    FIXED_GAP_BETWEEN_ENTRIES,
} from './Constants'
import {
    getPanchangamCards,
    createPanchangamCard,
    updatePanchangamCard,
    deletePanchangamCard,
    uploadImageToTest,
    updateCard,
    createCard,
} from '../../AadhanApi'
import { dayNamesData } from '../Daily Horoscope/LanguageSpecificDays'
import { monthsData } from '../Daily Horoscope/LanguageSpecificMonths.js'
import moment from 'moment'
import dayjs from 'dayjs'
import PanchangamTemplate from '../../assets/Panchangam/Astrology BG 01.jpg'

function Panchangam() {
    const { Option } = Select
    const languageData = useSelector((state) => state.app.languageData)
    const [inputErrors, setInputErrors] = useState({})
    const [selectedLanguage, setSelectedLanguage] = useState(
        languageData?.[0]?.language_id
    )
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
    const [selectedDay, setSelectedDay] = useState(new Date().getDate())
    const [daysInMonth, setDaysInMonth] = useState([])
    const [inputValues, setInputValues] = useState({})

    const [currentPanchangamCode, setCurrentPanchangamCode] = useState(null)
    const [uploadedDate, setUploadedDate] = useState(null)
    const [cardEntryId, setCardEntryId] = useState(null)
    const accessToken = useSelector((state) => state.app.accessToken)

    const [isEditing, setIsEditing] = useState(false)
    const [hasData, setHasData] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    const getStorageKey = () =>
        `panchangam-${selectedLanguage}-${selectedMonth}-${selectedDay}`

    useEffect(() => {
        fetchPanchangamCard()
    }, [selectedLanguage, selectedMonth, selectedDay])

    useEffect(() => {
        // Load saved values
        const savedLanguage = localStorage.getItem('selectedLanguage')
        const savedMonth = localStorage.getItem('selectedMonth')
        const savedDay = localStorage.getItem('selectedDay')

        if (savedLanguage) setSelectedLanguage(parseInt(savedLanguage))
        if (savedMonth) setSelectedMonth(parseInt(savedMonth))
        if (savedDay) setSelectedDay(parseInt(savedDay))

        // Initialize days in month
        const currentMonth = savedMonth
            ? parseInt(savedMonth)
            : new Date().getMonth()
        initializeDaysInMonth(currentMonth)
    }, [])

    const initializeDaysInMonth = (month) => {
        const daysInSelectedMonth = new Date(2024, month + 1, 0).getDate()
        const daysArray = Array.from(
            { length: daysInSelectedMonth },
            (_, i) => i + 1
        )
        setDaysInMonth(daysArray)
    }

    const fetchPanchangamCard = async () => {
        try {
            const now = moment()
            const date = new Date(
                2024,
                selectedMonth,
                selectedDay,
                now.hour(),
                now.minute(),
                now.second()
            )
                .toISOString()
                .split('T')[0]
            const response = await getPanchangamCards(
                accessToken,
                selectedLanguage,
                date
            )

            if (response.data && response.data.data.length > 0) {
                const card = response.data.data[0]
                setCurrentPanchangamCode(card.code)
                setUploadedDate(card.uploaded_date)
                // // setCardEntryId(card.entry_id)
                // if (storedEntryId) {
                //     setCardEntryId(storedEntryId)
                // }
                const storedEntryId = checkStoredEntryId()
                if (storedEntryId) {
                    const storedData = JSON.parse(
                        localStorage.getItem(getStorageKey())
                    )
                    if (storedData?.uploadedDate) {
                        setUploadedDate(storedData.uploadedDate)
                    }
                }

                const newInputValues = {}
                Object.entries(card.panchangam_text).forEach(
                    ([key, value], index) => {
                        newInputValues[index] = value
                    }
                )
                setInputValues(newInputValues)
                setHasData(true)
                setIsEditing(false)
                setIsDisabled(true)
            } else {
                setCurrentPanchangamCode(null)
                setUploadedDate(null)
                setCardEntryId(null)
                removeEntryIdFromStorage()
                setInputValues({})
                setHasData(false)
                setIsEditing(true)
                setIsDisabled(false)
            }
        } catch (error) {
            console.error('Error fetching Panchangam card:', error)
            message.error('Failed to fetch Panchangam card')
        }
    }

    const handleMonthChange = (value) => {
        setSelectedMonth(value)
        localStorage.setItem('selectedMonth', value)
        initializeDaysInMonth(value)
    }

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value)
        localStorage.setItem('selectedLanguage', value)
    }

    const handleDayChange = (value) => {
        setSelectedDay(value)
        localStorage.setItem('selectedDay', value)
    }

    const handleInputChange = (index, value) => {
        const newInputValues = {
            ...inputValues,
            [index]: value,
        }
        setInputValues(newInputValues)
        if (value.trim() !== '') {
            setInputErrors((prev) => {
                const newErrors = { ...prev }
                delete newErrors[index]
                return newErrors
            })
        }
    }

    const validateInputs = () => {
        const content = languageContent[selectedLanguage]
        const errors = {}
        let isValid = true

        content.labels.forEach((_, index) => {
            if (!inputValues[index] || inputValues[index].trim() === '') {
                errors[index] = '* This field is required'
                isValid = false
            }
        })

        setInputErrors(errors)
        return isValid
    }

    const checkStoredEntryId = () => {
        const key = getStorageKey()
        const storedData = localStorage.getItem(key)
        if (storedData) {
            const parsedData = JSON.parse(storedData)
            setCardEntryId(parsedData.entryId)
            setUploadedDate(parsedData.uploadedDate)
            return parsedData.entryId
        }
        return null
    }

    const saveEntryIdToStorage = (entryId, uploadedDate) => {
        const key = getStorageKey()
        localStorage.setItem(key, JSON.stringify({ entryId, uploadedDate }))
    }

    const removeEntryIdFromStorage = () => {
        const key = getStorageKey()
        localStorage.removeItem(key)
    }

    const saveImage = async () => {
        if (!validateInputs()) {
            return
        }

        const canvas = document.querySelector('canvas')
        const exportCanvas = document.createElement('canvas')
        const exportCtx = exportCanvas.getContext('2d')
        const scaleFactor = 2

        exportCanvas.width = canvas.width * scaleFactor
        exportCanvas.height = canvas.height * scaleFactor

        const templateImg = new Image()
        templateImg.src = PanchangamTemplate

        templateImg.onload = async () => {
            exportCtx.drawImage(
                templateImg,
                0,
                0,
                exportCanvas.width,
                exportCanvas.height
            )

            const content = languageContent[selectedLanguage]

            const convertRemToPixels = (rem) => {
                const fontSize = parseFloat(rem)

                return Math.round(fontSize * 16 * scaleFactor)
            }

            const scaledFontProperties = {
                heading: {
                    fontSize: content.fontProperties.heading.fontSize.includes(
                        'rem'
                    )
                        ? convertRemToPixels(
                              content.fontProperties.heading.fontSize
                          )
                        : parseFloat(content.fontProperties.heading.fontSize) *
                          scaleFactor,
                    fontFamily: content.fontProperties.heading.fontFamily,
                },
                labels: {
                    fontSize: content.fontProperties.labels.fontSize.includes(
                        'rem'
                    )
                        ? convertRemToPixels(
                              content.fontProperties.labels.fontSize
                          )
                        : parseFloat(content.fontProperties.labels.fontSize) *
                          scaleFactor,
                    fontFamily: content.fontProperties.labels.fontFamily,
                },

                timeBasedValues: {
                    fontSize:
                        content.fontProperties.values.timeBasedFont.fontSize.includes(
                            'rem'
                        )
                            ? convertRemToPixels(
                                  content.fontProperties.values.timeBasedFont
                                      .fontSize
                              )
                            : parseFloat(
                                  content.fontProperties.values.timeBasedFont
                                      .fontSize
                              ) * scaleFactor,
                    fontFamily:
                        content.fontProperties.values.timeBasedFont.fontFamily,
                },
                textBasedValues: {
                    fontSize:
                        content.fontProperties.values.textBasedFont.fontSize.includes(
                            'rem'
                        )
                            ? convertRemToPixels(
                                  content.fontProperties.values.textBasedFont
                                      .fontSize
                              )
                            : parseFloat(
                                  content.fontProperties.values.textBasedFont
                                      .fontSize
                              ) * scaleFactor,
                    fontFamily:
                        content.fontProperties.values.textBasedFont.fontFamily,
                },
            }

            // Draw heading
            exportCtx.fillStyle = '#F7AB4B'
            exportCtx.font = `bold ${scaledFontProperties.heading.fontSize}px ${scaledFontProperties.heading.fontFamily}`

            let headingX, headingY
            if (selectedLanguage === 1) {
                headingX = 22 * scaleFactor
                headingY = 90 * scaleFactor
            } else if (selectedLanguage === 2) {
                headingX = 56 * scaleFactor
                headingY = 95 * scaleFactor
            } else if (selectedLanguage === 3) {
                headingX = 53 * scaleFactor
                headingY = 95 * scaleFactor
            } else {
                headingX = 26 * scaleFactor
                headingY = 90 * scaleFactor
            }
            exportCtx.fillText(content.heading, headingX, headingY)

            // Draw date

            // language string from number
            const getLanguageString = (langNumber) => {
                switch (langNumber) {
                    case 1:
                        return 'english'
                    case 2:
                        return 'telugu'
                    case 3:
                        return 'hindi'
                    case 4:
                        return 'tamil'
                    default:
                        return 'english'
                }
            }

            const formatDate = (day, month, languageNumber) => {
                const language = getLanguageString(languageNumber)
                const currentYear = new Date().getFullYear()
                const monthName = monthsData[language][month]
                const dayName =
                    dayNamesData[language][
                        new Date(currentYear, month, day).getDay()
                    ]

                return `${monthName} ${day}, ${dayName}`
            }
            const formattedDate = formatDate(
                selectedDay,
                selectedMonth,
                selectedLanguage
            )

            // Calculate text width to center it
            exportCtx.font = `${15 * scaleFactor}px Sree Krushnadevaraya`
            const textWidth = exportCtx.measureText(formattedDate).width
            const canvasWidth = exportCtx.canvas.width
            const x = (canvasWidth - textWidth) / 2

            exportCtx.fillStyle = '#FFFFFF'
            exportCtx.fillText(formattedDate, x, 116 * scaleFactor)

            // Draw labels and values
            let currentY = 160 * scaleFactor
            const lineHeight =
                (LINE_HEIGHT[selectedLanguage] || LINE_HEIGHT.default) *
                scaleFactor

            // const scaledLineHeight = LINE_HEIGHT * scaleFactor

            let scaledTextStartX
            // = TEXT_START_X * scaleFactor
            if (selectedLanguage === 3) {
                scaledTextStartX = (TEXT_START_X - 10) * scaleFactor
            } else {
                scaledTextStartX = TEXT_START_X * scaleFactor
            }
            const scaledTextEndX = TEXT_END_X * scaleFactor

            Object.entries(inputValues).forEach(([index, value]) => {
                const numericIndex = parseInt(index)
                // Determine which font to use
                let useTextBasedFont = false
                if (selectedLanguage === 2) {
                    useTextBasedFont =
                        TEXT_BASED_INDICES[2].includes(numericIndex)
                } else {
                    useTextBasedFont = numericIndex > TIME_BASED_INDICES.default
                }
                // Draw label
                exportCtx.fillStyle = '#F7AB4B'
                exportCtx.font = `bold ${scaledFontProperties.labels.fontSize}px ${scaledFontProperties.labels.fontFamily}`
                if (selectedLanguage === 3) {
                    exportCtx.fillText(
                        content.labels[index],
                        17 * scaleFactor,
                        currentY
                    )
                } else {
                    exportCtx.fillText(
                        content.labels[index],
                        10 * scaleFactor,
                        currentY
                    )
                }

                // Choose font based on index
                const fontProperties = useTextBasedFont
                    ? scaledFontProperties.timeBasedValues
                    : scaledFontProperties.textBasedValues

                // Draw value
                exportCtx.fillStyle = '#FFFFFF'
                exportCtx.font = `${fontProperties.fontSize}px ${fontProperties.fontFamily}`

                const manualLines = value.split('\\')
                let lastLineY = currentY

                manualLines.forEach((manualLine, lineIndex) => {
                    const words = manualLine.trim().split(' ')
                    let line = ''
                    let testLine = ''
                    // let lineCount = 0

                    words.forEach((word) => {
                        testLine = line + word + ' '
                        const metrics = exportCtx.measureText(testLine)

                        if (
                            metrics.width >
                                scaledTextEndX - scaledTextStartX - 10 &&
                            line !== ''
                        ) {
                            exportCtx.fillText(line, scaledTextStartX, currentY)
                            currentY += lineHeight
                            line = word + ' '
                        } else {
                            line = testLine
                        }
                    })

                    if (line !== '') {
                        exportCtx.fillText(line, scaledTextStartX, currentY)
                        currentY += lineHeight
                    }

                    if (lineIndex < manualLines.length - 1) {
                        currentY += lineHeight * 0.1
                    }
                })

                lastLineY = currentY - lineHeight

                currentY = lastLineY + FIXED_GAP_BETWEEN_ENTRIES * scaleFactor
            })

            const dataUrl = exportCanvas.toDataURL('image/jpeg', 1.0)
            const blob = await (await fetch(dataUrl)).blob()
            const imageFile = new File([blob], 'panchangam.jpg', {
                type: 'image/jpeg',
            })

            try {
                // Upload image
                const imageUploadResponse = await uploadImageToTest(
                    imageFile,
                    accessToken
                )
                const imageUrl = imageUploadResponse.data

                // Prepare panchangam_text object
                const panchangamText = {}
                languageContent[selectedLanguage].labels.forEach(
                    (label, index) => {
                        panchangamText[label] = inputValues[index] || ''
                    }
                )

                const now = moment()
                const date = new Date(
                    2024,
                    selectedMonth,
                    selectedDay,
                    now.hour(),
                    now.minute(),
                    now.second()
                ).toISOString()

                const selectedDate = dayjs()
                    .year(new Date().getFullYear())
                    .month(selectedMonth)
                    .date(selectedDay)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)

                const currentDate = dayjs()

                const publishedDate = selectedDate.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                const currentDateFormatted = currentDate.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )

                const panchangamPayload = {
                    language_id: selectedLanguage,
                    image_url: imageUrl,
                    panchangam_text: panchangamText,
                    date: date,
                }

                let panchangamResponse
                if (currentPanchangamCode) {
                    panchangamPayload.code = currentPanchangamCode
                    panchangamPayload.uploaded_date = uploadedDate
                    panchangamResponse = await updatePanchangamCard(
                        currentPanchangamCode,
                        panchangamPayload,
                        accessToken
                    )
                } else {
                    panchangamResponse = await createPanchangamCard(
                        panchangamPayload,
                        accessToken
                    )
                    setCurrentPanchangamCode(panchangamResponse.data.code)
                }
                //  card payload
                const cardPayload = {
                    title: 'Daily Panchangam Card',
                    source_id: 0,
                    source_url: '',
                    category_id: 144,
                    content_type: 'card',
                    expiry_hours: 24,
                    published_date: publishedDate,
                    popular_card: false,
                    dynamic_url: '',
                    is_active: true,
                    content_status: 'approved',
                    language_id: selectedLanguage,
                    image_urls: [
                        {
                            uid: '',
                            lastModified: {},
                            lastModifiedDate: currentDateFormatted,
                            name: '',
                            size: null,
                            type: 'image/jpeg',
                            percent: null,
                            originFileObj: {},
                            status: '',
                            response: imageUrl,
                            thumbUrl: '',
                        },
                    ],
                    thumbnailimage_url: '',
                    code: '',
                    state: '',
                    district: '',
                    city: '',
                    keywords: '',
                    uploaded_by_name: 'Aadhan Bot',
                    proof_read_by_name: 'Aadhan Bot',
                    category_name: 'భక్తి',
                    proof_read_by: '437',
                    proof_read_date: publishedDate,
                    is_proof_read: true,
                    uploaded_by: '437',
                    updated_by: 0,
                    uploaded_date: currentDateFormatted,
                }

                let cardResponse
                if (cardEntryId) {
                    const storedData = JSON.parse(
                        localStorage.getItem(getStorageKey())
                    )
                    const originalUploadedDate =
                        storedData?.uploadedDate || currentDateFormatted

                    cardPayload.uploaded_date = originalUploadedDate
                    cardResponse = await updateCard(
                        cardPayload,
                        cardEntryId,
                        accessToken
                    )
                    message.success('Panchangam Card updated successfully')
                } else {
                    cardResponse = await createCard(cardPayload, accessToken)
                    if (cardResponse.data.status === 'success') {
                        const newEntryId = cardResponse.data.entry_id
                        setCardEntryId(newEntryId)
                        saveEntryIdToStorage(newEntryId, currentDateFormatted)
                        message.success('Panchangam Card created successfully')
                    } else {
                        throw new Error('Card creation failed')
                    }
                }

                setHasData(true)
                setIsEditing(false)
                setIsDisabled(true)
                fetchPanchangamCard()
            } catch (error) {
                console.error('Error saving panchangam and card:', error)
                message.error({
                    content:
                        'Unable to update the panchangam card. This may be because the original card has been deleted from Cards. Please delete the existing data here and create a new card to proceed.',
                    duration: 6,
                })
            }
        }
    }

    const handleDelete = async () => {
        if (!currentPanchangamCode) {
            message.error('No Panchangam card to delete')
            return
        }

        try {
            await deletePanchangamCard(currentPanchangamCode, accessToken)
            message.success('Panchangam card deleted successfully')
            setCurrentPanchangamCode(null)
            removeEntryIdFromStorage()
            setInputValues({})
            setHasData(false)
            setIsEditing(false)
            setIsDisabled(false)
            fetchPanchangamCard()
        } catch (error) {
            console.error('Error deleting Panchangam card:', error)
            message.error('Failed to delete Panchangam card')
        }
    }

    const handleEdit = () => {
        setIsEditing(true)
        setIsDisabled(false)
    }

    return (
        <div className="panchangam-container">
            <div className="heading-buttons">
                <h1>Daily Panchangam</h1>
                <div className="btns">
                    <Button
                        type="primary"
                        onClick={saveImage}
                        disabled={!isEditing && hasData}
                    >
                        {currentPanchangamCode ? 'Update Card' : 'Save as Card'}
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleEdit}
                        disabled={!hasData || isEditing}
                    >
                        Edit Card
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleDelete}
                        disabled={!hasData}
                    >
                        Delete Card
                    </Button>
                </div>
            </div>
            <hr />
            <div className="both-sections">
                <div className="left-section">
                    <div className="dropdowns-panchangam">
                        <Select
                            className="dropdown-1"
                            onChange={handleLanguageChange}
                            value={selectedLanguage}
                            placeholder="Select Language"
                        >
                            {languageData.map((arr) => (
                                <Option
                                    key={arr.language_id}
                                    value={arr.language_id}
                                >
                                    {arr.language_name}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            className="dropdown-2"
                            onChange={handleMonthChange}
                            value={selectedMonth}
                            placeholder="Select Month"
                        >
                            {allMonths.map((month, index) => (
                                <Option key={month} value={index}>
                                    {month}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            className="dropdown-3"
                            onChange={handleDayChange}
                            value={selectedDay}
                            placeholder="Select Day"
                        >
                            {daysInMonth.map((day) => (
                                <Option key={day} value={day}>
                                    {day}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="input-fields-panch">
                        {languageContent[selectedLanguage]?.labels.map(
                            (label, index) => (
                                <div key={index} className="input-group">
                                    <label
                                        style={{
                                            fontFamily: 'Sree Krushnadevaraya',
                                            marginBottom: '0.125rem',
                                            fontWeight: '630px',
                                            fontSize:
                                                selectedLanguage === 4
                                                    ? '1rem'
                                                    : '1.25rem',
                                        }}
                                    >
                                        {label}:
                                    </label>
                                    <TextArea
                                        value={inputValues[index] || ''}
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        onChange={(e) =>
                                            handleInputChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                        style={
                                            languageContent[selectedLanguage]
                                                .fontProperties.values
                                        }
                                        placeholder="Use '\' for manual line breaks"
                                        disabled={isDisabled}
                                    />
                                    {inputErrors[index] && (
                                        <div
                                            className="error-message"
                                            style={{
                                                color: 'red',
                                                fontSize: '0.75rem',
                                            }}
                                        >
                                            {inputErrors[index]}
                                        </div>
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="right-section">
                    <div className="canvas-preview">
                        <PanchangamCanvas
                            selectedLanguage={selectedLanguage}
                            selectedMonth={selectedMonth}
                            selectedDay={selectedDay}
                            inputValues={inputValues}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Panchangam
