import { Form, Input, Select, message } from 'antd'
import { SubCategories, updateSubCategories } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { subcategoryTypeOptions } from './AddSubCategory.constant'
import { useState, useEffect } from 'react'

const AddSubCategory = ({
    selectedSubCategory,
    getSubCategoryTable,
    accessToken,
    setShowModal,
}) => {
    const [form] = Form.useForm()
    const languageData = useSelector((state) => state.app.languageData)
    const statesData = useSelector((state) => state.app.locations)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [stateId, setStateId] = useState(null)
    const categories = useSelector((state) => state.app.categories)
    const [messageApi, contextHolder] = message.useMessage()

    // Use useEffect to handle updates when selectedSubCategory changes
    useEffect(() => {
        if (selectedSubCategory) {
            const stateName = statesData.find(
                (states) => states.state_id === selectedSubCategory?.state_id
            )
            form.setFieldsValue({
                subcategoryName: selectedSubCategory?.sub_category_name,
                category: selectedSubCategory?.category_id,
                language: selectedSubCategory?.language_id,
                categoryType: selectedSubCategory?.content_types,
                shortCode: selectedSubCategory?.short_code,
                stateName: stateName?.state_id, // Use state_id to update the form
            })
            setStateId(selectedSubCategory?.state_id) // Update the stateId
            setLanguageOption(selectedSubCategory?.language_id) // Update the language option
        }
    }, [selectedSubCategory, statesData, form])

    const onFinish = async (values) => {
        const obj = {
            sub_category_name: values.subcategoryName,
            category_id: values.category,
            content_types: values.categoryType,
            language_id: values.language,
            short_code: values.shortCode,
            state_id: stateId,
        }
        try {
            if (selectedSubCategory) {
                await updateSubCategories(
                    accessToken,
                    obj,
                    selectedSubCategory?.sub_category_id
                )
            } else {
                await SubCategories(accessToken, obj)
            }
            messageApi.open({
                type: 'success',
                content: 'Subcategory saved successfully!',
                duration: 3,
            })
            getSubCategoryTable()
            form.resetFields()
            setShowModal(false)
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const handleLanguageChange = (id) => {
        setLanguageOption(id)
    }
    const handleCategory = (categoryId) => {
        const selected = categories.find(
            (category) => category.category_id === categoryId
        )
    }
    const onStateChange = (value) => {
        setStateId(value)
    }

    return (
        <div>
            {contextHolder}
            <Form
                id="categoryForm"
                form={form}
                name="categoryForm"
                scrollToFirstError
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Language"
                    name="language"
                >
                    <Select onChange={handleLanguageChange}>
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select
                        placeholder="--Select Category--"
                        onChange={handleCategory}
                    >
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                    languageData?.find(
                                        (l) => l.language_id === languageOption
                                    )?.language_name
                            )
                            ?.map((option) => (
                                <Select.Option
                                    key={option.category_id}
                                    value={option.category_id}
                                >
                                    {option.category_name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label=" Sub Category Name"
                    name="subcategoryName"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter category name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label=" Short Code"
                    name="shortCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="stateName" label="State" labelCol={{ span: 24 }}>
                    {statesData && (
                        <Select
                            placeholder="Select State"
                            onChange={(value) => onStateChange(value)}
                        >
                            {statesData?.map((option) => (
                                <Select.Option
                                    key={option?.state_id}
                                    value={option?.state_id}
                                >
                                    {option?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Content Type"
                    name="categoryType"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Content type',
                        },
                    ]}
                >
                    <Select mode="multiple">
                        {subcategoryTypeOptions.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddSubCategory
