export const TEXT_START_X = 120

export const TEXT_END_X = 290
// export const LINE_HEIGHT = 23

export const LINE_HEIGHT = {
    4: 18.5,
    default: 22,
}

export const TEXT_BASED_INDICES = {
    2: [0, 1, 8],
}

export const TIME_BASED_INDICES = {
    default: 3,
}

export const FIXED_GAP_BETWEEN_ENTRIES = 26.5

export const languageContent = {
    1: {
        heading: "Today's Panchangam",
        labels: [
            'Good Time',
            'Rahu Kalam',
            'Yama Gandam',
            'Kuligai',
            'Sulam',
            'Nakshatram',
            'Thithi',
            'Yogam',
            'Chandrashtamam',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Noto Serif',
                fontSize: '1.4375rem',
            },
            labels: {
                fontFamily: 'Sree Krushnadevaraya',
                fontSize: '1rem',
            },
            values: {
                timeBasedFont: { fontFamily: 'Oswald', fontSize: '1rem' },
                textBasedFont: { fontFamily: 'Mallanna', fontSize: '1rem' },
            },
        },
    },
    2: {
        heading: 'నేటి పంచాంగం',
        labels: [
            'తిథి',
            'నక్షత్రం',
            'వర్జ్యం',
            'బ్రహ్మ ముహూర్తం',
            'రాహు కాలం',
            'యమగండం',
            'అమృత కాలం',
            'దుర్ముహుర్తం',
            'ఈరోజు ప్రత్యేకం',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Peddana',
                fontSize: '2.1875rem',
            },
            labels: {
                fontFamily: 'Mandali',
                fontSize: '1.0625rem',
            },
            values: {
                timeBasedFont: {
                    fontFamily: 'Noto Sans Telugu',
                    fontSize: '0.95rem',
                },
                textBasedFont: {
                    fontFamily: 'Noto Sans Telugu',
                    fontSize: '0.95rem',
                },
            },
        },
    },
    4: {
        heading: 'தினசரி பஞ்சாங்கம்',
        labels: [
            'நல்ல நேரம்',
            'இராகு காலம்',
            'எமகண்டம்',
            'குளிகை',
            'சூலம்',
            'நட்சத்திரம்',
            'திதி',
            'யோகம்',
            'சந்திராஷ்டமம்',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Tamil Sangam MN',
                fontSize: '1.3125rem',
            },
            labels: {
                fontFamily: 'Roboto Condensed',
                fontSize: '0.75rem',
            },
            values: {
                timeBasedFont: { fontFamily: 'Oswald', fontSize: '0.8rem' },
                textBasedFont: {
                    fontFamily: 'Oswald',
                    fontSize: '0.8rem',
                },
            },
        },
    },
    3: {
        heading: 'आज का पंचांग ',
        labels: [
            'शुभ समय',
            'राहु काल',
            'शूल',
            'योग',
            'यमगंड',
            'कुलिक',
            'नक्षत्र',
            'तिथि',
            'चंद्राष्टम',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Devanagari Sangam MN',
                fontSize: '1.875rem',
            },
            labels: {
                fontFamily: 'Devanagari Sangam MN',
                fontSize: '0.9rem',
            },

            values: {
                timeBasedFont: { fontFamily: 'Noto Sans', fontSize: '0.9rem' },
                textBasedFont: { fontFamily: 'Noto Sans', fontSize: '0.9rem' },
            },
        },
    },
}

export const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
