import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import './CommonTable.scss'

const CommonTableWithBorder = ({className,columns, data, dataLoading,rowClassName, disablePagination, onRow }) => {
    const [inputColumns, setInputColums] = useState([...columns])
    const [paginationOptions,setpaginationOptions] = useState({
       
            pageSizeOptions: ['2','7', '20', '25', '30',],
            showSizeChanger: true,           
            current:1
        
    })
    
    const handleOnchange = (pagination, filters, sorter) => {
       
        
        setpaginationOptions(prevOptions => ({
            ...prevOptions,
            current: pagination.current,
            pageSize: pagination.pageSize
        }));
        
       
    };
    
    useEffect(() => {
        setInputColums([...columns])
    }, [columns])

    useEffect(()=>{
        setpaginationOptions({...paginationOptions,current:1})
    },[data])

    return (
        <div className="table-container">
            <Table
                className={`custom-table ${className}`}
                pagination={disablePagination ? false : paginationOptions}
                scroll={{ x: '100%' }}
                columns={inputColumns}
                dataSource={data}
                loading={dataLoading}
                onChange={handleOnchange}
                rowClassName={rowClassName}
                onRow={onRow}
                bordered
                
            />
        </div>
    )
}
export default CommonTableWithBorder


