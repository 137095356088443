import {Select,} from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import '../stories.scss'
import CommonTableWithBorder from '../../CommonTable/CommonTableWithBorder'
import AddStories from './AddStories'
import { DatePicker} from 'antd';

const { RangePicker } = DatePicker;

const ListOfStories = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const categories = useSelector((state) => state.app.categories)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [language, setLanguage] = useState(1)
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Date Created',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
        },
        {
            title: 'Current Stage',
            dataIndex: 'currentStage',
            key: 'currentStage',
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
        },
        {
            title: 'Published Links',
            dataIndex: 'publishedLinks',
            key: 'publishedLinks',
            render: (links) => (
                <span>
                    {links.map((link, index) => (
                        <a
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Link {index + 1}
                        </a>
                    ))}
                </span>
            ),
        },
    ]

    const data = [
        {
            key: '1',
            title: 'Story 1',
            location: 'New York',
            dateCreated: '2023-07-21',
            currentStage: 'Editing',
            assignedTo: 'John Doe',
            publishedLinks: [
                'http://example.com/link1',
                'http://example.com/link2',
            ],
        },
        {
            key: '2',
            title: 'Story 2',
            location: 'Los Angeles',
            dateCreated: '2023-07-20',
            currentStage: 'Review',
            assignedTo: 'Jane Smith',
            publishedLinks: ['http://example.com/link3'],
        },
        // Add more data as needed
    ]
    const handleLanguageChange = (e) => {
        console.log(e)
    }
    return (
        <div>
            <div className="title">List Of Stories</div> <AddStories languageData={languageData}/> 
            <hr></hr>
            <div className="filters">
                <Select
                    style={{ width: "16%" }}
                    defaultValue={language}
                    onChange={handleLanguageChange}
                >
                    {languageData?.map((option) => (
                        <Select.Option
                            key={option?.language_id}
                            value={option?.language_id}
                        >
                            {option?.language_name}
                        </Select.Option>
                    ))}
                </Select>
                <Select
                    style={{ width: "16%" }}
                    placeholder={'Type'}
                    onChange={handleLanguageChange}
                ></Select>
                <Select
                    style={{ width: "16%" }}
                    placeholder={'Team'}
                    onChange={handleLanguageChange}
                ></Select>
                
                    {/* <RangePicker/> */}
                    <DatePicker/>
            
                <Select
                    style={{ width: "17%" }}
                    placeholder={'Pending'}
                    onChange={handleLanguageChange}
                ></Select>
            </div>
            <div style={{ padding: '1rem' }}>
                
                <CommonTableWithBorder className={'no-padding-table'} data={data} columns={columns} />
            </div>
        </div>
    )
}

export default ListOfStories
