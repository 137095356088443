import { Select } from 'antd';
import React from 'react';
export const SelectComponentWithSearch = ({ data, value, onChange, key_value, display, className, style, placeholder }) => {
  return (
    <Select
      className={className}
      value={value}
      placeholder={placeholder}
      style={style}
      onChange={(value) => onChange(value)}
      showSearch
      allowClear
      filterOption={(input, option) => 
        option.children.toLowerCase().includes(input.toLowerCase()) 
      }
    >
      {data?.map((e) => (
        <Select.Option key={e?.[key_value]} value={e?.[key_value]}>
          {e[display]}
        </Select.Option>
      ))}
    </Select>
  );
};
