import {
    Button,
    Col,
    Form,
    Input,
    Radio,
    Row,
    Select,
    Upload,
    message,
} from 'antd'
import { createFeature, getImageUrlR2, updateFeature } from '../../AadhanApi'
import dayjs from 'dayjs'
import { typeOptions } from './AddFeature.constant'
import { UploadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

const AddFeature = ({
    setShowModal,
    selectedFeature,
    accessToken,
    getFeatureTableData,
}) => {
    const languageData = useSelector((state) => state.app.languageData)
    const employeeId = useSelector((state) => state.app.employeeId)
    const [messageApi, contextHolder] = message.useMessage()
    const [form] = Form.useForm()

    form.setFieldsValue({
        name: selectedFeature?.name,
        image: selectedFeature?.image_url,
        type: selectedFeature?.feature_type,
        html: selectedFeature?.web_url,
        status: selectedFeature?.status,
        language: selectedFeature?.language,
        rank: selectedFeature?.rank,
    })

    const onFinish = async (values) => {
        const obj = {
            name: values?.name,
            status: values?.status,
            language: values?.language,
            image_url: values.image?.file?.response ?? values.image,
            feature_type: values.type,
            feature_url: 'None',
            web_url: values.html,
            rank:values?.rank,
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        try {
            if (selectedFeature) {
                await updateFeature(
                    obj,
                    accessToken,
                    selectedFeature.feature_id
                )
            } else {
                await createFeature(obj, accessToken)
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }

        getFeatureTableData()
        setShowModal(false)
        form.resetFields()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const draggerProps = {
        name: 'file',
        customRequest: ({ file, onSuccess }) => {
            getImageUrlR2(file, accessToken, 'feature').then((pathUriArr) => {
                if (pathUriArr?.data) {
                    const path = pathUriArr?.data
                    onSuccess(path)
                }
            })
        },
    }
    return (
        <>
            {contextHolder}
            <Form
                id="featureForm"
                form={form}
                name="featureForm"
                scrollToFirstError
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col span={10}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Feature Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Image"
                            name="image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please upload image',
                                },
                            ]}
                        >
                            <Upload {...draggerProps}>
                                <Button icon={<UploadOutlined />}>
                                    Upload
                                </Button>
                                <div style={{ color: '#199be9' }}>
                                    Image size 540*525
                                </div>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col span={10}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter value',
                                },
                            ]}
                        >
                            <Select>
                                {typeOptions.map((option, index) => (
                                    <Select.Option
                                        key={index}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Language',
                                },
                            ]}
                        >
                            <Select placeholder="--Select Language--">
                                {languageData?.map((option) => (
                                    <Select.Option
                                        key={option.language_id}
                                        value={option.language_id}
                                    >
                                        {option.language_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                        labelCol={{ span: 24 }}
                        label="HTML Link"
                        name="html"
                    >
                        <Input />
                    </Form.Item> */}
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    {selectedFeature && (
                        <Col span={10}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                label="Rank"
                                name="rank"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please select an rank',
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={10}>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.type !== currentValues.type
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('type') === 'html' ? (
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label="HTML Link"
                                        name="html"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'please enter html',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col span={10}>
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label="Status"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: 'please select an option',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="active"> Active </Radio>
                                <Radio value="inactive"> Inactive </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AddFeature
