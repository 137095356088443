import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { CloudUploadOutlined, SwitcherOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

const Tab = ({ tab1, tab1text, tab2text, tab2 ,icon,tab3,tab3text}) => {
    const [activeTabKey, setActiveTabKey] = useState('1');

    // Scroll to top on tab change
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [activeTabKey]);

    const handleTabChange = (key) => {
        setActiveTabKey(key);
    };

    return (
        <Tabs
            defaultActiveKey="1"
            activeKey={activeTabKey}
            onChange={handleTabChange}
            style={{
                width: '100%',
                 // Take the full width of the parent container
            }}
            tabBarStyle={{
                width: '100%', // Stretch the tab bar to the full width
                display: 'flex',
                flex:1
            
            }}
        >
            <TabPane
                tab={
                    <span
                        style={{
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                      {icon &&  <CloudUploadOutlined style={{ marginRight: 8 }} />}
                        {tab1text}
                    </span>
                }
                key="1"
            >
                {tab1}
            </TabPane>
            <TabPane
                tab={
                    <span
                        style={{
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {icon && <SwitcherOutlined style={{ marginRight: 8 }} /> }
                        {tab2text}
                    </span>
                }
                key="2"
            >
                {tab2}
            </TabPane>
            <TabPane
                tab={
                    <span
                        style={{
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {icon && <SwitcherOutlined style={{ marginRight: 8 }} /> }
                        {tab3text}
                    </span>
                }
                key="3"
            >
                {tab3}
            </TabPane>
        </Tabs>
    
    );
};

export default Tab;
