import { Button, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import {getUgContentReport} from '../../AadhanApi'
import dayjs from 'dayjs'
import DateRangePicker from './DateRangePicker'
import { useSelector } from 'react-redux'


const UgContentRepor = () => {
    const locationsData = useSelector((state)=>state.app.locations)
    const [stateId, setStateId] = useState('')
    const [reportesData, setReportesData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [contentStatus, setContentStatus] = useState('approved')
    const [status, setStatus] = useState('Paid')
    const accessToken = localStorage.getItem('ACCESS_TOKEN')
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
    })
   

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }
    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
        }
        setDates(updatedDates)
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
            
        },
        {
            title: 'PhoneNumber',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
        },
        {
            title: '#Images',
            dataIndex: 'content_with_images',
            key: 'content_with_images',
        },
        {
            title: '#Videos',
            dataIndex: 'content_with_videos',
            key: 'content_with_videos',
        },
    ]

    async function onFilter() {
        setDataLoading(true)
        if (
            stateId !== '' &&
            status !== '' &&
            dates !== '' &&
            contentStatus !== ''
        ) {
            const data = await getUgContentReport(
                stateId,
                status === 'Paid' ? true : false,
                dates.startDate,
                dates.endDate,
                accessToken,
                contentStatus
            )
            setReportesData(data.data.data)
            setDataLoading(false)
        }
    }

    useEffect(()=>{
        setStateId(locationsData?.[1]?.state_id)
    },[locationsData])
    function handleStateChange(e) {
        setStateId(e)
    }
    return (
        <div>
            <h2 style={{ padding: '0 0 0 1rem' }}>UG Content Report</h2>
            <hr></hr>
            <div>
                <div
                    style={{
                        margin: '1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginRight: '25%',
                    }}
                >
                    <Select
                        style={{width:"7rem"}}
                        value={contentStatus}
                        onChange={(e) => setContentStatus(e)}
                    >
                        <Select.Option key={'approved'} value={'approved'}>
                            approved
                        </Select.Option>
                        <Select.Option key={'rejected'} value={'rejected'}>
                            rejected
                        </Select.Option>
                        <Select.Option key={'submit'} value={'submit'}>
                            submit
                        </Select.Option>
                    </Select>
                    <Select
                        style={{ width: '5rem' }}
                        value={status}
                        onChange={(e) => setStatus(e)}
                    >
                        <Select.Option key={'Paid'} value={'Paid'}>
                            Paid
                        </Select.Option>
                        <Select.Option key={'Unpaid'} value={'Unpaid'}>
                            Unpaid
                        </Select.Option>
                    </Select>
                    <Select
                        onChange={(e) => {
                            handleStateChange(e)
                        }}
                        defaultValue={"Andhra Pradesh"}
                        style={{ width: '10rem', height: '2rem' }}
                    >
                        {locationsData.map((e) => {
                            return (
                                <Select.Option
                                    value={e.state_id}
                                    key={e.state_id}
                                >
                                    {e.name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                    <DateRangePicker
                        addDates={addDates}
                        clearDates={clearDates}
                        dates={dates}
                    />
                    <Button type="primary" onClick={onFilter}>
                        Apply
                    </Button>
                </div>
                <Table
                    style={{ marginTop: '1rem' }}
                    loading={dataLoading}
                    dataSource={reportesData}
                    columns={columns}
                    pagination={true}
                    rowClassName={(record, index) =>
                        
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                    }
                />
            </div>
        </div>
    )
}

export default UgContentRepor
