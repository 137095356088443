import { Form, Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { postElectionData, getImageUrlR2 } from '../../AadhanApi'
import ImageUploader from '../image-uploader-crop/ImageUploader'

const AddPartyForm = ({
    selectedRole, 
    getTableData, 
    setShowModal,
    selectedStateKey,
    electionsData,
    accessToken
}) => {
    const [form] = Form.useForm()
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [imagePath, setImagePath] = useState('')

    const onFinish = async (values) => {
        let filteredData = electionsData;
        let stateIndex = filteredData.findIndex(state => state.name === selectedStateKey);
        let newPartyData = {
            "name": values.name,
            "logo": imagePath,
            "contested": values.seats
        }
        if (stateIndex !== -1) {
            filteredData[stateIndex].parties.push(newPartyData);
        }
        let obj = {
            "election_type": "assembly",
            "states": [
                ...filteredData
            ]
        }
        if (selectedRole) {
            // await updateRole("obj", accessToken, selectedRole.id)
        } else {
            await postElectionData(obj, accessToken)
        }
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedRole) {
            form.setFieldsValue({
                name: selectedRole?.name,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRole])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const getImagePath = async (path) => {
        const pathUriArr = await getImageUrlR2(path, accessToken, "election")
        if (pathUriArr?.data) {
            const path = pathUriArr?.data
            setImagePath(path)
        }
    }

    return (
        <Form
            id="partyForm"
            form={form}
            name="partyForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 5 }}
                label="Party Name"
                name="name"
                rules={[{ required: true, message: 'Please enter name' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 5 }}
                label={
                    <div>
                        Contested<br />seats
                    </div>
                }
                name="seats"
            >
                <Input />
            </Form.Item>
            <Form.Item
                    style={{ marginBottom: '0px' }}
                    label="Logo"
                    name="logo"
                    rules={[
                        { required: true, message: 'Please select an Image' },
                    ]}
                >
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <ImageUploader getImagePath={getImagePath} />
                        <div>{isImageUploading && <LoadingOutlined/>}</div>
                    </div>
                </Form.Item>
        </Form>
    )
}

export default AddPartyForm;
