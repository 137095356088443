import React, { useEffect, useState } from 'react'
import CommonTable from "../CommonTable/CommonTable"
import { Button, Checkbox, Input, Modal, Select, message } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import './AssignedStories.scss'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'

const AssignedStories = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const allStates = useSelector((state) => state.app.locations)

    const [languageId, setLanguageId] = useState(languageData?.[0]?.language_id)
    const storyStatus = [
        // all, draft, accepted, rejected, completed
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Draft',
            value: 'draft'
        },
        {
            label: 'Accepted',
            value: 'accepted'
        },
        {
            label: 'Rejected',
            value: 'rejected'
        },
        {
            label: 'Completed',
            value: 'completed'
        },
    ]

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'District',
            dataIndex: 'district',
            key: 'district',
        },
        {
            title: 'Constituency',
            dataIndex: 'constituency',
            key: 'constituency',
        },
        {
            title: 'Date Created',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
        },
        {
            title: 'Date Covered',
            dataIndex: 'dateCovered',
            key: 'dateCovered',
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
        },
        {
            title: 'Content Covered',
            dataIndex: 'contentCovered',
            key: 'contentCovered',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ]

    return (
        <div className='assigned-stories-container' >
            <div className='header'>
                <h3>List Of Stories</h3>
                <Button type='primary'>Assign a story</Button>
            </div>
            <hr />
            <div className='filter-stories'>

                <Select
                    style={{ width: 200 }}
                    value={languageId}
                    placeholder='Select Language'
                    onChange={setLanguageId}
                >
                    {languageData.map(l => (
                        <Select.Option key={l.language_id} value={l.language_id}>{l.language_name}</Select.Option>
                    ))}
                </Select>

                <Select
                    style={{ width: 200 }}
                    placeholder='Select state'
                    onChange={(value) => console.log(value)}
                >
                    {allStates.map(state => (
                        <Select.Option key={state.state_id} value={state.state_id}>{state.name}</Select.Option>
                    ))}
                </Select>

                <ManageContentDateRange />

                <Select
                    style={{ width: 200 }}
                    placeholder='Select status'
                    onChange={(value) => console.log(value)}
                // value={languageId}
                >
                    {storyStatus.map(status => (
                        <Select.Option key={status.value} value={status.value}>{status.label}</Select.Option>
                    ))}
                </Select>
            </div>

            <div>
                    <CommonTable columns={columns} />
            </div>


        </div>
    )
}

export default AssignedStories;