import { Form, Input, Radio, Select, Row, Col, Switch } from 'antd'
import { useEffect, useState } from 'react'
import { createLanguage, updateLanguage, getStates } from '../../AadhanApi'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const AddLanguage = ({
    setShowModal,
    selectedLanguageRecord,
    accessToken,
    getLanguageData,
}) => {
    const statesData = useSelector((state)=>state.app.locations)
    const [statesList, setStatesList] = useState([])
    const [district, setDistrict] = useState(false)
    const [form] = Form.useForm()
    const employeeId = useSelector((state) => state.app.employeeId)
    useEffect(() => {
        setDistrict(selectedLanguageRecord?.districts_available || false);
        form.setFieldsValue({
            regionalName: selectedLanguageRecord?.regional_name,
            status: selectedLanguageRecord?.status,
            languageName: selectedLanguageRecord?.language_name,
            languageCode: selectedLanguageRecord?.language_code,
            states: getStateIds(selectedLanguageRecord?.states),
        })
    }, [selectedLanguageRecord])

   

    const getStateIds = (data) => {
        let stateIds = [];
        if (data?.length) {
            stateIds = data.map(ele => ele.state_id)
        }
        return stateIds;
    }

    const onFinish = async (values) => {
        const obj = {
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            language_name: values.languageName,
            status: values.status,
            language_code: values.languageCode,
            regional_name: values.regionalName,
            created_by: employeeId,
            states: statesList,
            districts_available: district,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        if (selectedLanguageRecord) {
            await updateLanguage(
                obj,
                accessToken,
                selectedLanguageRecord.language_id
            )
        } else {
            await createLanguage(obj, accessToken)
        }
        getLanguageData()
        setShowModal(false)
        form.resetFields()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const selectedStates = (selectedValues) => {
        const deselectedState = statesList.find((state) => !selectedValues.includes(state.state_id));

        if (deselectedState) {
            setStatesList(statesList.filter((state) => state.state_id !== deselectedState.state_id));
        } else {
            const newStatesList = selectedValues.map((value) => {
                const state = statesData.find((ele) => ele.state_id === value);
                return { "state_id": state.state_id, "name": state.name, "local_name": state.local_name };
            });
            setStatesList(newStatesList);
        }
    };

    return (
        <Form
            id="languageForm"
            form={form}
            name="languageForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Row style={{ justifyContent: 'space-between' }}>
                <Col span={10}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Regional Name"
                        name="regionalName"
                        rules={[{ required: true, message: 'Please enter value' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Language Name"
                        name="languageName"
                        rules={[{ required: true, message: 'Please enter value' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
                <Col span={10}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Language Code"
                        name="languageCode"
                        rules={[{ required: true, message: 'Please enter value' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="States"
                        name="states"
                    >
                        <Select
                            placeholder="States"
                            mode="multiple"
                            onChange={(selectedValues) => selectedStates(selectedValues)}
                        >
                            {statesData?.map((option) => (
                                <Select.Option
                                    key={option.state_id}
                                    value={option.state_id}
                                >
                                    {option.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
                <Col span={10}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="District"
                        name="district"
                    >
                        <div style={{ marginRight: '5px' }}>
                            <Switch checkedChildren="Yes" unCheckedChildren="No" className='status'
                                checked={district}
                                onChange={() => setDistrict(!district)}
                            />
                        </div>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Status"
                        name="status"
                        rules={[{ required: true, message: 'please select an option' }]}
                    >
                        <Radio.Group>
                            <Radio value="active"> Active </Radio>
                            <Radio value="inactive"> Inactive </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default AddLanguage
