import { useEffect, useState } from 'react'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import dayjs from 'dayjs'
import ReviewContentForm from './ReviewContentForm/ReviewContentForm'
import { getReporterStatus, gettvContentByContentId, getUgcContentByContentId } from '../../AadhanApi'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    LeftOutlined,
} from '@ant-design/icons'
import { changePopup } from '../../store/slice/AppSlice'
import { addLogo } from '../addLogo'

const ReviewContent = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [videoPath, setVideoPath] = useState('')
    const location = useLocation()
    const [hashTagId, setHashTagId] = useState()
    const [sourceId, setSourceId] = useState()
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('contentId')
    const dispatch = useDispatch()
    const mode = searchparam.get('mode')
    const [data, setData] = useState({})
    const languageData = useSelector((state) => state.app.languageData)
    const locationsData = useSelector((state) => state.app.locations)
    const categories = useSelector((state)=>state.app.newsSectionData);
    const accessToken = useSelector((state) => state.app.accessToken)
    const [fileList, setFileList] = useState([])
    const [videoList, setVideoList] = useState([])
    const [code, setCode] = useState('')
    const [selectedImageUrl, setSelectedImageUrl] = useState([])
    const [selectedVideoUrl, setSelectedVideoUrl] = useState([])
    const [rejectedImageUrl, setRejectedImageUrl] = useState([])
    const [rejectedVideoUrl, setRejectedVideoUrl] = useState([])
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [rejectionReason, setRejectionReason] = useState('')
    const [initialFormData, setInitialFormData] = useState({
        language: languageData?.[0]?.language_id,
        breakingNews: false,
        relevancy: 'veryImportant',
        disturbingVisuals: 'no',
        media: '',
        publish: dayjs(),
        locationSpecific: 'no',
        proofRead: '',
        // category: categories?.filter(
        //     (ct) => ct.language_name === languageData?.[0]?.language_name
        // )?.[0]?.category_id,
        expiryHours: 'Never',
    })
    const [isCompensated, setIsCompensated] = useState({
        isVerified: false,
        totalApproved: 0,
    })
    const navigate = useNavigate()
    const getMedia = (response) => {
        if (response?.stream_video_url !== 'null') {
            return 'video'
        } else if (response?.yt_video_url !== 'null') {
            return 'youtubeLink'
        } else {
            return ''
        }
    }
    async function isCompensation(id, start, end) {
        const res = await getReporterStatus(
            id,
            start.slice(0, 10),
            end.slice(0, 10),
            accessToken
        )
        setIsCompensated({
            isVerified: res?.data?.data?.verified_stringer,
            totalApproved: res?.data?.data?.approved,
        })
    }
    const updateFormData = async (contentId, accessToken) => {
        try {
           let response={};
           if(location?.pathname?.includes('tvContent')){
             response = await gettvContentByContentId(
                contentId,
                accessToken
            )
        }
            else{
                 response = await getUgcContentByContentId(
                    contentId,
                    accessToken
                )
            }
           
            if (response?.data) {
                if (response?.data?.uploaded_by) {
                    isCompensation(
                        response?.data?.uploaded_by,
                        response?.data?.uploaded_date,
                        response?.data?.uploaded_date
                    )
                }
                setData(response?.data)
                // const logoimage = await addLogo(response?.data?.image_url[0],'https://static.aadhan.in/mainImages/aadhan_white_logo.png',accessToken,2)
                // response.data.image_url = ['https://imagedelivery.net/mdsbDomRNG5YuM5B_oh9og/8c3a964e-9c70-490e-15bf-08cd252f3200/public'];
                let mandalID = []
                if (response?.data?.mandal?.length !== 0) {
                    mandalID = response?.data?.mandal?.split(',')
                }
                let districtID = []
                if (response?.data?.district?.length !== 0) {
                    districtID = response?.data?.district?.split(',')
                }

                setCode(response?.data?.code)
                if (response?.data?.stream_video_url !== 'null') {
                    setVideoList(
                        response?.data?.stream_video_url?.length > 0
                            ? response?.data?.stream_video_url
                            : []
                    )
                }
                setFileList(
                    response?.data?.image_url?.length > 0
                        ? response?.data?.image_url
                        : []
                )
                setVideoList(
                    response?.data?.stream_video_url?.length > 0
                        ? response?.data?.stream_video_url
                        : []
                )
                setTitle(response?.data?.title)
                setDescription(response?.data?.description)
                setHashTagId(response?.data?.hashtag_id)
                setSourceId(response?.data?.source_id)
                setInitialFormData({
                    ...initialFormData,
                    nickname: response?.data?.stringer_nickname,
                    created_type: response?.data?.created_type,
                    video: response?.data?.stream_video_url,
                    title: response?.data?.title,
                    rejectionReason: response?.data?.rejectionReason,
                    language: response?.data?.language_id,
                    breakingNews: response?.data?.breaking_news,
                    relevancy: response?.data?.relevancy,
                    category: response?.data?.category_id,
                    disturbingVisuals: response?.data?.disturbing_visuals,
                    media: getMedia(response?.data),
                    publish: dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss')),
                    locationSpecific:
                        response?.data?.state !== '' ? 'yes' : 'no',
                    expiryHours:
                        response?.data?.expiry_hours === 0
                            ? 'Never'
                            : response?.data?.expiry_hours,
                    shortDescription: response?.data?.description,
                    stringer_type:response?.data?.stringer_type,
                    district_id: districtID,
                    district: response?.data?.district_name,
                    proofRead: '',
                    image: response?.data?.image_url,
                    constituency_ids: response?.data?.constituency_ids,
                    assemblyconstituencies: response?.data?.constituency_names,
                    state_id: response?.data?.state,
                    state: response?.data?.state_name,
                    mandal_id: mandalID,
                    mandal: response?.data?.mandal_name,
                    keywords: response?.data?.keywords,
                    updated_by: response?.data?.updated_by,
                    uploaded_by: response?.data?.uploaded_by,
                    uploadedByName: response?.data?.uploaded_by_name,
                    uploaded_date: response?.data?.uploaded_date,
                    subcategory: response?.data?.sub_category_name,
                    subcategory_id: response?.data?.sub_category_id,
                    gender: response?.data?.gender,
                    assigned_to: response?.data?.assigned_to,
                    assigned_at: response?.data?.assigned_at,
                })
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get UG Content Data from content Id',
                duration: 5,
            })
        }
    }
    useEffect(() => {
        if (contentId) {
            updateFormData(contentId, accessToken)
        }
    }, [contentId])
    const titleChange = (newTitle) => {
        setTitle(newTitle)
    }
    const rejectionReasonChange = (newValue) => {
        setRejectionReason(newValue)
    }
    const descriptionChange = (newDesc) => {
        const inputLines = newDesc.split('\n')?.length
        if (inputLines <= 9) {
            setDescription(newDesc)
        }
    }
    return (
        <div style={{ padding: '0px 20px'}}>
            {contextHolder}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div
                    onClick={() => {
                        dispatch(changePopup(false))
                        navigate(-1)
                    }}
                    className="link-style"
                >
                    <LeftOutlined /> <span>{location?.pathname?.includes("tv") ? "TV Content" : "UG Content"}</span>
                </div>

                <div style={{ margin: '0 2rem' }} className="container_">
                    Content By
                    <span className="box_">
                        {initialFormData?.uploadedByName}
                        {initialFormData?.nickname && (
                            <span> ({initialFormData?.nickname})</span>
                        )}
                    </span>
                    <span
                        style={{
                            marginRight:"1rem",
                            color:
                                isCompensated?.isVerified === true
                                    ? 'green'
                                    : 'red',
                            fontSize: '1rem',
                        }}
                    >
                        {isCompensated?.isVerified === true ? (
                            <CheckCircleOutlined />
                        ) : (
                            <CloseCircleOutlined />
                        )}{' '}
                    </span>
                    From
                    {initialFormData?.assemblyconstituencies?.[0] && (
                        <span className="box">
                            {initialFormData?.assemblyconstituencies?.join(
                                ', '
                            )}
                            <span className="separator">, </span>
                        </span>
                    )}
                    {initialFormData?.district?.[0] && (
                        <>
                            <span className="box">
                                {initialFormData?.district?.join(', ')}
                            </span>
                        </>
                    )}
                </div>
                {isCompensated ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            margin: '0 10% 0 auto',
                        }}
                    >
                        <label
                            style={{
                                marginRight: '8px',
                                fontWeight: '500',
                                fontSize: '16px',
                                color: 'gray',
                            }}
                        >
                            Approved:
                        </label>
                        <span
                            style={{
                                fontSize: '20px',
                                color:
                                    isCompensated?.totalApproved > 5
                                        ? 'red'
                                        : isCompensated?.totalApproved === 5
                                        ? 'green'
                                        : 'orange',
                                fontWeight: 'bold',
                            }}
                        >
                            {isCompensated?.totalApproved}
                        </span>
                    </div>
                ) : null}
            </div>
            <hr style={{ margin: '0 0 7px 0' }}></hr>
            <div className="add-ad-container row">
                <div className="col-md">
                    <ReviewContentForm
                        data={data}
                        descriptionChange={descriptionChange}
                        titleChange={titleChange}
                        rejectionReasonChange={rejectionReasonChange}
                        initialFormData={initialFormData}
                        languageData={languageData}
                        languageOption={languageOption}
                        setLanguageOption={setLanguageOption}
                        categories={categories}
                        accessToken={accessToken}
                        mode={mode}
                        contentId={contentId}
                        setVideoPath={setVideoPath}
                        setHashTagId={setHashTagId}
                        hashTagId={hashTagId}
                        sourceId={sourceId}
                        setSourceId={setSourceId}
                        locationsData={locationsData}
                        setFileList={setFileList}
                        fileList={fileList}
                        videoPath={videoPath}
                        videoList={videoList}
                        setVideoList={setVideoList}
                        code={code}
                        setSelectedImageUrl={setSelectedImageUrl}
                        setSelectedVideoUrl={setSelectedVideoUrl}
                        selectedImageUrl={selectedImageUrl}
                        selectedVideoUrl={selectedVideoUrl}
                        setRejectedImageUrl={setRejectedImageUrl}
                        setRejectedVideoUrl={setRejectedVideoUrl}
                        rejectedImageUrl={rejectedImageUrl}
                        rejectedVideoUrl={rejectedVideoUrl}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReviewContent
