import { Carousel, Modal } from 'antd';
import './ViewPageVisuals.scss'
const PreviewModal = ({data,isModalOpen,setIsModalOpen}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal width={150} className='preview-modal' open={isModalOpen} footer={null} closable={false} onOk={handleOk} onCancel={handleCancel}>
      <div className="smartphone">
      <Carousel
                    style={{ height: '100%', width: '100%' }}
                    swipeToSlide
                    draggable
                >
                    {data?.map((images, index) => {
                       return (
                                <img
                                    key={index}
                                    src={
                                        images}
                                    alt={`${index}`}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            )
                        
                    })}
                </Carousel>
      </div>
      </Modal>
    </div>
  );
};
export default PreviewModal;