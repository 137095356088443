import React, { useState } from 'react'
import { Button, Modal, Select } from 'antd'
import '../stories.scss'
const AssignModal = ({ title }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <button onClick={showModal} className="stage_btn">
                Assign
            </button>
            <Modal
                footer={null}
                className="custom-modal"
                width={300}
                title={`Assign a ${title}`}
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Select
                    placeholder={`Select a ${title}`}
                    style={{ width: 190, marginBottom: '1rem' }}
                ></Select>
                <Button onClick={handleOk} type='primary' style={{padding:".5rem 2rem"}}>
                Assign
                </Button>
            </Modal>
            
        </>
    )
}
export default AssignModal
