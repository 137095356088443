import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

const HoroscopeTextArea=({title,value,onchange}) => (
    
  <div style={{display:"flex" ,width:"100%",alignItems:"start",marginBottom:"1.5rem"}}>
    
   
<label style={{width:"8rem"}}  htmlFor="myTextArea">{title}</label>

<TextArea id="myTextArea" value={value} onChange={onchange}  rows={5} required={true} placeholder="Max 500 Characters" maxLength={500} />

  
  </div>
);

export default HoroscopeTextArea;