export const languageOptions = [
    {
        label: 'English',
        value: 'english',
    },
    {
        label: 'French',
        value: 'french',
    },
]
