import { useSelector } from 'react-redux'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import AddShortVideoForm from './AddShortVideoForm/AddShortVideoForm'
import { useLocation, useNavigate } from 'react-router'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import {
    createShortVideo,
    getNewsSections,
    getShortVideoByContentId,
    updateShortVideo,
} from '../../AadhanApi'
import { Button, message } from 'antd'
import { CloseSquareFilled } from '@ant-design/icons'
import { languageOptions } from '../AddAd/AddAdForm/AddAdConstant'

const AddShortVideo = () => {
    const location = useLocation()
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('contentId')
    const mode = searchparam.get('mode')
    const languageData = useSelector((state) => state.app.languageData)
    const [categories,setCategories] = useState([])
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const accessToken = useSelector((state) => state.app.accessToken)
    const employeeId = useSelector((state) => state.app.employeeId)
    const employeeName = useSelector((state) => state.app.employeeName)
    const [videoPath, setVideoPath] = useState()
    const [imagePath, setImagePath] = useState('')
    const [contentStatus, setContentStatus] = useState('')
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [code, setCode] = useState('')
    const [messageApi, contextHolder] = message.useMessage()
    const titleChange = (newTitle) => {
        setTitle(newTitle)
    }
    const [isLoading, setIsLoading] = useState(false)
    const [youtubeLink, setYoutubeLink] = useState('')
    const [otherLink, setOtherLink] = useState('')

    const [initialFormData, setInitialFormData] = useState({
        language: languageData?.[0]?.language_id,
        publish: dayjs(),
        expiryHours: 'Never',
        title: '',
        media: 'youtubeLink',
        thumbnailImage: '',
        disturbingVisuals: false,
    })
    const getNewsSection=async()=>{
        const data= await getNewsSections(languageOption,'all',accessToken)
        setCategories(data?.data?.data)

    }
    useEffect(()=>{
        getNewsSection()
    },[languageOption])
    const updateFormData = async (contentId, accessToken) => {
        const response = await getShortVideoByContentId(contentId, accessToken)

        if (response?.data) {
            setCode(response?.data[0].code)
            setTitle(response?.data?.[0]?.title)
            if (
                response?.data?.[0]?.stream_video_url !== '' &&
                response?.data?.[0]?.media_type === 'Video'
            ) {
                setVideoPath(response?.data?.[0]?.stream_video_url)
            } else if (
                response?.data?.[0]?.yt_video_url !== '' &&
                response?.data?.[0]?.media_type === 'Youtube'
            ) {
                setVideoPath(response?.data?.[0]?.yt_video_url)
            }
            setImagePath(response?.data?.[0]?.image_url)
            setContentStatus(response?.data?.[0]?.content_status)
            setInitialFormData({
                ...initialFormData,
                language: response?.data?.[0]?.language_id,
                category: response?.data?.[0]?.category_id,
                publish: dayjs(
                    dayjs(response?.data?.[0]?.published_date).format(
                        'YYYY-MM-DD HH:mm:ss'
                    )
                ),
                expiryHours:
                    response?.data?.[0]?.expiry_hours == 0
                        ? 'Never'
                        : response?.data?.[0]?.expiry_hours,
                youtubeLink:
                    response?.data?.[0]?.yt_video_url === ''
                        ? ''
                        : response?.data?.[0]?.yt_video_url,
                otherLink:
                    response?.data?.[0]?.mp4_video_url === ''
                        ? ''
                        : response?.data?.[0]?.mp4_video_url,
                title: response?.data?.[0]?.title,
                thumbnailImage: response?.data?.[0]?.image_url,
                disturbingVisuals: response?.data?.[0]?.disturbing_visuals,
                video: response?.data?.[0].stream_video_url,
                proofRead:
                    response?.data?.[0]?.content_status !== 'draft' &&
                    response?.data?.[0]?.content_status !== 'submit'
                        ? response?.data?.[0]?.is_proof_read
                        : '',
                uploadedBy: response?.data?.[0]?.uploaded_by,
                uploadedByName: response?.data?.[0]?.uploaded_by_name,
                uploadedDate: response?.data?.[0]?.uploaded_date,
                englishTitle: response?.data?.[0]?.english_title,
                media:
                    response?.data?.[0]?.media_type === 'Youtube'
                        ? 'youtubeLink'
                        : response?.data?.[0]?.media_type === 'others'
                        ? 'otherLink'
                        : response?.data?.[0]?.media_type?.toLowerCase(),
            })
        }
    }

    useEffect(() => {
        if (contentId) {
            updateFormData(contentId, accessToken)
        }
    }, [contentId])

    const createRequest = (values, type) => {
        let categoryName = categories
            ?.map((ele, i) => {
                if (
                    ele.category_id === values.category 
                )
                    return ele.category_name
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]
        return {
            title: values?.title?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            .replace(
                /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                '$1 '
            ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
            .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
            .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
            .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
            .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
            .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
            .trim(), // Trim leading and trailing spaces
            source_id: 0,
            english_title: values?.englishTitle ?? '',
            source_url: values?.sourceLink ?? '',
            category_id: values?.category,
            content_type: 'shortvideo',
            expiry_hours:
                values?.expiryHours == 'Never' ? 0 : values?.expiryHours,
            // expiry_date: dayjs(values?.publish)
            //     .add(values?.expiryHours, 'hour')
            //     ?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            popular_card: values?.popularCard,
            dynamic_url: '',
            is_active: true,
            content_status: type,
            disturbing_visuals: values?.disturbingVisuals,
            language_id: values?.language,
            image_url_16_9: imagePath,
            image_url_3_4: imagePath,
            image_url: imagePath,
            thumbnailimage_url: imagePath,
            code,
            stream_video_url: videoPath?.includes('stream.mux.com')
                ? videoPath
                : '',
            state: '',
            district: '',
            city: '',
            keywords: '',
            uploaded_by_name: initialFormData?.uploadedByName || employeeName,
            proof_read_by_name: employeeName,
            category_name: categoryName,
            yt_video_url: youtubeLink || values?.youtubeLink || '',
            mp4_video_url: otherLink || values?.otherLink || '',
            media_type:
                values?.media === 'youtubeLink'
                    ? 'Youtube'
                    : values?.media === 'otherLink'
                    ? 'others'
                    : 'Video',
        }
    }

    const onSave = async (values, type) => {
        setIsLoading(true)
        if (!imagePath) {
            messageApi.open({
                type: 'error',
                content: 'Please select thumbnail',
                duration: 5,
            })
            return
        }
        const request = createRequest(values, type)
        if (mode === 'edit') {
            try {
                request.updated_by = employeeId
                request.updated_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate
                const videoResponse = await updateShortVideo(
                    request,
                    contentId,
                    accessToken
                )
                if (videoResponse?.data === 'Video updated successfully') {
                    setIsLoading(false)
                    navigate('/home/shortVideo')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to update Short video',
                    duration: 5,
                })
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            try {
                const videoResponse = await createShortVideo(
                    request,
                    accessToken
                )
                if (videoResponse?.data === 'Video inserted successfully') {
                    setIsLoading(false)
                    navigate('/home/shortVideo')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to create Short video',
                    duration: 5,
                })
            }
        }
    }

    const onPublish = async (values) => {
        setIsLoading(true)

        const request = createRequest(values)
        request.content_status = 'approved'
        if (true) {
            request.proof_read_by = employeeId
            request.proof_read_date = dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            request.is_proof_read = true
        }

        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.uploaded_by = initialFormData?.uploadedBy
            request.uploaded_date = initialFormData?.uploadedDate
            try {
                const videoResponse = await updateShortVideo(
                    request,
                    contentId,
                    accessToken
                )
                if (videoResponse?.data === 'Video updated successfully') {
                    setIsLoading(false)
                    navigate('/home/shortVideo')
                }
            } catch (err) {
                console.log(err)
                setIsLoading(false)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to update Short video',
                    duration: 5,
                })
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            try {
                const videoResponse = await createShortVideo(
                    request,
                    accessToken
                )
                if (videoResponse?.data === 'Video inserted successfully') {
                    setIsLoading(false)
                    navigate('/home/shortVideo')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to create Short video',
                    duration: 5,
                })
            }
        }
    }

    return (
        <div style={{padding:"0 1rem"}}>
            {contextHolder}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h3>
                    {mode === 'edit' ? 'Edit Short Video' : 'Add Short Video'}
                </h3>

                <Button
                    onClick={() => navigate(-1)}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{ color: 'LightCoral', fontSize: '1.8rem' }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>

            <div className="add-ad-container row">
                <div className="col-md">
                    <AddShortVideoForm
                        titleChange={titleChange}
                        initialFormData={initialFormData}
                        accessToken={accessToken}
                        onSave={onSave}
                        onPublish={onPublish}
                        languageOption={languageOption}
                        setLanguageOption={setLanguageOption}
                        setVideoPath={setVideoPath}
                        languageData={languageData}
                        categories={categories}
                        setImagePath={setImagePath}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        contentStatus={contentStatus}
                        setYoutubeLink={setYoutubeLink}
                        setOtherLink={setOtherLink}
                    />
                </div>
                <div className="col-md">
                    <SmartphonePreview title={title} videoPath={videoPath} />
                </div>
            </div>
        </div>
    )
}
export default AddShortVideo
