export const typeOptions = [
    { label: 'Poll', value: 'poll' },
    { label: 'Quiz', value: 'quiz' },
    { label: 'Short News', value: 'shortNews' },
    { label: 'Short Video', value: 'shortVideo' },
    { label: 'Devotional', value: 'devotionalVideo' },
    { label: 'Card', value: 'card' },
    { label: 'HTML', value: 'html' },
    { label: 'Bookmarks', value: 'bookmarks' },
]
