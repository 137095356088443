import { DatePicker, Select } from 'antd'
import {
    AddTypeOptions,
    ApprovalOptions,
    LanguageOptions,
} from '../ManageContent/ManageContent.constant'
import Search from 'antd/es/transfer/search'
import './TopicsReport.scss'
import CommonTable from '../CommonTable/CommonTable'
import { languageOptions } from '../Hashtag/AddHashtag.constant'
import {
    timeOptions,
    typeOptions,
    writerOptions,
} from './TopicsReport.constant'

const TopicsReport = () => {
    const feedbackTableColumns = [
        {
            title: 'Topic Name',
            dataIndex: 'topicName',
            key: 'topicName',
        },
        {
            title: '# Posts',
            dataIndex: 'postNumber',
            key: 'postNumber',
        },
        {
            title: 'Percenatge',
            dataIndex: 'percenatge',
            key: 'percenatge',
        },
    ]
    const data = [
        {
            topicName: 'Topic 1',
            postNumber: 12,
            percenatge: 100,
        },
        {
            topicName: 'Topic 2',
            postNumber: 12,
            percenatge: 100,
        },
        {
            topicName: 'Topic 3',
            postNumber: 12,
            percenatge: 100,
        },
        {
            topicName: 'Topic 4',
            postNumber: 12,
            percenatge: 100,
        },
        {
            topicName: 'Topic 5',
            postNumber: 12,
            percenatge: 100,
        },
    ]
    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Topics Report</h2>
            </div>
            <div className="manage-content-inputs">
                <Select value={'English'} style={{ width: 200 }}>
                    {languageOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select style={{ width: 200 }} value={'short'}>
                    {typeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>
                <Select value="all" style={{ width: 200 }}>
                    {writerOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option.value}>
                                {option.label}
                            </Option>
                        )
                    })}
                </Select>
                <DatePicker style={{ width: 200 }} />
            </div>
            <CommonTable columns={feedbackTableColumns} data={data} />
        </div>
    )
}

export default TopicsReport
