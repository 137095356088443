export const typeOptions = [
    { label: 'All', value: 'all' },
    { label: 'Short News', value: 'shortnews' },
    { label: 'Short Video', value: 'shortvideo' },
    { label: 'Card', value: 'card' },
    { label: 'Quiz', value: 'quiz' },
    { label: 'Poll', value: 'poll' },
    { label: 'Html', value: 'html' },
]

export const writerOptions = [
    { label: 'All Writers', value: 'all' },
    { label: 'Atul', value: 'atul' },
    { label: 'Arun', value: 'arun' },
]

export const timeOptions = [
    { label: 'All', value: 'all' },
    { label: '6AM - 10AM', value: '6-10' },
    { label: '10AM - 4PM', value: '10-16' },
    { label: '4PM - 7PM', value: '16-19' },
    { label: '7PM - 12AM', value: '19-24' },
]

export const userTypeOptions = [
    {label: 'Employee', value: 'employee'},
    {label: 'Reporter', value: 'reporter'}
]