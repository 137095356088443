import { configureStore } from '@reduxjs/toolkit'
import AppSlice from './slice/AppSlice'
import ManageContentSlice from './slice/ManageContentSlice'
import HoroscopeSlice from './slice/HoroscopeSlice'
import  PanchangamSlice  from './slice/PanchangamSlice'
const Store = configureStore({
    reducer: {
        app: AppSlice,
        manageContent: ManageContentSlice,
        horoscope:HoroscopeSlice,
        panchangam:PanchangamSlice,
    },
})
export default Store
