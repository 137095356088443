export const typeOptions = [
    { label: 'All', value: 'all' },
    { label: 'Short News', value: 'shortnews' },
    { label: 'Short Video', value: 'shortvideo' },
    { label: 'Card', value: 'card' },
    { label: 'Quiz', value: 'quiz' },
    { label: 'Poll', value: 'poll' },
    { label: 'Html', value: 'html' },
]
export const monthArr = [
    { month_name: 'Jan', month_code: 1 },
    { month_name: 'Feb', month_code: 2 },
    { month_name: 'Mar', month_code: 3 },
    { month_name: 'Apr', month_code: 4 },
    { month_name: 'May', month_code: 5 },
    { month_name: 'June', month_code: 6 },
    { month_name: 'July', month_code: 7 },
    { month_name: 'Aug', month_code: 8 },
    { month_name: 'Sep', month_code: 9 },
    { month_name: 'Oct', month_code: 10 },
    { month_name: 'Nov', month_code: 11 },
    { month_name: 'Dec', month_code: 12 }
]