import React, { useEffect, useState } from 'react'
import { Button, Layout, Modal, Slider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import HoroscopeTextArea from './HoroscopeTextArea'
import SelectHoroscope from './SelectHoroscope'
import { Content } from 'antd/es/layout/layout'
import { updateDailyHoroscope } from '../../AadhanApi'
import { lamguageArray } from './languageConst'
import TranslateModal from './TranslateModal'

const AddHoroscopeModal = ({
    horoscopeName,
    token,
    horoscopeData,
    Id,
    setRender,
    language,
    month,
    year,
    title,
    date,
    horoscopeType,
    setLanguageOption,
}) => {
    const ontranslateDate = useSelector((state)=>state.horoscope.updateHoroscopeContent);
    const [translateHeader, setTranslateHeader] = useState(language)
    
    const [horoscopeName_, setHoroscopeName_] = useState(horoscopeName)
    const [general, setGeneral] = useState(
        
        horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.general
        )

    const [health, setHealth] = useState(
        horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.health
    )
    const [finance, setFinance] = useState(
        horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.finance
    )
    const [jobCareer, setJobCareer] = useState(
        horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.career
    )
    const [familyLove, setFamilyLove] = useState(
        horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.family
    )
    const [selectedLanguage, setSelectedLanguage] = useState(language)
    const [currentStatus, setCurrentStatus] = useState(
        horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.status
    )

    const header = [
        { title: 'Manage Horoscope', lang: 'English' },
        { title: 'Manage Horoscope', lang: 'Hindi' },
        { title: 'Manage Horoscope', lang: 'Tamil' },
        { title: 'Manage Horoscope', lang: 'Telugu' },
    ]
    const [isModalOpen, setIsModalOpen] = useState(false)
    const dispatch = useDispatch()

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const onchangeGeneral = (e) => {
        if (e.target.value !== ' ') {
            setGeneral(e.target.value)
        }
    }
    const onchangeHealth = (e) => {
        if (e.target.value !== ' ') {
            setHealth(e.target.value)
        }
    }
    const onchangeFinance = (e) => {
        if (e.target.value !== ' ') {
            setFinance(e.target.value)
        }
    }
    const onchangeJob = (e) => {
        if (e.target.value !== ' ') {
            setJobCareer(e.target.value)
        }
    }
    const onchangeFamily = (e) => {
        if (e.target.value !== ' ') {
            setFamilyLove(e.target.value)
        }
    }
   
    useEffect(() => {
        setTranslateHeader(language)
        setSelectedLanguage(language)
        setGeneral(
        
            horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.general
        )
        setHealth(
            horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.health
        )
        setFinance(
            horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.finance
        )
        setJobCareer(
            horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.career
        )
        setFamilyLove(
            horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.family
        )
        setCurrentStatus(
            horoscopeData[horoscopeName_]?.[lamguageArray[language]]?.status
        )
    }, [language])
    useEffect(() => {
        setTranslateHeader(language)
        setSelectedLanguage(language)
    }, [language])

    useEffect(() => {
        setGeneral(
            localStorage.getItem('onTranslate') && localStorage.getItem('onTranslate')==='true' && ontranslateDate.length!==0 ? ontranslateDate[0]?.gen :
             horoscopeData[horoscopeName_]?.[lamguageArray[translateHeader]]?.general
        );
        
        setHealth(
            localStorage.getItem('onTranslate') && localStorage.getItem('onTranslate')==='true' && ontranslateDate.length!==0 ? ontranslateDate[2]?.health :
            horoscopeData[horoscopeName_]?.[lamguageArray[translateHeader]]
                ?.health
        )
        setFinance(
            localStorage.getItem('onTranslate') && localStorage.getItem('onTranslate')==='true' && ontranslateDate.length!==0 ? ontranslateDate[3]?.finance :
            horoscopeData[horoscopeName_]?.[lamguageArray[translateHeader]]
                ?.finance
        )
        setJobCareer(
            localStorage.getItem('onTranslate') && localStorage.getItem('onTranslate')==='true' && ontranslateDate.length!==0 ? ontranslateDate[1]?.job :
            horoscopeData[horoscopeName_]?.[lamguageArray[translateHeader]]
                ?.career
        )
        setFamilyLove(
            localStorage.getItem('onTranslate') && localStorage.getItem('onTranslate')==='true' && ontranslateDate.length!==0 ? ontranslateDate[4]?.familyLove :
            horoscopeData[horoscopeName_]?.[lamguageArray[translateHeader]]
                ?.family
        )
        localStorage.setItem('onTranslate',false)
    }, [translateHeader, horoscopeName_])
    const text = {
        fontWeight: '650',
    }
    const style = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '.3rem 0 .3rem 0',
        flex: 1,
        cursor: 'pointer',
    }
    function setLanguage(lang) {
        if (lang === 'English') setSelectedLanguage(1)
        if (lang === 'Telugu') setSelectedLanguage(2)
        if (lang === 'Hindi') setSelectedLanguage(3)
        if (lang === 'Tamil') setSelectedLanguage(4)
    }
    async function handleSubmit() {
        let lan
        if (translateHeader === 'English') {
            lan = '1'
        }
        if (translateHeader === 'Tamil') {
            lan = '4'
        }
        if (translateHeader === 'Hindi') {
            lan = '3'
        }
        if (translateHeader === 'Telugu') {
            lan = '2'
        }

        const res = await updateDailyHoroscope(
            Id,
            lan,
            horoscopeName_.toLowerCase(),
            token,
            general,
            health,
            finance,
            jobCareer,
            familyLove
        )
        setRender(1)
        handleOk()
    }
   
    let horoscopePermission = localStorage.getItem('USER_PERMISSIONS')
    horoscopePermission = (JSON.parse(horoscopePermission)?.Astrology?.Horoscope)
    
    return (
        <>
            <Button
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow:'none',
                    color: 'blue',
                    textAlign: 'center',
                    padding: '0',
                    cursor: 'pointer',
                    
                }}
                onClick={showModal}
                disabled={currentStatus==='new' ? horoscopePermission.includes('Can Add')?false : true :currentStatus==='draft'? horoscopePermission.includes('Can Edit') ?false:true :currentStatus==='complete' ?horoscopePermission.includes('Can View')?false:true :false }
            >
                {currentStatus === 'new' ? (
                    'Add'
                ) : currentStatus === 'complete' ? (
                    <span
                        style={{
                            color: 'lightGreen',
                            textDecoration: 'underLine',
                        }}
                    >
                        View
                    </span>
                ) : (
                    <span
                        style={{ color: 'orange', textDecoration: 'underLine' }}
                    >
                        Draft
                    </span>
                )}
            </Button>
            <Modal
                open={isModalOpen}
                footer={null}
                okText={false}
                onOk={handleOk}
                width="80%"
                style={{ top: 40 }}
                onCancel={handleCancel}
            >
                <div
                    style={{
                        display: 'flex',
                        backgroundColor: '#f0f0f0',
                        margin: '0 5rem 0 0rem',
                    }}
                >
                    {header.map((e) => {
                        return (
                            <div
                                onClick={() => {
                                    setTranslateHeader(e.lang)
                                    setLanguage(e.lang)
                                }}
                                style={{
                                    ...style,
                                    backgroundColor:
                                        translateHeader === e.lang
                                            ? 'white'
                                            : 'transparent',
                                    border:
                                        translateHeader === e.lang
                                            ? 'solid lightblue 1px '
                                            : 'none',
                                }}
                            >
                                <h6>{e.title}</h6>
                                <div>{'( ' + e.lang + ' )'}</div>
                            </div>
                        )
                    })}
                </div>
                <hr style={{ margin: '0 0 .5rem 0' }} />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingRight: '12rem',
                    }}
                >
                    <div>
                        <label>Language : </label>{' '}
                        <span style={text}>{translateHeader}</span>
                    </div>
                    <div>
                        <label>Horoscope Type : </label>
                        <span style={text}> {horoscopeType}</span>
                    </div>
                    <div>
                        <label>Year : </label> <span style={text}> {year}</span>
                    </div>
                    {horoscopeType === 'Daily Horoscope' && (
                        <div>
                            <label>Month : </label>{' '}
                            <span style={text}>{month}</span>
                        </div>
                    )}

                    {horoscopeType === 'Daily Horoscope' ||
                    horoscopeType === 'Weekly Horoscope' ? (
                        <div>
                            <label>Date : </label>{' '}
                            <span style={text}>{date} </span>
                        </div>
                    ) : horoscopeType === 'Monthly Horoscope' ? (
                        <div>
                            <label>Month : </label>{' '}
                            <span style={text}>{date} </span>
                        </div>
                    ) : null}
                </div>
                <SelectHoroscope
                    title={title}
                    language={selectedLanguage}
                    setHoroscopeName_={setHoroscopeName_}
                />
                <Layout
                    style={{ height: '19rem', backgroundColor: 'transparent' }}
                >
                    <Content style={{ height: '500px', overflowY: 'auto' }}>
                        <div style={{ marginTop: '2rem' }}>
                            <div style={{ display: 'flex' }}>
                                <HoroscopeTextArea
                                    value={general}
                                    onchange={onchangeGeneral}
                                    title={'General'}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <HoroscopeTextArea
                                    title={'Health'}
                                    onchange={onchangeHealth}
                                    value={health}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <HoroscopeTextArea
                                    title={'Finance'}
                                    value={finance}
                                    onchange={onchangeFinance}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <HoroscopeTextArea
                                    title={'Job/Career'}
                                    value={jobCareer}
                                    onchange={onchangeJob}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <HoroscopeTextArea
                                  
                                    title={'Family/Love'}
                                    value={familyLove}
                                    onchange={onchangeFamily}
                                />
                            </div>
                        </div>
                    </Content>
                </Layout>
                <div style={{ marginLeft: '8rem' }}>
                    <Button
                        onClick={() => {
                            handleSubmit()
                        }}
                        type="primary"
                        style={{
                            marginRight: '2rem',
                            padding: '0 2rem 0 2rem',
                        }}
                        disabled={
                            horoscopePermission.includes('Can Add') &&
                            health &&
                            finance &&
                            jobCareer &&
                            general &&
                            familyLove
                                ? true
                                : false
                        }
                    >
                        Draft
                    </Button>
                    <Button
                        
                        style={{
                            marginRight: '2rem',
                            padding: '0 2rem 0 2rem',
                        }}
                        onClick={() => {
                            handleSubmit()
                        }}
                        
                        type="primary"
                        disabled={
                            horoscopePermission.includes('Can Add') &&
                            health &&
                            finance &&
                            jobCareer &&
                            general &&
                            familyLove
                                ? false
                                : true
                        }
                    >
                        Save
                    </Button>
                
                     
                    <TranslateModal horoscopeType={horoscopeType} horoscopeName={horoscopeName_} arraay={lamguageArray} horoscopeData={horoscopeData} date={date} setTranslateHeader={setTranslateHeader} setLanguage={setLanguage}  currentLanguage={translateHeader} general={general} setGeneral={setGeneral} familyLove={familyLove} setFamilyLove={setFamilyLove} finance={finance} setFinance={setFinance} health={health} setHealth={setHealth} jobCareer={jobCareer} setJobCareer={setJobCareer}/>
            
                </div>
            </Modal>
        </>
    )
}
export default AddHoroscopeModal
