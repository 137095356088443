import { Button, Form, Input, Modal, Select } from 'antd'
import './ReviewContentForm.scss'
const RejectModal = ({
    isModalOpen,
    handleSave,reason,
    setIsModalOpen,setRejectionReason,setShowCustomReasonInput,
   setIsDisabled,showCustomReasonInput,handleRejectionChanges,rejectionReasonData,rejectionReasonError,setReason
}) => {
    const [form] = Form.useForm()
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        setIsDisabled(false)
        setReason('')
        setRejectionReason('')
        setShowCustomReasonInput(false)
        form.setFieldValue("rejectionReason",undefined)
        form.setFieldValue("Custom Rejection Reason",undefined)

    }
    return (
        <div>
            <Modal
                title="Rejected Reason"
                footer={null}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
            >
                <hr></hr>
                <Form 
                 form={form}
                 scrollToFirstError
                 labelWrap
                 labelCol={{ span: 8 }}
                 wrapperCol={{ span: 18 }}
                //  onFinish={onFinish}
                >
                <Form.Item
                    label="Rejection Reason"
                    name="rejectionReason"
                    rules={[
                        {
                            required: !showCustomReasonInput,
                            message: 'Please select a rejection reason',
                        },
                    ]}
                >
                    <Select placeholder="Select Rejected Reason" onChange={handleRejectionChanges}>
                        {rejectionReasonData?.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                        <Select.Option value="Custom Reason">
                            Custom Reason
                        </Select.Option>
                    </Select>
                    {rejectionReasonError && (
                        <div className="error-card">
                            Rejection Reason is mandatory
                        </div>
                    )}
                </Form.Item>
                {showCustomReasonInput && (
                    <Form.Item
                        label="Custom Rejection Reason"
                        name="Custom Rejection Reason"
                        rules={[
                            {
                                required: true,
                                whitespace:true,
                                message:
                                    'Please provide a custom rejection reason',
                            },
                        ]}
                    >
                        <Input placeholder='Please Enter Custom Reason' onChange={(e) => setReason(e.target.value)} />
                    </Form.Item>
                )}
                <Form.Item style={{marginLeft:"35%"}} >
                    <Button onClick={handleCancel} style={{marginRight:"1rem"}} ghost type='primary'>cancel</Button>
                    <Button  htmlType="submit" type='primary'  onClick={() =>(reason!==undefined && reason!=='' && reason!==null ) | (!showCustomReasonInput) && handleSave('rejected')} style={{padding:"0 2rem"}}>Reject</Button>
                </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
export default RejectModal
