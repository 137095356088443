export const FREQUENCY_OPTIONS = [
    { label: 'Daily', value: 'daily', key: 1 },
    { label: 'Weekly', value: 'weekly', key: 2 },
    { label: 'Monthly', value: 'monthly', key: 3 },
    { label: 'Date Specific', value: 'date_specific', key: 4 },
    { label: 'Date Range', value: 'date_range', key: 5 },
]

export const WEEKDAY_OPTIONS = [
    { label: 'Monday', value: 1, key: 1 },
    { label: 'Tuesday', value: 2, key: 2 },
    { label: 'Wednesday', value: 3, key: 3 },
    { label: 'Thursday', value: 4, key: 4 },
    { label: 'Friday', value: 5, key: 5 },
    { label: 'Saturday', value: 6, key: 6 },
    { label: 'Sunday', value: 7, key: 7 },
]

export const DATE_OPTIONS = Array.from({ length: 31 }, (_, i) => ({
    label: (i + 1).toString(),
    value: (i + 1).toString(),
    key: i + 1,
}))
