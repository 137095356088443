import { Select, message, DatePicker, Table, Spin, Modal, Button } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getReportersReport } from '../../AadhanApi'
import './districtReport.scss'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
const DistrictsReport = () => {
    const accessToken = useSelector((state) => state.app.accessToken)
    const allDistricts = useSelector((state) => state.app.districts)
    const [dataLoading, setDataLoading] = useState(false)
    const statesData = useSelector((state) => state.app.locations)
    const [dates, setDates] = useState({
        startDate: dayjs()
            .subtract(1, 'day')
            .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const [districtsReporterData, setDistrictsReporterData] = useState([])
    const [ReportersData, setReportersData] = useState([])
    const [selectedDistrictName, setSelectedDistrictName] = useState('')
    const [selectedStateKey, setSelectedStateKey] = useState(null)
    const [messageApi, contextHolder] = message.useMessage()
    const [approved, setApproved] = useState(0)
    const [rejected, setRejected] = useState(0)
    const [submitted, setSubmitted] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalReporters, setTotalReporters] = useState(0)

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }

    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
    }
    const getStatesDetails = async () => {
        setSelectedStateKey(statesData?.[1]?.state_id)
        setDistrictsReporterData(allDistricts?.[statesData?.[1]?.state_id])
    }
    useEffect(() => {
        getStatesDetails()
    }, [statesData])

    useEffect(() => {
        setReportersData([])
        setApproved(0)
        setRejected(0)
        setSubmitted(0)
        setTotalReporters(0)
        setTotal(0)
        if (selectedStateKey) {
            getDistrictsContent()
        }
    }, [selectedStateKey])
    useEffect(() => {
        onClick()
    }, [dates])
    const onClick = async () => {
        setReportersData([])
        setApproved(0)
        setRejected(0)
        setSubmitted(0)
        setTotal(0)
        setTotalReporters(0)
        if (selectedDistrictName !== '') {
            await getReportersContent(
                selectedDistrictName,
                dates?.startDate,
                dates?.endDate
            )
        }
    }
    const handleDistrictClick = (district) => {
        setSelectedDistrictName(district)
    }
    function processResponses(responses) {
        return new Promise((resolve, reject) => {
            let countApproved = 0
            let countRejected = 0
            let countSubmitted = 0
            let total = 0

            try {
                responses.data.data.forEach((e) => {
                    countApproved += e.approved_contents
                    countSubmitted += e.submitted_contents
                    countRejected += e.rejected_contents
                    total += e.total_contents
                })

                // Assuming setApproved, setSubmitted, and setRejected are asynchronous functions
                setApproved(countApproved)
                setSubmitted(countSubmitted)
                setRejected(countRejected)
                setTotal(total)

                resolve({
                    approved: countApproved,
                    submitted: countSubmitted,
                    rejected: countRejected,
                })
            } catch (error) {
                reject(error)
            }
        })
    }
    const getReportersContent = async (district, start_date, end_date) => {
        const startDateFormat = dayjs(start_date).format('YYYY-MM-DD')
        const endDateFormat = dayjs(end_date).format('YYYY-MM-DD')
        setDataLoading(true)
        setReportersData([])
        try {
            const responses = await getReportersReport(
                district,
                startDateFormat,
                endDateFormat,
                accessToken
            )

            setTotalReporters(responses?.data.data.length)
            processResponses(responses)
                .then((counts) => {})
                .catch((error) => {
                    console.error('Error:', error)
                })
            setReportersData(responses.data.data)
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        } finally {
            setDataLoading(false)
        }
    }
    const getDistrictsContent = async () => {
        setDistrictsReporterData([])
        try {
            setDistrictsReporterData(allDistricts?.[selectedStateKey])
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get district',
                duration: 5,
            })
        } finally {
            // setDataLoading(false)
        }
    }

    const reporter_columns = [
        {
            title: 'Reporter #' + totalReporters,
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <span style={{ fontSize: '18px' }}>{record.name}</span>
            ),
        },
        {
            title: 'Total #' + total,
            dataIndex: 'total_contents',
            key: 'total_contents',
            render: (_, record) => (
                <span style={{ fontSize: '18px' }}>
                    {record.total_contents}
                </span>
            ),
        },
        {
            title: 'Submitted #' + submitted,
            dataIndex: 'submitted_contents',
            key: 'submitted_cotents',
            render: (_, record) => (
                <span style={{ fontSize: '18px' }}>
                    {record.submitted_contents}
                </span>
            ),
        },
        {
            title: 'Rejected #' + rejected,
            dataIndex: 'rejected_contents',
            key: 'rejected_contents',
            render: (_, record) => (
                <span style={{ fontSize: '18px' }}>
                    {record.rejected_contents}
                </span>
            ),
        },
        {
            title: 'Approved #' + approved,
            dataIndex: 'approved_contents',
            key: 'approved_contents',
            sorter: (a, b) => a.approved_contents - b.approved_contents,
            defaultSortOrder: 'ascend',
            render: (_, record) => {
                let color = 'inherit'
                if (record.approved_contents >= 5) {
                    color = 'green'
                } else if (record.approved_contents < 5) {
                    color = 'red'
                }
                return (
                    <span style={{ color: color, fontSize: '18px' }}>
                        {record?.approved_contents}
                    </span>
                )
            },
        },
        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            style: { fontSize: '18px' },
            render: (_, record) => (
                <span style={{ fontSize: '18px' }}>{5}</span>
            ),
        },
    ]

    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Districts Report</h2>
            </div>
            <div className="manage-content-inputs">
                <Select
                    defaultValue={'Andhra Pradesh'}
                    onChange={(e) => {
                        setSelectedStateKey(e)
                        setSelectedDistrictName('')
                    }}
                    style={{ width: 200, marginRight: '10px' }}
                >
                    {statesData.map((state) => (
                        <Select.Option
                            key={state.state_id}
                            value={state.state_id}
                        >
                            {state.name}
                        </Select.Option>
                    ))}
                </Select>
                {districtsReporterData?.length !== 0 ? (
                    <Select
                        placeholder="select district"
                        onChange={(e) => {
                            handleDistrictClick(e)
                        }}
                        style={{ width: 200, marginRight: '10px' }}
                    >
                        {districtsReporterData.map((e) => (
                            <Select.Option key={e.name} value={e.name}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    <Select
                        placeholder="select district"
                        style={{ width: 200, marginRight: '10px' }}
                        value={null}
                    ></Select>
                )}
                <Button type="primary" onClick={() => onClick()}>
                    Apply
                </Button>
                <div className="date-picker-container">
                    <span style={{ marginRight: '5px' }}>Date Range :</span>
                    <ManageContentDateRange
                        dates={dates}
                        addDates={addDates}
                        clearDates={clearDates}
                    />
                </div>
            </div>
            <div
                className="tables-container"
                style={{
                    display: 'flex',
                    marginTop: '7px',
                    marginBottom: '50px',
                }}
            >
                <div
                    style={{
                        marginRight: '20px',
                        width: '100%',
                        overflowY: 'auto',
                    }}
                >
                    <Spin spinning={dataLoading}>
                        <Table
                            columns={reporter_columns.map((column) => ({
                                ...column,
                                align: 'center',
                            }))}
                            dataSource={ReportersData}
                            pagination={false}
                        />
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default DistrictsReport
