import React, { useEffect, useState } from 'react'
import {Select, Button, message } from 'antd'
import './Permissions.scss'
import {
    getPermissionWithRoleId,
    getRoleList,
    updatePermission,
} from '../../AadhanApi'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import PermissionTable from './PermissionTable'

const ManagePermissions = () => {
    const [selectedOption, setSelectedOption] = useState('')
    const [availableRole, setAvailableRoles] = useState([])
    const employeeId = useSelector((state) => state.app.employeeId)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [loading,setLoading] = useState(false)
    const { Option } = Select
    const [data, setData] = useState([])

    useEffect(() => {
        if (availableRole.length > 0) {
            setSelectedOption(availableRole[0].role_id)
        }
    }, [availableRole])

    useEffect(() => {
        try {
            getPermissionTableData()
        } catch (err) {
            console.log('err')
        }
    }, [selectedOption])

    const getPermissionTableData = async () => {
        setLoading(true)
        if (selectedOption && accessToken) {
            const response = await getPermissionWithRoleId(
                selectedOption,
                accessToken
            )
            let arr = []
            Object.keys(response.data.permission_menu).forEach(
                (menuSection) => {
                    let temp=[];
                    Object.keys(
                        response?.data?.permission_menu?.[menuSection]
                    ).forEach((menu, index) => {
                        const menuSectionObj =
                            response?.data?.permission_menu?.[menuSection]
                        temp.push({
                            key: menu,
                            menu: menuSection,
                            ...getPermissionObject(menuSectionObj?.[menu]),
                        })
                    })
                    arr.push({key: menuSection,
                        menu: menuSection,
                        title:true,
                        permissions: temp})
                }
            )
            setData(arr)
            setLoading(false)
        }
    }
    const getPermissionObject = (permissionArr) => {
        const permissions = Array.isArray(permissionArr) ? permissionArr : [];    
        return {
            canAdd: permissions.includes('Can Add'),
            canEdit: permissions.includes('Can Edit'),
            canView: permissions.includes('Can View'),
            canDelete: permissions.includes('Can Delete'),
            canApprove: permissions.includes('Can Approve'),
        };
    };
    
    useEffect(() => {
        getAvailableRoles()
    }, [])
    const getAvailableRoles = async () => {
        const roleListResponse = await getRoleList(accessToken)
        setAvailableRoles(roleListResponse?.data)
    }
    const getPermissionsForPayload = () => {
        let obj={};
        data.map((e)=>{
            obj[e?.menu] = {};
            e?.permissions?.map((row)=>{
            let arr = [];
                row.canAdd && arr.push('Can Add')
                row.canEdit && arr.push('Can Edit')
                row.canView && arr.push('Can View')
                row.canDelete && arr.push('Can Delete')
                row.canApprove && arr.push('Can Approve')
                obj[e?.menu][row?.key] = arr
            }) 
        })
        return obj
    }
  

    const createobj = () => {
        return {
            permission_menu: getPermissionsForPayload(),
            role_id: selectedOption,
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
    }

    const handleSavePermission = async () => {
        try {
            const response = await updatePermission(
                createobj(),
                accessToken,
                selectedOption
            )
            if (response.status === 200) {
                getPermissionTableData()
                messageApi.open({
                    type: 'success',
                    content: 'Permissions Saved Succesfully',
                    duration: 5,
                })
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to Save Permissions',
                duration: 5,
            })
        }
    }

    const handleCheckboxChange = (menu, key, columnName, checked) => {
        setData((prevData) =>
          prevData.map((record) => {
            if (record.menu === menu) {
              const updatedPermissions = record.permissions.map((perm) => {
                if (perm.key === key && columnName!=='all') {
                  return { ...perm, [columnName]: checked };
                }
                if(columnName==='all' && perm.key === key){
                  return {...perm,canAdd:checked,canEdit:checked,canView:checked,canDelete:checked,canApprove:checked}
                }
                return perm;
              });
              return { ...record, permissions: updatedPermissions };
            }
            return record;
          })
        );
      };
      
    const handleSelectChange = (value) => {
        setSelectedOption(value)
    }
    return (
        <div className="manage-permissions-container">
           <div className='header_permission'>
           <h3>Manage Permissions</h3>
            {contextHolder}
            <Select
                value={selectedOption}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleSelectChange}
                style={{ width: '12rem'}}
            >
                {availableRole.map((role) => (
                    <Option key={role?.role_id} value={role.role_id}>
                        {role.role_name}
                    </Option>
                ))}
            </Select>
           </div>
            <PermissionTable loading={loading} data={data} handleCheckboxChange={handleCheckboxChange}/>
           <div style={{display:"flex",justifyContent:"center"}}>
           <Button
                style={{margin:"1.5rem 0 1.5rem 0"}}
                onClick={handleSavePermission}
                type="primary"
            >
                Save Permissions
            </Button>
           </div>
        </div>
    )
}
export default ManagePermissions
                                                                                                                                                                                                                                                
