import { Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import {
    updateAssemblyConstituencies,
    updateState,
    updateDistrict,
    updateMandal,
    getStates,
} from '../../AadhanApi'
import { addLocations } from '../../store/slice/AppSlice'
import { useDispatch } from 'react-redux'
import { Option } from 'antd/es/mentions'

const EditLocation = ({
    selectedRole,
    getTableData,
    setShowModal,
    accessToken,
    selectedLocation,
    selectedDistrictKey,
    selectedMandalKey,
    selectedStateKey,
    selectedAssemblyConstituencyKey,
    selectedRecord,
    districtsData,
}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const onFinish = async (values) => {
        if (selectedRole) {
            // await updateRole("obj", accessToken, selectedRole.id)
        } else {
            if (selectedLocation === 'Assembly') {
                await updateAssemblyConstituencies(
                    values.name,
                    values.local_name,
                    selectedAssemblyConstituencyKey,
                    accessToken,
                    values.district_id
                )
            } else if (selectedLocation === 'State') {
                await updateState(
                    values.name,
                    values.local_name,
                    selectedStateKey,
                    accessToken
                )
            } else if (selectedLocation === 'District') {
                await updateDistrict(
                    values.name,
                    values.local_name,
                    selectedDistrictKey,
                    accessToken
                )
            } else if (selectedLocation === 'Mandal') {
                await updateMandal(
                    values.name,
                    values.local_name,
                    selectedMandalKey,
                    accessToken
                )
            }
        }
        const allLocationResponse = await getStates(accessToken)
        await dispatch(addLocations(allLocationResponse?.data))
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedRecord) {
            form.setFieldsValue({
                name: selectedRecord?.name,
                local_name: selectedRecord?.local_name,
                district_id: selectedRecord?.district_id,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRecord])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    return (
        <Form
            id="roleForm"
            form={form}
            name="roleForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 24 }}
                label={selectedLocation}
                name="name"
                rules={[{ required: true, message: 'Please enter name' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 24 }}
                label="Local Name"
                name="local_name"
                rules={[{ required: false, message: 'Please enter name' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 24 }}
                label="District"
                name="district_id"
                rules={[{ required: false, message: 'please select district' }]}
            >
                <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.children ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    placeholder="Select District"
                >
                    {districtsData.map((option) => (
                        <Option
                            key={option?.district_id}
                            value={option?.district_id}
                        >
                            {option?.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    )
}

export default EditLocation
