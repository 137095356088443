// import React from 'react';
// import { Card, Row, Col, Statistic, Typography } from 'antd';
// import { ClockCircleOutlined } from '@ant-design/icons';
// import './NotificationStatsCard.scss';

// const { Title } = Typography;

// const NotificationStatsCard = () => {
//   return (
//     <div className="notification-stats-wrapper">
//       <Title level={5} className="notification-stats-title">
//         Total Manual Notifications Sent
//       </Title>
//       <Card bodyStyle={{ padding: 0 }} className="notification-stats-card">
//         <Row gutter={0}>
//           <Col span={12} className="stats-column stats-column-left">
//             <Statistic
//               title="Yesterday"
//               value={45}
//               valueStyle={{ color: '#000000' }}
//             />
//           </Col>
//           <Col span={12} className="stats-column stats-column-right">
//             <Statistic
//               title="Today"
//               value={50}
//               valueStyle={{ color: '#3f8600' }}
//             />
//             <div className="time-display">
//               <ClockCircleOutlined className="clock-icon" />
//               <span className="time-text">11:00 AM</span>
//             </div>
//           </Col>
//         </Row>
//       </Card>
//     </div>
//   );
// };

// export default NotificationStatsCard;

import React from 'react';
import { Card, Row, Col, Statistic, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import './NotificationStatsCard.scss';
const { Title } = Typography;

const NotificationStatsCard = () => {
  return (
    <div className='notification-stats-wrapper'>
      <Title level={5} style={{ textAlign: 'center', marginBottom: 16 }}>
        Total Manual Notifications Sent
      </Title>
      <Card bodyStyle={{ padding: 0 }}>
        <Row gutter={0}>
          <Col span={12} style={{ borderRight: '1px solid #f0f0f0' }}>
            <Statistic
              title="Yesterday"
              value={45}
              valueStyle={{ color: '#000000' }}
              style={{ padding: '16px' }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Today"
              value={50}
              valueStyle={{ color: '#3f8600' }}
              style={{ padding: '16px' }}
            />
            <div style={{ textAlign: 'center', marginTop: -16, marginBottom: 8 }}>
              <ClockCircleOutlined style={{ marginRight: 4 }} />
              <span style={{ color: '#8c8c8c', fontSize: 12 }}>11:00 AM</span>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default NotificationStatsCard;

// import { useState, useEffect } from 'react'
// import './NotificationStatsCard.scss'

// const NotificationStatsCard = () => {
//     return (
//         <div className='notification-stats-container'>
//             <p>Total Manual Notifications Sent</p>

//             <div className='stats-card' >
//                 <div className='column'>
//                     <p className='label'>Yesterday</p>
//                     <p className='value'>45</p>

//                 </div>

//                 {/* <div style={{ width: 1, height: "80%", backgroundColor: "#ccc" }}></div> */}

//                 <div className='column'>
//                     <p className='label'>Today</p>
//                     <p className='value green'>50</p>
//                     <p class="time">11:00 AM</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default NotificationStatsCard