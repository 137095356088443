import { Table, Checkbox } from 'antd';
import "./Permissions.scss"

const PermissionTable = ({data,handleCheckboxChange,loading}) => {
  
  const columns = [
    {
      title: 'Menu',
      dataIndex: 'menu',
      key: 'menu',
      width:200,
      render:(_,record)=>{return <div style={{display:"flex",marginLeft:!record?.title ? "19%" : '',padding:record?.title===true ? "12px 0" :'5px 0'}}>
      
      <div style={{textAlign:"start"}}>{record.title===true ? <></> : <><Checkbox className={record?.canApprove && record?.canDelete && record?.canEdit && record?.canView && record?.canAdd ? 'check_box' : "check_box_before"}
          checked={record?.canApprove && record?.canDelete && record?.canEdit && record?.canView && record?.canAdd}
          onChange={(e) => handleCheckboxChange(record.menu, record.key, 'all', e.target.checked)}
        /></>}</div>
      <div className={record?.title?'menu_permission':'menu_row'}>{record?.title ? record?.menu : record?.key}</div>  
        
      </div>
      }
    },
    {
      title: 'Can Add',
      dataIndex: 'canAdd',
      key: 'canAdd',
      width:50,
      render: (text, record) => (
        <div>{record.title===true ? <></> :<><Checkbox className={record?.canAdd ? 'check_box' :'check_box_before'}
          checked={record.canAdd}
          onChange={(e) => handleCheckboxChange(record.menu, record.key, 'canAdd', e.target.checked)}
        /></>}</div>
      ),
    },
    {
      title: 'Can View',
      dataIndex: 'canView',
      key: 'canView',
      width:50,
      render: (text, record) => (
      <div>{record?.title===true ? <></> :<div> <Checkbox className={record?.canView ? 'check_box' : 'check_box_before'}
        checked={record.canView}
        onChange={(e) => handleCheckboxChange(record.menu, record.key, 'canView', e.target.checked)}
      /></div>}</div>
      ),
    },
    {
      title: 'Can Edit',
      dataIndex: 'canEdit',
      key: 'canEdit',
      width:50,
      render: (text, record) => (
        <div>{record?.title===true ? <></> :<><Checkbox className={record?.canEdit ? 'check_box' : 'check_box_before'}
          checked={record.canEdit}
          onChange={(e) => handleCheckboxChange(record.menu, record.key, 'canEdit', e.target.checked)}
        /></>}</div>
        ),
    },
    {
      title: 'Can Delete',
      dataIndex: 'canDelete',
      key: 'canDelete',
      width:50,
      render: (text, record) => (
        <div style={{textAlign:"start"}}>{record.title===true ? <></> : <><Checkbox className={record?.canDelete ? 'check_box' :"check_box_before"}
          checked={record.canDelete}
          onChange={(e) => handleCheckboxChange(record.menu, record.key, 'canDelete', e.target.checked)}
        /></>}</div>
        ),
    },
    {
      title: 'Can Approve',
      dataIndex: 'canApprove',
      key: 'canApprove',
      width:50,
      render: (text, record) => (
        <div style={{textAlign:"start"}}>{record.title===true ? <></> : <><Checkbox className={record?.canApprove ? 'check_box' :"check_box_before"}
          checked={record.canApprove}
          onChange={(e) => handleCheckboxChange(record.menu, record.key, 'canApprove', e.target.checked)}
        /></>}</div>
        ),
    },

  ];
  return (
    <Table
      className='expandTable'
      columns={columns}
      dataSource={data}
      loading={loading}
      rowKey="key"
      expandable={{
        expandedRowRender: (record) => (
          <Table
            className='expandTable inner'
            columns={columns}
            loading={loading}
            dataSource={record.permissions}
            rowKey="key"
            pagination={false}
            showHeader={false} // Hide header of the inner table
            size="small"
            onRow={(record) => ({ index: record.key, category: record.menu })}
          />
        ),
        expandRowByClick: true, // Expands the row by clicking anywhere
        rowExpandable: (record) => record.permissions.length > 0,
      }}
      pagination={false}
    />
  );
};

export default PermissionTable;
