import {
    Form,
    Input,
    Radio,
    Select,
    message,
    Switch,
    Button,
    Modal,
    Upload,
} from 'antd'
import { addNewsSection, getImageUrlR2, updateNewsSection } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import './NewsSection.scss'
import { DeleteFilled, EditOutlined, UploadOutlined } from '@ant-design/icons'

const NewsSectionForm = ({
    accessToken,
    getNewsSectionTable,
    language,
    mode,
    record
}) => {
    const [form] = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const languageData = useSelector((state) => state.app.languageData)
    const [messageApi, contextHolder] = message.useMessage()
    const employeeId = useSelector((state) => state.app.employeeId)
    const [imageUrl, setImageUrl] = useState(null) // State to hold image URL

    useEffect(() => {
        if (mode === 'edit') {
            form.setFieldsValue({
                news_section_id: record?.category_id,
                news_section_name: record?.category_name,
                language: record?.language_id,
                status: record?.status,
                display_name: record?.display_name,
                target: record?.target,
                showin_app: record?.displayin_app,
            })
            setImageUrl(record?.image_url) // Set image URL when in edit mode
        } else {
            form.setFieldsValue({
                language: language,
                status: 'inactive',
                showin_app: false,
                target: 0
            })
            setImageUrl(null) // Reset image URL when in add mode
        }
    }, [isModalOpen])

    const onFinish = async (values) => {
        let obj = {}
        if (mode === 'add') {
            obj = {
                category_name: values?.['news_section_name'],
                displayin_app: values?.showin_app,
                target: values?.target,
                created_by: employeeId,
                updated_by: employeeId,
                language_id: values?.language,
                status: values?.status,
                display_name: values?.display_name,
                image_url: imageUrl, // Add image URL to the payload
            }
        } else {
            obj = {
                category_id: record?.['category_id'],
                category_name: values?.['news_section_name'],
                displayin_app: values?.showin_app,
                target: values?.target,
                created_by: record?.created_by,
                updated_by: employeeId,
                language_id: values?.language,
                status: values?.status,
                display_name: values?.display_name,
                image_url: imageUrl, // Add image URL to the payload
            }
        }
        try {
            if (mode === 'edit') {
                const response = await updateNewsSection(accessToken, obj, record?.category_id)
                if (response?.data?.success === true) {
                    message.success("News section updated successfully")
                } else {
                    message.error("Failed to update news section")
                }
            } else {
                const response = await addNewsSection(accessToken, obj)
                if (response?.data?.success === true) {
                    message.success("News section created successfully")
                } else {
                    message.error("Failed to create news section")
                }
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }
        setIsModalOpen(false)
        getNewsSectionTable()
        form.resetFields()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const draggerProps = {
        name: 'file',
        customRequest: ({ file, onSuccess }) => {
            getImageUrlR2(file, accessToken, 'category').then((pathUriArr) => {
                if (pathUriArr?.data) {
                    const path = pathUriArr?.data
                    setImageUrl(path) // Update image URL on successful upload
                    onSuccess(path)
                }
            })
        },
    }

    const handleRemoveImage = () => {
        setImageUrl(null) // Reset the image URL when removing the image
    }

    return (
        <div className="news_btn">
            {contextHolder}
            {mode === 'add' ? <Button type="primary" onClick={showModal}>
                Add News Section
            </Button> : <EditOutlined onClick={showModal} />}
            <Modal
                title={mode === 'add' ? "Add News Section" : "Edit News Section"}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null} // No footer to allow custom buttons
            >
                <Form
                    id="news-section-form"
                    form={form}
                    name="news-section-form"
                    scrollToFirstError
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Language"
                        name="language"
                        rules={[{
                            required: true,
                            message: 'Please select Language',
                        }]}
                    >
                        <Select placeholder="--Select Language--">
                            {languageData?.map((option) => (
                                <Select.Option
                                    key={option.language_id}
                                    value={option.language_id}
                                >
                                    {option.language_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="News Section Name"
                        name="news_section_name"
                        rules={[{
                            required: true,
                            message: 'Please enter news section name',
                        }]}
                    >
                        <Input placeholder="News section name" />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Display Name"
                        name="display_name"
                        rules={[{
                            required: true,
                            message: 'Please enter display name',
                        }]}
                    >
                        <Input placeholder="Display name" />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Image"
                        name="image"
                        rules={[{ required:!imageUrl ? true : false, message: 'Please upload image' }]}
                    >
                        <Upload {...draggerProps} className='news_image'>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                            <div style={{ color: '#199be9' }}>
                                image size as 144*144
                            </div>
                        </Upload>
                        {imageUrl && (
                            <div style={{ marginTop: '1rem' ,display:"flex",alignItems:"center"}}>
                                <img
                                    src={imageUrl}
                                    alt="uploaded"
                                    style={{ width: '100px', height: '100px', borderRadius: 5, overflow: "hidden" }}
                                />
                                <div
                                    style={{color:"red",cursor:"pointer"}}
                                    onClick={handleRemoveImage}
                                >
                                    <DeleteFilled/>
                                </div>
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Input placeholder='Image url' value={imageUrl} onChange={(e)=>setImageUrl(e.target.value)} />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Target"
                        name="target"
                    >
                        <Input placeholder="Target" type="number" />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Show in app"
                        name="showin_app"
                    >
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Status"
                        required
                        name="status"
                        labelCol={{ span: 70 }}
                    >
                        <Radio.Group>
                            <Radio value="active">Active</Radio>
                            <Radio value="inactive">Inactive</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                        <div style={{ padding: '1rem 0', display: "flex", justifyContent: "flex-end" }}>
                            <Button ghost type='primary' key="close" onClick={handleCancel}>
                                Close
                            </Button>
                            <Button
                                style={{ marginLeft: '1rem' }}
                                key="submit"
                                htmlType="submit"
                                type="primary"
                            >
                                {mode === 'add' ? "Add " : "Update"}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default NewsSectionForm
