import React, { useEffect, useState } from 'react'
import { Input, Select, Table, Button, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { updateReporter } from '../../AadhanApi'
import { useLocation, useNavigate } from 'react-router'
import { CloseOutlined, EditOutlined , RightOutlined, SaveOutlined } from '@ant-design/icons'
const { Option } = Select

const ExcelSheetUI = ({
    data,
    dataLoading,
    allConstituencies,
    locations,
    accessToken,
    allDistricts,
    allMandals
}) => {
    const [rowObj, setRowObj] = useState({
        name: '',
        id: '',
        state_id: '',
        state_name: '',
        district_id: '',
        district_name: '',
        mandal_id: undefined,
        mandal_name: undefined,
        constituency_id: [],
        constituency_name: [],
        mobile: '',
        type:''
    })
    const [dataSource, setDataSource] = useState(data)
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const location =useLocation()
    useEffect(() => {
        setDataSource(data)
    }, [data])
    const handleInputChange = (value, option, column) => {
        if(column==='type'){
           setRowObj((pre)=>({...pre,type:value}))
           return
        }
        if (column === 'state_id') {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
                state_name: option?.children,
                district_id: undefined,
                district_name: undefined,
                mandal_id: undefined,
                mandal_name: undefined,
                constituency_id: [],
                constituency_name: [],
            }))
        } else if (column === 'district_id') {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
                district_name: option?.children,
                mandal_id: undefined,
                mandal_name: undefined,
            }))
        } else if (column === 'mandal_id') {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
                mandal_name: option?.children,
            }))
        } else if (column === 'constituency_id') {
            setRowObj((prev) => ({
                ...prev,
                mandal_id:undefined,
                mandal_name:undefined,
                [column]: value.length > 0 ? [value[value.length - 1]] : [],
                district_id:
                    option.length > 0
                        ? option[option.length - 1]['data-additional-prop']
                        : undefined,
                district_name:
                    option.length > 0
                        ? allDistricts[rowObj?.state_id].filter(
                              (e) =>
                                  e.district_id ===
                                  option[option.length - 1][
                                      'data-additional-prop'
                                  ]
                          )?.[0]?.name
                        : '',

                constituency_name:
                    option.length > 0
                        ? [option[option?.length - 1]].map((e) => e.children)
                        : [],
            }))
        } else if (column === 'mobile') {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
            }))
        } else {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
            }))
        }
    }
    const handleCancel=(record)=>{
        const key = record.user_id
        let newData = [...dataSource]
        newData = newData.map((item) => {
            if (item.user_id === key) {
                return { ...item, isEditing: false }
            } else {
                return item
            }
        })
        setDataSource(newData)
    }
    const handleEdit = (record) => {
        const key = record.user_id
        let newData = [...dataSource]
        newData = newData.map((item) => {
            if (item.user_id === key) {
                return { ...item, isEditing: true }
            } else {
                return { ...item, isEditing: false }
            }
        })
        setRowObj({
            name: record?.full_name,
            id: record?.user_id,
            state_id: record?.reporter_information?.state_id,
            state_name: record?.reporter_information?.state,
            district_id:
                record?.reporter_information?.district_id?.split(',')[0],
            district_name:
                record?.reporter_information?.district?.split(',')[0],
            mandal_id:
                record?.reporter_information?.mandal_id === ""
                    ? undefined
                    : record?.reporter_information?.mandal_id,
            mandal_name:
                record?.reporter_information?.mandal?.split(',')[0] === ''
                    ? ''
                    : record?.reporter_information?.mandal,
            constituency_id:
                record?.reporter_information?.constituency_ids?.[0] === ''
                    ? []
                    : record?.reporter_information?.constituency_ids,
            constituency_name:
                record?.reporter_information?.constituencies?.[0] === ''
                    ? []
                    : record?.reporter_information?.constituencies,
            mobile: record?.mobile_number,
            type:record?.stringer_type
        })

        setDataSource(newData)
    }
    const handleSave = async (key) => {
        let obj = key
        obj.full_name = rowObj?.name
        obj.reporter_information.state = rowObj?.state_name
        obj.reporter_information.state_id = rowObj?.state_id
        obj.reporter_information.district = rowObj?.district_name
        obj.reporter_information.district_id = rowObj?.district_id
        obj.reporter_information.mandal=rowObj?.mandal_name;
        obj.reporter_information.mandal_id=rowObj?.mandal_id;
        obj.reporter_information.constituency_ids = rowObj?.constituency_id
        obj.reporter_information.constituencies = rowObj?.constituency_name
        obj.stringer_type=rowObj?.type
        obj.mobile_number = rowObj?.mobile
        const stringerResponse = await updateReporter(
            obj,
            obj.user_id,
            accessToken
        )
        let newData = [...dataSource]
        newData = newData.map((item) => {
            return { ...item, isEditing: false }
        })
        setDataSource(newData)

        if (stringerResponse.data.status === 'success') {
            messageApi.open({
                type: 'success',
                content: 'Reporter information updated successfully',
                duration: 5,
            })
        } else {
            messageApi.open({
                type: 'error',
                content: 'Failed to update reporter',
                duration: 5,
            })
        }
    }
    const handleRowDoubleClick = (key) => {
        handleEdit(key)
    }
    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <TextArea
                            autoSize
                            value={rowObj?.name}
                            onChange={(e) =>
                                handleInputChange(e.target.value, '', 'name')
                            }
                            disabled
                        />
                    ) : (
                        <div
                            style={{
                                padding: '3px 4px',
                                color: 'Blue',
                                fontSize: '14px',
                                fontWeight:"500"
                            }}
                        >
                            {record?.full_name}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'State',
            dataIndex: 'state',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Select
                            placeholder="Select State"
                            value={rowObj?.state_id}
                            onChange={(value, Option) =>
                                handleInputChange(value, Option, 'state_id')
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 150 }}
                        >
                            {locations?.map((e) => (
                                <Option value={e.state_id} key={e.state_id}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div>

                        {record?.reporter_information?.state_id && 
                            <div
                            style={{
                                padding: '3px 6px',
                                    color: '#333',
                                    fontSize: '14px',
                                    margin: '0 0 5px 0',
                                }}
                                >
                            {record?.reporter_information?.state}
                        </div>
                        }
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'District',
            dataIndex: 'district',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Select
                            disabled
                            placeholder=""
                            value={
                                rowObj?.district_id === undefined
                                    ? undefined
                                    : rowObj?.district_id
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value, Option) =>
                                handleInputChange(value, Option, 'district_id')
                            }
                            style={{ width: 170 }}
                        >
                            {allDistricts?.[rowObj?.state_id]?.map((e) => (
                                <Option
                                    key={e.district_id}
                                    value={e.district_id}
                                >
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div>{record?.reporter_information?.district_id && 
                            <div
                            style={{
                                padding: '3px 6px',
                                color: '#333',
                                fontSize: '14px',
                                margin: '0 0 5px 0',
                            }}
                        >
                            {record?.reporter_information?.district?.replaceAll(
                                ',',
                                ', '
                            )}
                        </div>
                        }</div>
                    )}
                </div>
            ),
        },
        {
            title: 'Assembly Constituency',
            dataIndex: 'assemblyConstituency',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Select
                            mode="multiple"
                            placeholder="select constituencies"
                            value={rowObj?.constituency_id}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value, Option) =>
                                handleInputChange(
                                    value,
                                    Option,
                                    'constituency_id'
                                )
                            }
                            style={{ width: '200px' }}
                        >
                            {allConstituencies?.[rowObj?.state_id]?.map((e) => (
                                <Option
                                    key={e.assembly_constituency_id}
                                    value={e.assembly_constituency_id}
                                    data-additional-prop={e.district_id}
                                >
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div>
                            {record?.reporter_information?.constituencies?.map(
                                (e) => (
                                    <div
                                        style={{
                                            padding: '3px 6px',
                                            color: '#333',
                                            fontSize: '14px',
                                            margin: '0 0 5px 0',
                                        }}
                                    >
                                        {e}
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Mandal',
            dataIndex: 'mandal',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Select
                            placeholder="Select Mandal"
                            value={
                                rowObj?.mandal_id === undefined
                                    ? undefined
                                    : rowObj?.mandal_id
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value, Option) =>
                                handleInputChange(value, Option, 'mandal_id')
                            }
                            style={{ width: 170 }}
                        >
                            {allMandals?.[rowObj?.district_id]?.map((e) => (
                                <Option
                                    key={e.mandal_id}
                                    value={e.mandal_id}
                                >
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div>{record?.reporter_information?.mandal_id && 
                            <div
                            style={{
                                padding: '3px 6px',
                                color: '#333',
                                fontSize: '14px',
                                margin: '0 0 5px 0',
                            }}
                        >
                            {record?.reporter_information?.mandal?.replaceAll(
                                ',',
                                ', '
                            )}
                        </div>
                        }</div>
                    )}
                </div>
            ),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Input
                            style={{ width: 110 }}
                            value={rowObj?.mobile}
                            disabled
                            onChange={(e) => {
                                const value = e.target.value.slice(0, 10) // Ensure only 10 digits
                                handleInputChange(value, '', 'mobile')
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                padding: '3px 6px',
                                color: 'green',
                                fontSize: '14px',
                                fontWeight:"500",
                                margin: '0 0 5px 0',
                            }}
                        >
                            {record?.mobile_number}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) =>
                record.isEditing ? (
                   <div style={{display:"flex"}}>
                     <Button
                        style={{marginRight:"1rem"}}
                        type="primary"
                        onClick={() => handleSave(record)}
                        icon={<SaveOutlined/>}
                    >
                    
                    </Button>
                    <Button icon={<CloseOutlined/>} onClick={()=>handleCancel(record)}></Button>
                   </div>
                ) : (
                    <div style={{display:"flex"}}><Button style={{marginRight:"1rem"}} onClick={() => handleEdit(record)} icon={<EditOutlined />}></Button>
                    <Button type='primary' onClick={()=> navigate(
                   `edit?mode=edit&reporterId=${record?.user_id}`
                    )} icon={<RightOutlined />}></Button>
                   </div>
                ),
        },
    ]
    return (
        <>
            {contextHolder}
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                loading={dataLoading}
                bordered
                style={{ marginTop: '1rem' }}
                scroll={{ x: 'max-content' }}
                onRow={(record) => ({
                    onDoubleClick: () => handleRowDoubleClick(record),
                })}
            />
        </>
    )
}

export default ExcelSheetUI
