import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NewsSectionForm from './NewsSectionForm'
import { SelectComponent } from '../AntdComponent/SelectComponent'
import { DeleteOutlined} from '@ant-design/icons'
import { message, Popconfirm } from 'antd'
import { deleteNewsSection, getNewsSections } from '../../AadhanApi'
import CommonTable from '../CommonTable/CommonTable'

const NewsSection = () => {
    const accessToken = useSelector((state) => state.app.accessToken)
    const languages = useSelector((state) => state.app.languageData)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [language, setLanguage] = useState(languages?.[0]?.language_id)
    const [status, setStatus] = useState([
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
    ])
    const [selectedStatus, setSelectedStatus] = useState('all')
    const handleLanguageChange = (language) => {
        setLanguage(language)
    }
    const handleStatusChange = (status) => {
        setSelectedStatus(status)
    }
    const getNewsSectionTable = async () => {
        setLoading(true)
        if(language){
            const data = await getNewsSections(
                language,
                selectedStatus,
                accessToken
            )
            setData(data?.data?.data)
        }
        setLoading(false)
    }
    const handleDelete = async (record) => {
        const response = await deleteNewsSection(
            accessToken,
            record?.category_id
        )
        if (response?.data?.success === true) {
            message.success('news section deleted successfully')
        } else {
            message.error('failed to delete news section')
        }
        getNewsSectionTable()
    }
    useEffect(() => {
        getNewsSectionTable()
    }, [language, selectedStatus])
    const columns = [
        {
            title: 'News Section',
            dataIndex: 'display_name',
            sorter: (a, b) =>
                a?.display_name?.localeCompare(b.display_name),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div style={{ fontSize: 16, fontWeight: 500 }}>
                    {record?.category_name}{' '}
                    <span
                        style={{ color: 'gray' }}
                    >{`( ${record?.display_name} )`}</span>
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (_, record) => (
                <div
                    style={{
                        fontWeight: 500,
                        color: record?.status === 'active' ? 'gray' : 'gray',
                    }}
                >
                    {record?.status === 'active' ? 'Active' : 'Inactive'}
                </div>
            ),
        },
        {
            title: 'Show In App',
            dataIndex: 'displayin_app',
            key: 'displayin_app',
            align: 'center',
            render: (_, record) => (
                <div
                    style={{
                        fontWeight: 500,
                        color: record?.displayin_app ? 'green' : 'red',
                    }}
                >
                    {record?.displayin_app ? 'Yes' : 'No'}
                </div>
            ),
        },

        {
            title: 'Target',
            dataIndex: 'target',
            key: 'target',
            align: 'center',
            sorter: (a, b) =>a?.target-b.target,
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div style={{ fontWeight: 500, fontSize: 16, color: 'gray' }}>
                    {record?.target}
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div
                    size="middle"
                    style={{
                        fontSize: '1.2rem',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ paddingRight: '5px' }}>
                        <NewsSectionForm
                            language={language}
                            languages={languages}
                            mode={'edit'}
                            record={record}
                            getNewsSectionTable={getNewsSectionTable}
                            accessToken={accessToken}
                        />
                    </div>
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => handleDelete(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]
    return (
        <div className="container">
            <div className="news_title">Manage News Sections</div>
            <div className="filters">
                <SelectComponent
                    data={status}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    key_value={'value'}
                    display={'label'}
                    className={'language_filter'}
                    style={{}}
                    placeholder={''}
                />
                <SelectComponent
                    data={languages}
                    value={language}
                    onChange={handleLanguageChange}
                    key_value={'language_id'}
                    display={'language_name'}
                    className={'language_filter'}
                    style={{}}
                    placeholder={''}
                />
                <NewsSectionForm
                    language={language}
                    languages={languages}
                    mode={'add'}
                    getNewsSectionTable={getNewsSectionTable}
                    accessToken={accessToken}
                />
            </div>
            <CommonTable
                columns={columns}
                className={'no-padding-table'}
                data={data}
                dataLoading={loading}
            />
        </div>
    )
}

export default NewsSection
