import { Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { postElectionData } from '../../AadhanApi'

const AddStateForm = ({
    selectedRole,
    getTableData,
    setShowModal,
    states,
    electionsData,
    accessToken
}) => {

    const [form] = Form.useForm()
    const onFinish = async (values) => {
        let obj = {
            "election_type": "assembly",
            "states": [
                ...electionsData,
                {
                    "name": values.state,
                    "total_constituencies": values.seats
                }
            ]
        }
        if (selectedRole) {
            // await updateRole("obj", accessToken, selectedRole.id)
        } else {
            await postElectionData(obj, accessToken)
        }
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedRole) {
            form.setFieldsValue({
                name: selectedRole?.role,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRole])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Form
            id="stateForm"
            form={form}
            name="stateForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="state"
                label="State Name"
                rules={[
                    {
                        required: true,
                        message: 'please select an option',
                    },
                ]}
            >
                <Select
                    placeholder="Select State"
                >
                    {states?.map((option) => (
                        <Select.Option
                            key={option.name}
                            value={option.name}
                        >
                            {option.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                labelCol={{ span: 5 }}
                label="Total Seats"
                name="seats"
                rules={[{ required: true, message: 'Please enter seats' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    )
}

export default AddStateForm;
